import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const createObjectivePlayer = async (windowId, objectiveId, player) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        properties: {
            saveGame: saveId,
            transfer_window_id: windowId,
            transfer_objective_id: objectiveId,
            ...player
        }
    };

    let response = await Axios({
        method: 'POST',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objective/players"
        ], ""),
        headers,
        data
    });

    return response;
}

export const updateObjectivePlayer = async (windowId, objectiveId, player) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        properties: {
            saveGame: saveId,
            transfer_window_id: windowId,
            transfer_objective_id: objectiveId,
            ...player
        }
    };

    let response = await Axios({
        method: 'PATCH',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objective/players"
        ], ""),
        headers,
        data
    });

    return response;
}

export const deleteObjectivePlayer = async (windowId, objectiveId, playerId) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objective/players",
            "?saveGame=", saveId, 
            "&transfer_window_id=", windowId,
            "&transfer_objective_id=", objectiveId,
            "&id=", playerId
        ], ""),
        headers
    });

    return response;
}