import Axios from "axios";
import { config, storage } from "../config/constants"
import _, { join } from 'lodash';

export const createMatch = async (matchData) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            ...matchData
        }
    }

    let response = await Axios({
        method: "POST",
        url: config.API_URL + "/save/matches",
        headers,
        data
    });

    return response;
}

export const updateMatch = (id, updatedData) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            ...updatedData,
            'id': id,
            'saveGame': saveId
        }
    };

    return Axios({
        method: 'PATCH',
        url: config.API_URL + "/save/matches",
        headers,
        data
    });
}

export const deleteMatch = async (matchId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: "DELETE",
        url: _.join([config.API_URL, "/save/matches?saveGame=", saveId, "&id=", matchId], ""),
        headers
    });

    return response;
}

export const getLatestMatches = async (page) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: "GET",
        url: _.join([config.API_URL, "/save/matches?saveGame=", saveId, "&page=", page], "")
    });

    return response;
}

export const getMatchById = async (id) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: _.join([
            config.API_URL, 
            "/save/matches/id?", 
            "saveGame=", saveId, 
            "&id=", id,
            "&extra_data=managers"
        ], ""),
        headers
    });

    return response;
}

export const getMatchesFromTeam = async (team, page) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: _.join([config.API_URL, "/save/matches/team?saveGame=", saveId, "&team=", team, "&page=", page], ""),
        headers
    });

    return response;
}

export const getMatchesFromLeague = async (league, page) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: 'GET',
        url: _.join([
            config.API_URL, 
            "/save/matches/competition?saveGame=", saveId,
            "&competition_id=", league, 
            "&page=", page], "")
    });

    return response;
}

export const getMatchesFromPlayer = async (player, page) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: _.join([
            config.API_URL,
            "/save/matches/player?saveGame=",
            saveId,
            "&player=",
            player,
            "&page=",
            page
        ], ""),
        headers
    });

    return response;
}

export const getMatchesFromManager = async (managerId, page) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET', 
        url: join([
            config.API_URL,
            "/save/manager/tenures/matches?saveGame=", saveId,
            "&manager_id=", managerId,
            "&page=", page
        ], ""),
        headers
    });

    return response;
}

export const getMatchesFromSeasonAndLeague = async (season, league, page) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/matches/season?saveGame=", saveId,
            "&season=", season, 
            "&league=", league,
            "&page=", page
        ], ""),
        headers
    });

    return response;
}

export const getMatchesBetweenTeams = async (teams) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: _.join([config.API_URL, "/save/matches/head_to_head?saveGame=", saveId, "&teamOne=", teams[0], "&teamTwo=", teams[1]], ""),
        headers
    });

    return response;
}

export const getMatchTopScorers = async (teams) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: _.join([config.API_URL, "/save/matches/top_scorers?saveGame=", saveId, "&teamOne=", teams[0], "&teamTwo=", teams[1]], ""),
        headers
    });

    return response;
}

export const getMatchAverageGoals = async (teamOne, teamTwo) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/matches/head_to_head/average_goals?saveGame=${saveId}&teamOne=${teamOne}&teamTwo=${teamTwo}`
    });
}

export const getMatchGoalDistribution = async (teamOne, teamTwo) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/matches/head_to_head/goal_distribution?saveGame=${saveId}&teamOne=${teamOne}&teamTwo=${teamTwo}`
    });
}

export const getLatestMatchInSave = async () => {
    const accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    const saveId = localStorage.getItem(storage.SAVE_ID);

    const headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    return Axios({
        method: 'GET',
        url: _.join([config.API_URL, "/save/matches/latest?saveGame=", saveId], ""),
        headers
    });
}

export const getPlayerMatchWinStats = async (player) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/player/match_wins?saveGame=${saveId}&player=${player}`
    });
}