import {errorHandlerInterceptor, networkErrorInterceptor } from "../data/interceptors";
import {api, config, storage} from "./constants";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from "axios";

export const registerInterceptors = (axios) => {
    if (config.DEBUG_REQUESTS) {
        axios.interceptors.request.use(debugInterceptor);
    }

    createAuthRefreshInterceptor(axios, refreshAccessToken);

    axios.interceptors.request.use(tokenInterceptor);

    axios.interceptors.response.use((response) => {
        errorHandlerInterceptor(response);
        return response;
    }, (error) => {
        if (!error.response) {
            networkErrorInterceptor(error);
        } else {
            errorHandlerInterceptor(error.response);
        }
        return error;
    });
}

const tokenInterceptor = (request) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    request.headers.Authorization = 'Bearer ' + accessToken;

    return request;
}

const refreshAccessToken = async (failedRequest) => {
    const refreshToken = localStorage.getItem(storage.REFRESH_TOKEN);

    if (refreshToken !== null) {
        const request = new URLSearchParams();
        request.set("grant_type", "refresh_token");
        request.set("client_id", config.CLIENT_ID);
        request.set("refresh_token", refreshToken);

        const response = await axios({
            method: 'POST',
            url: `${config.OAUTH_URL}/oauth`,
            data: request
        });

        if (!response.data.is_error) {
            localStorage.setItem(storage.ACCESS_TOKEN, response.data.access_token);
            localStorage.setItem(storage.ACCESS_TOKEN_EXPIRE_DATE, response.data.access_token_expire_date);
            localStorage.setItem(storage.REFRESH_TOKEN, response.data.refresh_token);

            failedRequest.response.config.headers["Authorization"] = `Bearer ${response.data.access_token}`;

            return Promise.resolve();
        }

        return Promise.reject();
    }
}

const debugInterceptor = (request) => {
    console.log("--- AXIOS REQUEST START ---");
    console.log(request);
    console.log("--- AXIOS END REQUEST ---");
    return request;
}
