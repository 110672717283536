import { map } from "lodash"
import { objectivePositions } from "../../config/constants"
import { Select, SelectOption } from "../generics/Forms"

export const TransferObjectivePositionSelect = ({
    value, 
    onChange,
    label
}) => {

    const renderOptions = () => {
        return map(objectivePositions, (position, key) => {
            return (
                <SelectOption key={key}
                    value={key}
                    content={position.name}/>
            )
        })
    }

    return(
        <Select value={value} label={label} onChange={onChange}>
            {renderOptions()}
        </Select>
    )
}