
/**
 * Verifies if an object has all values for all the keys required.
 * @param {*} formValues The object to test
 * @param  {...any} requiredValues The keys that need to be present in the object
 * 
 * @return {Object} with the following properties:
 *  - result: boolean - will be true if all keys are present and false if at least 1
 *  field is missing
 * - missing: Array<String> - if there are missing keys, the missing keys will be
 * listed in this array
 */
export const valuesExist = (formValues, ...requiredValues) => {
    let results = {result: true, missing: []};
    requiredValues.forEach((value) => {
        if (formValues[value] === undefined
            || formValues[value] === null
            || formValues[value] === "") {
            results.result = false;
            results.missing.push(value);
        }
    })
    return results;
}

/**
 * Sets all the properties of an object to an empty string. Which is equal to 
 * resetting the form values.
 * @param {Object} formValues The object holding the values for the form
 */
export const clearFormValues = (formValues = {}) => {
    let emptyForm = {};

    for (const property in formValues) {
        emptyForm[property] = "";
    }

    return emptyForm;
}