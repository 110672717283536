import {useEffect, useState} from "react";
import {storage} from "../../config/constants";
import {createSave, getSaves} from "../../service/saveService";
import Header from "../generics/Header";
import _, {filter, findIndex, isEmpty, map} from 'lodash';
import {Fab} from "../generics/Buttons";
import {SelectSheet} from "../generics/Tabs";
import {FormContainer, Input} from "../generics/Forms";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {ContentCard, SaveCard} from "../generics/Cards";
import withAuthorization from "../generics/WithAuthorizationHOC";
import {MatchViewModeInput} from "./match-view-mode/MatchViewModeInput";
import {ContentWrapper} from "../generics/Layout";
import "./styles/save-picker.css";

const WrappedSavePicker = ({ props }) => {

    const [saves, setSaves] = useState([]);
    const [sheetOpen, setSheetOpen] = useState(false);
    const [saveCreateData, setSaveCreateData] = useState({});
    const [formIsUploading, setFormIsUploading] = useState(false);
    const [formError, setFormError] = useState(null);

    const [pinnedSaves, setPinnedSaves] = useState([]);
    const [otherSaves, setOtherSaves] = useState([]);

    useEffect(() => {
        getSaves(localStorage.getItem(storage.ACCESS_TOKEN))
            .then((response) => {
                if (_.isEmpty(response.data)) {
                    return;
                }
                setSaves(response.data);
            });
    }, [])

    useEffect(() => {
        setPinnedSaves(filter(saves, save => save.pinned === 1));
        setOtherSaves(filter(saves, save => save.pinned !== 1));
    }, [saves]);

    const handleChange = (field, value) => {
        setSaveCreateData({
            ...saveCreateData,
            [field]: value
        });
    }

    const handleUpdated = (updatedSave) => {
        let savesCopy = [...saves];
        const saveIndex = findIndex(savesCopy, (s) => s.id === updatedSave.id);

        if (saveIndex > -1) {
            savesCopy[saveIndex] = updatedSave;

            savesCopy.sort((a, b) => b.pinned - a.pinned || a.name.localeCompare(b.name));

            setSaves(savesCopy);
        }
    }

    const submitForm = () => {
        setFormIsUploading(true);
        createSave(localStorage.getItem(storage.ACCESS_TOKEN), saveCreateData)
        .then((response) => {
            setFormIsUploading(false);
            if (!response.data.type) {
                setSaves([...saves, response.data[0]]);
                setSaveCreateData(Object.assign({}, {}));
                setSheetOpen(false);
            } else {
                setFormError(getFriendlyErrorMessage(response.data));
            }
        })
        .catch((err) => {
            setFormIsUploading(false);
            setFormError(getFriendlyErrorMessage(err.response.data));
        });
    }

    const renderSaves = () => {
        return map(otherSaves, (os, index) => {
            return <SaveCard key={index} save={os} onUpdate={handleUpdated}/>
        })
    }

    const renderPinnedSaves = () => {
        return map(pinnedSaves, (ps, index) => {
            return <SaveCard key={index} save={ps} onUpdate={handleUpdated}/>
        })
    }

    return (
        <div className={"save-picker"}>
            <Header title={"Pick a save"} />

            <ContentWrapper>
                {
                    !isEmpty(pinnedSaves) &&
                    <>
                        <h4>Pinned</h4>
                        <ContentCard className={"pinned-saves"}>
                            {renderPinnedSaves()}
                        </ContentCard>
                    </>
                }
                {
                    isEmpty(pinnedSaves) ?
                        <h4>All</h4>
                        :
                        <h4>Others</h4>
                }
                <ContentCard>
                    { renderSaves() }
                </ContentCard>
            </ContentWrapper>
            
            <Fab onClick={() => setSheetOpen(true)} 
                openedState={sheetOpen}/>

            <SelectSheet title={"New save"} isOpen={sheetOpen} onDismiss={() => setSheetOpen(false)}>
                <FormContainer onSubmit={submitForm} error={formError} isLoading={formIsUploading}>
                    <Input id="saveName" name="saveName" label="Save name" type="text" max={100} valueChange={(value) => handleChange("name", value)} />
                    <MatchViewModeInput saveGameId={0} onChange={(value) => handleChange("match_view_mode", value)} value={saveCreateData.match_view_mode}/>
                </FormContainer>
            </SelectSheet>
        </div>
    )
}

export const SavePicker = withAuthorization(WrappedSavePicker, true, false);