import './App.css';
import {AnimatedApp} from './AnimatedApp';
import {useEffect} from 'react';
import {storage, storageSettings} from './config/constants';
import {getUserSettings} from './service/userSettingsService';
import {reloadAppIfNewVersion} from "./service/AppVersionService";
import ErrorBoundary from "./ErrorBoundary";

function App() {

    useEffect(() => {
        reloadAppIfNewVersion()
            .then(() => {
                // Attempt to load user settings when the app renders
                // If not signed in: nothing will be returned
                // After sign in, app is rendered again so it is safe to fetch
                if (localStorage.getItem(storage.ACCESS_TOKEN)) {
                    getUserSettings()
                        .then((response) => {
                            localStorage.setItem(storageSettings.CURRENCY, response.data.currency);
                        })
                        .catch((err) => {
                        })
                }
            })

    }, []);

    return (
        <ErrorBoundary>
            <AnimatedApp/>
        </ErrorBoundary>
    );
}


export default App;
