import { join } from "lodash"
import { useState } from "react"
import { messages } from "../../config/constants"
import { clearFormValues, valuesExist } from "../../data/forms"
import { getFriendlyErrorMessage } from "../../service/errorService"
import { createTransfer } from "../../service/transferService"
import {FormContainer, Input, InputRegex, Select, SelectOption, TeamNameInput} from "../generics/Forms"
import { Row } from "../generics/Layout"

export const NewTransferForm = ({
    onSubmit
}) => {

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        player: "",
        from_team: "",
        to_team: "",
        transfer_date: "",
        return_date: "",
        cost: "",
        transfer_type: ""
    })

    const handleCreation = () => {
        let missingValues;
        if (values.transfer_type === "career_end") {
            missingValues = valuesExist(values, "player", "from_team", "transfer_date", "transfer_type");
        } else {
            missingValues = valuesExist(values, "player", "from_team", "transfer_date", "transfer_type", "cost", "to_team");
        }

        if (missingValues.result) {
            if (values.transfer_type === "career_end") {
                addNewTransfer({
                    ...values,
                    "cost": 0,
                    "to_team": ""
                });
            } else {
                addNewTransfer({...values});
            }
        } else {
            setError("Missing fields: " + join(missingValues.missing, ", "));
        }
    }

    const addNewTransfer = (values) => {
        setLoading(true);
        createTransfer(values)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    onSubmit.call(this, response.data);
                }
                setValues(clearFormValues(values))
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                
                setValues(clearFormValues(values));
                setLoading(false);
            })
    }

    const handleChange = (value, field) => {
        let fields = {...values};
        fields[field] = value;
        setValues(fields);
    }

    return (
        <FormContainer isLoading={loading} onSubmit={handleCreation} error={error}>
            <Input type="text"
                label="Player *"
                id="player"
                max={140}
                value={values.player}
                valueChange={(value) => handleChange(value, "player")} />
            <Row>
                <TeamNameInput name={"from_team"}
                               label={"From team *"}
                               max={140}
                               value={values.from_team}
                               onChange={value => handleChange(value, "from_team")} />

                <TeamNameInput name={"to_team"}
                               label={"To team *"}
                               max={140}
                               disabled={(values.transfer_type === "career_end")}
                               value={values.to_team}
                               onChange={value => handleChange(value, "to_team")} />
            </Row>

            <Row>
                <Input type="text"
                    id="transfer-date"
                    label="Transfer date *"
                    info={"Use: yyyy-mm-dd"}
                    max={10}
                    regex={InputRegex.date}
                    value={values.transfer_date}
                    valueChange={value => handleChange(value, "transfer_date")} />

                <Input type="text"
                    id="return-date"
                    label="Return date"
                    info="Use: yyyy-mm-dd"
                    max={10}
                    disabled={(values.transfer_type === "career_end")}
                    regex={InputRegex.date}
                    value={values.return_date}
                    valueChange={value => handleChange(value, "return_date")} />
            </Row>

            <Input type="number"
                id="cost"
                label="Cost *"
                value={values.cost}
                disabled={(values.transfer_type === "career_end")}
                valueChange={value => handleChange(value, "cost")}
            />

            <Select label="Transfer type *" 
                value={values.transfer_type}
                onChange={value => handleChange(value, "transfer_type")}>
                <SelectOption content="Transfer" value="transfer" />
                <SelectOption content="Loan" value="loan" />
                <SelectOption content="End of Career" value={"career_end"}/>
            </Select>
        </FormContainer>
    )
}