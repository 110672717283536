import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const getStandingsFromCompetition = async (competition) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        headers,
        url: join([
            config.API_URL,
            "/save/standings?saveGame=", saveGame,
            "&competition_id=", competition,
            "&extra_data=standing_positions"
        ], "")
    });

    return response;
}

export const createStanding = async (standing) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveGame,
            ...standing
        }
    };

    let response = await Axios({
        method: 'POST',
        url: join([
            config.API_URL,
            "/save/standings"
        ], ""),
        headers,
        data
    });

    return response;
}

export const updateStanding = async (standing) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'id': standing.id,
            'saveGame': saveGame,
            ...standing
        }
    };

    let response = await Axios({
        method: 'PATCH',
        url: join([
            config.API_URL,
            "/save/standings"
        ], ""),
        headers,
        data
    });

    return response;
}

export const deleteStanding = async (standingId, competitionId) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        url: join([
            config.API_URL,
            "/save/standings?saveGame=", saveGame,
            "&competition_id=", competitionId,
            "&id=", standingId
        ], ""),
        headers
    });

    return response;
}