import {useCallback, useEffect, useMemo, useState} from "react";
import {CSSTransition} from "react-transition-group";
import "./styles/search-window.css";
import {Input} from "./Forms";
import {capitalize, chain, debounce, each, groupBy, isEmpty, isFunction, map, replace, sortBy, sum, trim} from "lodash";
import {search} from "../../service/search-service";
import {Tag} from "./Text";
import {HorizontalSelect} from "./form/HorizontalSelect";
import {useHistory} from "react-router-dom";
import {UnmanagedFab} from "./Buttons";
import CloseIcon from "mdi-react/CloseIcon";
import {FeaturePreview} from "../feature/FeaturePreview";
import {translations} from "../../constants/translations.en";
import MapSearchOutlineIcon from "mdi-react/MapSearchOutlineIcon";
import {createPortal} from "react-dom";
import {ContentWrapper} from "./Layout";

export const SearchWindow = ({isOpen, onDismiss}) => {

    const history = useHistory();

    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [itemToSearch, setItemToSearch] = useState("player");
    const [searchHasError, setSearchHasError] = useState(false);

    useEffect(() => {
        doSearch(searchText);
    }, [itemToSearch]);

    const doSearch = async (value) => {
        if (isEmpty(trim(value))) {
            return;
        }

        setSearchText(value);
        setSearchHasError(false);

        try {
            const response = await search(value, itemToSearch);

            if (response.data && response.data.results) {
                setSearchResults([...response.data.results]);
            } else {
                setSearchHasError(true);
                setSearchResults([...[]]);
            }
        } catch (err) {
            setSearchHasError(true);
            setSearchResults([...[]]);
        }
    }

    const debounced = useMemo(() =>
            debounce((v) => {
                doSearch(v)
            }, 500)
        , [doSearch]);

    const handleSearchChange = useCallback(v => {
        debounced(v)
    }, [debounced]);

    const handleSearchItemChange = (item) => {
        setItemToSearch(item);
    }

    const navigateToSearchResult = (clickedResult) => {
        onDismiss.call(this);

        if (itemToSearch === "player") {
            history.push(`/player/${clickedResult}`);
        } else {
            history.push(`/team/${clickedResult}`);
        }
    }

    const handleDismiss = () => {
        setSearchText("");
        setSearchHasError(false);
        setSearchResults([...[]]);
        setItemToSearch("player");

        if (isFunction(onDismiss)) {
            onDismiss.call(this);
        }
    }

    return (
        <CSSTransition in={isOpen} timeout={250}>
            <div className={"search-window"}>
                <ContentWrapper>
                    <Input type={"text"}
                           label={"Search"}
                           autofocus={isOpen}
                           onChange={handleSearchChange}
                    />

                    <HorizontalSelect value={itemToSearch}
                                      options={[
                                          {
                                              label: "Players",
                                              value: "player"
                                          },
                                          {
                                              label: "Teams",
                                              value: "team"
                                          }
                                      ]}
                                      onChange={handleSearchItemChange}
                    />

                    <FeaturePreview isShown={(isEmpty(searchText) && isEmpty(searchResults))}
                                    title={translations.features.SEARCH}
                                    description={translations.features.SEARCH_DESC}
                                    icon={<MapSearchOutlineIcon />}
                    >
                        <SearchResults results={searchResults}
                                       onClick={navigateToSearchResult}
                        />
                    </FeaturePreview>

                    {
                        createPortal(
                            <CSSTransition in={isOpen} timeout={250} classNames={"search-window-fab"}>
                                <UnmanagedFab onClick={handleDismiss}
                                              classNames={"search-window-fab"}
                                              icon={<CloseIcon />} />
                            </CSSTransition>,
                            document.getElementById("root")
                        )
                    }
                </ContentWrapper>
            </div>
        </CSSTransition>
    )

}

const SearchResults = ({results, onClick}) => {

    const handleResultClick = (value) => {
        if (isFunction(onClick)) {
            onClick.call(this, value);
        }
    }

    const renderResults = () => {
        const groupedByName = groupBy(results, "value");
        const mappedResults = [];

        each(groupedByName, (item, name) => {
            mappedResults.push({
                value: name,
                occurrences: sum(map(item, i => i.occurrences)),
                sources: map(item, i => i.source)
            });
        })

        const resultsSorted = sortBy(mappedResults, ["occurrences"]).reverse();

        return map(resultsSorted, (result, index) => {
            return <SingleSearchResult value={result.value}
                                       onClick={handleResultClick}
                                       resultsFromValue={result}
                                       key={index}/>
        })
    }

    return (
        <div className={"search-results"}>
            {renderResults()}
        </div>
    )
}

const SingleSearchResult = ({value, resultsFromValue, onClick}) => {

    const handleClick = () => {
        if (isFunction(onClick)) {
            onClick.call(this, value);
        }
    }

    const getSourceTags = () => {
        return map(resultsFromValue.sources, (source, i) => {
            const sourceName = capitalize(replace(source, "_", " "));

            return <Tag key={i} value={sourceName} className={source}/>
        })
    }

    return (
        <div className={"search-result"} onClick={handleClick}>
            <p className={"value"}>
                {value}
            </p>
            <div className={"footer"}>
                <p>
                    Found {`${resultsFromValue.occurrences > 100 ? "more than 100" : resultsFromValue.occurrences} `}
                    {`${resultsFromValue.occurrences === 1 ? " time" : " times"} `}
                    in
                </p>
                {getSourceTags()}
            </div>
        </div>
    )
}
