import "./styles/offline.css";
import React, {useRef, useState} from 'react';
import {SignInButton} from '../generics/Buttons';
import {resetCodeChallenge} from '../../data/token';
import {authorizeUser} from '../../data/authorization';
import {config} from '../../config/constants';
import {join} from 'lodash';
import ReactVisibilitySensor from 'react-visibility-sensor';
import {ContentWrapper} from "../generics/Layout";

export const Offline = ({props}) => {

    const baseUrl = useRef(config.BASE_URL + "/resources/images/promo/").current;

    const [showBottomSignIn, setShowBottomSignIn] = useState(false);

    authorizeUser(false, true);
    resetCodeChallenge();

    const toggleBottomSignIn = (isVisible) => {
        setShowBottomSignIn(!isVisible)
    }

    return (
        <>
            <HomeScreenHeader onVisibilityChange={toggleBottomSignIn}/>

            <div className={"features"}>
                <FeatureShowcase subtitle="Create your own matches and keep track of your wins, draws and losses wherever you are. View head to head stats and other fixture specific stats so you can plan the next encounter to perfection."
                                 image={join([baseUrl, "01_MatchScreenWithScore.png"], "")}>
                    Keep track of your matches in <FeatureShowcaseHighlight>Football Manager, FIFA, eFootball</FeatureShowcaseHighlight> and more!
                </FeatureShowcase>

                <FeatureShowcase subtitle="Relive all the action from your matches by adding match events. MyScores uses your match events to calculate stats? Add goals, substitutions, yellow cards and even VAR interventions and never forget about that last minute dramatic winner!"
                                 image={join([baseUrl, "02_MatchScreenAddMatchFact.png"], "")}>
                    Describe your matches with <FeatureShowcaseHighlight>match events</FeatureShowcaseHighlight> in as much detail as you want
                </FeatureShowcase>

                <FeatureShowcase subtitle="Create lineups for each of your matches and remember who took part in your epic wins. Using the same lineup often? With lineup templates you won't be wasting any time on filling in the same players over and over."
                                 image={join([baseUrl, "03_MatchScreenAddLineups.png"], "")}>
                    Let the stars line up in your <FeatureShowcaseHighlight>match lineups</FeatureShowcaseHighlight>
                </FeatureShowcase>

                <FeatureShowcase subtitle="Define your transfer objectives and your base budget. Plan which positions you want to reinforce and which players you want to offload. MyScores Transfer Center helps you to stay within your budget and see how much you've earned from player sales."
                                 image={join([baseUrl, "05_TransferScreenOverview.png"], "")}>
                    Plan your perfect <FeatureShowcaseHighlight>transfer market offensive</FeatureShowcaseHighlight>
                </FeatureShowcase>

                <FeatureShowcase subtitle="MyScores' intuitive match creation interfaces allow you to create a match, add match events and lineups quickly and easily so you can focus on gaming and winning."
                                 image={join([baseUrl, "04_HomeScreenNewMatch.png"], "")}>
                    Made to be <FeatureShowcaseHighlight>a companion</FeatureShowcaseHighlight>, not a distraction
                </FeatureShowcase>
            </div>

            <ContentWrapper>
                <LegalDisclaimer/>
            </ContentWrapper>

            <BottomSignInButton isVisible={showBottomSignIn}/>
        </>
    )
}

const HomeScreenHeader = ({onVisibilityChange}) => {

    return (
        <div className="homescreen-header">
            <div className={"content"}>
                <div className={"desktop-left"}>
                    <h1>
                        MyScores lets you keep track of your scores, events, transfers,
                        match stats, player stats, manager stats,... from all your
                        favorite football games!
                    </h1>

                    <p>
                        Say goodbye to long and messy spreadsheets and hello to MyScores! Sign up for free
                        and keep track of your progress in games as Football Manager, FIFA, eFootball and more!
                    </p>

                    <ReactVisibilitySensor onChange={onVisibilityChange}>
                        <SignInButton></SignInButton>
                    </ReactVisibilitySensor>
                </div>
                <div className={"desktop-right"}>
                    <div className={"images-wrapper"}>
                        <img src={`${process.env.PUBLIC_URL}/resources/images/promo/desktop-1.png`}
                             alt={"Screenshot of match screen"}/>
                        <img src={`${process.env.PUBLIC_URL}/resources/images/promo/01_MatchScreenWithScore.png`}
                             alt={"Mobile screenshot of match screen"}/>
                    </div>
                </div>
            </div>
            <div className={"fader"}></div>
        </div>
    )
}

const FeatureShowcaseHighlight = ({children}) => {

    return (
        <span className={"fs-highlight"}>
            {children}
        </span>
    )

}

const FeatureShowcase = ({
                             children,
                             subtitle,
                             image
                         }) => {
    return (
        <div className="fs-container">
            <div className={"text"}>
                <p className="fs-title">
                    {children}
                </p>
                <p className="fs-subtitle">
                    {subtitle}
                </p>
            </div>
            <div className="fs-image-container">
                <img src={image} alt={""} className="fs-image"/>
            </div>
        </div>
    )
}

const BottomSignInButton = ({isVisible}) => {
    return (
        <SignInButton className={join(["button button-signin bottom", (isVisible) ? "show" : "hide"], " ")}/>
    )
}

const LegalDisclaimer = () => {

    return (
        <div className="legal-disclaimer">
            <small>Football Manager is a trademark™ or registered® trademark by Sports Interactive Limited and Sega
                Corporation,
                PES is a trademark™ or registered® trademark by Konami Holdings Corporation
            </small>
        </div>
    )
}