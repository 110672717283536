import { each, join } from "lodash";
import analyze from 'rgbaster';
import { RATINGS, STANDING_POSITIONS_COLORS } from "../config/color-vars";
import { standingPosition } from "../config/constants";

const colorPalette = [
    { r: 229, g: 57, b: 53 },
    { r: 216, g: 27, b: 96 },
    { r: 142, g: 36, b: 170 },
    { r: 94, g: 53, b: 177 },
    { r: 57, g: 73, b: 171 },
    { r: 30, g: 136, b: 229 },
    { r: 3, g: 155, b: 229 },
    { r: 0, g: 172, b: 193 },
    { r: 0, g: 137, b: 123 },
    { r: 67, g: 160, b: 71 },
    { r: 124, g: 179, b: 66 },
    { r: 205, g: 220, b: 57 },
    { r: 253, g: 216, b: 53 },
    { r: 255, g: 193, b: 7 },
    { r: 251, g: 140, b: 0 },
    { r: 244, g: 81, b: 30 },
    //{ r: 238, g: 238, b: 238 },
    { r: 186, g: 104, b: 200 },
    { r: 129, g: 212, b: 250 },
    { r: 129, g: 199, b: 132 },
    { r: 83, g: 216, b: 251 },
    //{ r: 54, g: 55, b: 50 },
    { r: 234, g: 115, b: 23 },
    { r: 115, g: 191, b: 184 },
    { r: 25, g: 133, b: 161 },
    { r: 137, g: 2, b: 62 },
    { r: 124, g: 35, b: 140 },
    { r: 124, g: 114, b: 160 },
    { r: 94, g: 116, b: 127 },
    { r: 74, g: 240, b: 201 },
    { r: 102, g: 201, b: 237 },
    { r: 227, g: 54, b: 207 },
    { r: 255, g: 224, b: 48 },
    { r: 250, g: 151, b: 90 },
    { r: 108, g: 88, b: 237},
    { r: 15, g: 25, b: 102 },
    { r: 240, g: 219, b: 146},
    { r: 99, g: 126, b: 145 },
    { r: 117, g: 69, b: 143 },
    { r: 171, g: 29, b: 29 },
    { r: 171, g: 167, b: 48 },
    { r: 217, g: 211, b: 52 },
    { r: 237, g: 53, b: 40 },
    { r: 23, g: 108, b: 255 },
    { r: 48, g: 194, b: 94 },
    { r: 105, g: 209, b: 167 },
    { r: 247, g: 80, b: 47 },
    { r: 250, g: 170, b: 42 },
    { r: 245, g: 196, b: 113 },
    { r: 110, g: 175, b: 255 },
    { r: 35, g: 38, b: 41 },
    { r: 215, g: 219, b: 216 },
    { r: 69, g: 148, b: 204 }
];

const rgbRegex = /^rgb\(([0-9]{1,3}?),([0-9]{1,3}?),([0-9]{1,3}?)\)$/;

const rgbaRegex = /^rgba\(([0-9]{1,3}?),([0-9]{1,3}?),([0-9]{1,3}?),(\d*\.?\d*?)\)$/;

export const rgb2json = (rgbString) => {
    let values = rgbRegex.exec(rgbString);

    if (values == null) {
        values = rgbaRegex.exec(rgbString);
    }

    if (values == null) {
        return {r: 0, g: 0, b: 0};
    }

    return { r: values[1], g: values[2], b: values[3] };
}

export const json2rgb = (rgbJson) => {
    if (rgbJson.r === undefined || rgbJson.g === undefined || rgbJson.b === undefined) {
        return "rgb(255,255,255)";
    }

    return join([
        "rgb(",
        rgbJson.r,
        ",",
        rgbJson.g,
        ",",
        rgbJson.b,
        ")"
    ], "");
}

export const getColorViaVibrant = async (team) => {
    return new Promise((resolve) => {
        analyze(process.env.PUBLIC_URL + "/resources/images/clubs/" + team + ".png", {
            ignore: [
                "rgb(255,255,255)", "rgb(0,0,0)", "rgba(0,0,0,1)", "rgba(255, 255, 255, 1)"
            ],
            scale: 0.4
        })
            .then(result => {
                resolve(result);
            })
            .catch(err => {
            })
    })
}

export const getClosestMaterialColor = (team) => {
    return new Promise((resolve) => {
        getColorViaVibrant(team)
            .then(colors => {
                let activeColor = colors[0].color;
                resolve(findNearestColor(rgb2json(activeColor)));
            })
            .catch(err => {
                resolve({ r: 255, g: 255, b: 255 });
            })
    })

}

const findNearestColor = nodeColor => {
    return new Promise((resolve) => {
        let currentBest = { index: -1, distance: Number.MAX_SAFE_INTEGER };

        each(colorPalette, (materialColor, index) => {
            let distanceRed = Math.pow(materialColor.r - nodeColor.r, 2);
            let distanceGreen = Math.pow(materialColor.g - nodeColor.g, 2);
            let distanceBlue = Math.pow(materialColor.b - nodeColor.b, 2);

            let distanceSum = distanceRed + distanceGreen + distanceBlue;

            let distance = Math.sqrt(distanceSum);
            if (distance < currentBest.distance) {
                currentBest.distance = distance;
                currentBest.index = index;
            }
        })

        resolve(colorPalette[currentBest.index]);
    })
}

export const getTeamColor = (team) => {
    return new Promise((resolve) => {
        getClosestMaterialColor(team)
            .then(color => {
                let rgbColor = "rgb(" + color.r + "," + color.g + "," + color.b + ")";
                resolve(rgbColor);
            })
            .catch(err => {
                resolve("#ffffff");
            })
    })
}

export const getTeamColors = (team) => {
    const defaultColors = [
        "#383838",
        "#ededed"
    ];

    return new Promise((resolve) => {
        getColorViaVibrant(team)
            .then((colors) => {
                resolve([
                    colors[0].color,
                    colors[1].color
                ]);
            })
            .catch((err) => {
                resolve(defaultColors);
            })
    })
}

/**
 * Determines the best text color to use on this color background.
 * It will return either #ffffff or #000000.
 * @param {*} color The background color as an rgb() string
 */
export const getTextColor = (color) => {
    let rgb = rgb2json(color);

    let redCalc = rgb.r * 0.299;
    let greenCalc = rgb.g * 0.587;
    let blueCalc = rgb.b * 0.114;

    if ((redCalc + greenCalc + blueCalc) > 150) {

        return "#000000";
    } else {
        return "#ffffff";
    }
}

export const getRatingColor = (rating) => {
    if (rating < 5.8) {
        return RATINGS.AWFUL;
    } else if (rating < 7) {
        return RATINGS.BAD;
    } else if (rating < 8) {
        return RATINGS.DECENT;
    } else if (rating < 9) {
        return RATINGS.GOOD;
    } else if (rating <= 10) {
        return RATINGS.PERFECT;
    }
}

export const getSpecialStandingPositionColor = (special) => {
    if (special === standingPosition.CL_QUALIFICATION) {
        return STANDING_POSITIONS_COLORS.CL_QUALIFICATION;
    } else if (special === standingPosition.CL_QUALIFIED) {
        return STANDING_POSITIONS_COLORS.CL_QUALIFIED;
    } else if (special === standingPosition.EL_QUALIFICATION) {
        return STANDING_POSITIONS_COLORS.EL_QUALIFICATION;
    } else if (special === standingPosition.EL_QUALIFIED) {
        return STANDING_POSITIONS_COLORS.EL_QUALIFIED;
    } else if (special === standingPosition.NEXT_STAGE_QUALIFIED) {
        return STANDING_POSITIONS_COLORS.NEXT_STAGE_QUALIFIED;
    } else if (special === standingPosition.RELEGATED) {
        return STANDING_POSITIONS_COLORS.REL;
    } else if (special === standingPosition.REL_PLAYOFF) {
        return STANDING_POSITIONS_COLORS.REL_PLAYOFF;
    } else if (special === standingPosition.WINNER) {
        return STANDING_POSITIONS_COLORS.WINNER;
    } else {
        return STANDING_POSITIONS_COLORS.NONE;
    }
}