export const LinearLoaderInfinite = ({message = null}) => {

    return(
        <> 
            {message && 
                <p>{message}</p>
            }
            <div className="loader linear">
                <div className="loader-progress"></div>
            </div>
        </>
    )
}

export const CircularLoaderInfinite = (props) => {

    return(
        <div className="loader circular">
            <div className={"loader-progress"}>
            </div>
        </div>
    )
}