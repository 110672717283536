import filter from 'lodash.filter';
import CountryNames from '../constants/countries.json';

export const getMatchingCountryNames = (text) => {
    return new Promise((resolve) => {
        let countries = filter(CountryNames.countries, (country) => {
            return country.startsWith(text);
        });

        if (countries.length > 5) {
            countries = countries.slice(0, 5);
        }

        resolve(countries);
    })
    
}