import Axios from "axios";
import {join} from "lodash";
import {config, storage} from "../config/constants";


export const reloadAppIfNewVersion = async () => {
    const currentVersion = localStorage.getItem(storage.VERSION);

    const response = await Axios({
        method: "GET",
        url: join([config.API_URL, "/version"], "")
    });

    if (response.data) {
        if (response.data.version) {
            if (response.data.version !== currentVersion) {
                console.log(`New version (${response.data.version}) available. Reloading application.`);
                localStorage.setItem(storage.VERSION, response.data.version);
                window.location.reload();
            }
        }
    }
}