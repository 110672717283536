import {useRef} from "react";
import {find} from "lodash";
import TeamNames from "../../constants/teamnames.json";
import ReactVisibilitySensor from "react-visibility-sensor";

const Crest = ({club, isInternational = false}) => {

    const crestClass = (isInternational) ? "crest international" : "crest";
    const fallBackCrestLoaded = useRef(false);

    const setDefaultCrest = (event) => {
        if (!fallBackCrestLoaded.current) {
            fallBackCrestLoaded.current = true;
            event.target.src = process.env.PUBLIC_URL + "/resources/images/clubs/crest.png";
        }
    }

    const getCrestImage = () => {
        const team = find(TeamNames.teams, t => t === club);

        if (team) {
            return encodeURI(team);
        } else {
            return "crest"
        }
    }

    return (
        <div className={crestClass}>
            <img src={process.env.PUBLIC_URL + "/resources/images/clubs/" + getCrestImage() + ".png"}
                 alt={club + " crest"}
                 onError={e => setDefaultCrest(e)}/>

        </div>
    )
}

export const HeaderCrest = ({onVisibilityChange, club, background = "white"}) => {
    return (
        <ReactVisibilitySensor onChange={onVisibilityChange}>
            <div className="header-crest" style={{backgroundColor: background}}>
                <Crest club={club}/>
            </div>
        </ReactVisibilitySensor>
    )
}

export const FallbackCrest = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             className={"fallback"} viewBox="0 0 1046.000000 1280.000000"
             preserveAspectRatio="xMidYMid meet">
            <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
               fill="currentColor" stroke="none">
                <path d="M4885 12645 c-1070 -549 -1968 -902 -2830 -1110 -336 -81 -723 -154
                        -1570 -294 -395 -65 -453 -77 -472 -93 -25 -21 -16 -459 32 -1523 56 -1213
                        109 -1961 160 -2250 42 -235 142 -710 189 -900 171 -690 295 -1086 520 -1652
                        166 -420 227 -549 418 -893 283 -507 500 -852 793 -1262 261 -363 362 -485
                        727 -875 213 -228 278 -289 673 -634 583 -510 683 -589 920 -734 88 -54 207
                        -129 264 -168 121 -81 381 -217 462 -243 l57 -17 173 79 c186 84 199 92 559
                        319 274 173 294 187 494 353 391 325 880 767 1071 968 453 476 554 598 891
                        1079 277 395 400 592 679 1085 250 442 447 893 665 1521 221 635 460 1665 529
                        2279 63 558 135 1934 167 3211 6 245 5 257 -12 263 -53 16 -516 103 -785 146
                        -1094 176 -1651 315 -2439 608 -436 162 -1083 454 -1668 753 -240 122 -279
                        139 -320 138 -40 0 -90 -23 -347 -154z"/>
            </g>
        </svg>

    )
}

export default Crest;