import _ from 'lodash';
import { PlayerStatsTable } from '../../generics/Tables';
import "./top-scorers.css";
import {ContentCard} from "../../generics/Cards";

export const TopScorers = ({data = {}, homeTeam, awayTeam}) => {

    const renderTopScorers = () => {
        let children = [];
        let itemsOrdered = [{team: homeTeam, data: data[homeTeam]}, 
                            {team: awayTeam, data: data[awayTeam]}];

        _.each(itemsOrdered, (team, index) => {
            children.push(
                <PlayerStatsTable key={index}
                    header={team.team}
                    data={team.data}
                    keyName="player"
                    valueName="goals_scored"/>
            )
        })

        return children;
    }

    return(
        <div className="match-top-scorers">
            <h4>
                Top scorers in this fixture
            </h4>
            <ContentCard>
                {renderTopScorers()}
            </ContentCard>
        </div>
    )
}