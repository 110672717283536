import { Link } from "react-router-dom"
import Crest from "../team/Crest"
import { TeamColorsFlag } from "../team/TeamColors"

export const TeamManager = ({ manager, team }) => {

    return (
        <Link to={"/manager/" + manager.id}>
            <div className="team-manager">
                <TeamColorsFlag team={team} />

                <Crest club={team} />

                <div className="manager-data">
                    <h5>{team}</h5>
                    <p>{manager.name}</p>
                </div>
            </div>
        </Link>
    )
}