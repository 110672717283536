import { useEffect, useState } from "react";
import { getAccessToken } from "../../service/oauthService";
import _ from 'lodash';
import { config, storage } from "../../config/constants";

const AUTHZ_CODE = "code";

const CallbackScreen = ({props}) => {

    const [message, setMessage] = useState("Just a few more seconds until kick-off!");

    useEffect(() => {
        let queryParamString = window.location.search;
        let urlParams = new URLSearchParams(queryParamString);
        
        if (urlParams.has(AUTHZ_CODE)) {
            let authzCode = urlParams.get(AUTHZ_CODE);

            getAccessToken(authzCode)
            .then((response) => {
                if (response === false) {
                    _.delay(function() {
                        window.location = config.BASE_URL;
                     }, 3000);
                    setMessage("Uh-oh... Could not sign you in at this time. Please try again.");
                } else {
                    localStorage.setItem(storage.ACCESS_TOKEN, response.data.access_token);
                    localStorage.setItem(storage.ACCESS_TOKEN_EXPIRE_DATE, response.data.access_token_expire_date);
                    localStorage.setItem(storage.REFRESH_TOKEN, response.data.refresh_token);

                    _.delay(function() {
                        window.location = config.BASE_URL + "/save";
                    }, 1500);
                }
            })
            .catch((err) => {
                _.delay(function() {
                    window.location = config.BASE_URL;
                 }, 3000);
                 setMessage("Oh no.. An error happened when we tried signing you in. Please try again.");
            });
        } else {
            _.delay(function() {
               window.location = config.BASE_URL;
            }, 3000);
            setMessage("Kick-off cancelled! No authorization code could be found. You will be redirected soon...");
        }
    }, []);

    return (
        <div className={"callback-container"}>
            <img src={process.env.PUBLIC_URL + "/resources/images/animations/authorizing_animation_1.gif"}
                alt="Two people playing a football game on a console."/>
            <p>
                {message}
            </p>
        </div>
    )
}

export default CallbackScreen;