export const FeaturePreview = ({
    children,
    isShown,
    title,
    description,
    icon
}) => {

    return (
        <>
            {isShown ?
                <div className="feature-description">
                    {icon}
                    <h4>{title}</h4>
                    <p>{description}</p>
                </div>
                :
                children
            }
        </>
    )
}