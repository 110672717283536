import {map} from "lodash"
import {TeamManager} from "../manager/TeamManager"
import {ContentCard} from "../generics/Cards";

export const LineupInformation = ({match}) => {

    const renderTeamManagers = () => {
        if (match.extra_fields && match.extra_fields.managers) {
            return map(match.extra_fields.managers, (manager, index) => {
                if (index === "home") {
                    return (
                        <TeamManager key={index}
                            manager={manager}
                            team={match.home_team}/>
                    )
                } else if (index === "away") {
                    return (
                        <TeamManager key={index}
                            manager={manager}
                            team={match.away_team}/>
                    )
                }
            })
        }
    }

    return(
        <div className="lineup-information">
            <ContentCard>
                {renderTeamManagers()}
            </ContentCard>
        </div>
    )
}