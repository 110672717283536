import "./match-view-mode.css";
import {useEffect, useState} from "react";
import {isFunction, toString} from "lodash";

export const MatchViewModeInput = ({ saveGameId, value, onChange }) => {

    const [options, setOptions] = useState({
        0: false, // standard view mode
        1: false  // timeline view mode
    });

    useEffect(() => {
        const optionsCopy = {...options};

        for (const option in optionsCopy) {
            if (toString(option) === toString(value)) {
                optionsCopy[option] = true;
            } else {
                optionsCopy[option] = false;
            }
        }

        setOptions(optionsCopy);
    }, [value]);

    const handleSelection = (id, isSelected) => {
        onChange.call(this, id);
    }

    return (
        <div className={"match-view-mode-input"}>
            <p>Match view mode</p>
            <div className={"scrollable"}>
                <MatchViewModeOption saveGameId={saveGameId}
                                     selected={options[0]}
                                     onSelect={handleSelection}
                                     name={"Standard"}
                                     id={0}
                                     matchViewMode={<StandardMatchViewMode/>}/>

                <MatchViewModeOption saveGameId={saveGameId}
                                     selected={options[1]}
                                     onSelect={handleSelection}
                                     name={"Timeline"}
                                     id={1}
                                     matchViewMode={<TimelineMatchViewMode/>}/>
            </div>
        </div>
    )
}

const MatchViewModeOption = ({saveGameId, selected, onSelect, name, id, matchViewMode}) => {

    const handleSelect = () => {
        if (isFunction(onSelect)) {
            if (!selected) {
                onSelect.call(this, id, !selected);
            }
        }
    }

    return(
        <div className={`match-view-mode-option ${(selected) ? "selected" : ""}`} onClick={handleSelect}>
            <div className={"example"}>
                { matchViewMode }
            </div>
            <div className={"toggle"}>
                <p className={"name"}>{ name }</p>
                <input type={"radio"}
                       name={`${saveGameId}-match-view-mode`}
                       id={`${saveGameId}-match-view-mode-${id}`}
                       checked={selected}
                       onSelect={handleSelect}
                />
            </div>
        </div>
    )
}

const StandardMatchViewMode = ({onSelect}) => {

    return (
        <div className={"match-view-mode standard"}
             style={{
                 backgroundImage: `url(${process.env.PUBLIC_URL}/resources/images/match-view-modes/match-view-mode-0.jpg)`
             }}>
        </div>
    )
}

const TimelineMatchViewMode = ({onSelect}) => {
    return (
        <div className={"match-view-mode timeline"}
             style={{
                 backgroundImage: `url(${process.env.PUBLIC_URL}/resources/images/match-view-modes/match-view-mode-1.jpg)`
             }}>
        </div>
    )
}