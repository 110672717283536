import Axios from "axios";
import {join} from "lodash";
import {config, storage} from "../config/constants"

export const getCompetitions = async () => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: "GET",
        url: join([config.API_URL, "/save/competitions/unique?saveGame=", saveGame], "")
    });

    return response;
}

export const getCompetitionsFromTeam = (team) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: "GET",
        url: `${config.API_URL}/save/competitions/unique?saveGame=${saveGame}&team=${team}`
    })
}

export const getCompetitionById = async (id) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: "GET",
        url: join([config.API_URL, "/save/competitions?saveGame=", saveGame, "&id=", id], "")
    });

    return response;
}

export const getLeagueSeasons = async (name, country) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: "GET",
        url: join([config.API_URL, 
            "/save/competitions/unique?saveGame=", saveGame, 
            "&name=", name, 
            "&country=", country], "")
    });

    return response;
}

export const getLeagueWinners = async (name, country) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: "GET",
        url: join([
            config.API_URL,
            "/save/competitions/winners?saveGame=", saveGame,
            "&name=", name,
            "&country=", country
        ], "")
    });

    return response;
}

export const getPastLeagueWinners = async (name, country, page) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: "GET",
        url: join([
            config.API_URL,
            "/save/competitions/winners/all?saveGame=", saveGame,
            "&name=", name,
            "&country=", country,
            "&page=", page
        ], "")
    });

    return response;
}

export const createCompetition = async (competition) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    if (!competition.is_knockout) {
        competition.is_knockout = 0;
    } else {
        competition.is_knockout = 1;
    }
    
    if (!competition.is_international) {
        competition.is_international = 0;
    } else {
        competition.is_international = 1;
    }

    const data = {
        'properties': {
            'saveGame': saveGame,
            ...competition
        }
    }

    let response = await Axios({
        method: 'POST',
        url: join([config.API_URL, "/save/competitions"], ""),
        data
    })

    return response;
}