import {isFunction, map} from "lodash"
import {useEffect, useRef, useState} from "react"
import {messages} from "../../config/constants"
import {getSpecialStandingPositionColor, getTextColor} from "../../data/colors"
import useLongPress from "../../hooks/longPress"
import {getFriendlyErrorMessage} from "../../service/errorService"
import {createStandingPositions, updateStandingPosition} from "../../service/standingPositionService"
import {createStanding, deleteStanding, updateStanding} from "../../service/standingService"
import {Button, ButtonRow, DangerButton, Fab} from "../generics/Buttons"
import {Input} from "../generics/Forms"
import {GridRow} from "../generics/Layout"
import {DeletionModal, LoadingModal, Modal} from "../generics/Modals"
import {SelectSheet} from "../generics/Tabs"
import Crest from "../team/Crest"
import {NewStandingForm, PositionSpecialSelect} from "./NewStandingForm"
import "./styles/standing-screen.css";
import {ContentCard} from "../generics/Cards";

export const StandingScreen = ({ 
    onCreate,
    onUpdate, 
    standings,
    competition,
    fabBackgroundColor = "var(--primary)",
    fabColor = "#fff"
}) => {

    const [createStandingOpen, setCreateStandingOpen] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const [loadingText, setLoadingText] = useState("");
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    const [tabSheetAction, setTabSheetAction] = useState("");

    const [standingEditOpen, setStandingEditOpen] = useState(false);
    const [positionEditOpen, setPositionEditOpen] = useState(false);
    const [workingStanding, setWorkingStanding] = useState({});
    const [workingPosition, setWorkingPosition] = useState({});

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [createError, setCreateError] = useState("");

    useEffect(() => {
        if (tabSheetAction === "open") {
            setCreateStandingOpen(true);
        } else if (tabSheetAction === "close") {
            setCreateStandingOpen(false);
        }
    }, [tabSheetAction])

    const handleStandingChange = (field, value) => {
        let standing = workingStanding;
        standing[field] = value;
        setWorkingStanding(standing);
    }

    const handlePositionChange = (field, value) => {
        let position = workingPosition;
        position[field] = value;
        setWorkingPosition(position);
    }

    const openEditStanding = (standing) => {
        setWorkingStanding(standing);
        setStandingEditOpen(true);
    }

    const openEditPosition = (position) => {
        position.competition_id = competition.id;
        setWorkingPosition(position);
        setPositionEditOpen(true);
    }

    const handleCreateStanding = (standingData, positionData) => {
        setCreateLoading(true);

        standingData.competition_id = competition.id;
        if (!standingData.name) {
            setCreateError("Standing does not have a name.");
            setCreateLoading(false);
            return;
        }

        for (let i = 0; i < standingData.teams; i++) {
            let position = positionData[i];
            
            if (!position.team) {
                setCreateError(`Standing position ${i+1} does not have a team`);
                setCreateLoading(false);
                return;
            }

            if (position.points == null || position.points === undefined) {
                setCreateError(`Standing position ${i+1} does not have points.`);
                setCreateLoading(false);
                return;
            }

            position.competition_id = competition.id;
            position.position_number = i+1;
        }

        createStanding(standingData)
            .then((response) => {
                if (response.data && response.data.type) {
                    setCreateError(getFriendlyErrorMessage(response.data));
                    return setCreateLoading(false);
                }

                handleCreateStandingPositions(response.data[0].id, positionData, response.data[0]);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setCreateError(getFriendlyErrorMessage(error.response.data));
                    setCreateLoading(false);
                } else {
                    setCreateError(messages.ERR_UNKNOWN);
                    setCreateLoading(false);
                }
            });
    }

    const handleEditStanding = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Updating standing...");
        setLoadingText("Please wait while the standing is being updated.");

        updateStanding(workingStanding)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (response.data.changedRows === 1) {
                    let standingIndex = findStanding(workingStanding.id);
                    let allStandings = standings;   
                    allStandings[standingIndex].name = workingStanding.name;
                    onUpdate(allStandings);
                    setLoadingText("The standing has been updated successfully.");
                    closeLoading();
                } else {
                    setLoadingText("There were no changes to the standing.");
                    closeLoading();
                }

                setStandingEditOpen(false);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                    closeLoading();
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                    closeLoading();
                }
            })
    }

    const handleDeleteStanding = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Deleting standing...")
        setLoadingText("Please wait while the standing and its positions are being deleted.");

        deleteStanding(workingStanding.id, workingStanding.competition_id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                setLoadingText("The standing has been deleted successfully.");

                let index = findStanding(workingStanding.id);
                setWorkingStanding({});
                let allStandings = standings;
                allStandings.splice(index, 1);

                onUpdate(allStandings);
                setDeleteModalOpen(false);
                setStandingEditOpen(false);
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                    closeLoading();
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                    closeLoading();
                }
            })
    }

    const handleCreateStandingPositions = (standingId, positions, createdStanding) => {
        createStandingPositions(standingId, createdStanding.competition_id, positions)
            .then((response) => {
                if (response.data && response.data.type) {
                    setCreateError(getFriendlyErrorMessage(response.data));
                    return setCreateLoading(false); 
                }

                createdStanding.extra_fields = {
                    standing_positions: response.data
                };

                onCreate(createdStanding);
                setCreateLoading(false);
                setTabSheetAction("close");
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setCreateError(getFriendlyErrorMessage(error.response.data));
                    setCreateLoading(false);
                } else {
                    setCreateError(messages.ERR_UNKNOWN);
                    setCreateLoading(false);
                }
            });
    }

    const handleEditPosition = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Updating standing position");
        setLoadingText("Please wait while this standing position is being updated...");

        updateStandingPosition(
            workingPosition.position_number, 
            workingPosition.standing_id, 
            workingPosition
        ).then((response) => {
            if (response.data && response.data.type) {
                setLoadingText(getFriendlyErrorMessage(response.data));
                return closeLoading();
            }

            if (response.data.changedRows < 1) {
                setLoadingText("There were no changes to the standing position.");
            } else {
                setLoadingText("The standing position has been updated successfully.");
                let standingIndex = findStanding(workingPosition.standing_id);
                let positionIndex = findPosition(standingIndex, workingPosition.position_number);
    
                standings[standingIndex].extra_fields.standing_positions[positionIndex] = workingPosition;
    
                onUpdate(standings);
            }
            
            closeLoading();
        })
        .catch((error) => {
            if (error.response && error.response.data) {
                setLoadingText(getFriendlyErrorMessage(error.response.data));
                closeLoading();
            } else {
                setLoadingText(messages.ERR_UNKNOWN);
                closeLoading();
            }
        })
    }

    const findStanding = (id) => {
        for (let i = 0; i < standings.length; i++) {
            if (standings[i].id === id) {
                return i;
            }
        }

        return -1;
    }

    const findPosition = (standingIndex, number) => {
        let standing = standings[standingIndex];
        for (let i = 0; i < standing.extra_fields.standing_positions.length; i++) {
            if (standing.extra_fields.standing_positions[i].position_number === number) {
                return i;
            }
        }

        return -1;
    }

    const closeLoading = () => {
        setTimeout(() => {
            setLoadingModalOpen(false);
        }, 700);
    }

    const renderStandings = () => {
        return map(standings, (standing, index) => {
            return <StandingContainer key={index} 
                        standing={standing}
                        onLongPressStanding={(standing) => openEditStanding(standing)}
                        onLongPressPosition={(position) => openEditPosition(position)} />
        })
    }

    return (
        <div className="standing-screen">
            {renderStandings()}

            <Fab openedState={createStandingOpen} 
                onClick={() => setCreateStandingOpen(true)}
                styles={{backgroundColor: fabBackgroundColor, color: fabColor}}/>

            <SelectSheet title={"New standing"} isOpen={createStandingOpen} 
                onDismiss={() => setCreateStandingOpen(false)}>
                <NewStandingForm onCreate={(standing, positions) => handleCreateStanding(standing, positions)}
                    isLoading={createLoading}
                    createError={createError}/>
            </SelectSheet>

            <Modal isOpen={standingEditOpen} onDismiss={() => setStandingEditOpen(false)}>
                <h3>Edit standing</h3>

                <Input type="text"
                    label="Name"
                    value={workingStanding.name}
                    valueChange={value => handleStandingChange("name", value)}/>
                
                <ButtonRow>
                    <DangerButton onClick={() => setDeleteModalOpen(true)}>
                        Delete
                    </DangerButton>
                    <Button onClick={handleEditStanding}>
                        Confirm
                    </Button>
                </ButtonRow>
            </Modal>

            <Modal isOpen={positionEditOpen} onDismiss={() => setPositionEditOpen(false)}>
                <h3>Edit standing position</h3>

                <GridRow templateColumns={["60%", "40%"]}>
                    <Input type="text"
                        label="Team name"
                        value={workingPosition.team}
                        valueChange={value => handlePositionChange("team", value)}
                        id="tn-edit" />
                    <Input type="number"
                        label="Points"
                        value={workingPosition.points}
                        valueChange={value => handlePositionChange("points", value)}
                        id="p-edit" />
                </GridRow>

                <PositionSpecialSelect 
                    onChange={(number, team) => handlePositionChange("special", team)}
                    teamNumber={-1}
                    value={workingPosition.special}/>

                <ButtonRow>
                    <Button onClick={handleEditPosition}>
                        Confirm
                    </Button>
                </ButtonRow>
            </Modal>

            <LoadingModal isOpen={loadingModalOpen}
                onDismiss={() => setLoadingModalOpen(false)}
                title={loadingTitle}
                text={loadingText} />

            <DeletionModal isOpen={deleteModalOpen} 
                onDismiss={() => setDeleteModalOpen(false)}
                onConfirm={handleDeleteStanding}
                title={"delete standing"}
                text="You are about to delete this standing and all its positions permanently."
                />
        </div>
    )
}

export const StandingContainer = ({ standing, onLongPressPosition, onLongPressStanding }) => {

    const longPressStandingHandler = () => {
        if (isFunction(onLongPressStanding)) {
            onLongPressStanding.call(this, standing);
        }
    }

    const longPressEvent = useLongPress(longPressStandingHandler);

    const renderPositions = () => {
        if (standing.extra_fields && standing.extra_fields.standing_positions) {
            return map(standing.extra_fields.standing_positions, (position, index) => {
                return <StandingPosition position={position} key={index} 
                    onLongPressPosition={onLongPressPosition}/>
            })
        }
    }

    return ( 
        <div className="standing-container">
            <div className="standing-header" {...longPressEvent}>
                <h4>{standing.name}</h4>
            </div>
            <ContentCard>
                <div className={"standing-positions-header"}>
                    <p>#</p>
                    <p>Team</p>
                    <p>Pts</p>
                </div>
                <div className="standing-positions">
                    {renderPositions()}
                </div>
            </ContentCard>
        </div>
    )
}

export const StandingPosition = ({ position, onLongPressPosition }) => {

    const longPressPositionHandler = () => {
        if (isFunction(onLongPressPosition)) {
            onLongPressPosition.call(this, position);
        }
    }

    const longPressEvent = useLongPress(longPressPositionHandler);

    return (
        <div className="standing-position" {...longPressEvent}>
            <StandingPositionNumber number={position.position_number} special={position.special} />
            <Crest club={position.team} />
            <p className="team">{position.team}</p>
            <p className="points">{position.points}</p>
        </div>
    )
}

export const StandingPositionNumber = ({ number, special }) => {

    const backgroundColor = useRef(getSpecialStandingPositionColor(special)).current;
    const color = useRef(getTextColor(backgroundColor)).current;

    return (
        <div className="position-number">
            <div className="inner-position-number"
                style={{
                    backgroundColor,
                    color
                }}>
                <p>{number}</p>
            </div>
        </div>
    )
}