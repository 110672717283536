import {isEmpty, map, reverse, sortBy} from "lodash";
import forEach from "lodash.foreach";
import {useEffect, useRef, useState} from "react"
import {useHistory, useParams} from "react-router";
import {Tab, TabList, TabPanel} from "react-tabs";
import {api, messages} from "../../config/constants";
import {getTeamColor, getTextColor} from "../../data/colors";
import {
    getCompetitionById,
    getLeagueSeasons,
    getLeagueWinners,
    getPastLeagueWinners
} from "../../service/competitionService";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {getMatchesFromLeague} from "../../service/matchService";
import {getStandingsFromCompetition} from "../../service/standingService";
import Header from "../generics/Header"
import {BottomNavWithSheet} from "../generics/Navigation";
import withAuthorization from "../generics/WithAuthorizationHOC";
import {FeaturedMatch} from "../match/FeaturedMatch";
import {MatchContainer} from "../overview/LeagueMatches";
import {StandingScreen} from "../standing/StandingScreen";
import {getKnockoutRoundsByCompetition} from "../../service/knockoutRoundService";
import {KnockoutScreen} from "../knockout/KnockoutScreen";
import {SelectSheet, SelectSheetTab, TabWrapper} from "../generics/Tabs";
import TrophyIcon from "mdi-react/TrophyIcon";
import SoccerIcon from "mdi-react/SoccerIcon";
import NewMatchForm from "../match/NewMatchForm";
import {NewLeagueForm} from "./NewLeagueForm";
import {TabSheetAction} from "../../constants/TabSheetAction";
import "./styles/league-screen.css";
import {ContentWrapper} from "../generics/Layout";
import {LeagueWinners} from "./LeagueWinners";
import {PastWinnersScreen} from "./PastWinnersScreen";

export const WrappedLeagueScreen = (props) => {

    const { id } = useParams();
    const oldId = useRef(id);

    const history = useHistory();

    const [loadedTabs, setLoadedTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);

    const [league, setLeague] = useState({});
    const [seasons, setSeasons] = useState([]);
    const [seasonOptions, setSeasonOptions] = useState([]);
    const [winners, setWinners] = useState({});

    const [matches, setMatches] = useState([]);
    const [matchPage, setMatchPage] = useState(1);
    const [stopLoadingMatches, setStopLoadingMatches] = useState(false);

    const [standings, setStandings] = useState([])

    const [pastWinnersPage, setPastWinnersPage] = useState(1);
    const [pastWinners, setPastWinners] = useState([]);
    const [stopLoadingPastWinners, setStopLoadingPastWinners] = useState(false);

    const [knockoutRounds, setKnockoutRounds] = useState([]);

    const [backgroundColor, setBackgroundColor] = useState("var(--primary)");
    const [color, setColor] = useState("var(--header-text)");

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");

    const [tabSheetAction, setTabSheetAction] = useState("none");
    const [newMatchSheetOpen, setNewMatchSheetOpen] = useState(false);
    const [newSeasonSheetOpen, setNewSeasonSheetOpen] = useState(false);

    useEffect(() => {
        loadLeagueDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadLeagueSeasons();
        loadCompetitionColors();
        loadLeagueWinners();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [league]);

    useEffect(() => {
        loadLeagueDetails();

        setStopLoadingMatches(false);
        setStopLoadingPastWinners(false);
        setMatchPage(1);
        setMatches([]);
        setLoadedTabs([]);
        setCurrentTab(0);
        setKnockoutRounds([]);
        setStandings([]);
        setWinners({});
        setPastWinners([]);
        setPastWinnersPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // After stopLoadingMatches has changed, and another 
    // league is being loaded, load matches again
    useEffect(() => {
        if (stopLoadingMatches === false && oldId !== id) {
            loadLeagueMatches();
            oldId.current = id;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopLoadingMatches])

    useEffect(() => {
        let sortedSeasons = reverse(sortBy(seasons, ["season"]));

        let optionValues = map(sortedSeasons, (value, index) => {
            return value.season;
        });

        setSeasonOptions(optionValues);
    }, [seasons]);

    const loadLeagueDetails = () => {
        getCompetitionById(id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    if (!isEmpty(response.data)) {
                        setLeague(response.data[0]);
                    }
                }
            })  
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            });
    }

    const loadLeagueSeasons = () => {
        getLeagueSeasons(league.name, league.country)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setSeasons(response.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadLeagueWinners = () => {
        getLeagueWinners(league.name, league.country)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setWinners(response.data);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            });
    }

    const loadPastLeagueWinners = () => {
        if (stopLoadingPastWinners) {
            return;
        }

        getPastLeagueWinners(league.name, league.country, pastWinnersPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setPastWinners([...pastWinners, ...response.data]);
                    setPastWinnersPage(pastWinnersPage + 1);

                    if (isEmpty(response.data)) {
                        setStopLoadingPastWinners(true);
                    }
                }
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            });
    }

    const loadCompetitionColors = () => {
        getTeamColor(league.country)
            .then((value) => {
                let textColor = getTextColor(value);
                setBackgroundColor(value);

                if (textColor === "#000000") {
                    setColor("rgba(0, 0, 0, 0.7)");
                } else {
                    setColor(textColor); 
                }
            })
            .catch(err => {
            })
    }

    const loadLeagueMatches = () => {
        if (stopLoadingMatches === true) {
            return;
        }
        getMatchesFromLeague(id, matchPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setMatches([...matches, ...response.data]);

                    if (response.data.length === api.RESULTS_PER_PAGE) {
                        setMatchPage(matchPage + 1);
                    } else {
                        setStopLoadingMatches(true);
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadStandings = () => {
        getStandingsFromCompetition(id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setStandings(response.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadKnockoutRounds = () => {
        getKnockoutRoundsByCompetition(league.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data))
                } else {
                    setKnockoutRounds(response.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const onStandingsCreate = (standing) => {
        setStandings([...standings, standing]);
    }

    const onStandingsUpdate = (standings) => {
        setStandings([...standings]);
    }

    const onSeasonSelected = (selectedSeason) => {
        let competitionId;

        forEach(seasons, (value) => {
            if (value.season === selectedSeason) {
                competitionId = value.id;
            }
        })

        history.push("/league/" + competitionId);
    }

    const handleInitialTabLoad = (newIndex, oldIndex) => {
        if (newIndex === oldIndex) {
            return;
        }

        setCurrentTab(newIndex);

        if (loadedTabs.includes(newIndex)) {
            return;
        }

        switch(newIndex) {
            case 1:
                // fetch standings
                loadStandings();
            break;
            case 2:
                if (league.is_knockout === 1) {
                    loadKnockoutRounds();
                } else {
                    loadPastLeagueWinners();
                }
            break;
            case 3:
                if (league.is_knockout === 1) {
                    loadPastLeagueWinners();
                }
            break;
            default:

            break;
        }

        setLoadedTabs([...loadedTabs, newIndex]);
    }

    const handleNewSeasonCreation = (league) => {
        let seasonsArray = [...seasons, ...league];

        seasonsArray = sortBy(seasonsArray, (league) => league.season);

        setSeasons(seasonsArray);

        setNewSeasonSheetOpen(false);
        setTabSheetAction(TabSheetAction.CLOSE);
        setTabSheetAction(TabSheetAction.RESET);
    }

    return(
        <div className="league-screen">
            <Header title={league.name}
                background={backgroundColor}
                color={color}
                options={seasonOptions}
                optionsLabel={league.season}
                onOptionSelect={(value) => onSeasonSelected(value)}
                optionsTitle="Choose season"
                withExtrasButton={true}/>

            <TabWrapper selectedIndex={currentTab} onSelect={(newIndex, oldIndex) => handleInitialTabLoad(newIndex, oldIndex)}>
                <TabList style={{backgroundColor: backgroundColor}}>
                    <Tab>Details</Tab>
                    <Tab>Standings</Tab>
                    {
                        league.is_knockout === 1 &&
                            <Tab>Knockout</Tab>
                    }
                    <Tab>Winners</Tab>
                </TabList>  

                <ContentWrapper>
                    <TabPanel className={"matches"}>
                        { !isEmpty(matches) &&
                            <FeaturedMatch match={matches[0]}/>
                        }

                        { winners && (winners.last || winners.top) &&
                            <LeagueWinners top={winners.top} last={winners.last} />
                        }

                        <h4>Latest matches</h4>
                        <MatchContainer data={matches}
                                        onContinueLoading={() => loadLeagueMatches()}/>
                    </TabPanel>

                    <TabPanel>
                        <StandingScreen standings={standings}
                                        competition={league}
                                        onCreate={(updatedStandings) =>  onStandingsCreate(updatedStandings)}
                                        onUpdate={(updatedStandings) => onStandingsUpdate(updatedStandings)}
                                        fabBackgroundColor={backgroundColor}
                                        fabColor={color}
                        />
                    </TabPanel>

                    {
                        league.is_knockout === 1 &&
                        <TabPanel>
                            <KnockoutScreen matches={matches}
                                            knockoutRounds={knockoutRounds}
                                            isInternational={(league.is_international === 1)}
                            />
                        </TabPanel>
                    }

                    <TabPanel>
                        <PastWinnersScreen data={pastWinners}
                                           onLoadMore={() => loadPastLeagueWinners()} />
                    </TabPanel>
                </ContentWrapper>
            </TabWrapper>

            <BottomNavWithSheet tabSheetTitle={"What do you want to do?"} tabSheetAction={tabSheetAction}>
                <SelectSheetTab icon={<SoccerIcon/>} text={"Add match in this competition"} onClick={() => setNewMatchSheetOpen(true)}/>
                <SelectSheetTab icon={<TrophyIcon/>} text={"Add season in this competition"} onClick={() => setNewSeasonSheetOpen(true)}/>
            </BottomNavWithSheet>

            <SelectSheet isOpen={newMatchSheetOpen} title={"Add match to competition"} onDismiss={() => setNewMatchSheetOpen(false)}>
                <NewMatchForm competition={league} knockoutRounds={knockoutRounds}/>
            </SelectSheet>

            <SelectSheet isOpen={newSeasonSheetOpen} title={"Add season to competition"} onDismiss={() => setNewSeasonSheetOpen(false)}>
                <NewLeagueForm isBaseDataEditable={true} baseData={{
                    name: league.name,
                    country: league.country,
                    is_knockout: (league.is_knockout === 1) ? true : false,
                    knockout_total_rounds: league.knockout_total_rounds,
                    knockout_with_group_stage: league.knockout_with_group_stage,
                    knockout_with_third_place_final: league.knockout_with_third_place_final
                }} onCreate={item => handleNewSeasonCreation(item)}/>
            </SelectSheet>
        </div>
    )
}

export const LeagueScreen = withAuthorization(WrappedLeagueScreen);