import "./goal-distribution-chart.css";
import {useEffect, useState} from "react";
import {each, findIndex, map, max, round, sum} from "lodash";
import Crest from "../../team/Crest";

export const GoalDistributionChart = ({team, distribution, showLegend = false}) => {

    const [actualDistribution, setActualDistribution] = useState([]);
    const [totalGoals, setTotalGoals] = useState(0);

    useEffect(() => {
        setActualDistribution([...enrichDistributionWithShade(distribution)]);
        setTotalGoals(sum(map(distribution, d => d.amount)));
    }, [distribution]);

    const renderSquares = () => {
        return map(actualDistribution, (d, key) => {
            return (
                <div key={key} className={`distribution-square shade-${d.shade}`}>
                    <p>{d.amount}</p>
                </div>
            )
        })
    }

    return (
        <>
            <div className={"goal-distribution-chart"}>
                <Crest club={team}/>
                <div className={"total"}>
                    <p>{totalGoals}</p>
                </div>
                <div className={"chart"}>
                    {renderSquares()}
                </div>
            </div>

            {showLegend &&
                <div className={"goal-distribution-chart legend"}>
                    <div className={"placeholder"}/>
                    <div className={"placeholder"}/>
                    <div className={"labels"}>
                        <p style={{left: "0%"}}>0'</p>
                        <p style={{left: "15%"}}>15'</p>
                        <p style={{left: "30%"}}>30'</p>
                        <p style={{left: "46%"}}>45'</p>
                        <p style={{left: "62%"}}>60'</p>
                        <p style={{left: "79%"}}>75'</p>
                        <p style={{left: "92%"}}>90'</p>
                    </div>
                </div>
            }
        </>
    )

}

const enrichDistributionWithShade = (distribution) => {
    const distributionCopy = JSON.parse(JSON.stringify(distribution));

    addMissingTimeRanges(distributionCopy);
    calculateShades(distributionCopy);

    return distributionCopy;
}

const addMissingTimeRanges = (distributions = []) => {
    if (findIndex(distributions, d => d.time_range === "0-15") === -1) {
        distributions.splice(0, 0, emptyDistribution("0-15"));
    }

    if (findIndex(distributions, d => d.time_range === "15-30") === -1) {
        distributions.splice(1, 0, emptyDistribution("15-30"))
    }

    if (findIndex(distributions, d => d.time_range === "30-45") === -1) {
        distributions.splice(2, 0, emptyDistribution("30-45"));
    }

    if (findIndex(distributions, d => d.time_range === "45-60") === -1) {
        distributions.splice(3, 0, emptyDistribution("45-60"));
    }

    if (findIndex(distributions, d => d.time_range === "60-75") === -1) {
        distributions.splice(4, 0, emptyDistribution("60-75"));
    }

    if (findIndex(distributions, d => d.time_range === "75-90") === -1) {
        distributions.splice(5, 0, emptyDistribution("75-90"));
    }
}

const calculateShades = (distributions) => {
    const availableShades = 4;
    const values = map(distributions, d => d.amount);
    const highestValue = max(values);
    const steps = highestValue / availableShades;

    each(distributions, d => {
        d.shade = round(d.amount / steps);
    });
}

const emptyDistribution = (timeRange) => {
    return {
        amount: 0,
        time_range: timeRange
    }
}