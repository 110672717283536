import {MatchFactIcon} from "./MatchFactIcon";
import {factTypes, factTypesDetailsPrefix, factTypesExtraDetailsName} from "../../config/constants";

export const DefaultMatchFact = ({data, scoreHome, scoreAway}) => {

    return (
        <>
            <MatchFactIcon factType={data.type} time={data.fact_time}/>
            {
                [factTypes.GOAL, factTypes.OWNGOAL, factTypes.PENALTY_GOAL].includes(data.type) &&
                <div className={"score"}>
                    <p>
                        <span className={"home-score"}>{scoreHome}</span> <span className={"divider"}> - </span> <span className={"away-score"}>{scoreAway}</span>
                    </p>
                </div>
            }
            <div className={"details"}>
                <p>
                    {
                        (factTypesDetailsPrefix[data.type]) ?
                            factTypesDetailsPrefix[data.type] + ": "
                            :
                            ""
                    }
                    {data.details}
                </p>
                {data.extra_details &&
                    <p className="extra">
                        {
                            (factTypesExtraDetailsName[data.type]) ?
                                factTypesExtraDetailsName[data.type] + ": "
                                :
                                ""
                        }
                        {data.extra_details}
                    </p>
                }
            </div>
        </>
    )

}