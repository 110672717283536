import { useState } from "react";
import { useEffect } from "react";
import { getTeamColors } from "../../data/colors";

export const TeamColorsFlag = ({team}) => {

    const [mainColor, setMainColor] = useState("");
    const [secondColor, setSecondColor] = useState("");

    useEffect(() => {
        getTeamColors(team)
            .then((colors) => {
                setMainColor(colors[0]);
                setSecondColor(colors[1]);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div className="team-colors flag">
            <div className="team-color-1" style={{backgroundColor: mainColor}}></div>
            <div className="team-color-2" style={{backgroundColor: secondColor}}></div>
        </div>
    )
}