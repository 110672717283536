export const storage = {
    ACCESS_TOKEN: "access_token",
    ACCESS_TOKEN_EXPIRE_DATE: "access_token_expire_date",
    REFRESH_TOKEN: "refresh_token",
    VERIFICATION_CODE: "verification_code",
    CODE_CHALLENGE: "code_challenge",
    SAVE_ID: "save_id",
    VERSION: "version",
    MATCH_VIEW_MODE: "match_view_mode"
}

export const storageSettings = {
    CURRENCY: "currency"
}

export const api = {
    RESULTS_PER_PAGE: 50
}

let config;

//If the protocol is HTTP -> we are on localhost so we use local environment
//If the protocol is HTTPS -> use prod environment
if (window.location.protocol === "http:") {
    config = {
        BASE_URL: 'http://localhost:3000',
        CALLBACK: 'http://localhost:3000/callback',
        API_URL: 'http://localhost:8081',
        AUTH_URL: 'http://localhost:8080/authorize',
        OAUTH_URL: 'http://localhost:8080',
        CLIENT_ID: 'u5XskHoP-vlFXCmN1-qwJ6P436-Sq3GYFJi',
        DEBUG_REQUESTS: false
    }
} else if (window.location.protocol === "https:") {
    config = {
        BASE_URL: 'https://myscores.sweejee.com',
        CALLBACK: 'https://myscores.sweejee.com/callback',
        API_URL: 'https://api.myscores.sweejee.com',
        AUTH_URL: 'https://account.sweejee.com/authorize',
        OAUTH_URL: 'https://account.sweejee.com',
        CLIENT_ID: 'CZclmTlp-lQgtX8i6-GkgXcXZF-EOzoGA7v',
        DEBUG_REQUESTS: false
    }
}

export {config};

export const codes = {
    TOKEN_EXPIRED: 912,
    TOKEN_INVALID: 913,
    NO_TOKEN: 924,
    SAVE_NOT_FOUND: 926,
    SAVE_NO_ACCESS: 927,
    GENERIC_ERROR: 500,
    ACCESS_REFUSED: 917,
    ITEM_NOT_FOUND: 302
}

export const messages = {
    ERR_UNKNOWN: "An unknown error occurred. Please try again later."
}

export const leagues = {
    NATIONAL: 0,
    CONTINENTAL: 1,
    INTERNATIONAL: 2
}

export const factTypes = {
    GOAL: 'goal',
    OWNGOAL: 'owngoal',
    PENALTY_GOAL: 'penalty_goal',
    PENALTY_MISS: 'penalty_miss',
    YELLOW_CARD: 'yellow_card',
    DOUBLE_YELLOW_CARD: 'double_yellow_card',
    RED_CARD: 'red_card',
    VAR: 'var_check',
    SUB: 'substitution'
}

export const factTypeData = {
    goal: {
        uiName: "Goal",
        uiDetailsPrefix: "",
        uiExtraDetailsPrefix: "Assist: ",
        formDetails: "Goalscorer name",
        formExtraDetails: "Assist giver name"
    },
    owngoal: {
        uiName: "Own goal",
        uiDetailsPrefix: "",
        uiExtraDetailsPrefix: "",
        formDetails: "Player name"
    },
    penalty_goal: {
        uiName: "Penalty scored",
        uiDetailsPrefix: "",
        uiExtraDetailsPrefix: "",
        formDetails: "Goalscorer name"
    },
    penalty_miss: {
        uiName: "Penalty missed",
        uiDetailsPrefix: "",
        uiExtraDetailsPrefix: "",
        formDetails: "Player name",
        formExtraDetails: "Reason"
    },
    yellow_card: {
        uiName: "Yellow card",
        uiDetailsPrefix: "",
        uiExtraDetailsPrefix: "Reason: ",
        formDetails: "Player name",
        formExtraDetails: "Reason"
    },
    double_yellow_card: {
        uiName: "Second yellow card",
        uiDetailsPrefix: "",
        uiExtraDetailsPrefix: "Reason: ",
        formDetails: "Player name",
        formExtraDetails: "Reason"
    },
    red_card: {
        uiName: "Red card",
        uiDetailsPrefix: "",
        uiExtraDetailsPrefix: "Reason: ",
        formDetails: "Player name",
        formExtraDetails: "Reason"
    },
    var_check: {
        uiName: "VAR check",
        uiDetailsPrefix: "Decision: ",
        uiExtraDetailsPrefix: "",
        formDetails: "Decision"
    },
    substitution: {
        uiName: "Substitution",
        uiDetailsPrefix: "In: ",
        uiExtraDetailsPrefix: "Out: ",
        formDetails: "Player in",
        formExtraDetails: "Player out"
    }
}

export const factTypesDetailsPrefix = {
    'substitution': 'In'
}

export const factTypesExtraDetailsName = {
    'goal': 'Assist',
    'penalty_miss': 'Reason',
    'yellow_card': 'Reason',
    'double_yellow_card': 'Reason',
    'red_card': 'Reason',
    'substitution': 'Out'
}

export const goalTypes = [
    factTypes.GOAL,
    factTypes.OWNGOAL,
    factTypes.PENALTY_GOAL
];

export const positionsOrder = ["GK", "D", "DM", "AM", "A"];

export const loaderStatus = {
    LOADING: "loading",
    FAILED: "failed",
    SUCCESS: "success"
};

export const standingPosition = {
    WINNER: "winner",
    CL_QUALIFIED: "cl",
    CL_QUALIFICATION: "cl_qual",
    EL_QUALIFIED: "el",
    EL_QUALIFICATION: "el_qual",
    REL_PLAYOFF: "rel_phase",
    RELEGATED: "rel",
    NEXT_STAGE_QUALIFIED: "next",
    NONE: "none"
}

export const objectiveTypes = {
    BUY: 'buy',
    SELL: 'sell'
}

export const objectivePositions = {
    GK: {
        name: 'Goalkeeper',
        abbr: 'GK',
        color: '#f0bf2e' 
    },
    FBL: {
        name: 'Fullback left',
        abbr: 'FBL',
        color: '#ed1411'
    },
    WBL: {
        name: 'Wingback left',
        abbr: 'WBL',
        color: '#ed1411'
    },
    CB: {
        name: 'Center back',
        abbr: 'CB',
        color: '#ed1411',
    },
    FBR: {
        name: 'Fullback right',
        abbr: 'FBR',
        color: '#ed1411'
    },
    WBR: {
        name: 'Wingback right',
        abbr: 'WBR',
        color: '#ed1411'
    },
    DM: {
        name: 'Defensive midfielder',
        abbr: 'DM',
        color: '#44b340'
    },
    ML: {
        name: 'Midfielder left',
        abbr: 'ML',
        color: '#44b340'
    },
    CM: {
        name: 'Central midfielder',
        abbr: 'CM',
        color: '#44b340'
    },
    MR: {
        name: 'Midfielder right',
        abbr: 'MR',
        color: '#44b340'
    },
    AM: {
        name: 'Attacking midfielder',
        abbr: 'AM',
        color: '#44b340'
    },
    LW: {
        name: 'Left winger',
        abbr: 'LW',
        color: '#4046b3'
    },
    RW: {
        name: 'Right winger',
        abbr: 'RW',
        color: '#4046b3'
    },
    ST: {
        name: 'Striker',
        abbr: 'ST',
        color: '#4046b3'
    }
}

export const matchViewMode = {
    DEFAULT: {
        id: 0,
        className: "default"
    },
    TIMELINE: {
        id: 1,
        className: "timeline"
    },
    COMPACT: {
        id: 2,
        className: "compact"
    }
}