import {each, sortBy} from "lodash";

export const generateTransferListWithLoans = (transfers) => {
    const transferList = [];

    each(transfers, (transfer) => {
        transferList.push(transfer);

        if (transfer.transfer_type === "loan") {
            transferList.push({
                "from_team": transfer.to_team,
                "to_team": transfer.from_team,
                "player": transfer.player,
                "type": "loan_end",
                "cost": 0,
                "transfer_date": transfer.return_date
            })
        }
    })

    return transferList;
}

export const sortTransfersByDate = (transfers) => {
    return sortBy(transfers, (transfer) => {
        let date = new Date(transfer.transfer_date);
        return date;
    });
}