export const PlayerPriorities = Object.freeze({
    LOWEST: {
        name: 'Very low',
        value: 5,
        color: '#42eb8e'
    },
    LOW: {
        name: 'Low',
        value: 4,
        color: '#89e330'
    },
    NORMAL: {
        name: 'Normal',
        value: 3,
        color: '#f0d71a'
    },
    HIGH: {
        name: 'High',
        value: 2,
        color: '#f7892f'
    },
    HIGHEST: {
        name: 'Very high',
        value: 1,
        color: '#f73131'
    },
    INACTIVE: {
        name: 'Abandoned',
        value: -1,
        color: '#cfd4d1'
    }
});