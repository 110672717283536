import {each, map} from "lodash"
import {getRelativeOdds} from "../../data/chance";
import {DifficultyBar} from "../generics/Graphs"
import Crest from "./Crest";
import "./styles/team-form.css";

const getSideWon = (match) => {
    if (match.home_team_score > match.away_team_score) {
        return "home";
    } else if (match.away_team_score > match.home_team_score) {
        return "away";
    } else {
        return "draw";
    }
}

const getHighestBars = (matches, currentTeam) => {
    let highest = 0;

    each(matches, (value) => {
        let sideWon = getSideWon(value);
        let odds = getRelativeOdds(value.home_team_odds, value.away_team_odds);

        if (sideWon === "home") {
            if (odds.away > highest) {
                highest = odds.away;
            }
        } else if (sideWon === "away") {
            if (odds.home > highest) {
                highest = odds.home;
            }
        } else {
            if (odds.home > odds.away) {
                if ((odds.home / 2) > highest) {
                    highest = odds.home / 2;
                }
            } else {
                if ((odds.away / 2) > highest) {
                    highest = odds.away / 2;
                }
            }
        }
    })

    return highest;
}

export const TeamForm = ({ matches, team }) => {

    const renderMatches = () => {
        let relativeOddToCompareWith = getHighestBars(matches, team);
        return map(matches, (match, index) => {
            let opponent = (match.home_team === team) ? match.away_team : match.home_team;
            let opponentSide = (match.home_team === team) ? "away" : "home";
            return <SingleMatch opponent={opponent}
                opponentSide={opponentSide}
                maxOddsPercent={relativeOddToCompareWith}
                match={match}
                key={index} />
        })
    }

    return (
        <div className="team-recent-form">
            <div className={"wrapper"}>
                {renderMatches()}
            </div>
        </div>
    )
}

const SingleMatch = ({ match, opponent, maxOddsPercent, opponentSide }) => {

    return (
        <div className="match">
            <Crest club={opponent} />

            <DifficultyBar homeOdds={match.home_team_odds}
                awayOdds={match.away_team_odds}
                sideWon={getSideWon(match)}
                maxOddsPercent={maxOddsPercent}
                opponentSide={opponentSide} />
        </div>
    )
}