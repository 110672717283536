import React from 'react';
import ReactDOM from 'react-dom';
import './styles/fonts.css';
import './styles/colors.css';
import './styles/main.css';
import './styles/desktop.css';
import App from './App';
import Axios from 'axios';
import { registerInterceptors } from './config/AxiosInterceptors';

registerInterceptors(Axios);

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
