import _ from 'lodash';
import { Link } from 'react-router-dom';
import { monthName } from '../../data/dates';

const MatchPreview = ({ data, team }) => {

    const correctedDate = new Date(data.play_date);

    const getResult = () => {
        if (!team) {
            return false;
        }

        if (team === data.home_team) {
            if (data.home_team_score > data.away_team_score) {
                return "W";
            } else if (data.home_team_score === data.away_team_score) {
                return "D";
            } else {
                return "L";
            }
        } else if (team === data.away_team) {
            if (data.home_team_score > data.away_team_score) {
                return "L";
            } else if (data.home_team_score === data.away_team_score) {
                return "D";
            } else {
                return "W";
            }
        }
    }

    return (
        <Link className={"match-preview-link"} to={"/match/" + data.id}>
            <div className="match-preview">
                <div className="match-date">
                    <p>
                        <span className="day-month">
                            {_.join([
                                correctedDate.getDate(), 
                                monthName(correctedDate.getMonth(), true, true)], " "
                            )}
                        </span>
                        <span className="year">
                            {correctedDate.getFullYear()}
                        </span>
                       
                    </p>
                </div>
                <div className="match-teams">
                    <p className={(data.home_team_score > data.away_team_score) ? "won" : ""}>
                        {data.home_team}
                    </p>
                    <p className={(data.home_team_score < data.away_team_score) ? "won" : ""}>
                        {data.away_team}
                    </p>
                </div>
                <div className="match-score">
                    <p className={(data.home_team_score > data.away_team_score) ? "won" : ""}>
                        {data.home_team_score}
                    </p>
                    <p className={(data.home_team_score < data.away_team_score) ? "won" : ""}>
                        {data.away_team_score}
                    </p>
                </div>
                {team &&
                    <div className={"match-result"}>
                        <div className={"inner-result " + getResult()}>
                            <p>{getResult()}</p>
                        </div>
                    </div>
                }
            </div>
        </Link>
    )
}

export default MatchPreview;