import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const getTransferWindows = async (page) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken 
    }

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/transfer_windows",
            "?saveGame=", saveId,
            "&page=", page
        ], ""),
        headers
    });

    return response;
}

export const getAllTransferWindows = async (limit, offset, orderBy = "id", order = "DESC") => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/transfer_windows?saveGame=${saveId}&limit=${limit}&offset=${offset}&orderBy=${orderBy}&order=${order}`
    });
}

export const getTransferWindowById = async (id) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    }

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/transfer_windows",
            "?saveGame=", saveId,
            "&id=", id
        ], ""),
        headers
    });

    return response;
}

export const createTransferWindow = async (transferWindowData) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            ...transferWindowData
        }
    };

    let response = await Axios({
        method: 'POST',
        url: join([
            config.API_URL,
            "/save/transfer_windows"
        ], ""),
        headers,
        data
    });

    return response;
}

export const updateTransferWindow = async (id, transferWindowData) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'id': id,
            'saveGame': saveId,
            ...transferWindowData
        }
    };


    let response = await Axios({
        method: 'PATCH',
        url: join([
            config.API_URL,
            "/save/transfer_windows"
        ], ""),
        headers,
        data
    });

    return response;
}

export const deleteTransferWindow = async (transferWindowId) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        url: join([
            config.API_URL,
            "/save/transfer_windows",
            "?saveGame=", saveId,
            "&id=", transferWindowId
        ], ""),
        headers
    });

    return response;
}