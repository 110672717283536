import {ContentCard} from "../generics/Cards";
import Crest from "../team/Crest";
import {map, toNumber} from "lodash";
import {useEffect, useState} from "react";
import "./styles/player-team-stats.css";
import {Link} from "react-router-dom";

export const PlayerTeamStats = ({
    data
}) => {

    const [statData, setStatData] = useState({});

    useEffect(() => {
        const cleanData = {
            goals: [],
            assists: [],
            appearances: []
        };

        cleanData.goals = map(data.goals ?? [], stat => {
            console.log("goals:", stat);
            return {
                team: stat.team,
                value: stat.goals ?? "N/A"
            }
        });

        cleanData.assists = map(data.assists ?? [], stat => {
            return {
                team: stat.team,
                value: stat.assists ?? "N/A"
            }
        });

        cleanData.appearances = map(data.appearances ?? [], stat => {
           return {
               team: stat.team,
               value: toNumber(stat.appearances ?? 0) + toNumber(stat.substitutions ?? 0)
           }
        });

        setStatData(cleanData);
    }, [data]);

    return (
        <div className={"player-team-stats"}>
            <h4>Goals</h4>
            <ContentCard>
                <PlayerTeamStat data={statData.goals} />
            </ContentCard>

            <h4>Assists</h4>
            <ContentCard>
                <PlayerTeamStat data={statData.assists} />
            </ContentCard>

            <h4>Appearances</h4>
            <ContentCard>
                <PlayerTeamStat data={statData.appearances} />
            </ContentCard>
        </div>
    )
}

const PlayerTeamStat = ({data}) => {

    const render = () => {
        return map(data, stat => {
            return (
                <Link to={`/team/${stat.team}`}>
                    <div className={"player-team-stat"}>
                        <Crest club={stat.team} />
                        <p className={"team"}>{stat.team}</p>
                        <p className={"value"}>{stat.value}</p>
                    </div>
                </Link>
            )
        })
    }

    return (
        <div className={"player-team-stat-table"}>
            {render()}
        </div>
    )
}