import { map } from "lodash";
import { isFunction } from "lodash"
import { PlayerPriorities } from "../../constants/PlayerPriorities";
import { FormContainer, Input, Select, SelectOption } from "../generics/Forms"

export const NewTransferObjectivePlayerForm = ({
    error,
    onSubmit,
    loading,
    values,
    onChange
}) => {

    const handleChange = (field, value) => {
        if (isFunction(onChange)) {
            onChange.call(this, field, value);
        }
    }

    return(
        <FormContainer error={error} onSubmit={onSubmit} isLoading={loading}>
            <Input type="text"
                value={values.player}
                id="cop-player"
                label="Player"
                onChange={value => handleChange("player", value)}
                max={80}/>

            <Input type="number"
                value={values.amount}
                id="cop-amount"
                label="Max. amount"
                onChange={value => handleChange("amount", value)}/>

            <ObjectivePlayerPrioritySelect value={values.priority} 
                onChange={value => handleChange("priority", value)}
                label="--- Priority ---"/>
        </FormContainer>
    )
}

export const ObjectivePlayerPrioritySelect = ({value, onChange, label}) => {

    const renderOptions = () => {
        let priorities = map(PlayerPriorities, (priority) => { return priority; });
        priorities.sort((a, b) => { return a.value - b.value });
        priorities.filter((value) => { return value.value !== -1 });

        return map(priorities, (priority, index) => {
            return (
                <SelectOption key={index} value={priority.value} content={priority.name}/>
            )
        })
    }

    return(
        <Select label={label} value={value} onChange={val => onChange.call(this, val)}>
            {renderOptions()}
        </Select>
    )
}