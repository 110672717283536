import {delay, each, isEmpty, isFunction, join, map, round} from "lodash";
import {useEffect, useState} from "react";
import {messages, objectivePositions, objectiveTypes} from "../../config/constants";
import {getTextColor} from "../../data/colors";
import {SmoothProgressBar} from "../generics/Graphs";
import {GridRow} from "../generics/Layout";
import {Label, MonetaryAmount, Tag} from "../generics/Text";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import {SelectSheet, SelectSheetTab} from "../generics/Tabs";
import {DeletionModal, LoadingModal, Modal} from "../generics/Modals";
import {Button, ButtonRow} from "../generics/Buttons";
import {Input, Select, SelectOption} from "../generics/Forms";
import {TransferObjectivePositionSelect} from "./TransferObjectivePositionSelect";
import {valuesExist} from "../../data/forms";
import {
    associateTransfer,
    deleteTransferObjective,
    updateTransferObjective
} from "../../service/transferObjectiveService";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {TransferObjectivePlayers} from "./TransferObjectivePlayers";
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import {ObjectivePlayerPrioritySelect} from "./NewTransferObjectivePlayerForm";
import {deleteObjectivePlayer, updateObjectivePlayer} from "../../service/transferObjectivePlayerService";
import {NewTransferForm} from "./NewTransferForm";
import SwapHorizontalIcon from 'mdi-react/SwapHorizontalIcon';
import LinkOffIcon from 'mdi-react/LinkOffIcon';
import SwapHorizontalCircleOutlineIcon from 'mdi-react/SwapHorizontalCircleOutlineIcon';
import {TransferManageModal} from "./TransferManageModal";
import {ContentCard} from "../generics/Cards";

export const TransferObjectives = ({
    transferObjectives,
    transferWindow,
    onUpdate,
    onDelete,
    onCreatePlayer,
    onUpdatePlayer,
    onDeletePlayer,
    onCreateTransfer,
    onUpdateTransfer,
    onDeleteTransfer,
    onRemoveAssociation
}) => {

    const [workingObjective, setWorkingObjective] = useState({});
    const [actionSelectSheetOpen, setActionSelectSheetOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [transferManageModalOpen, setTransferManageModalOpen] = useState(false);

    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const [loadingText, setLoadingText] = useState("");

    const [workingPlayer, setWorkingPlayer] = useState({});
    const [playerActionSheetOpen, setPlayerActionSheetOpen] = useState(false);
    const [playerEditModalOpen, setPlayerEditModalOpen] = useState(false);
    const [playerDeleteModalOpen, setPlayerDeleteModalOpen] = useState(false);

    const [transferCreateOpen, setTransferCreateOpen] = useState(false);

    const handleClickMore = (objective) => {
        setWorkingObjective(objective);
        setActionSelectSheetOpen(true);
    }

    const handleClickMorePlayer = (player) => {
        setWorkingPlayer(player);
        setPlayerActionSheetOpen(true);
    }

    const openEditModal = () => {
        setActionSelectSheetOpen(false);
        setEditModalOpen(true);
    }

    const openDeleteModal = () => {
        setActionSelectSheetOpen(false);
        setDeleteModalOpen(true);
    }

    const openPlayerEditModal = () => {
        setPlayerActionSheetOpen(false);
        setPlayerEditModalOpen(true);
    }

    const openPlayerDeleteModal = () => {
        setPlayerActionSheetOpen(false);
        setPlayerDeleteModalOpen(true);
    }

    const objectiveValueChange = (field, value) => {
        let fields = workingObjective;
        fields[field] = value;
        setWorkingObjective({ ...fields });
    }

    const handleUpdate = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Updating transfer objective");
        setLoadingText("Please wait while the transfer objective is being updated...");

        if (workingObjective.objective_type === objectiveTypes.BUY) {
            if (!valuesExist(workingObjective, "position").result) {
                setLoadingText("You need to specify a position for buying objectives.");
                return closeLoading();
            }
        } else if (workingObjective.objective_type === objectiveTypes.SELL) {
            if (!valuesExist(workingObjective, "player").result) {
                setLoadingText("You need to specify a player for selling objectives.");
                return closeLoading();
            }
        } else {
            setLoadingText("The objective type needs to be either 'buy' or 'sell'.");
            return closeLoading();
        }

        updateTransferObjective(transferWindow.id, workingObjective)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (response.data.changedRows > 0) {
                    if (isFunction(onUpdate)) {
                        setLoadingText("The transfer objective has been updated successfully.");
                        onUpdate.call(this, workingObjective);
                    }
                } else {
                    setLoadingText("There were no changes.");
                }
                
                setEditModalOpen(false);
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const handleDelete = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Deleting transfer objective");
        setLoadingText("Please wait while the transfer objective is being deleted...");

        deleteTransferObjective(transferWindow.id, workingObjective.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (response.data.affectedRows === 1) {
                    setLoadingText("The transfer objective has been deleted successfully.");
                    if (isFunction(onDelete)) {
                        onDelete.call(this, workingObjective);
                    }
                } else {
                    setLoadingText("Could not retrieve the transfer objective to delete.");
                }

                setDeleteModalOpen(false);
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const handlePlayerChange = (field, value) => {
        let fields = { ...workingPlayer };
        fields[field] = value;
        setWorkingPlayer(fields);
    }

    const handlePlayerUpdate = (player = null) => {
        let updatedPlayer = player || workingPlayer;

        setLoadingModalOpen(true);
        setLoadingTitle("Updating objective player");
        setLoadingText("Please wait while the objective player is being updated...");

        let missing = valuesExist(updatedPlayer, "player", "amount", "priority");
        if (!missing.result) {
            setLoadingText("Not all fields have a valid value:", join(missing.missing, ", "));
            return closeLoading();
        }

        updateObjectivePlayer(transferWindow.id, updatedPlayer.transfer_objective_id, updatedPlayer)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (response.data.changedRows > 0) {
                    setLoadingText("The player has been updated successfully.");
                    if (isFunction(onUpdatePlayer)) {
                        onUpdatePlayer.call(this, updatedPlayer);
                    }
                } else {
                    setLoadingText("There were no changes.");
                }

                setPlayerEditModalOpen(false);
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const handleActiveState = () => {
        let player = { ...workingPlayer };

        if (player.active === 0) {
            player.active = 1;
        } else {
            player.active = 0;
        }

        setWorkingPlayer(player);
        handlePlayerUpdate(player);
        setPlayerActionSheetOpen(false);
    }

    const handlePlayerDelete = () => {
        setPlayerDeleteModalOpen(false);
        setLoadingModalOpen(true);
        setLoadingTitle("Deleting objective player");
        setLoadingText("Please wait while the objective player is being deleted.");

        deleteObjectivePlayer(transferWindow.id, workingPlayer.transfer_objective_id, workingPlayer.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (response.data.affectedRows > 0) {
                    if (isFunction(onDeletePlayer)) {
                        onDeletePlayer.call(this, workingPlayer);
                    }
                }

                setLoadingText("The objective player has been deleted successfully.");
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const closeLoading = () => {
        delay(() => {
            setLoadingModalOpen(false);
        }, 700);
    }

    const openTransferModal = () => {
        setActionSelectSheetOpen(false);
        setTransferCreateOpen(true);
    }

    const openTransferManageModal = () => {
        setActionSelectSheetOpen(false);
        setTransferManageModalOpen(true);
    }

    const handleCreateTransfer = (transfer) => {
        setLoadingModalOpen(true);
        setLoadingTitle("Associating transfer");
        setLoadingText("Please wait while the transfer is being associated to this transfer objective...");

        associateTransfer(transferWindow.id, workingObjective.id, transfer[0].id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (isFunction(onCreateTransfer)) {
                    onCreateTransfer.call(this, workingObjective.id, transfer);
                }

                setLoadingText("The transfer has been created and associated to this objective.");
                setTransferCreateOpen(false);
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const removeTransferAssociation = () => {
        setActionSelectSheetOpen(false);
        setLoadingModalOpen(true);
        setLoadingTitle("Removing transfer association");
        setLoadingText("Please wait while the transfer is being unassociated...");

        associateTransfer(transferWindow.id, workingObjective.id, null)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (isFunction(onRemoveAssociation)) {
                    onRemoveAssociation.call(this, workingObjective.id);
                }

                setLoadingText("The transfer has been unassociated successfully.");
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const doTransferUpdate = (transfer) => {
        setTransferManageModalOpen(false);
        if (isFunction(onUpdateTransfer)) {
            onUpdateTransfer.call(this, workingObjective.id, transfer);
        }
    }

    const doTransferDelete = (transfer) => {
        setTransferManageModalOpen(false);
        if (isFunction(onDeleteTransfer)) {
            onDeleteTransfer.call(this, workingObjective.id, transfer);
        }
    }

    const renderObjectives = () => {
        let sortedObjectives = transferObjectives.sort((a, b) => {
            return (a.transfer_id || -1) - (b.transfer_id || -1)
                || a.objective_type.localeCompare(b.objective_type)
        })

        let hasBuy = false, hasSell = false, hasDone = false;

        return map(sortedObjectives, (objective, index) => {
            let label = null;

            if (objective.transfer_id !== null && !hasDone) {
                label = <h4>Completed objectives</h4>;
                hasDone = true;
            }

            if (objective.transfer_id == null && objective.objective_type === objectiveTypes.BUY && !hasBuy) {
                label = <h4>Incoming objectives</h4>;
                hasBuy = true;
            }

            if (objective.transfer_id == null && objective.objective_type === objectiveTypes.SELL && !hasSell) {
                label = <h4>Outgoing objectives</h4>;
                hasSell = true;
            }

            return (
            <>
                {label}
                <TransferObjective key={index}
                    onClickMore={handleClickMore}
                    onCreatePlayer={onCreatePlayer}
                    data={objective}
                    transferWindow={transferWindow}
                    onClickMorePlayer={handleClickMorePlayer} />
            </>
            )
        })
    }

    return (
        <div className="transfer-objectives">
            {renderObjectives()}

            <SelectSheet title={"Manage transfer objective"}
                isOpen={actionSelectSheetOpen}
                onDismiss={() => setActionSelectSheetOpen(false)}>

                {workingObjective.extra_fields && workingObjective.extra_fields.transfer ?
                    <SelectSheetTab text={"Remove transfer association"} icon={<LinkOffIcon />} onClick={removeTransferAssociation} />
                    :
                    <SelectSheetTab text={"Associate transfer"} icon={<SwapHorizontalIcon />} onClick={openTransferModal} />
                }

                {workingObjective.extra_fields && workingObjective.extra_fields.transfer &&
                    <SelectSheetTab text={"Manage transfer"} icon={<SwapHorizontalCircleOutlineIcon/>} onClick={openTransferManageModal} />
                }
                <SelectSheetTab text={"Edit objective"} icon={<PencilOutlineIcon />} onClick={openEditModal} />
                <SelectSheetTab text={"Delete objective"} icon={<DeleteOutlineIcon />} onClick={openDeleteModal} />
            </SelectSheet>

            <SelectSheet title={"Manage objective player"}
                isOpen={playerActionSheetOpen}
                onDismiss={() => setPlayerActionSheetOpen(false)} >

                {workingPlayer.active === 0 ?
                    <SelectSheetTab text="Mark as active" icon={<EyeOutlineIcon />} onClick={handleActiveState} />
                    :
                    <SelectSheetTab text="Mark as inactive" icon={<EyeOffOutlineIcon />} onClick={handleActiveState} />
                }
                <SelectSheetTab text="Edit player" icon={<PencilOutlineIcon />} onClick={openPlayerEditModal} />
                <SelectSheetTab text="Delete player" icon={<DeleteOutlineIcon />} onClick={openPlayerDeleteModal} />
            </SelectSheet>

            <Modal isOpen={editModalOpen} onDismiss={() => setEditModalOpen(false)}>
                <h3>Edit transfer objective</h3>

                <Select label="--- Objective type ---"
                    id="uo-type"
                    value={workingObjective.objective_type}
                    onChange={(value) => objectiveValueChange("objective_type", value)}>
                    <SelectOption value={objectiveTypes.BUY} content="Buy" />
                    <SelectOption value={objectiveTypes.SELL} content="Sell" />
                </Select>

                {
                    workingObjective.objective_type === objectiveTypes.BUY &&

                    <>
                        <Input value={workingObjective.amount} id="uo-amount" onChange={value => objectiveValueChange("amount", value)} label="Max. spending" />
                        <TransferObjectivePositionSelect id="uo-position" value={workingObjective.position}
                            label="--- Playing position ---"
                            onChange={value => objectiveValueChange("position", value)} />
                    </>
                }

                {
                    workingObjective.objective_type === objectiveTypes.SELL &&
                    <>
                        <Input value={workingObjective.amount} id="uo-amount" onChange={value => objectiveValueChange("amount", value)} label="Min. amount" />
                        <Input value={workingObjective.player} id="uo-player" onChange={value => objectiveValueChange("player", value)} label="Player to sell" />
                    </>
                }

                <ButtonRow>
                    <Button onClick={() => setEditModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleUpdate}>
                        Update
                    </Button>
                </ButtonRow>
            </Modal>

            <Modal isOpen={playerEditModalOpen} onDismiss={() => setPlayerEditModalOpen(false)}>
                <h3>Edit objective player</h3>

                <Input type="text"
                    value={workingPlayer.player}
                    label="Player"
                    max={80}
                    onChange={(value) => handlePlayerChange("player", value)} />

                <Input type="number"
                    value={workingPlayer.amount}
                    label="Max. amount"
                    onChange={(value) => handlePlayerChange("amount", value)} />

                <ObjectivePlayerPrioritySelect value={workingPlayer.priority}
                    label="--- Priority ---"
                    onChange={value => handlePlayerChange("priority", value)} />

                <ButtonRow>
                    <Button onClick={() => setPlayerEditModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={() => handlePlayerUpdate(workingPlayer)}>
                        Update
                    </Button>
                </ButtonRow>
            </Modal>

            <DeletionModal isOpen={deleteModalOpen}
                onDismiss={() => setDeleteModalOpen(false)}
                onConfirm={handleDelete}
                title="delete transfer objective?"
                text="You are about to delete this transfer objective permanently."
            />

            <DeletionModal isOpen={playerDeleteModalOpen}
                onDismiss={() => setPlayerDeleteModalOpen(false)}
                onConfirm={() => handlePlayerDelete()}
                title="delete objective player?"
                text="You are about to delete this player from the transfer objective permanently."
            />

            <LoadingModal isOpen={loadingModalOpen}
                onDismiss={() => setLoadingModalOpen(false)}
                title={loadingTitle}
                text={loadingText}
            />

            <SelectSheet title={"Associate a transfer to this objective"} isOpen={transferCreateOpen} onDismiss={() => setTransferCreateOpen(false)}>
                <NewTransferForm onSubmit={(transfer) => handleCreateTransfer(transfer)} />
            </SelectSheet>

            {
                workingObjective.extra_fields && workingObjective.extra_fields.transfer &&
                <TransferManageModal isOpen={transferManageModalOpen}
                    onDismiss={() => setTransferManageModalOpen(false)}
                    transfer={workingObjective.extra_fields.transfer[0]}
                    onUpdate={doTransferUpdate}
                    onDelete={doTransferDelete} />
            }
        </div>
    )
}

export const TransferObjective = ({
    data,
    transferWindow,
    onClickMore,
    onCreatePlayer,
    onClickMorePlayer
}) => {

    const handleMoreClick = () => {
        if (isFunction(onClickMore)) {
            onClickMore.call(this, Object.assign(data));
        }
    }

    return (
        <ContentCard>
            <div className="transfer-objective">
                <div className="icon" onClick={() => handleMoreClick()}>
                    <ChevronDownIcon />
                </div>
                {
                    (data.objective_type === objectiveTypes.BUY) ?
                        <BuyTransferObjective data={data}
                                              baseBudget={transferWindow.base_budget}
                                              transferWindow={transferWindow}
                                              onCreate={onCreatePlayer}
                                              onClickMorePlayer={onClickMorePlayer} />
                        :
                        <SellTransferObjective data={data}
                                               expectedRevenue={transferWindow.expected_revenue} />
                }
            </div>
        </ContentCard>
    )
}

export const BuyTransferObjective = ({
    data,
    baseBudget,
    transferWindow,
    onCreate,
    onClickMorePlayer
}) => {
    return (
        <div className="objective objective-buy">
            <GridRow templateColumns={["40px", "calc(80% - 40px)", "20%"]}>
                <ObjectivePosition position={data.position} />
                <TransferObjectiveTransfer objective={data} />
                <ObjectiveAmount value={data.amount} total={baseBudget} />
            </GridRow>
            <TransferObjectivePlayers objective={data}
                transferWindow={transferWindow}
                onCreate={onCreate}
                onClickMore={onClickMorePlayer} />
        </div>
    )
}

export const SellTransferObjective = ({ data, expectedRevenue }) => {
    return (
        <div className="objective objective-sell">
            <GridRow templateColumns={["60%", "20%", "20%"]}>
                <p className="player">
                    {data.player}
                </p>

                <TransferObjectiveTransfer exclude={["player"]} objective={data} />

                <ObjectiveAmount value={data.amount} total={expectedRevenue} />
            </GridRow>
        </div>
    )
}

export const TransferObjectiveTransfer = ({ objective, exclude }) => {

    const renderTransfer = () => {
        if (objective.extra_fields && objective.extra_fields.transfer && !isEmpty(objective.extra_fields.transfer)) {
            return <TransferObjectiveTransferData exclude={exclude} objective={objective} />
        } else {
            // TODO: Link transfer button
        }
    }

    return (
        <div className="objective-transfer">
            {renderTransfer()}
        </div>
    )
}

export const TransferObjectiveTransferData = ({ objective, exclude = [] }) => {

    const transfer = objective.extra_fields.transfer[0];


    return (
        <div className="transfer-objective-data">
            {!exclude.includes("player") &&
                <Label value="Player" />
            }

            {!exclude.includes("fee") &&
                <Label value="Fee" />
            }

            {!exclude.includes("player") &&
                <p className="player">
                    {transfer.player}
                </p>
            }

            {!exclude.includes("fee") &&
                <TransferObjectiveTransferFee cost={transfer.cost} objectiveAmount={objective.amount} />
            }
        </div>
    )
}

export const TransferObjectiveTransferFee = ({ cost, objectiveAmount }) => {
    const difference = cost - objectiveAmount;
    let pct = round(difference / objectiveAmount * 100, 0);

    if (pct > 0) {
        pct = "+" + pct;
    }

    const differenceClass = (difference > 0) ? "negative" : "positive";

    return (
        <div className="transfer-objective-transfer-fee">
            <MonetaryAmount value={cost} />

            <Tag className={differenceClass} value={join([pct, "%"], "")} />
        </div>
    )
}

export const ObjectiveAmount = ({
    value,
    total
}) => {

    return (
        <div className="objective-amount">
            <MonetaryAmount value={value} />
            <SmoothProgressBar amountLeft={value} total={total} />
        </div>
    )
}

export const ObjectivePosition = ({
    position
}) => {

    const [positionData, setPositionData] = useState({});

    useEffect(() => {
        getPositionData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);

    const getPositionData = () => {
        each(objectivePositions, (pos, key) => {
            if (key === position) {
                setPositionData(pos);
            }
        })
    }

    return (
        <div className="position" style={{
            backgroundColor: positionData.color,
            color: getTextColor(positionData.color)
        }}>
            <p>
                {positionData.abbr}
            </p>
        </div>
    )
}