import {useEffect, useState} from "react"
import {useParams} from "react-router";
import {Tab, TabList, TabPanel} from "react-tabs";
import {api, messages} from "../../config/constants";
import {getTeamColor, getTextColor} from "../../data/colors";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {getMatchesFromTeam} from "../../service/matchService";
import Header from "../generics/Header";
import {BottomNavWithSheet} from "../generics/Navigation";
import NewMatchForm from "../match/NewMatchForm";
import Crest, {HeaderCrest} from "./Crest";
import {TeamForm} from "./TeamForm";
import _, {each, map} from 'lodash';
import {MatchContainer} from "../overview/LeagueMatches";
import {TopPlayers} from "./TopPlayers";
import {
    getTeamAppearances,
    getTeamAppearancesForAllSeasonsAndCompetition,
    getTeamAppearancesForSeason,
    getTeamAppearancesForSeasonAndCompetition,
    getTeamMOTM,
    getTeamMOTMForSeason,
    getTeamSeasons,
    getTeamTopAssistGivers,
    getTeamTopAssistGiversForAllSeasonsAndCompetition,
    getTeamTopAssistGiversForSeasonAndCompetition,
    getTeamTopAssistGiversFromSeason,
    getTeamTopDuos,
    getTeamTopDuosForAllSeasonsAndCompetition,
    getTeamTopDuosForSeason,
    getTeamTopDuosForSeasonAndCompetition,
    getTeamTopMOTMForAllSeasonsAndCompetition,
    getTeamTopMOTMForSeasonAndCompetition,
    getTeamTopScorers,
    getTeamTopScorersForAllSeasonsAndCompetition,
    getTeamTopScorersForSeason,
    getTeamTopScorersForSeasonAndCompetition
} from "../../service/statService";
import {Transfers} from "./Transfers";
import {getTransfersFromTeam} from "../../service/transferService";
import withAuthorization from "../generics/WithAuthorizationHOC";
import {TabWrapper} from "../generics/Tabs";
import {ContentWrapper} from "../generics/Layout";
import "./styles/team-screen.css";
import {ContentCard} from "../generics/Cards";
import {getCompetitionsFromTeam} from "../../service/competitionService";

export const WrappedTeamScreen = (props) => {

    const {teamName} = useParams();

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");

    const [selectedTab, setSelectedTab] = useState(0);
    const [loadedTabs, setLoadedTabs] = useState([]);

    const [headerTitle, setHeaderTitle] = useState(<div className={"header-title-normal"}>
        <p>{teamName}</p>
    </div>);
    const [teamColor, setTeamColor] = useState(null);
    const [headerColor, setHeaderColor] = useState(null);

    const [matches, setMatches] = useState([]);
    const [matchesPage, setMatchesPage] = useState(1);
    const [stopLoadingMatches, setStopLoadingMatches] = useState(false);

    const [seasons, setSeasons] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [statsSelectedCompetition, setStatsSelectedCompetition] = useState("All");
    const [statsSelectedSeason, setStatsSelectedSeason] = useState("All");
    const [topScorers, setTopScorers] = useState([]);
    const [topScorersPage, setTopScorersPage] = useState(1);
    const [stopTopScorers, setStopTopScorers] = useState(false);
    const [topAssists, setTopAssists] = useState([]);
    const [stopTopAssists, setStopTopAssists] = useState(false);
    const [topAssistsPage, setTopAssistsPage] = useState(1);
    const [topAppearances, setTopAppearances] = useState([]);
    const [stopTopAppearances, setStopTopAppearances] = useState(false);
    const [topAppearancesPage, setTopAppearancesPage] = useState(1);
    const [topMOTM, setTopMOTM] = useState([]);
    const [stopTopMOTM, setStopTopMOTM] = useState(false);
    const [topMOTMPage, setTopMOTMPage] = useState(1);
    const [topDuos, setTopDuos] = useState([]);
    const [stopTopDuos, setStopTopDuos] = useState(false);
    const [topDuosPage, setTopDuosPage] = useState(1);

    const [transfers, setTransfers] = useState([]);
    const [transfersPage, setTransfersPage] = useState(1);
    const [stopLoadingTransfers, setStopLoadingTransfers] = useState(false);

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedTab(0);
        setError("");
        setLoadedTabs([...[]]);
        setTeamColor(null);
        setHeaderColor(null);

        setMatches([...[]]);
        setMatchesPage(1);
        setStopLoadingMatches(false);

        setSeasons([...[]]);
        setCompetitions(...[]);
        setStatsSelectedCompetition("All");
        setStatsSelectedSeason("All");
        setTopScorers([...[]]);
        setTopScorersPage(1);
        setStopTopScorers(false);
        setTopAssists([...[]]);
        setTopAssistsPage(1);
        setStopTopAssists(false);
        setTopAppearances([...[]]);
        setTopAppearancesPage(1);
        setStopTopAppearances(false);
        setTopMOTM([...[]]);
        setTopMOTMPage(1);
        setStopTopMOTM(false);
        setTopDuos([...[]]);
        setTopDuosPage(1);
        setStopTopDuos(false);

        setTransfers([...[]]);
        setTransfersPage(1);
        setStopLoadingTransfers(false);


        init();
    }, [teamName]);

    useEffect(() => {
        if (topScorersPage === 1 && topAssistsPage === 1 && topAppearancesPage === 1
            && topMOTMPage === 1 && topDuosPage === 1 && stopTopScorers === false
            && stopTopAppearances === false && stopTopAssists === false
            && stopTopMOTM === false && stopTopDuos === false) {
            if (statsSelectedSeason === "All" && statsSelectedCompetition === "All") {
                getTopScorers();
                getTopAssistGivers();
                getTopAppearances();
                getTopMOTM();
                getTopDuos();
            } else if (statsSelectedSeason !== "All" && statsSelectedCompetition === "All") {
                getTopScorersBySeason(statsSelectedSeason);
                getTopAssistGiversBySeason(statsSelectedSeason);
                getTopAppearancesBySeason(statsSelectedSeason);
                getTopMOTMBySeason(statsSelectedSeason);
                getTopDuosBySeason(statsSelectedSeason);
            } else if (statsSelectedSeason === "All" && statsSelectedCompetition !== "All") {
                // by all seasons from competition
                getTopScorersByAllSeasonsAndCompetition();
                getTopAssistGiversByAllSeasonsAndCompetition();
                getTopAppearancesByAllSeasonsAndCompetition();
                getTopMOTMByAllSeasonsAndCompetition();
                getTopDuosByAllSeasonsAndCompetition();
            } else if (statsSelectedSeason !== "All" && statsSelectedCompetition !== "All") {
                // by season from competition
                getTopScorersBySeasonAndCompetition();
                getTopAssistGiversBySeasonAndCompetition();
                getTopAppearancesBySeasonAndCompetition();
                getTopMOTMBySeasonAndCompetition();
                getTopDuosBySeasonAndCompetition();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statsSelectedSeason, topScorersPage, topAssistsPage, topAppearancesPage, topMOTMPage, topDuosPage, stopTopScorers, stopTopAssists, stopTopAppearances, stopTopMOTM, stopTopDuos]);

    const init = () => {
        getTeamColor(teamName)
            .then((color) => {
                let textColor = getTextColor(color);
                setTeamColor(color);
                setHeaderColor(textColor);
            })
            .catch((err) => {
            });

        getMatchesFromTeam(teamName, 1)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                setMatches(response.data);
                setMatchesPage(2);
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const handleCrestVisibilityChange = (isVisible) => {
        if (isVisible) {
            setHeaderTitle(<div className={"header-title-normal"}>
                <p>{teamName}</p>
            </div>)
        } else {
            setHeaderTitle(
                <div className={"header-title-secondary"}>
                    <Crest club={teamName}/>
                    <p>{teamName}</p>
                </div>
            )
        }
    }

    const loadMoreMatches = () => {
        if (stopLoadingMatches) {
            return;
        }

        getMatchesFromTeam(teamName, matchesPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    setStopLoadingMatches(true);
                    return;
                }

                setMatches([...matches, ...response.data]);

                if (response.data.length === api.RESULTS_PER_PAGE) {
                    setMatchesPage(matchesPage + 1);
                } else {
                    setStopLoadingMatches(true);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                setStopLoadingMatches(true);
            })
    }

    const getCurrentTeamSeasons = () => {
        getTeamSeasons(teamName)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                const responseSeasons = map(response.data, (s) => {
                    return s.season;
                });

                // manually put entry for "all" seasons as this isn't included in the repsonse
                responseSeasons.unshift("All");

                setSeasons(responseSeasons);
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopAssistGivers = () => {
        getTeamTopAssistGivers(teamName, topAssistsPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopAssists(true);
                }

                if (topAssistsPage === 1) {
                    setTopAssists(response.data)
                } else {
                    setTopAssists([...topAssists, ...response.data]);
                }
                setTopAssistsPage(topAssistsPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopAssistGiversBySeason = () => {
        getTeamTopAssistGiversFromSeason(teamName, statsSelectedSeason, topAssistsPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setTopAssists(...[]);
                    return setError(getFriendlyErrorMessage(response.data));
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopAssists(true);
                }

                if (topAssistsPage === 1) {
                    setTopAssists(response.data)
                } else {
                    setTopAssists([...topAssists, ...response.data]);
                }
                setTopAssistsPage(topAppearancesPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                setTopAssists(...[]);
            });
    }

    const getTopAssistGiversByAllSeasonsAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopAssistGiversForAllSeasonsAndCompetition(teamName, json.country, json.name, topAssistsPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopAssists(true);
            }

            if (topAssistsPage === 1) {
                setTopAssists(response.data);
            } else {
                setTopAssists([...topAssists, ...response.data]);
            }
            setTopAssistsPage(topAssistsPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopAssistGiversBySeasonAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopAssistGiversForSeasonAndCompetition(teamName, statsSelectedSeason, json.country, json.name, topAssistsPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopAssists(true);
            }

            if (topAssistsPage === 1) {
                setTopAssists(response.data);
            } else {
                setTopAssists([...topAssists, ...response.data]);
            }
            setTopAssistsPage(topAssistsPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopScorersBySeason = () => {
        getTeamTopScorersForSeason(teamName, statsSelectedSeason, topScorersPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setTopScorers(...[])
                    return setError(getFriendlyErrorMessage(response.data));
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopScorers(true);
                }

                if (topScorersPage === 1) {
                    setTopScorers(response.data);
                } else {
                    setTopScorers([...topScorers, ...response.data]);
                }
                setTopScorersPage(topScorersPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                setTopScorers(...[])
            });
    }

    const getTopScorersByAllSeasonsAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopScorersForAllSeasonsAndCompetition(teamName, json.country, json.name, topScorersPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopScorers(true);
            }

            if (topScorersPage === 1) {
                setTopScorers(response.data);
            } else {
                setTopScorers([...topScorers, ...response.data]);
            }
            setTopScorersPage(topScorersPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopScorersBySeasonAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopScorersForSeasonAndCompetition(teamName, statsSelectedSeason, json.country, json.name, topScorersPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopScorers(true);
            }

            if (topScorersPage === 1) {
                setTopScorers(response.data);
            } else {
                setTopScorers([...topScorers, ...response.data]);
            }
            setTopScorersPage(topScorersPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const handleSelectedSeasonChange = (newSeason) => {
        setStatsSelectedSeason(newSeason);
        setTopScorersPage(1);
        setTopAssistsPage(1);
        setTopAppearancesPage(1);
        setTopMOTMPage(1);
        setTopDuosPage(1);
        setStopTopScorers(false);
        setStopTopAssists(false);
        setStopTopAppearances(false);
        setStopTopMOTM(false);
        setStopTopDuos(false);
    }

    const getTopScorers = () => {
        getTeamTopScorers(teamName, topScorersPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopScorers(true);
                }

                if (topScorersPage === 1) {
                    setTopScorers(response.data);
                } else {
                    setTopScorers([...topScorers, ...response.data]);
                }
                setTopScorersPage(topScorersPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopAppearances = () => {
        getTeamAppearances(teamName, topAppearancesPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopAppearances(true);
                }

                if (topAppearancesPage === 1) {
                    setTopAppearances(response.data);
                } else {
                    setTopAppearances([...topAppearances, ...response.data]);
                }
                setTopAppearancesPage(topAppearancesPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopAppearancesBySeason = () => {
        getTeamAppearancesForSeason(teamName, statsSelectedSeason, topAppearancesPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopAppearances(true);
                }

                if (topAppearancesPage === 1) {
                    setTopAppearances(response.data);
                } else {
                    setTopAppearances([...topAppearances, ...response.data]);
                }
                setTopAppearancesPage(topAppearancesPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopAppearancesByAllSeasonsAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamAppearancesForAllSeasonsAndCompetition(teamName, json.country, json.name, topAppearancesPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopAppearances(true);
            }

            if (topAppearancesPage === 1) {
                setTopAppearances(response.data);
            } else {
                setTopAppearances([...topAppearances, ...response.data]);
            }
            setTopAppearancesPage(topAppearancesPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopAppearancesBySeasonAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamAppearancesForSeasonAndCompetition(teamName, statsSelectedSeason, json.country, json.name, topAppearancesPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopAppearances(true);
            }

            if (topAppearancesPage === 1) {
                setTopAppearances(response.data);
            } else {
                setTopAppearances([...topAppearances, ...response.data]);
            }
            setTopAppearancesPage(topAppearancesPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopMOTM = () => {
        getTeamMOTM(teamName, topMOTMPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopMOTM(true);
                }

                if (topMOTMPage === 1) {
                    setTopMOTM(response.data);
                } else {
                    setTopMOTM([...topMOTM, ...response.data]);
                }
                setTopMOTMPage(topMOTMPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopMOTMBySeason = () => {
        getTeamMOTMForSeason(teamName, statsSelectedSeason, topMOTMPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopMOTM(true);
                }

                if (topMOTMPage === 1) {
                    setTopMOTM(response.data);
                } else {
                    setTopMOTM([...topMOTM, ...response.data]);
                }
                setTopMOTMPage(topMOTMPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopMOTMByAllSeasonsAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopMOTMForAllSeasonsAndCompetition(teamName, json.country, json.name, topMOTMPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopMOTM(true);
            }

            if (topMOTMPage === 1) {
                setTopMOTM(response.data);
            } else {
                setTopMOTM([...topMOTM, ...response.data]);
            }
            setTopMOTMPage(topMOTMPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopMOTMBySeasonAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopMOTMForSeasonAndCompetition(teamName, statsSelectedSeason, json.country, json.name, topMOTMPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopMOTM(true);
            }

            if (topMOTMPage === 1) {
                setTopMOTM(response.data);
            } else {
                setTopMOTM([...topMOTM, ...response.data]);
            }
            setTopMOTMPage(topMOTMPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopDuos = () => {
        getTeamTopDuos(teamName, topDuosPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopDuos(true);
                }

                if (topDuosPage === 1) {
                    setTopDuos(response.data);
                } else {
                    setTopDuos([...topDuos, ...response.data]);
                }
                setTopDuosPage(topDuosPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopDuosBySeason = () => {
        getTeamTopDuosForSeason(teamName, statsSelectedSeason, topDuosPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                if (response.data.length !== api.RESULTS_PER_PAGE) {
                    setStopTopDuos(true);
                }

                if (topDuosPage === 1) {
                    setTopDuos(response.data);
                } else {
                    setTopDuos([...topDuos, ...response.data]);
                }
                setTopDuosPage(topDuosPage + 1);
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTopDuosByAllSeasonsAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopDuosForAllSeasonsAndCompetition(teamName, json.country, json.name, topDuosPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopDuos(true);
            }

            if (topDuosPage === 1) {
                setTopDuos(response.data);
            } else {
                setTopDuos([...topDuos, ...response.data]);
            }
            setTopDuosPage(topDuosPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const getTopDuosBySeasonAndCompetition = async () => {
        try {
            const json = JSON.parse(statsSelectedCompetition);
            const response = await getTeamTopDuosForSeasonAndCompetition(teamName, statsSelectedSeason, json.country, json.name, topDuosPage);

            if (response.data.length !== api.RESULTS_PER_PAGE) {
                setStopTopDuos(true);
            }

            if (topDuosPage === 1) {
                setTopDuos(response.data);
            } else {
                setTopDuos([...topDuos, ...response.data]);
            }
            setTopDuosPage(topDuosPage + 1);
        } catch (err) {
            handleApiError(err);
        }
    }

    const loadMoreTransfers = () => {
        if (stopLoadingTransfers) {
            return;
        }

        getTransfersFromTeam(teamName, transfersPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    setStopLoadingTransfers(true);
                    return;
                }

                setTransfers([...transfers, ...response.data]);

                if (response.data.length === api.RESULTS_PER_PAGE) {
                    setTransfersPage(transfersPage + 1);
                } else {
                    setStopLoadingTransfers(true);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                setStopLoadingTransfers(true);
            })
    }

    const addTransfer = (newTransfer) => {
        setTransfers([...newTransfer, ...transfers]);
    }

    const updateTransfer = (transferToUpdate) => {
        let modifiedTransfers = transfers;
        each(modifiedTransfers, (value, index) => {
            if (value.id === transferToUpdate.id) {
                modifiedTransfers[index] = transferToUpdate;
                return;
            }
        });
        setTransfers(modifiedTransfers);
    }

    const removeTransfer = (transferToRemove) => {
        let modifiedTransfers = {...transfers};
        each(modifiedTransfers, (value, index) => {
            if (value.id === transferToRemove) {
                modifiedTransfers.splice(index, 1);
                return;
            }
        })
        setTransfers(modifiedTransfers);
    }

    const getCurrentTeamCompetitions = () => {
        getCompetitionsFromTeam(teamName)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                response.data.unshift("All");

                setCompetitions([...response.data]);
            })
            .catch((err) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            });
    }

    const handleInitialTabLoad = (newIndex, oldIndex) => {
        setSelectedTab(newIndex);
        if (newIndex === oldIndex) {
            return;
        }

        if (loadedTabs.includes(newIndex)) {
            return;
        }

        switch (newIndex) {
            case 1:
                getTopScorers();
                getTopAssistGivers();
                getTopAppearances();
                getTopMOTM();
                getTopDuos()
                getCurrentTeamSeasons();
                getCurrentTeamCompetitions();
                break;
            case 2:
                loadMoreTransfers();
                break;
            default:
        }

        setLoadedTabs([...loadedTabs, newIndex]);
    }

    const handleStatsLoadMore = (statName) => {
        if (statName === "topScorers" && !stopTopScorers) {
            loadStats({
                all: getTopScorers,
                bySeasonAndAllCompetitions: getTopScorersBySeason,
                byAllSeasonsAndCompetition: getTopScorersByAllSeasonsAndCompetition,
                bySeasonAndCompetition: getTopScorersBySeasonAndCompetition
            });
        } else if (statName === "topAssists" && !stopTopAssists) {
            loadStats({
                all: getTopAssistGivers,
                bySeasonAndAllCompetitions: getTopAssistGiversBySeason,
                byAllSeasonsAndCompetition: getTopAssistGiversByAllSeasonsAndCompetition,
                bySeasonAndCompetition: getTopAssistGiversBySeasonAndCompetition
            });
        } else if (statName === "topAppearances" && !stopTopAppearances) {
            loadStats({
                all: getTopAppearances,
                bySeasonAndAllCompetitions: getTopAppearancesBySeason,
                byAllSeasonsAndCompetition: getTopAppearancesByAllSeasonsAndCompetition,
                bySeasonAndCompetition: getTopAppearancesBySeasonAndCompetition
            });
        } else if (statName === "topMOTM" && !stopTopMOTM) {
            loadStats({
                all: getTopMOTM,
                bySeasonAndAllCompetitions: getTopMOTMBySeason,
                byAllSeasonsAndCompetition: getTopMOTMByAllSeasonsAndCompetition,
                bySeasonAndCompetition: getTopMOTMBySeasonAndCompetition
            });
        } else if (statName === "topDuos") {
            loadStats({
                all: getTopDuos,
                bySeasonAndAllCompetitions: getTopDuosBySeason,
                byAllSeasonsAndCompetition: getTopDuosByAllSeasonsAndCompetition,
                bySeasonAndCompetition: getTopDuosBySeasonAndCompetition
            });
        }
    }

    const handleSelectedCompetitionChange = (newCompetition) => {
        if (newCompetition === "All") {
            setStatsSelectedCompetition("All");
        } else {
            setStatsSelectedCompetition(newCompetition);
        }

        setTopScorersPage(1);
        setTopAssistsPage(1);
        setTopAppearancesPage(1);
        setTopMOTMPage(1);
        setTopDuosPage(1);
        setStopTopScorers(false);
        setStopTopAssists(false);
        setStopTopAppearances(false);
        setStopTopMOTM(false);
        setStopTopDuos(false);
    }

    const loadStats = ({
                           all,
                           bySeasonAndAllCompetitions,
                           byAllSeasonsAndCompetition,
                           bySeasonAndCompetition,
                       }) => {
        if (statsSelectedSeason === "All" && statsSelectedCompetition === "All") {
            all();
        } else if (statsSelectedSeason !== "All" && statsSelectedCompetition === "All") {
            bySeasonAndAllCompetitions();
        } else if (statsSelectedSeason === "All" && statsSelectedCompetition !== "All") {
            byAllSeasonsAndCompetition();
        } else if (statsSelectedSeason !== "All" && statsSelectedCompetition !== "All") {
            bySeasonAndCompetition();
        }
    }

    const handleApiError = (error) => {
        if (error.response && error.response.data) {
            setError(getFriendlyErrorMessage(error.response.data));
        } else {
            setError(messages.ERR_UNKNOWN);
        }
    }

    return (
        <div className={"team-screen"}>
            <Header title={headerTitle} background={teamColor} color={headerColor}/>
            <HeaderCrest club={teamName} background={teamColor} onVisibilityChange={handleCrestVisibilityChange}/>

            <TabWrapper selectedIndex={selectedTab}
                        onSelect={(newIndex, oldIndex) => handleInitialTabLoad(newIndex, oldIndex)}>
                <TabList style={{backgroundColor: teamColor, color: headerColor}}>
                    <Tab>Matches</Tab>
                    <Tab>Best players</Tab>
                    <Tab>Transfers</Tab>
                </TabList>

                <ContentWrapper>
                    <TabPanel className={"latest-matches"}>
                        <h4>Recent form</h4>
                        <ContentCard className={"team-recent-form-card"}>
                            <TeamForm matches={_.take(matches, 10)} team={teamName}/>
                        </ContentCard>

                        <h4>Latest matches</h4>

                        <MatchContainer
                            data={matches}
                            team={teamName}
                            onContinueLoading={loadMoreMatches}/>
                    </TabPanel>
                    <TabPanel>
                        <TopPlayers
                            topScorers={topScorers}
                            topAssists={topAssists}
                            topAppearances={topAppearances}
                            topMOTM={topMOTM}
                            topDuos={topDuos}
                            seasons={seasons}
                            filterCompetition={statsSelectedCompetition}
                            competitions={competitions}
                            onSelectedCompetitionChange={(newCompetition) => handleSelectedCompetitionChange(newCompetition)}
                            filterSeason={statsSelectedSeason}
                            onSelectedSeasonChange={(newSeason) => handleSelectedSeasonChange(newSeason)}
                            onLoadMore={(stat) => handleStatsLoadMore(stat)}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Transfers
                            team={teamName}
                            data={transfers}
                            onContinueLoading={loadMoreTransfers}
                            teamColor={teamColor} color={headerColor}
                            onCreateTransfer={transfer => addTransfer(transfer)}
                            onUpdateTransfer={(transfer) => updateTransfer(transfer)}
                            onDeleteTransfer={(id) => removeTransfer(id)}
                        />
                    </TabPanel>
                </ContentWrapper>
            </TabWrapper>

            <BottomNavWithSheet tabSheetTitle={"New match"}>
                <NewMatchForm/>
            </BottomNavWithSheet>
        </div>
    )
}


export const TeamScreen = withAuthorization(WrappedTeamScreen);