import { isFunction, map } from "lodash"
import {FullScreenSelect, Select, SelectOption} from "../generics/Forms"

export const KnockoutRoundSelect = ({
    onChange,
    rounds,
    value
}) => {

    const handleChange = (value) => {
        if (isFunction(onChange)) {
            onChange.call(this, value);
        }
    }

    const renderOptions = () => {
        return map(rounds, (value, index) => {
            return (
                <SelectOption content={value.label}
                    value={value.id}
                    key={index}/>
            )
        })
    }

    return (
        <FullScreenSelect value={value}
            onChange={(value) => handleChange(value)}
            label={"Choose round"}
            info={"Choose which round this match takes place in. The higher the round number, the later it is in the competition."}>
            {renderOptions()}
        </FullScreenSelect>
    )
}