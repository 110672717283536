import {slice} from 'lodash';
import filter from 'lodash.filter';
import {useEffect, useState} from 'react';
import {leagues} from '../../config/constants';
import {relatives} from '../../data/chance';
import {Toggle} from '../generics/Forms';
import {HorizontalBarChart} from '../generics/Graphs';
import {MatchContainer} from '../overview/LeagueMatches';
import Crest from '../team/Crest';
import "./head-to-head.css";
import {ContentCard} from "../generics/Cards";
import {PullToRefreshWrapper} from "../generics/RefreshWrapper";

export const H2HTeam = ({name, wins, league}) => {

    return (
        <div className="h2h-team">
            <Crest club={name} isInternational={(league === leagues.INTERNATIONAL)}/>
            <div className="data">
                <p className={"team-wins"}>
                    {wins}
                </p>
                <p className={"team-name"}>
                    {name}
                </p>
            </div>
        </div>
    )
}

export const HeadToHead = ({data, homeTeam, awayTeam, league, onRefresh}) => {

    const [wins, setWins] = useState(0);
    const [draws, setDraws] = useState(0);
    const [losses, setLosses] = useState(0);
    const [winPct, setWinPct] = useState([0.00, 0.00, 0.00]);

    const [onlyRecentMatches, setOnlyRecentMatches] = useState(true);
    const [onlyAtHomeTeam, setOnlyAtHomeTeam] = useState(false);
    const [onlyAtAwayTeam, setOnlyAtAwayTeam] = useState(false);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        let calculatedWins = getWins();
        let calculatedDraws = getDraws();
        let calculatedLosses = getLosses();

        setWins(calculatedWins);
        setDraws(calculatedDraws);
        setLosses(calculatedLosses);
        setWinPct(relatives([calculatedWins, calculatedDraws, calculatedLosses]));

        if (onlyRecentMatches === true) {
            setMatches(slice(data, 0, 20));
        } else {
            setMatches([...data]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, onlyRecentMatches, onlyAtHomeTeam, onlyAtAwayTeam]);

    const applyFilters = (filterData) => {
        if (onlyRecentMatches) {
            filterData = slice(filterData, 0, 20);
        }

        if (onlyAtHomeTeam === true) {
            filterData = filter(filterData, match => match.home_team === homeTeam);
        }

        if (onlyAtAwayTeam === true) {
            filterData = filter(filterData, match => match.home_team === awayTeam);
        }

        return filterData;
    }

    const getWins = () => {
        let filterData = [...data];

        filterData = applyFilters(filterData);

        return filter(filterData, (value) => {
            return (
                (value.home_team === homeTeam && value.home_team_score > value.away_team_score)
                ||
                (value.away_team === homeTeam && value.away_team_score > value.home_team_score)
            );
        }).length;
    }

    const getDraws = () => {
        let filterData = [...data];

        filterData = applyFilters(filterData);

        return filter(filterData, (value) => {
            return (
                (value.home_team_score === value.away_team_score)
            )
        }).length;
    }

    const getLosses = () => {
        let filterData = [...data];

        filterData = applyFilters(filterData);

        return filter(filterData, (value) => {
            return (
                (value.home_team === homeTeam && value.home_team_score < value.away_team_score)
                ||
                (value.away_team === homeTeam && value.away_team_score < value.home_team_score)
            );
        }).length;
    }

    return (
        <PullToRefreshWrapper onRefresh={onRefresh}>
            <div className={"head-to-head-wrapper"}>
                <div className={"head-to-head-header"}>

                    <h4>Head to head</h4>
                    <ContentCard>
                        <Toggle value={onlyRecentMatches}
                                label="Last 20 games"
                                onChange={(value) => setOnlyRecentMatches(value)}/>
                        <Toggle value={onlyAtHomeTeam}
                                label={<>
                                    <Crest club={homeTeam}/>
                                    Only at {homeTeam}
                                </>}
                                onChange={(value) => {
                                    setOnlyAtHomeTeam(value);
                                    if (value === true && onlyAtAwayTeam === true) {
                                        setOnlyAtAwayTeam(false);
                                    }
                                }}/>

                        <Toggle value={onlyAtAwayTeam}
                                label={<>
                                    <Crest club={awayTeam}/>
                                    Only at {awayTeam}
                                </>}
                                onChange={(value) => {
                                    setOnlyAtAwayTeam(value);
                                    if (value === true && onlyAtHomeTeam === true) {
                                        setOnlyAtHomeTeam(false);
                                    }
                                }}/>
                        <div className={"h2h-teams"}>
                            <H2HTeam name={homeTeam} wins={wins} league={league}/>
                            <p className={"team-draws"}>
                                {draws}
                            </p>
                            <H2HTeam name={awayTeam} wins={losses} league={league}/>
                        </div>

                        <HorizontalBarChart progressLeft={winPct[0]}
                                            progressRight={winPct[2]}/>
                    </ContentCard>
                </div>

                <h4>Past encounters</h4>
                <div className={"head-to-head-matches"}>
                    <MatchContainer data={matches}/>
                </div>
            </div>
        </PullToRefreshWrapper>
    )
}