import {TopScorers} from "./match-stats/TopScorers";
import React from "react";
import {MatchAverageGoals} from "./match-stats/MatchAverageGoals";
import {GoalDistributionChart} from "./match-stats/GoalDistributionChart";
import {ContentCard} from "../generics/Cards";
import {PullToRefreshWrapper} from "../generics/RefreshWrapper";

export const MatchStats = ({match, matchTopScorers, matchAverageGoals, matchGoalDistribution, onRefresh}) => {

    return (
        <PullToRefreshWrapper onRefresh={onRefresh}>
            <div className={"match-stats"}>
                <TopScorers data={matchTopScorers}
                            homeTeam={match.home_team}
                            awayTeam={match.away_team}/>

                <MatchAverageGoals match={match}
                                   matchAverageGoals={matchAverageGoals} />

                <h4>Goal distribution</h4>

                <ContentCard>
                    <GoalDistributionChart team={match.home_team}
                                           distribution={matchGoalDistribution?.teamOne ?? []} />
                    <GoalDistributionChart team={match.away_team}
                                           showLegend={true}
                                           distribution={matchGoalDistribution?.teamTwo ?? []} />
                </ContentCard>
            </div>
        </PullToRefreshWrapper>
    )

}