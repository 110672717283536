import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router"
import {Tab, TabList, TabPanel} from "react-tabs";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {createLineupTemplate, deleteLineup, getLineupTemplates, getMatchLineups} from "../../service/lineupService";
import {
    deleteMatchFact as deleteFact,
    getMatchFactsFromMatch,
    updateMatchFact as updateFact
} from "../../service/matchFactService";
import {
    deleteMatch as deleteMatchInDb,
    getMatchAverageGoals,
    getMatchById,
    getMatchesBetweenTeams,
    getMatchGoalDistribution,
    getMatchTopScorers,
    updateMatch as updateMatchInDb
} from "../../service/matchService";
import Header from "../generics/Header";
import {BottomNavWithSheet} from "../generics/Navigation";
import {Lineups} from "../lineup/Lineups";
import MatchFacts from "./MatchFacts";
import MatchHeader from "./MatchHeader";
import NewMatchFactForm from "./NewMatchFactForm";
import _, {isEmpty} from 'lodash';
import {matchViewMode, messages, storage} from "../../config/constants";
import {GenericMatchData} from "./GenericMatchData";
import {HeadToHead} from "./HeadToHead";
import {MatchMeta} from "./MatchMeta";
import {DeletionModal, Modal} from "../generics/Modals";
import {CircularLoaderInfinite} from "../generics/Loaders";
import {LineupInformation} from "./LineupInformation";
import withAuthorization from "../generics/WithAuthorizationHOC";
import {SelectSheet, TabWrapper} from "../generics/Tabs";
import {createLineupPositions} from "../../service/lineupPositionsService";
import {KeyValueTable} from "../generics/Tables";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import {getReadableDate} from "../../data/util";
import {ContentWrapper} from "../generics/Layout";
import "./match-details.css";
import {MatchStats} from "./MatchStats";
import Crest from "../team/Crest";

export const WrappedMatchDetails = (props) => {

    const history = useHistory();

    const {matchId} = useParams();

    const [viewMode, setViewMode] = useState(localStorage.getItem(storage.MATCH_VIEW_MODE) || matchViewMode.DEFAULT.id);
    const [headerTitle, setHeaderTitle] = useState(<div className={"header-title-normal"}>
        <p>Match details</p>
    </div>);

    const [loadedTabs, setLoadedTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);

    const [match, setMatch] = useState({});
    const [matchFacts, setMatchFacts] = useState([]);

    const [lineupPositionsHome, setLineupPositionsHome] = useState([]);
    const [lineupPositionsAway, setLineupPositionsAway] = useState([]);
    const [lineupHome, setLineupHome] = useState(null);
    const [lineupAway, setLineupAway] = useState(null);
    const [lineupTemplates, setLineupTemplates] = useState([]);

    const [headToHeadMatches, setHeadToHeadMatches] = useState([]);

    const [matchTopScorers, setMatchTopScorers] = useState([]);
    const [matchAverageGoals, setMatchAverageGoals] = useState({});
    const [matchGoalDistribution, setMatchGoalDistribution] = useState({});

    const [tabSheetAction, setTabSheetAction] = useState("none");

    const [templateDeleteModalOpen, setTemplateDeleteModalOpen] = useState(false);
    const [templateForDeletion, setTemplateForDeletion] = useState({});
    const [modalShown, setModalShown] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalText, setModalText] = useState("");

    const [matchInfoOpen, setMatchInfoOpen] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);

    useEffect(() => {
        if (tabSheetAction === "close") {
            setTabSheetAction("none");
        }
    }, [tabSheetAction]);

    useEffect(() => {
        setCurrentTab(1);
        setLoadedTabs([]);
        setMatchFacts([]);
        setLineupPositionsHome([]);
        setLineupPositionsAway([]);
        setLineupHome(null);
        setLineupAway(null);
        getMatch();
        getInitialMatchFacts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchId]);

    const getMatch = () => {
        getMatchById(matchId)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setError(null);
                    setMatch(response.data[0]);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError("Unknown error while getting match information.");
                }
            })
    }

    const getInitialMatchFacts = () => {
        getMatchFactsFromMatch(matchId)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setError(null);
                    setMatchFacts(response.data);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError("Unknown error while getting match facts.");
                }
            })
    }

    const getLineups = () => {
        getMatchLineups(matchId)
            .then((response) => {
                if (_.isEmpty(response)) {
                    return;
                }

                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                response.data.forEach((lineup) => {
                    if (lineup.extra_fields && lineup.extra_fields.positions) {
                        if (lineup.side === "home") {
                            setLineupPositionsHome(lineup.extra_fields.positions);
                            setLineupHome(lineup);
                        } else if (lineup.side === "away") {
                            setLineupPositionsAway(lineup.extra_fields.positions);
                            setLineupAway(lineup);
                        }
                    }
                });
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadLineupTemplates = () => {
        getLineupTemplates()
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                setLineupTemplates(response.data);
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const lineupDeleted = (side) => {
        if (side === "home") {
            setLineupHome(null);
            setLineupPositionsHome([]);
        } else {
            setLineupAway(null);
            setLineupPositionsAway([]);
        }
    }

    const positionUpdated = (player, side) => {
        if (side === "home") {
            let positions = [...lineupPositionsHome];
            let index = positions.findIndex(position => position.id === player.id);
            positions[index] = player;
            setLineupPositionsHome(positions);
        } else {
            let positions = [...lineupPositionsAway];
            let index = positions.findIndex(position => position.id === player.id);
            positions[index] = player;
            setLineupPositionsAway(positions);
        }
    }

    const lineupCreated = (lineup, positions, side) => {
        if (side === "home") {
            setLineupHome(lineup);
            setLineupPositionsHome(positions);
        } else {
            setLineupAway(lineup);
            setLineupPositionsAway(positions)
        }
    }

    const appendMatchFact = (newFact) => {
        setMatchFacts([...matchFacts, newFact]);
        setTabSheetAction("close");
    }

    const getHeadToHeadMatches = async () => {
        getMatchesBetweenTeams([match.home_team, match.away_team])
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                setHeadToHeadMatches(response.data);
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data.type));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const refreshHeadToHeadMatches = async () => {
        await getHeadToHeadMatches();
        return true;
    }

    const getMatchStats = async () => {
        try {
            const topScorersResponse = await getMatchTopScorers([match.home_team, match.away_team]);
            const averageGoalsResponse = await getMatchAverageGoals(match.home_team, match.away_team);
            const goalDistributionResponse = await getMatchGoalDistribution(match.home_team, match.away_team);

            setMatchTopScorers(topScorersResponse.data);
            setMatchAverageGoals(averageGoalsResponse.data);
            setMatchGoalDistribution(goalDistributionResponse.data);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.type) {
                setError(getFriendlyErrorMessage(err.response.data));
            } else {
                setError(messages.ERR_UNKNOWN);
            }
        }
    }

    const refreshMatchStats = async () => {
        await getMatchStats();
        return true;
    }

    const updateMatch = (updatedData) => {
        setModalShown(true);
        setModalTitle("Updating match...");
        setModalText("Please wait while the match is being updated...");
        updateMatchInDb(match.id, updatedData)
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    return hideModal();
                }

                if (isEmpty(response.data)) {
                    setModalText("The match has been updated, please refresh to view the new changes.");
                } else {
                    setMatch({...response.data[0]});
                    setModalText("The match has been updated successfully.");
                }
                hideModal();
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setModalText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal();
            })
    }

    const deleteMatch = () => {
        setModalShown(true);
        setModalTitle("Deleting match");
        setModalText("Please wait while the match is being deleted...");

        deleteMatchInDb(match.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    return hideModal();
                }

                setModalText("The match has been deleted successfully. You will be redirected to the match overview.");
                _.delay(() => {
                    setModalShown(false);
                    history.push("/overview");
                }, 2000);
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setModalText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal();
            })
    }

    const hideModal = () => {
        _.delay(() => {
            setModalShown(false);
        }, 700);
    }

    const closeModal = () => {
        setModalShown(false);
    }

    const handleInitialTabLoad = (newIndex, oldIndex) => {
        if (newIndex === oldIndex) {
            return;
        }

        setCurrentTab(newIndex);

        if (loadedTabs.includes(newIndex)) {
            return;
        }

        if (!match.home_team || !match.away_team) {
            return;
        }

        switch (newIndex) {
            case 1:

                break;
            case 2:
                getLineups();
                loadLineupTemplates();
                break;
            case 3:
                getHeadToHeadMatches();
                break;
            case 4:
                getMatchStats();
                break;
            default:

                break;
        }

        setLoadedTabs([...loadedTabs, newIndex]);
    }

    const updateMatchFact = (updatedMatchFact) => {
        setModalShown(true);
        setModalTitle("Updating match fact");
        setModalText("Please wait while the match fact is being updated...");
        updateFact(updatedMatchFact)
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    return hideModal();
                }

                if (response.data.changedRows === 0) {
                    setModalText("There was nothing to update.");
                } else {
                    setModalText("The match fact has been updated successfully.");
                    updateFactInState(updatedMatchFact);
                }
                hideModal();
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setModalText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal();
            })
    }

    const updateFactInState = (updatedFact) => {
        let facts = matchFacts;
        _.each(facts, (value, index) => {
            if (value.id === updatedFact.id) {
                facts[index] = updatedFact;
                return;
            }
        })
        setMatchFacts(facts);
    }

    const deleteMatchFact = (matchFact) => {
        setModalShown(true);
        setModalTitle("Deleting match fact");
        setModalText("Please wait while the match fact is being deleted.");

        deleteFact(match.id, matchFact.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    return hideModal();
                }

                setModalText("The match fact has been deleted successfully.");
                deleteFactFromState(matchFact);
                hideModal();
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setModalText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal();
            })
    }

    const deleteFactFromState = (fact) => {
        let facts = matchFacts;
        _.remove(facts, (value) => {
            return value.id === fact.id;
        })
        setMatchFacts(facts);
    }

    const createNewLineupTemplate = async (data) => {
        setModalShown(true);
        setModalTitle("Creating lineup template");
        setModalText("Creating a new lineup template.");

        try {
            const lineupResponse = await createLineupTemplate({
                side: data.side,
                template_name: data.template_name
            });

            const positions = await createLineupPositions(lineupResponse.data[0].id, null, data.positions);

            const createdLineupTemplate =
                {
                    id: lineupResponse.data[0].id,
                    side: lineupResponse.data[0].side,
                    is_template: 1,
                    template_name: lineupResponse.data[0].template_name,
                    extra_fields: {
                        positions: positions.data
                    }
                };

            setLineupTemplates([createdLineupTemplate, ...lineupTemplates]);

            setModalText("The lineup template has been created! Don't forget to also publish this lineup to link it to the match.");

            hideModal();
        } catch (err) {
            if (err.response && err.response.data && err.response.data.type) {
                setModalText(getFriendlyErrorMessage(err.response.data));
            } else {
                setModalText(messages.ERR_UNKNOWN);
            }
            hideModal();
        }
    }

    const handleTemplateDelete = async (template) => {
        setTemplateDeleteModalOpen(true);
        setTemplateForDeletion(template);
    }

    const proceedWithTemplateDeletion = async () => {
        setTemplateDeleteModalOpen(false);
        const template = templateForDeletion;
        setModalShown(true);
        setModalTitle("Deleting lineup template");
        setModalText(`The lineup template '${template.name}' is being deleted...`);

        try {
            const response = await deleteLineup(null, template.id);

            if (response.data.affectedRows === 1) {
                let templates = [...lineupTemplates];

                _.remove(templates, (t) => {
                    return t.id === template.id
                });

                setLineupTemplates(templates);
                setModalText("The lineup has been deleted successfully!");
                hideModal();
            } else {
                setModalText("Could not delete this lineup template. Please try again later.");
                hideModal();
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.type) {
                setModalText(getFriendlyErrorMessage(err.response.data));
            } else {
                setModalText(messages.ERR_UNKNOWN);
            }
            hideModal();
        } finally {
            setTemplateForDeletion(Object.assign({}, {}));
        }
    }

    const handleScoreVisibilityChanged = (isVisible) => {
        if (isVisible) {
            setHeaderTitle(<div className={"header-title-normal"}>
                <p>Match details</p>
            </div>);
        } else {
            setHeaderTitle(
                <div className={"header-title-secondary"}>
                    <Crest club={match.home_team}/>
                    <p>
                        {match.home_team_score}&nbsp;-&nbsp;{match.away_team_score}
                    </p>
                    <Crest club={match.away_team}/>
                </div>
            );
        }
    }

    return (
        <>
            <Header title={headerTitle}
                    background={"var(--header-match-details)"}
                    withExtrasButton={true}
                    extrasButtonIcon={<InformationOutlineIcon/>}
                    onExtraButtonClick={() => setMatchInfoOpen(true)}
            />

            <TabWrapper selectedIndex={currentTab}
                        onSelect={(newIndex, oldIndex) => handleInitialTabLoad(newIndex, oldIndex)}>
                <TabList style={{backgroundColor: "var(--header-match-details)"}}>
                    <Tab>Meta</Tab>
                    <Tab>Details</Tab>
                    <Tab>Lineups</Tab>
                    <Tab>Head to Head</Tab>
                    <Tab>Stats</Tab>
                </TabList>

                <ContentWrapper>
                    <TabPanel>
                        <MatchMeta match={match}
                                   onUpdate={updatedData => updateMatch(updatedData)}
                                   onDelete={deleteMatch}/>
                    </TabPanel>

                    <TabPanel className={"match-details"}>
                        <MatchHeader data={match} matchFacts={matchFacts}
                                     onScoreVisibleChange={handleScoreVisibilityChanged}/>

                        <h4>Events</h4>
                        <MatchFacts data={matchFacts}
                                    match={match}
                                    viewMode={viewMode}
                                    onUpdate={(fact) => updateMatchFact(fact)}
                                    onDelete={(fact) => deleteMatchFact(fact)}/>

                        <h4>About</h4>
                        <GenericMatchData match={match}/>
                    </TabPanel>

                    <TabPanel>
                        <Lineups matchId={match.id}
                                 onTemplateDelete={(t) => handleTemplateDelete(t)}
                                 matchFacts={matchFacts}
                                 homeTeam={match.home_team}
                                 awayTeam={match.away_team}
                                 lineupHome={lineupHome}
                                 lineupAway={lineupAway}
                                 lineupPositionsHome={lineupPositionsHome}
                                 lineupPositionsAway={lineupPositionsAway}
                                 lineupTemplates={lineupTemplates}
                                 onDelete={(side) => lineupDeleted(side)}
                                 onUpdate={(player, side) => positionUpdated(player, side)}
                                 onCreate={(lineup, positions, side) => lineupCreated(lineup, positions, side)}
                                 onCreateTemplate={(data) => createNewLineupTemplate(data)}
                        />

                        <LineupInformation
                            match={match}/>
                    </TabPanel>

                    <TabPanel>
                        <HeadToHead data={headToHeadMatches}
                                    homeTeam={match.home_team}
                                    awayTeam={match.away_team}
                                    league={match.league}
                                    onRefresh={refreshHeadToHeadMatches} />
                    </TabPanel>

                    <TabPanel>
                        <MatchStats match={match}
                                    matchTopScorers={matchTopScorers}
                                    matchAverageGoals={matchAverageGoals}
                                    matchGoalDistribution={matchGoalDistribution}
                                    onRefresh={refreshMatchStats}
                        />
                    </TabPanel>
                </ContentWrapper>
            </TabWrapper>

            <Modal isOpen={modalShown} onDismiss={closeModal}>
                <h3>{modalTitle}</h3>

                <p>
                    {modalText}
                </p>

                <CircularLoaderInfinite/>
            </Modal>

            <DeletionModal onDismiss={() => setTemplateDeleteModalOpen(false)}
                           onConfirm={() => proceedWithTemplateDeletion()}
                           isOpen={templateDeleteModalOpen}
                           title={"Delete this template?"}
                           text={`Do you want to permanently delete this template? 
                                This won't affect any lineups you've created using this template.`}
            />

            <SelectSheet isOpen={matchInfoOpen} onDismiss={() => setMatchInfoOpen(false)} title={"Match info"}>
                <KeyValueTable values={[
                    {
                        name: "Created at",
                        value: getReadableDate(match.created_at, {showTime: true, zeroTimestampShowsUnknown: true})
                    },
                    {
                        name: "Updated at",
                        value: getReadableDate(match.updated_at, {showTime: true, zeroTimestampShowsUnknown: true})
                    },
                    {
                        name: "ID",
                        value: match.id
                    }
                ]}/>
            </SelectSheet>

            <BottomNavWithSheet tabSheetTitle={"New match fact"} tabSheetAction={tabSheetAction}
                                addBackgroundColor={"var(--bottom-plus-match-details)"}>
                <NewMatchFactForm match={match} onNewFact={(newFact) => appendMatchFact(newFact)}/>
            </BottomNavWithSheet>
        </>
    )
}

export const MatchDetails = withAuthorization(WrappedMatchDetails);