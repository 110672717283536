import { delay, isFunction } from "lodash"
import { useEffect, useState } from "react"
import { messages } from "../../config/constants"
import { getSimpleDate } from "../../data/dates"
import { getFriendlyErrorMessage } from "../../service/errorService"
import { deleteTransfer, updateTransfer } from "../../service/transferService"
import { Button, ButtonRow, DangerButton } from "../generics/Buttons"
import {Input, InputRegex, Select, SelectOption, TeamNameInput} from "../generics/Forms"
import { Row } from "../generics/Layout"
import { DeletionModal, LoadingModal, Modal } from "../generics/Modals"

export const TransferManageModal = ({
    isOpen,
    onDismiss,
    transfer,
    onUpdate,
    onDelete,
}) => {

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const [loadingText, setLoadingText] = useState("");

    const [values, setValues] = useState({});

    useEffect(() => {
        setValues({
            ...transfer,
            transfer_date: getSimpleDate(transfer.transfer_date),
            return_date: getSimpleDate(transfer.return_date)
        });
    }, [transfer]);

    const addNewValue = (value, field) => {
        let fields = {...values};
        fields[field] = value;
        setValues(fields);
    }

    const doUpdate = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Updating transfer...");
        setLoadingText("Please wait while the transfer is being updated.");

        let data = {...values};

        if (values.transfer_type === "career_end") {
            data.cost = 0;
            data.to_team = "";
        }

        updateTransfer(data)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                setLoadingText("The transfer has been updated successfully.");

                if (isFunction(onUpdate)) {
                    onUpdate.call(this, values);
                }

                closeLoading();
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setLoadingText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const doDelete = () => {
        setDeleteModalOpen(false);
        setLoadingModalOpen(true);
        setLoadingTitle("Deleting transfer...");
        setLoadingText("Please wait while the transfer is being deleted.");

        deleteTransfer(values.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                setLoadingText("The transfer has been deleted successfully.");

                if (isFunction(onDelete)) {
                    onDelete.call(this, values.id);
                }
                
                closeLoading();
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setLoadingText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const closeLoading = () => {
        delay(() => {
            setLoadingModalOpen(false);
        }, 700);
    }

    return (
        <> 
            <Modal isOpen={isOpen} onDismiss={onDismiss}>
                <h3>Update a transfer</h3>

                <Input type="text"
                    label="Player *"
                    id="u-player"
                    value={values.player}
                    max={140}
                    valueChange={(value) => addNewValue(value, "player")} />
                <Row>
                    <TeamNameInput name={"from_team"}
                                   label={"From team *"}
                                   max={140}
                                   value={values.from_team}
                                   onChange={value => addNewValue(value, "from_team")} />

                    <TeamNameInput name={"to_team"}
                                   label={"To team *"}
                                   max={140}
                                   value={values.to_team}
                                   onChange={value => addNewValue(value, "to_team")} />
                </Row>

                <Row>
                    <Input type="text"
                        id="u-transfer-date"
                        value={values.transfer_date}
                        label="Transfer date *"
                        info={"Use: yyyy-mm-dd"}
                        max={10}
                        regex={InputRegex.date}
                        valueChange={value => addNewValue(value, "transfer_date")} />

                    <Input type="text"
                        id="u-return-date"
                        value={values.return_date}
                        label="Return date"
                        info="Use: yyyy-mm-dd"
                        max={10}
                        disabled={(values.transfer_type === "career_end")}
                        regex={InputRegex.date}
                        valueChange={value => addNewValue(value, "return_date")} />
                </Row>

                <Input type="number"
                    value={values.cost}
                    id="u-cost"
                    label="Cost"
                    disabled={(values.transfer_type === "career_end")}
                    valueChange={value => addNewValue(value, "cost")}
                />

                <Select label="Transfer type" value={values.transfer_type} onChange={value => addNewValue(value, "transfer_type")}>
                    <SelectOption content="Transfer" value="transfer" />
                    <SelectOption content="Loan" value="loan" />
                    <SelectOption content={"End of Career"} value={"career_end"}/>
                </Select>

                <ButtonRow>
                    <DangerButton onClick={() => setDeleteModalOpen(true)}>
                        Delete
                    </DangerButton>
                    <Button onClick={doUpdate}>
                        Update
                    </Button>
                </ButtonRow>
            </Modal>

            <DeletionModal isOpen={deleteModalOpen}
                onDismiss={() => setDeleteModalOpen(false)}
                title="delete transfer"
                text="You are about to delete this transfer permanently."
                onConfirm={doDelete}/>

            <LoadingModal isOpen={loadingModalOpen}
                onDismiss={() => setLoadingModalOpen(false)}
                title={loadingTitle}
                text={loadingText}/>
        </>
    )
}