import {useState} from "react";
import {factTypeData, factTypes} from "../../config/constants";
import {valuesExist} from "../../data/forms";
import _, {isEmpty} from 'lodash';
import {createMatchFact} from "../../service/matchFactService";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {MatchFactIcon} from "./MatchFactIcon";
import {LabeledSelect} from "../generics/form/LabeledSelect";
import Crest from "../team/Crest";

const {Select, FormContainer, SelectOption, Input} = require("../generics/Forms")

const NewMatchFactForm = ({onNewFact, match}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    const handleChange = (newValue, field) => {
        let updated = {...data};
        updated[field] = newValue;
        setData(updated);
    }

    const submitMatchFact = () => {
        setIsLoading(true);
        let values = valuesExist(data, "type", "fact_time", "details", "side");
        if (!values.result) {
            setError("Missing required values: " + _.join(values.missing, ", "));
            setIsLoading(false);
            return;
        }

        createMatchFact(match.id, data)
            .then((response) => {
                setIsLoading(false);
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    if (_.isFunction(onNewFact)) {
                        onNewFact.call(this, response.data[0]);
                    }
                    setData({"details": "", "type": "", "fact_time": "", "side": "", "extra_details": ""});
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response && err.response.data && err.response.data.type) {
                    setError(getFriendlyErrorMessage(err.response.data));
                } else {
                    setError("An unknown error occurred.");
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <FormContainer isLoading={isLoading} onSubmit={submitMatchFact} error={error}>
                <LabeledSelect className={"match-fact-type"}
                               label={data.type ? `Fact type: ${factTypeData[data.type].uiName}` : "Fact type"}
                               onChange={(value) => handleChange(value, "type")}
                               value={data.type}
                               options={[
                                   {
                                       icon: <MatchFactIcon factType={factTypes.GOAL}/>,
                                       value: factTypes.GOAL
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.OWNGOAL}/>,
                                       value: factTypes.OWNGOAL
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.PENALTY_GOAL}/>,
                                       value: factTypes.PENALTY_GOAL
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.PENALTY_MISS}/>,
                                       value: factTypes.PENALTY_MISS
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.SUB}/>,
                                       value: factTypes.SUB
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.YELLOW_CARD}/>,
                                       value: factTypes.YELLOW_CARD
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.DOUBLE_YELLOW_CARD}/>,
                                       value: factTypes.DOUBLE_YELLOW_CARD
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.RED_CARD}/>,
                                       value: factTypes.RED_CARD
                                   },
                                   {
                                       icon: <MatchFactIcon factType={factTypes.VAR}/>,
                                       value: factTypes.VAR
                                   }
                               ]}
                />

                <div className="fact-form fact">
                    <Input id="factTime"
                           type="text"
                           name="factTime"
                           label="Time"
                           value={data.fact_time}
                           valueChange={(value) => handleChange(value, "fact_time")}/>
                    <Input id="details"
                           type="text"
                           name="details"
                           label={factLabel(data.type, "formDetails", "Details")}
                           value={data.details}
                           max={80}
                           valueChange={(value) => handleChange(value, "details")}/>
                </div>

                <div className="fact-form extra-details">
                    <Input id="extraDetails"
                           type="text"
                           name="extraDetails"
                           label={factLabel(data.type, "formExtraDetails", "Extra details")}
                           value={data.extra_details}
                           max={140}
                           valueChange={(value) => handleChange(value, "extra_details")}/>
                </div>

                <LabeledSelect className={"match-fact-side"}
                               label={"Fact side"}
                               onChange={(value) => handleChange(value, "side")}
                               value={data.side}
                               options={[
                                   {
                                       icon: <Crest club={match.home_team}/>,
                                       label: match.home_team,
                                       value: "home"
                                   },
                                   {
                                       icon: <Crest club={match.away_team}/>,
                                       label: match.away_team,
                                       value: "away"
                                   }
                               ]}/>
            </FormContainer>
        </>
    )
}

function factLabel(matchFact, property, defaultLabel) {
    if (isEmpty(matchFact)) {
        return defaultLabel;
    }

    const factData = factTypeData[matchFact];
    if (!factData) {
        return defaultLabel;
    }

    return factData[property] ?? defaultLabel;
}

export default NewMatchFactForm;