import {SelectSheet, SelectSheetTabLarge} from "../Tabs";
import {useState} from "react";
import {SortOrderTypes} from "../../../constants/SortOrderTypes";
import {map} from "lodash";
import "./styles/simple-field-sort.css";

export const SimpleFieldSort = ({
    activeField,
    activeOrder,
    fields,
    orders,
    onFieldChange,
    onOrderChange
}) => {

    const [fieldSelectOpen, setFieldSelectOpen] = useState(false);
    const [orderSelectOpen, setOrderSelectOpen] = useState(false);

    const renderFields = () => {
        return map(fields, (field, index) => {
            return <SelectSheetTabLarge key={index} text={field.label} onClick={() => handleSortFieldSelect(field)}/>
        })
    }

    const handleSortFieldSelect = (field) => {
        onFieldChange(field);
        setFieldSelectOpen(false);
    }

    const handleSortSelect = (value) => {
        onOrderChange(value);
        setOrderSelectOpen(false);
    }

    return (
        <>
            <div className={"simple-field-sort"}>
                <div className={"label"}>
                    <p>Sort by:</p>
                </div>
                <div className={"toggles"}>
                    <div className={"sort-toggle"} onClick={() => setFieldSelectOpen(true)}>
                        <p>{activeField.label}</p>
                    </div>

                    <div className={"sort-toggle"} onClick={() => setOrderSelectOpen(true)}>
                        <p>{SortOrderTypes[activeField.sortType][activeOrder]}</p>
                    </div>
                </div>
            </div>

            <SelectSheet isOpen={fieldSelectOpen}
                         title={"Sort by field"}
                         onDismiss={() => setFieldSelectOpen(false)}>
                {renderFields()}
            </SelectSheet>

            <SelectSheet isOpen={orderSelectOpen}
                         title={"Sort direction"}
                         onDismiss={() => setOrderSelectOpen(false)}
            >
                <SelectSheetTabLarge text={SortOrderTypes[activeField.sortType].ASC} onClick={() => {handleSortSelect("ASC")}}/>
                <SelectSheetTabLarge text={SortOrderTypes[activeField.sortType].DESC} onClick={() => {handleSortSelect("DESC")}}/>
            </SelectSheet>
        </>
    )
}