import {filter, isFunction, join, map, round} from "lodash"
import {useState} from "react";
import {Link} from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor"
import {monthName} from "../../data/dates";
import useLongPress from "../../hooks/longPress";
import {ListCardRow, ListCardWithClub} from "../generics/Cards";
import {ArrowRight, getCurrencySymbol} from "../generics/Text";
import {TransferManageModal} from "../transfer/TransferManageModal";
import Crest from "./Crest";
import {sortTransfersByDate} from "../transfer/TransferUtils";

export const TransferContainer = ({
    data,
    onContinueLoading,
    isPlayerTransfer = false,
    onUpdate,
    onDelete,
    sortResultsByDate = true,
    filterFunction = () => {return true}
}) => {

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateData, setUpdateData] = useState({});

    const closeModal = () => {
        setUpdateData({});
        setUpdateModalOpen(false);
    }

    const startUpdate = data => {
        setUpdateData(data);
        setUpdateModalOpen(true);
    }

    const renderTransfers = () => {
        let transfers = filter(data, (transfer) => {
            return filterFunction(transfer);
        });

        if (sortResultsByDate) {
            transfers = sortTransfersByDate(transfers).reverse();
        }

        return map(transfers, (value, index) => {
            if (isPlayerTransfer) {
                if (index === transfers.length - 1) {
                    return <PlayerTransferItem data={value} key={index} showFrom={true}/>
                } else {
                    return <PlayerTransferItem data={value} key={index} />
                }
            }
            return <TransferItem data={value} onLongPress={(data) => startUpdate(data)} key={index} />
        });
    }

    const visibilityChanged = (isVisible) => {
        if (isVisible) {
            if (isFunction(onContinueLoading)) {
                onContinueLoading.call(this);
            }
        }
    }

    const handleUpdate = (transfer) => {
        setUpdateModalOpen(false);
        if (isFunction(onUpdate)) {
            onUpdate.call(this, transfer);
        }
    }

    const handleDelete = (transferId) => {
        setUpdateModalOpen(false);
        if (isFunction(onDelete)) {
            onDelete.call(this, transferId);
        }
    }

    return (
        <div className="transfers-wrapper">
            {renderTransfers()}

            <ReactVisibilitySensor onChange={(isVisible) => visibilityChanged(isVisible)}>
                <div className="sensor-load-more"></div>
            </ReactVisibilitySensor>

            <TransferManageModal isOpen={updateModalOpen}
                onDismiss={closeModal}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                transfer={updateData}/>
            
        </div >
    )
}

export const getReadableTransferFee = (transferFee, currencySymbol) => {
    if (transferFee === 0 || transferFee === null || transferFee === undefined) {
        return "Free";
    }

    let divideBy = 1;
    let letter = "";

    if (transferFee >= 1000) {
        letter = "k";
        divideBy = 1000;
    }

    if (transferFee >= 1_000_000) {
        letter = "M";
        divideBy = 1_000_000;
    }

    if (transferFee >= 1_000_000_000) {
        letter = "B";
        divideBy = 1_000_000_000;
    }

    let readableNumber = round(transferFee / divideBy, 2);

    return currencySymbol + " " + readableNumber + "" + letter;
}

export const TransferItem = ({ data, onLongPress }) => {
    const getFee = () => {
        return getReadableTransferFee(data.cost, getCurrencySymbol());
    }

    const getTransferDate = () => {
        let date = new Date(data.transfer_date);

        return date.getDate() + " " + monthName(date.getMonth(), true, true) + " " + date.getFullYear();
    }

    const longPressed = () => {
        if (isFunction(onLongPress)) {
            const clone = Object.assign({}, data);
            onLongPress.call(this, clone);
        }
    }

    const longPressEvent = useLongPress(longPressed);

    return (
        <Link to={"/player/" + data.player}>
            <div className="transfer-item"
                {...longPressEvent}>
                <div className="player-data">
                    <h5>{data.player}</h5>
                    <div className="teams">
                        <Crest club={data.from_team} />

                        <ArrowRight/>

                        <Crest club={data.to_team} />
                    </div>
                </div>
                <div className="transfer-data">
                    <p className="fee-data">
                        <span className="type">
                            {(data.transfer_type === "transfer") ? "Transfer" : "Loan"}
                        </span>
                        <span className="fee">
                            {getFee()}
                        </span>
                    </p>
                    <p className="date">{getTransferDate()}</p>
                </div>
            </div>
        </Link>
    )
}

export const SinglePlayerTransferItem = ({ data, isReturn = false, showFrom = false }) => {

    const transferDate = new Date((isReturn) ? data.return_date : data.transfer_date);

    const getRedirectUrl = () => {
        let base = "/team/";

        if (isReturn === true) {
            base = base + data.from_team;
        } else {
            base = base + data.to_team;
        }

        return base;
    }

    return (
        <Link to={getRedirectUrl()}>
            <ListCardWithClub club={(isReturn) ? data.from_team : data.to_team}>
                <ListCardRow>
                    <h5>
                        {isReturn ?
                            data.from_team
                            :
                            data.to_team
                        }
                    </h5>

                    <p className="fee">
                        {((data.transfer_type === "loan" && data.cost === 0) || isReturn) ?
                            "-"
                            :
                            getReadableTransferFee(data.cost, getCurrencySymbol())
                        }
                    </p>
                </ListCardRow>
                <ListCardRow>
                    <p className="date">
                        {join([
                            transferDate.getDate(),
                            monthName(transferDate.getMonth(), true, true),
                            transferDate.getFullYear()
                        ], " ")}
                    </p>
                    {
                        !showFrom ?
                            <>
                                {
                                    data.transfer_type === "loan" &&
                                    <p className="loan-status">
                                        {isReturn ?
                                            "Return from loan"
                                            :
                                            "Loan"
                                        }
                                    </p>
                                }
                            </>
                            :
                            <>
                                <p className={"from-team"}>
                                   From: {data.from_team}
                                </p>
                            </>
                    }

                </ListCardRow>
            </ListCardWithClub>
        </Link>
    )
}

export const PlayerTransferItem = ({ data, showFrom }) => {
    return (
        <>
            {
                data.return_date !== null &&
                <SinglePlayerTransferItem isReturn={true} data={data} />
            }
            <SinglePlayerTransferItem data={data} showFrom={showFrom} />
        </>
    )
}

