import { useEffect, useState } from "react";
import { api, messages } from "../../config/constants";
import { getFriendlyErrorMessage } from "../../service/errorService";
import { getLatestMatches } from "../../service/matchService";
import { FeaturePreview } from "../feature/FeaturePreview";
import { MatchContainer } from "./LeagueMatches";
import {translations} from "../../constants/translations.en.js";

const MatchesPerLeague = (props) => {

    const [matches, setMatches] = useState([]);
    const [matchesPage, setMatchesPage] = useState(1);
    const [stopLoadingMatches, setStopLoadingMatches] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);

    const loadLatestMatches = () => {
        if (stopLoadingMatches) {
            return;
        }

        getLatestMatches(matchesPage)
            .then((response) => {
                if (response.data && response.data.type === "error") {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setError(null);
                    if (response.data.length === api.RESULTS_PER_PAGE) {
                        setMatchesPage(matchesPage + 1);
                    } else {
                        setStopLoadingMatches(true);
                    }
                    setMatches([...matches, ...response.data]);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data.type === "error") {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            });
    }

    useEffect(() => {
        loadLatestMatches();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FeaturePreview isShown={(matches.length === 0)}
            title={translations.features.NO_NATIONAL_MATCHES}
            description={translations.features.NATIONAL_MATCHES_DESC}>
            <MatchContainer data={matches} onContinueLoading={loadLatestMatches} />
        </FeaturePreview>
    )
}

export default MatchesPerLeague;