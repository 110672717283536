import _, {isFunction} from 'lodash';
import {useEffect, useState} from 'react';
import {getSimpleDate} from '../../data/dates';
import {Button, DangerButton} from '../generics/Buttons';
import {FormContainer, Input, InputRegex, TeamScoreInput} from '../generics/Forms';
import {Row} from '../generics/Layout';
import {DeletionModal} from '../generics/Modals';
import {CompetitionSelectWrapper} from "../league/CompetitionSelectWrapper";
import {SelectSheet} from "../generics/Tabs";
import "./match-meta.css";
import {KeyValueTable} from "../generics/Tables";
import {ContentCard} from "../generics/Cards";

export const MatchMeta = ({match, onUpdate, onDelete}) => {

    const [data, setData] = useState({});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [changeCompetitionSheetOpen, setChangeCompetitionSheetOpen] = useState(false);

    useEffect(() => {
        setData({
            ...match,
            play_date: getSimpleDate(match.play_date)
        });
    }, [match]);

    const handleValueChange = (newValue, fieldName) => {
        let fields = {...data};
        fields[fieldName] = newValue;
        setData(fields);
    }

    const handleTeamScoreChange = (field, value) => {
        handleValueChange(value, field);
    }

    const doUpdate = () => {
        if (_.isFunction(onUpdate)) {
            onUpdate.call(this, data);
        }
    }

    const doDelete = () => {
        if (_.isFunction(onDelete)) {
            onDelete.call(this);
        }
    }

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    }

    const handleCompetitionUpdate = (competitionData) => {
        setChangeCompetitionSheetOpen(false);

        const updatedData = {
            competition_id: competitionData.competition_id,
            knockout_round_id: competitionData.knockout_round_id
        };

        //setData({...data, ...updatedData});

        if (isFunction(onUpdate)) {
            onUpdate.call(this, updatedData);
        }
    }

    const getCompetitionTableValues = () => {
        const values = [];

        values.push({
            name: "Competition",
            value: match.name
        });
        values.push({
            name: "Season",
            value: match.season
        });

        if (match.is_knockout === 1 && match.label) {
            values.push({
                name: "Round",
                value: match.label
            })
        }

        return values;
    }

    return (
        <div className="match-meta">
            <h4>Edit match</h4>

            <ContentCard>
                <Row>
                    <TeamScoreInput homeTeamScore={data.home_team_score}
                                    awayTeamScore={data.away_team_score}
                                    onChange={(field, value) => handleTeamScoreChange(field, value)}
                    />
                </Row>

                <Row>
                    <Input type="text"
                           label="Home team name"
                           id="htn"
                           min={0}
                           max={120}
                           value={data.home_team}
                           valueChange={newValue => handleValueChange(newValue, "home_team")}/>

                    <Input type="text"
                           label="Away team name"
                           id="atn"
                           min={0}
                           max={120}
                           value={data.away_team}
                           valueChange={newValue => handleValueChange(newValue, "away_team")}/>
                </Row>

                <Input type="text"
                       regex={InputRegex.date}
                       label="Match play date"
                       id="mpd"
                       value={data.play_date}
                       valueChange={newValue => handleValueChange(newValue, "play_date")}/>

                <Row>
                    <Input type="number"
                           label="Home team's odds"
                           id="hto"
                           min={0.00}
                           max={999.99}
                           value={data.home_team_odds}
                           valueChange={newValue => handleValueChange(newValue, "home_team_odds")}/>

                    <Input type="number"
                           label="Away team's odds"
                           id="ato"
                           min={0.00}
                           max={999.99}
                           value={data.away_team_odds}
                           valueChange={newValue => handleValueChange(newValue, "away_team_odds")}/>
                </Row>

                <h5>Best players</h5>

                <div className={"meta-best-players"}>
                    <Row>
                        <Input type="text"
                               label={`${data.home_team} best player`}
                               id="hbp"
                               value={data.home_team_best_player}
                               valueChange={newValue => handleValueChange(newValue, "home_team_best_player")}/>

                        <Input type="number"
                               label="Rating"
                               id="hbr"
                               min={0}
                               max={10}
                               value={data.home_team_best_rating}
                               valueChange={newValue => handleValueChange(newValue, "home_team_best_rating")}/>
                    </Row>

                    <Row>
                        <Input type="text"
                               label={`${data.away_team} best player`}
                               id="abp"
                               value={data.away_team_best_player}
                               valueChange={newValue => handleValueChange(newValue, "away_team_best_player")}/>

                        <Input type="number"
                               label="Rating"
                               id="abr"
                               min={0}
                               max={10}
                               value={data.away_team_best_rating}
                               valueChange={newValue => handleValueChange(newValue, "away_team_best_rating")}/>
                    </Row>
                </div>

                <Row>
                    <Button onClick={doUpdate}>Save changes</Button>
                </Row>
            </ContentCard>

            <h4>Competition</h4>

            <ContentCard>
                <KeyValueTable values={getCompetitionTableValues()}/>

                <Row>
                    <Button onClick={() => setChangeCompetitionSheetOpen(true)}>Change competition</Button>
                </Row>
            </ContentCard>

            <h4>Delete match</h4>

            <ContentCard>
                <p>
                    <strong>Attention:</strong> deleting this match will delete all of its match events and lineups.
                    This will affect stats such as goal scored, assists and appearances among others.
                    You will be asked for confirmation.
                </p>
                <DangerButton onClick={() => setDeleteModalOpen(true)}>
                    Delete match
                </DangerButton>
            </ContentCard>

            <DeletionModal isOpen={deleteModalOpen}
                           onDismiss={closeDeleteModal}
                           onConfirm={doDelete}
                           title="delete this match?"
                           text="You are about to delete this match permanently. This action cannot be undone."
            />

            <SelectSheet title={"Change match competition"}
                         classNames={"match-competition-change"}
                         onDismiss={() => setChangeCompetitionSheetOpen(false)}
                         isOpen={changeCompetitionSheetOpen}>
                <FormContainer hideSubmitButton={true}>
                    <CompetitionSelectWrapper onChange={handleCompetitionUpdate}/>
                </FormContainer>
            </SelectSheet>
        </div>
    )

}