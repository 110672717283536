import { isFunction } from "lodash";
import { useEffect, useState } from "react"
import { objectiveTypes } from "../../config/constants";
import { FormContainer, Input, Select, SelectOption } from "../generics/Forms"
import { TransferObjectivePositionSelect } from "./TransferObjectivePositionSelect";

export const NewTransferObjectiveForm = ({isLoading, error, onSubmit, action}) => {

    const [values, setValues] = useState({
        objective_type: "",
        amount: "",
        player: "",
        position: ""
    });

    const clearValues = () => {
        setValues({
            objective_type: "",
            amount: "",
            player: "",
            position: ""
        });
    }

    useEffect(() => {
        if (action === "clear") {
            clearValues();
        }
    }, [action])

    const updateValue = (field, value) => {
        let fields = Object.assign(values);
        fields[field] = value;
        setValues({...fields});
    }

    const handleSubmission = () => {
        if (isFunction(onSubmit)) {
            onSubmit.call(this, values);
        }
    }

    return(
        <FormContainer isLoading={isLoading} error={error} onSubmit={handleSubmission}>
            <Select value={values.objective_type} id="co-type" onChange={value => updateValue("objective_type", value)} label="--- Objective type ---">
                <SelectOption value="buy" content="Buy"/>
                <SelectOption value="sell" content="Sell"/>
            </Select>

            {
                values.objective_type === objectiveTypes.BUY &&

                <>
                    <Input value={values.amount} id="co-amount" onChange={value => updateValue("amount", value)} label="Max. spending"/>
                    <TransferObjectivePositionSelect id="co-position" value={values.position}
                        label="--- Playing position ---"
                        onChange={value => updateValue("position", value)}/>
                </>
            }

            {
                values.objective_type === objectiveTypes.SELL &&
                <>
                    <Input value={values.amount} id="co-amount" onChange={value => updateValue("amount", value)} label="Min. amount"/>
                    <Input value={values.player} id="co-player" onChange={value => updateValue("player", value)} label="Player to sell"/>
                </>
            }

        </FormContainer>
    )

}