import "./timeline-match-fact.css";
import {
    factTypeData,
    goalTypes
} from "../../../config/constants";
import {MatchFactIcon} from "../MatchFactIcon";
import {getTextColor} from "../../../data/colors";
import Crest from "../../team/Crest";

const getHeaderBackgroundColor = (factType, factSide, homeTeamColor, awayTeamColor, defaultColor = "var(--card-background-color)") => {
    if (goalTypes.includes(factType)) {
        return (factSide === "home") ? homeTeamColor : awayTeamColor
    } else {
        return defaultColor;
    }
}
const getHeaderTextColor = (factType, factSide, homeTeamColor, awayTeamColor) => {
    if (goalTypes.includes(factType)) {
        return getTextColor(getHeaderBackgroundColor(factType, factSide, homeTeamColor, awayTeamColor));
    } else {
        return "var(--text)";
    }
}

export const TimelineMatchFact = ({data, match, scoreHome, scoreAway, homeTeamColor, awayTeamColor, isLastFact}) => {

    return (
        <>
            <div className={`fact-header ${data.type}`} style={{
                backgroundColor: getHeaderBackgroundColor(data.type, data.side, homeTeamColor, awayTeamColor),
                color: getHeaderTextColor(data.type, data.side, homeTeamColor, awayTeamColor),
                borderColor: getHeaderBackgroundColor(data.type, data.side, homeTeamColor, awayTeamColor, "var(--card-border-color)")
            }}>
                <div className={"fact-header-content"}>
                    <MatchFactIcon factType={data.type}/>
                    <div className={"type-and-time"}>
                        {factTypeData[data.type].uiName}
                        <div className={"time"}>
                            {data.fact_time}'
                        </div>
                    </div>
                </div>
                {
                    goalTypes.includes(data.type) &&
                    <div className={"fact-header-footer"}>
                        <p className={"score"}>
                            <span className={`home ${(data.side === "home") ? "highlighted" : ""}`}>
                                { match.home_team } { scoreHome }
                            </span>
                            &nbsp;-&nbsp;
                            <span className={`away ${(data.side === "away") ? "highlighted" : ""}`}>
                                { scoreAway } { match.away_team }
                            </span>
                        </p>
                    </div>
                }
            </div>
            <div className={"content"}>
                <p className={"details"}>
                    {
                        factTypeData[data.type].uiDetailsPrefix
                    }
                    {data.details}
                </p>
                <div className={"extra-details"}>
                    {data.extra_details &&
                        <p className="extra">
                            {
                                factTypeData[data.type].uiExtraDetailsPrefix
                            }
                            {data.extra_details}
                        </p>
                    }
                </div>

                <Crest club={(data.side === "home") ? match.home_team : match.away_team} isInternational={match.is_international} />
            </div>
        </>
    )
}