import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import CallbackScreen from './modules/callback/CallbackScreen';
import { LeagueOverviewScreen } from './modules/league/LeagueOverviewScreen';
import { LeagueScreen } from './modules/league/LeagueScreen';
import { ManagerOverviewScreen } from './modules/manager/ManagerOverviewScreen';
import { ManagerScreen } from './modules/manager/ManagerScreen';
import { MatchDetails } from './modules/match/MatchDetails';
import { Offline } from './modules/offline/offlineComponent';
import { Overview } from "./modules/overview/overviewComponent"
import { PlayerScreen } from './modules/player/PlayerScreen';
import {SavePicker} from './modules/save/SavePicker';
import { TeamScreen } from './modules/team/TeamScreen';
import { TransferWindowOverviewScreen } from './modules/transfer/TransferWindowOverviewScreen';
import { TransferWindowScreen } from './modules/transfer/TransferWindowScreen';
import {TransferCenterScreen} from "./modules/transfer/transfer-center/TransferCenterScreen";
import {TransferScreen} from "./modules/transfer/TransferScreen";

export const AnimatedApp = (props) => {

    return(
        <Router>
            <Switch>
                <Route exact path={"/"} component={Offline}/>
                <Route path={"/overview"} component={Overview}/>
                <Route path={"/save"} component={SavePicker}/>
                <Route path={"/match/:matchId"} component={MatchDetails}/>
                <Route path={"/team/:teamName"} component={TeamScreen}/>
                <Route path={"/player/:playerName"} component={PlayerScreen}/>
                <Route path={"/managers/"} component={ManagerOverviewScreen}/>
                <Route path={"/manager/:managerId"} component={ManagerScreen}/>
                <Route path={"/leagues"} component={LeagueOverviewScreen}/>
                <Route path={"/league/:id"} component={LeagueScreen}/>
                <Route path={"/callback"} component={CallbackScreen}/>
                <Route exact path={"/transfers"} component={TransferCenterScreen}/>
                <Route exact path={"/transfers/windows"} component={TransferWindowOverviewScreen}/>
                <Route path={"/transfers/all"} component={TransferScreen}/>
                <Route path={"/transfers/windows/:id"} component={TransferWindowScreen}/>
            </Switch>
        </Router>
    )
} 