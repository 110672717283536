import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const getTransferObjectivesFromTransferWindow = async (windowId) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objectives",
            "?saveGame=", saveId,
            "&transfer_window_id=", windowId,
            "&extra_data=objective_players,transfer"
        ], ""),
        headers
    });

    return response;
}

export const createTransferObjective = async (transferWindowId, objectiveData) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        properties: {
            saveGame: saveId,
            transfer_window_id: transferWindowId,
            ...objectiveData
        }
    }

    let response = await Axios({
        method: 'POST',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objectives"
        ], ""),
        headers,
        data
    });

    return response;
}

export const updateTransferObjective = async (transferWindowId, objectiveData) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        properties: {
            saveGame: saveId,
            transfer_window_id: transferWindowId,
            ...objectiveData
        }
    }

    let response = await Axios({
        method: 'PATCH',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objectives",
        ], ""),
        headers,
        data
    });

    return response;
}

export const associateTransfer = async (windowId, objectiveId, transferId) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        properties: {
            saveGame: saveId,
            transfer_window_id: windowId,
            id: objectiveId,
            transfer_id: transferId
        }
    };

    let response = await Axios({
        method: 'POST',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objectives/associate"
        ], ""),
        headers,
        data
    });

    return response;
}

export const deleteTransferObjective = async (transferWindowId, objectiveId) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        url: join([
            config.API_URL,
            "/save/transfer_window/transfer_objectives",
            "?saveGame=", saveId,
            "&transfer_window_id=", transferWindowId,
            "&id=", objectiveId
        ], ""),
        headers
    });

    return response;
}