import {join} from "lodash"
import "./styles/layout.css";

export const Row = (props) => {
    return (
        <div className={`row${(props.className) ? " " + props.className : ""}`}>
            {props.children}
        </div>
    )
}

export const GridRow = ({children, templateColumns, className}) => {

    const getGridTemplateColumns = () => {
        return join(templateColumns, " ");
    }

    return (
        <div className={join(["fs-grid-row", className], " ")}
            style={{
                gridTemplateColumns: getGridTemplateColumns()
            }}>
            {children}
        </div>
    )
}

export const HorizontalScrollable = ({
    children
}) => {

    return (
        <div className={"horizontal-scrollable"}>
            { children }
        </div>
    )
}

export const ContentWrapper = ({children}) => {
    return (
        <div className={"content-wrapper"}>
            { children }
        </div>
    )
}