import "./match-average-goals.css";
import Crest from "../../team/Crest";
import {asDecimal} from "../../../data/number";
import {ContentCard} from "../../generics/Cards";

export const MatchAverageGoals = ({match, matchAverageGoals}) => {

    return (
        <div className={"match-average-goals"}>
            <h4>Average goals</h4>

            <ContentCard>
                <div className={"average-goals-scoreline"}>
                    <Crest club={match.home_team}/>
                    <Crest club={match.away_team}/>

                    <p>{asDecimal(matchAverageGoals.teamOne, 2)}</p>
                    <p>{asDecimal(matchAverageGoals.teamTwo, 2)}</p>

                </div>
            </ContentCard>
        </div>
    )
}