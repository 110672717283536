import MatchPreview from "../match/MatchPreview"
import React from 'react';
import ReactVisibilitySensor from "react-visibility-sensor";
import _, { join } from 'lodash';
import { CircularLoaderInfinite } from "../generics/Loaders";
import Crest from "../team/Crest";
import { ArrowRight } from "../generics/Text";
import { Link } from "react-router-dom";

export const MatchContainer = ({ onContinueLoading, data, title, team, isLoading = false }) => {

    const visibilityChanged = (isVisible) => {
        if (isVisible) {
            if (_.isFunction(onContinueLoading)) {
                onContinueLoading.call(this);
            }
        }
    }

    const renderChildren = () => {
        let currentGroupedCompetitionId = "";

        return data.map((value, index) => {
            let item = [];
            let currentCompetitionId = join([value.name, value.country], "");

            if (currentCompetitionId !== currentGroupedCompetitionId) {
                currentGroupedCompetitionId = join([value.name, value.country], "");
                item.push(<CompetitionGroupHeader competition={value} key={currentGroupedCompetitionId + index} />)
            }

            item.push(<MatchPreview team={team} data={value} key={index} />);

            return item;
        })
    }

    return (
        <div className="tab-component">
            {title &&
                <h4>{title}</h4>
            }

            {renderChildren()}

            {isLoading &&
                <CircularLoaderInfinite />
            }

            <ReactVisibilitySensor onChange={(isVisible) => visibilityChanged(isVisible)}>
                <div className="sensor-load-more"></div>
            </ReactVisibilitySensor>
        </div>
    )
};

const CompetitionGroupHeader = ({ competition }) => {
    return (
        <Link to={join(["/league/", competition.competition_id], "")}>
            <div className="competition-group-header">
                <div className="country-container">
                    <div className="country">
                        <Crest club={competition.country} isInternational={true} />
                    </div>
                </div>


                <p>
                    {competition.country}
                </p>
                <ArrowRight color="var(--text-label-darker)" />
                <p>
                    {competition.name}
                </p>
            </div>
        </Link>
    )
}