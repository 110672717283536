import {useEffect, useState} from "react";
import {valuesExist} from "../../data/forms";
import {createMatch} from "../../service/matchService";
import {FormContainer, Input, InputRegex, TeamNameInput, Toggle} from "../generics/Forms";
import {Row} from "../generics/Layout";
import _, {isEmpty} from 'lodash';
import {getFriendlyErrorMessage} from "../../service/errorService";
import {useHistory} from "react-router";
import {messages} from "../../config/constants";
import {getCompetitions, getLeagueSeasons} from "../../service/competitionService";
import {LeagueSelect} from "../league/LeagueSelect";
import {LeagueSeasonSelect} from "../league/LeagueSeasonSelect";
import {join} from "lodash";
import {getKnockoutRoundsByCompetition} from "../../service/knockoutRoundService";
import {KnockoutRoundSelect} from "../knockout/KnockoutRoundSelect";

const NewMatchForm = ({
                          competition,
                          knockoutRounds
                      }) => {

    const history = useHistory();

    const [isFormUploading, setIsFormUploading] = useState(false);
    const [formValues, setFormValues] = useState({
        home_team_score: 0,
        away_team_score: 0
    });
    const [error, setError] = useState(null);

    const [leagues, setLeagues] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState("");
    const [selectedLeagueCountry, setSelectedLeagueCountry] = useState("");
    const [selectedLeagueName, setSelectedLeagueName] = useState("");
    const [leagueSeasons, setLeagueSeasons] = useState([]);
    const [selectedLeagueSeason, setSelectedLeagueSeason] = useState({});
    const [leagueRounds, setLeagueRounds] = useState([]);

    const [matchScoreShown, setMatchScoreShown] = useState(false);

    useEffect(() => {
        getCompetitions()
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setLeagues([...response.data]);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }, [])

    const submitNewMatchForm = () => {
        setIsFormUploading(true);

        let fieldsMissing = valuesExist(formValues, "home_team", "away_team", "play_date", "competition_id");
        if (selectedLeagueSeason.is_knockout === 1) {
            if (!formValues.knockout_round_id) {
                setError("A knockout round needs to be selected for this competition.");
                setIsFormUploading(false);
                return;
            }
        }

        if (!fieldsMissing.result) {
            setError("Missing required fields: " + _.join(fieldsMissing.missing, ", ") + ".");
            setIsFormUploading(false);
            return;
        }

        createMatch(formValues)
            .then(response => {
                if (!response.data.type) {
                    setIsFormUploading(false);
                    history.push("/match/" + response.data[0].id);
                } else {
                    setError(getFriendlyErrorMessage(response.data));
                    setIsFormUploading(false);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                setIsFormUploading(false);
            });
    }

    const handleChange = (newValue, field) => {
        let updated = {...formValues};
        updated[field] = newValue;
        setFormValues(updated);
    }

    const resetLeagueSeasonFields = () => {
        setLeagueSeasons([]);
        setSelectedLeagueSeason({...{}});
        handleChange("", "competition_id");
    }

    const resetKnockoutRoundFields = () => {
        handleChange("", "knockout_round_id");
        setLeagueRounds([]);
    }

    const handleLeagueChange = (newLeague) => {
        resetLeagueSeasonFields();
        resetKnockoutRoundFields();
        setSelectedLeague(newLeague);

        let leagueData = newLeague.split(/:(.+)/);
        let country = leagueData[0];
        let name = leagueData[1];

        setSelectedLeagueCountry(country);
        setSelectedLeagueName(name);

        // league selection should be locked until seasons are loaded
        // to avoid race conditions and have wrong league seasons
        // showing up..
        getLeagueSeasons(name, country)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setLeagueSeasons([...response.data]);
                }
            })
            .catch((error) => {
                if (error.response) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const handleLeagueSeasonChange = (value, newLeague) => {
        resetKnockoutRoundFields();
        let updated = formValues;
        updated["competition_id"] = value;
        setSelectedLeagueSeason({...newLeague});

        if (newLeague.is_knockout === 1) {
            getKnockoutRoundsByCompetition(value)
                .then((response) => {
                    if (response.data && response.data.type) {
                        setError(getFriendlyErrorMessage(response.data));
                    } else {
                        setLeagueRounds([...response.data]);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setError(getFriendlyErrorMessage(error.response.data));
                    } else {
                        setError(messages.ERR_UNKNOWN);
                    }
                    console.error(error);
                })
        } else {
            updated["knockout_round_id"] = null;
        }

        setFormValues(updated);
    }

    const handleLeagueCreation = (data) => {
        setLeagues([data, ...leagues]);

        handleLeagueChange(join([data.country, data.name], ":"));
    }

    const handleLeagueSeasonCreation = (data) => {
        setLeagueSeasons([data, ...leagueSeasons]);

        handleLeagueSeasonChange(data.id, data);
    }

    const toggleShowMatchScore = (show) => {
        if (!show) {
            setFormValues({
                ...formValues,
                home_team_score: 0,
                away_team_score: 0
            })
        }

        setMatchScoreShown(show);
    }

    return (
        <>
            <FormContainer onSubmit={submitNewMatchForm} isLoading={isFormUploading} error={error}>
                <Row>
                    <TeamNameInput onChange={value => handleChange(value, "home_team")}
                                   name={"homeTeam"}
                                   label={"Home team name"}
                                   value={formValues.home_team}
                                   max={120}/>
                    <TeamNameInput name={"awayTeam"}
                                   label={"Away team name"}
                                   max={120}
                                   value={formValues.away_team}
                                   onChange={(value) => handleChange(value, "away_team")}/>
                </Row>

                <Toggle value={matchScoreShown}
                        name={"matchScoreShown"}
                        onChange={(value) => toggleShowMatchScore(value)}
                        label={"Set match score"}/>

                {
                    matchScoreShown &&

                    <Row>
                        <Input id={"homeTeamScore"}
                               type={"number"}
                               name={"homeTeamScore"}
                               min={0}
                               label={"Home team score"}
                               value={formValues.home_team_score}
                               onChange={(value) => handleChange(value, "home_team_score")}/>

                        <Input id={"awayTeamScore"}
                               type={"number"}
                               name={"awayTeamScore"}
                               min={0}
                               label={"Away team score"}
                               value={formValues.away_team_score}
                               onChange={(value) => handleChange(value, "away_team_score")}/>
                    </Row>
                }

                <Input id="playDate"
                       type="text"
                       name="playDate"
                       label="Match date"
                       info={"Use the following format: yyyy-mm-dd."}
                       regex={InputRegex.date}
                       valueChange={(value) => handleChange(value, "play_date")}/>
                <Row>
                    <Input id="homeTeamOdds"
                           type="number"
                           name="homeTeamOdds"
                           label="Home team odds"
                           info="Decimal format."
                           valueChange={(value) => handleChange(value, "home_team_odds")}/>

                    <Input id="awayTeamOdds"
                           type="number"
                           name="awayTeamOdds"
                           label="Away team odds"
                           info="Decimal format."
                           valueChange={(value) => handleChange(value, "away_team_odds")}/>
                </Row>

                <LeagueSelect leagues={leagues}
                              onChange={(value) => handleLeagueChange(value)}
                              onCreate={(created) => handleLeagueCreation(created)}
                              value={selectedLeague}/>

                {!isEmpty(selectedLeagueName) && !isEmpty(selectedLeagueCountry) &&
                    <LeagueSeasonSelect seasons={leagueSeasons}
                                        competitionCountry={selectedLeagueCountry}
                                        competitionName={selectedLeagueName}
                                        onChange={(value, obj) => handleLeagueSeasonChange(value, obj)}
                                        onCreate={(created) => handleLeagueSeasonCreation(created)}
                                        value={formValues.competition_id}/>
                }

                {selectedLeagueSeason.is_knockout === 1 &&
                    <KnockoutRoundSelect onChange={value => handleChange(value, "knockout_round_id")}
                                         rounds={leagueRounds}
                                         value={formValues.knockout_round_id}/>
                }

            </FormContainer>
        </>
    )
}

export default NewMatchForm;