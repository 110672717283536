import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const getManagerTenures = async (managerId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/manager/tenures?saveGame=", saveId,
            "&manager_id=", managerId
        ], ""),
        headers
    });

    return response;
}

export const getManagerTenuresFromManager = async (managerId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/manager/tenures/stats?saveGame=", saveId,
            "&manager_id=", managerId
        ], ""),
        headers
    });

    return response;
}

export const createManagerTenure = async (managerId, managerTenure) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            'manager_id': managerId,
            ...managerTenure
        }
    };

    let response = await Axios({
        method: 'POST',
        url: config.API_URL + '/save/manager/tenures',
        headers,
        data
    });

    return response;
}

export const updateManagerTenure = async (id, managerId, tenureData) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'id': id,
            'manager_id': managerId,
            'saveGame': saveId,
            ...tenureData
        }
    };

    let response = await Axios({
        method: 'PATCH',
        url: config.API_URL + "/save/manager/tenures",
        headers,
        data
    });

    return response;
}

export const deleteManagerTenure = async (id, managerId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        url: join([
            config.API_URL,
            "/save/manager/tenures?saveGame=", saveId,
            "&id=", id,
            "&manager_id=", managerId
        ], ""),
        headers
    });

    return response;
}