import "./match-story.css";
import {each, isNaN, map, split} from "lodash";
import {MatchFactIcon} from "./MatchFactIcon";

export const MatchStory = ({
    matchFacts
}) => {

    const renderFacts = () => {
        let matchDuration = determineMatchDurationBasedOnLatestFact(matchFacts);

        return map(matchFacts, (fact, index) => {
            const time = getFactMinutesAsNumber(fact.fact_time);
            const styles = {};

            if (fact.side === "home") {
                styles.top = "calc(50% - 22px)";
            } else {
                styles.top = "calc(50% + 22px)";
            }

            const offsetLeft = time / matchDuration * 100;
            styles.left = `${offsetLeft}%`;

            return (
                <div key={index} className={`story-match-fact ${fact.side}`} style={
                    {
                        ...styles,
                        animationDelay: `${(125 * index) + 400}ms`
                    }
                }>
                    <MatchFactIcon factType={fact.type} />
                    <div className={"story-pin"}/>
                </div>
            )
        })
    }

    return (
        <div className={"match-story"}>
            <div className={"match-story-line"}>
                {renderFacts()}
            </div>
        </div>
    )

}

/**
 * Determines whether a match lasted 90 or 120 minutes by checking
 * if any match facts happened after the 90th minute.
 * @param facts The match facts to check
 */
const determineMatchDurationBasedOnLatestFact = (facts) => {
    let duration = 90;

    each(facts, fact => {
        if (duration === 120) {
            return false;
        }

       let minutesString = split(fact.fact_time, "+");
       let minutes = Number.parseInt(minutesString[0]);

       if (!isNaN(minutes)) {
           if (minutes > 90) {
               duration = 120;
           }
       }
    });

    return duration;
}

const getFactMinutesAsNumber = (factTime) => {
    let minutesString = split(factTime, "+");
    let minutes = Number.parseInt(minutesString[0]);

    if (!isNaN(minutes)) {
        return minutes;
    } else {
        return 0;
    }
}