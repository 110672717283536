import _, {isUndefined, map} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {Input} from '../generics/Forms';
import {Modal} from '../generics/Modals';
import {Button} from '../generics/Buttons';
import useLongPress from '../../hooks/longPress';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import {factTypes} from "../../config/constants";
import {MatchFactIcon} from "../match/MatchFactIcon";
import complementaryColors from 'complementary-colors';

const LINEUP_RELEVANT_FACT_TYPES = [factTypes.GOAL, factTypes.PENALTY_GOAL, factTypes.SUB, factTypes.DOUBLE_YELLOW_CARD, factTypes.RED_CARD];

export const LineupPosition = (props) => {

    const longPressed = (e) => {
        if (_.isFunction(props.onLongPress)) {
            props.onLongPress.call(this);
        }
    }

    const renderFactIcons = () => {
        let goalsScored = 0;
        let penaltiesScored = 0
        return map(props.matchFacts, fact => {
            let additionalStyles = {};

            if (!LINEUP_RELEVANT_FACT_TYPES.includes(fact.type)) {
                return;
            }

            if (fact.type === factTypes.GOAL) {
                if (goalsScored >= 1) {
                    return;
                }

                if (penaltiesScored >= 1) {
                    additionalStyles.transform = "translate(calc(100% + 4px), calc(-100% - 4px))";
                }

                goalsScored++;
            }

            if (fact.type === factTypes.PENALTY_GOAL) {
                if (penaltiesScored >= 1) {
                    return;
                }

                if (goalsScored >= 1) {
                    additionalStyles.transform = "translate(calc(100% + 4px), calc(-100% - 4px))";
                }

                penaltiesScored++;
            }
            return <MatchFactIcon factType={fact.type} style={additionalStyles}/>
        })
    }

    const longPressEvent = useLongPress(longPressed);

    return (
        <>
            <div onClick={props.onClick}
                 className="lineup-position-item"
                 {...longPressEvent}
            >
                {
                    (props.withLink && props.withLink === true) ?
                        <Link to={"/player/" + props.player}>
                            <Player color={props.color}/>
                            <p className={"number"}>{props.number}</p>

                            <div className={"icons"}>
                                {renderFactIcons()}
                            </div>
                        </Link>
                        :
                        <>
                            <Player color={props.color}/>
                            <p className={"number"}>{props.number}</p>
                        </>
                }
            </div>
            <p className={"player-name"}>{props.player || " "}</p>
        </>
    )
}

export const EditableLineupPosition = (props) => {

    const position = useRef(props.position);
    const rowNum = useRef(props.rowNum);
    const [number, setNumber] = useState(props.number || 0);
    const [player, setPlayer] = useState(props.player || "");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const confirmChangesHandler = () => {
        if (_.isFunction(props.onChange)) {
            props.onChange.call(this, position.current, rowNum.current, player, number);
        }

        closeModal();
    }

    const toggleEditor = () => {
        setIsModalOpen(!isModalOpen);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    return (
        <>
            <div className={"player-position-wrapper"}>
                <LineupPosition onClick={toggleEditor} color={props.color} number={number} player={player}/>
            </div>

            <Modal isOpen={isModalOpen} onDismiss={closeModal}>
                <Input type="text"
                       label={"Player name"}
                       value={props.player}
                       clearButton={true}
                       valueChange={value => setPlayer(value)}/>

                <Input type="number"
                       min={1}
                       max={99}
                       label={"Shirt number"}
                       valueChange={value => setNumber(value)}/>

                <Button onClick={confirmChangesHandler}>
                    Confirm
                </Button>
            </Modal>
        </>
    )
}


export const Player = ({color}) => {

    const [compColors, setCompColors] = useState([]);

    useEffect(() => {
        const colors = new complementaryColors(color);

        const comp = colors.tetradic();

        setCompColors(comp);
    }, [color]);

    return (
        <>

            <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 58 58" xmlSspace="preserve">
                <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRrule="evenodd">
                    <path fill={asRgbString(compColors[0]) || "currentColor"} d="m40.06 2.03 8.22 4.11 2.67 1.33a2.9 2.9 0 0 1 .91.72c.243.263.43.573.55.91l3.9 10.41-2.971 3.2-4.169 1.66-1.74-2.24-1.76-2.27-.04-.05a156.417 156.417 0 0 0 .28 35.42 1 1 0 0 1-.66 1.06C43.58 56.84 38.89 58 29 58s-14.58-1.16-16.25-1.71a1 1 0 0 1-.66-1.06 156.417 156.417 0 0 0 .28-35.42l-.03.04-1.77 2.28-1.74 2.24-4.14-1.557-3-3.3L5.59 9.1c.12-.337.307-.647.55-.91a2.9 2.9 0 0 1 .91-.72l2.67-1.33 8.22-4.11z" />
                    <path fill={asRgbString(compColors[1]) || "currentColor"} d="m56.68 24.88-3.13 2.09a2 2 0 0 1-2.69-.44L49.2 24.4l-.03-.03 7.14-4.86 1.13 3c.327.87.012 1.852-.76 2.37zM8.83 24.37l-.03.03-1.66 2.13a2 2 0 0 1-2.69.44l-3.13-2.09a2.005 2.005 0 0 1-.76-2.37l1.13-3zM40.06 2.03c-.055.801-.19 1.595-.4 2.37-2.87 10.63-18.45 10.63-21.32 0a12.224 12.224 0 0 1-.4-2.37L22 0h.01a7.938 7.938 0 0 0 .19 3.35A6.747 6.747 0 0 0 29 8.37a6.747 6.747 0 0 0 6.8-5.02A7.938 7.938 0 0 0 35.99 0H36z" />
                    <path fill={asRgbString(compColors[1]) || "currentColor"} d="M35.8 3.35A6.747 6.747 0 0 1 29 8.37a6.747 6.747 0 0 1-6.8-5.02A7.938 7.938 0 0 1 22.01 0h13.98a7.938 7.938 0 0 1-.19 3.35z"/>
                    <g fill={asRgbString(compColors[1]) || "currentColor"}>
                        <circle cx="38" cy="20" r="3" dataOriginal="#ffffff"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

const asRgbString = (rgbObj) => {
    if (isUndefined(rgbObj)) {
        return null;
    }
    return `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b})`
}