import {useHistory} from "react-router"
import {storage} from "../../config/constants"
import React, {useState} from 'react';
import Crest from "../team/Crest";
import {Label} from "./Text";
import {delay, isFunction, join, map} from "lodash";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";
import PinOffOutlineIcon from "mdi-react/PinOffOutlineIcon";
import PinOutlineIcon from "mdi-react/PinOutlineIcon";
import {LoadingModal} from "./Modals";
import {Input} from "./Forms";
import {Button, InlineShowMoreButton} from "./Buttons";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {updateSave} from "../../service/saveService";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import {SelectSheet} from "./Tabs";
import {MatchViewModeInput} from "../save/match-view-mode/MatchViewModeInput";
import "./styles/cards.css";

export const BottomCard = props => {
    return (
        <div className="card bottom-card">
            <div className="card-body">
                <h3>{props.title}</h3>
                <div className="card-content">
                    {props.children}
                </div>
            </div>
            <div className="card-footer">
                {props.footer}
            </div>
        </div>
    )
}

export const LineupTemplateCard = ({
                                       template,
                                       onClick,
                                       onClickDelete
                                   }) => {

    const renderIncludedPlayers = () => {
        return join(map(template.extra_fields.positions, (position) => {
            return position.player;
        }), ", ");
    }

    const handleClick = () => {
        if (isFunction(onClick)) {
            onClick.call(this, {...template});
        }
    }

    const handleDeleteClick = (event) => {
        // needed so that the template card's handleClick is not triggered
        event.stopPropagation();
        if (isFunction(onClickDelete)) {
            onClickDelete.call(this, template);
        }
    }

    return (
        <div className="lineup-template-card" onClick={() => handleClick()}>
            <div className="name-and-delete">
                <p className="template-name">
                    {template.template_name}
                </p>
                <TrashCanOutlineIcon onClick={(e) => handleDeleteClick(e)}/>
            </div>

            <p className="template-players">
                {renderIncludedPlayers()}
            </p>
        </div>
    )
}

export const CategoryCard = props => {

    const handleClick = (e) => {
        if (isFunction(props.onClick)) {
            props.onClick.call(this);
        }
    }

    const handleEditClick = (e) => {
        e.stopPropagation();
        if (isFunction(props.onClickEdit)) {
            props.onClickEdit.call(this);
        }
    }

    return (
        <div className={`card card-category${props.className ? " " + props.className : ""}`} onClick={handleClick}>
            {props.children}

            {props.editButton &&
                <DotsVerticalIcon onClick={(event) => handleEditClick(event)} />
            }
        </div>
    )
}

export const ContentCard = props => {
    return(
        <div className={`content-card${(props.className) ? " " + props.className : ""}`}>
            {props.children}
        </div>
    )
}

export const SaveCard = props => {

    const [workingSave, setWorkingSave] = useState({});
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [loadingModalTitle, setLoadingModalTitle] = useState("");
    const [loadingModalText, setLoadingModalText] = useState("");

    const history = useHistory();

    const accessSave = () => {
        localStorage.setItem(storage.SAVE_ID, props.save.id);
        localStorage.setItem(storage.MATCH_VIEW_MODE, props.save.match_view_mode);
        history.push("/overview");
    }

    const toggleEditModal = (event) => {
        event.stopPropagation();
        setWorkingSave({...props.save});
        setEditModalOpen(true);
    }

    const handleChange = (field, value) => {
        const newWorking = {...workingSave};
        newWorking[field] = value;
        setWorkingSave(newWorking);
    }

    const handleUpdate = async (data) => {
        setLoadingModalOpen(true);
        setLoadingModalTitle("Updating save...")
        setLoadingModalText("Updating save. Please wait...");

        try {
            const response = await updateSave(data);

            if (response && response.data && response.data.changedRows === 1) {
                setLoadingModalText("Save has been updated successfully.");

                if (isFunction(props.onUpdate)) {
                    props.onUpdate.call(this, data);
                }
            } else {
                setLoadingModalText("There were no changes.");
            }

            setEditModalOpen(false);
            setWorkingSave(Object.assign({}, {}));
        } catch (err) {
            console.log(err);
            setLoadingModalText(getFriendlyErrorMessage(err.response.data));
        } finally {
            closeLoadingModal();
        }
    }

    const toggleSavePinned = (event, newPinnedState) => {
        event.stopPropagation();
        handleUpdate({...props.save, pinned: newPinnedState});
    }

    const closeLoadingModal = () => {
        delay(() => setLoadingModalOpen(false), 750);
    }

    return (
        <>
            <CategoryCard className={`save-games ${props.save.pinned === 1 ? "pinned" : ""}`} onClick={accessSave}>
                {
                    props.save.pinned === 1 ?
                        <PinOffOutlineIcon onClick={(event) => toggleSavePinned(event, 0)}/>
                        :
                        <PinOutlineIcon onClick={(event) => toggleSavePinned(event, 1)} />
                }
                <p>{props.save.name}</p>

                <DotsVerticalIcon onClick={(e) => toggleEditModal(e)} />
            </CategoryCard>

            <SelectSheet classNames={"save-edit"} title={"Edit save"} isOpen={editModalOpen} onDismiss={() => setEditModalOpen(false)}>
                <Input type={"text"}
                       value={workingSave.name}
                       label={"Name"}
                       onChange={(value) => handleChange("name", value)}
                />

                <MatchViewModeInput value={workingSave.match_view_mode}
                                    saveGameId={workingSave.id}
                                    onChange={(value) => handleChange("match_view_mode", value)}
                />

                <Button onClick={() => handleUpdate(workingSave)}>
                    Update
                </Button>
            </SelectSheet>

            <LoadingModal isOpen={loadingModalOpen}
                          onDismiss={() => setLoadingModalOpen(false)}
                          title={loadingModalTitle}
                          text={loadingModalText} />
        </>
    )
}

export const HighlightCard = ({children, label}) => {

    return (
        <div className="card card-highlight">
            <Label value={label}/>
            {children}
        </div>
    )
}

export const HighlightCardTitle = ({value, color}) => {

    return (
        <h4 className="highlight-card-title" style={{
            color
        }}>
            {value}
        </h4>
    )
}

export const ListCardWithClub = (props) => {

    return (
        <div className="list-card" {...props}>
            <Crest club={props.club}/>
            <div className="list-info">
                {props.children}
            </div>
        </div>
    )
}

export const ListCardRow = (props) => {

    return (
        <div className="row">
            {props.children}
        </div>
    )
}

export const CardContentWrapper = ({children, showMoreButton = true, showMoreButtonText = "View all", onShowMoreClick}) => {

    return (
        <ContentCard className={`${showMoreButton ? "with-buttons" : ""}`}>
            {children}
            {showMoreButton &&
                <InlineShowMoreButton label={showMoreButtonText} onClick={onShowMoreClick}/>
            }
        </ContentCard>
    )
}