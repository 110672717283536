import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const getKnockoutRoundsByCompetition = async (competitionId) => {
    let saveGame = localStorage.getItem(storage.SAVE_ID);

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL, "/save/competition/knockout_rounds?saveGame=", saveGame,
            "&competition_id=", competitionId
        ], "")
    });

    return response;
}