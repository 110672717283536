import {delay, each, first, isEmpty, join, reverse} from "lodash";
import {useEffect, useState} from "react"
import {useParams} from "react-router";
import {Tab, TabList, TabPanel} from "react-tabs";
import {api, messages} from "../../config/constants";
import {getTeamColor, getTextColor} from "../../data/colors";
import {valuesExist} from "../../data/forms";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {getManagerById} from "../../service/managerService";
import {
    createManagerTenure,
    deleteManagerTenure,
    getManagerTenures,
    getManagerTenuresFromManager,
    updateManagerTenure
} from "../../service/managerTenureService";
import {getMatchesFromManager} from "../../service/matchService";
import {FeaturePreview} from "../feature/FeaturePreview";
import {FormContainer, Input, InputRegex} from "../generics/Forms";
import Header from "../generics/Header";
import {CircularLoaderInfinite} from "../generics/Loaders";
import {Modal} from "../generics/Modals";
import {BottomNavWithSheet} from "../generics/Navigation";
import {MatchContainer} from "../overview/LeagueMatches";
import {ManagerTenures} from "./ManagerTenures";
import {translations} from "../../constants/translations.en.js";
import withAuthorization from "../generics/WithAuthorizationHOC";
import {TabWrapper} from "../generics/Tabs";
import {ContentWrapper} from "../generics/Layout";

export const WrappedManagerScreen = () => {

    const { managerId } = useParams();

    useEffect(() => {
        getManager();
        getTenures();
        loadMoreMatches();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");
    const [createError, setCreateError] = useState("");
    const [creationData, setCreationData] = useState({});
    const [createFormLoading, setCreateFormLoading] = useState(false);

    const [manager, setManager] = useState(null);
    const [tenures, setTenures] = useState([]);
    const [currentTeam, setCurrentTeam] = useState("No team");

    const [headerBackground, setHeaderBackground] = useState("var(--header)");
    const [headerColor, setHeaderColor] = useState("var(--header-text)");

    const [matches, setMatches] = useState([]);
    const [matchesPage, setMatchesPage] = useState(1);
    const [stopLoadingMatches, setStopLoadingMatches] = useState(false);

    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [loadingModalTitle, setLoadingModalTitle] = useState("");
    const [loadingModalText, setLoadingModalText] = useState("");

    const [tabSheetAction, setTabSheetAction] = useState("none");

    useEffect(() => {
        if (tabSheetAction === "close") {
            setTabSheetAction("none");
        }
    }, [tabSheetAction]);

    useEffect(() => {
        if (isEmpty(tenures)) {
            return setCurrentTeam("No team");
        }

        let firstTenure = first(tenures);
        if (firstTenure.end_date !== null) {
            return setCurrentTeam("No team");
        }

        setCurrentTeam(firstTenure.team);

        getTeamColor(firstTenure.team)
            .then((color) => {
                let textColor = getTextColor(color);
                setHeaderBackground(color);
                setHeaderColor(textColor);
            })
            .catch((err) => {
            });
    }, [tenures]);


    const getManager = () => {
        getManagerById(managerId)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                setManager(response.data[0]);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            });
    }

    const getTenures = () => {
        getManagerTenures(managerId)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                getTenureStats(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const getTenureStats = (allTenures) => {
        getManagerTenuresFromManager(managerId)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                each(allTenures, (singleTenure, index) => {
                    singleTenure.played = 0;
                    singleTenure.won = 0;
                    singleTenure.draw = 0;
                    singleTenure.lost = 0;

                    let tenureIndex = findTenureByIdInList(response.data, singleTenure.id);

                    if (tenureIndex !== -1) {
                        singleTenure.played = response.data[tenureIndex].played;
                        singleTenure.draw = response.data[tenureIndex].draw;
                        singleTenure.lost = response.data[tenureIndex].lost;
                        singleTenure.won = singleTenure.played - singleTenure.draw - singleTenure.lost;
                    }

                    allTenures[index] = singleTenure;
                })

                setTenures(reverse(allTenures));
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const findTenureByIdInList = (tenureList, id) => {
        let found = -1;

        each(tenureList, (value, index) => {
            if (value.id === id) {
                found = index;
            }
        })

        return found;
    }

    const loadMoreMatches = () => {
        if (stopLoadingMatches) {
            return;
        }

        getMatchesFromManager(managerId, matchesPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return setStopLoadingMatches(true);
                }

                setMatches([...matches, ...response.data]);

                if (response.data.length === api.RESULTS_PER_PAGE) {
                    setMatchesPage(matchesPage + 1);
                } else {
                    setStopLoadingMatches(true);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                setStopLoadingMatches(true);
            })
    }

    const handleFormValue = (value, field) => {
        let fields = {...creationData};
        fields[field] = value;
        setCreationData(fields);
    }

    const addManagerTenure = () => {
        setCreateFormLoading(true);
        let requiredValuesExist = valuesExist(creationData, "team", "start_date");

        if (!requiredValuesExist.result) {
            setCreateError("Missing required values for: " + join(requiredValuesExist.missing, ", "));
            setCreateFormLoading(false);
            return;
        }

        createManagerTenure(manager.id, creationData)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setCreateError(getFriendlyErrorMessage(response.data));
                }

                setTenures([...response.data, ...tenures]);
                setTabSheetAction("close");
                setCreationData(Object.assign({}));
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setCreateError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setCreateError(messages.ERR_UNKNOWN);
                }
            })
            .finally(() => {
                setCreateFormLoading(false);
            })
    }

    const doTenureUpdate = (tenure) => {
        setLoadingModalOpen(true);
        setLoadingModalTitle("Updating manager tenure");
        setLoadingModalText("Please wait while the manager tenure is being updated...");

        updateManagerTenure(tenure.id, managerId, tenure)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingModalText(getFriendlyErrorMessage(response.data));
                    return;
                }

                setLoadingModalText("The manager tenure has been updated successfully. Reload the page to see updated stats.");

                each(tenures, (value, index) => {
                    if (value.id === tenure.id) {
                        let fields = tenures;
                        fields[index] = tenure;
                        setTenures(fields);
                        return;
                    }
                })
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingModalText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingModalText(messages.ERR_UNKNOWN);
                }
            })
            .finally(() => {
                hideModal();
            })
    }

    const doTenureDelete = (tenure) => {
        setLoadingModalOpen(true);
        setLoadingModalTitle("Deleting manager tenure");
        setLoadingModalText("Please wait while the manager tenure is being deleted.");

        deleteManagerTenure(tenure.id, managerId)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingModalText(getFriendlyErrorMessage(response.data));
                    return;
                }

                setLoadingModalText("The manager tenure has been deleted successfully.");

                each(tenures, (value, index) => {
                    if (value.id === tenure.id) {
                        let fields = tenures;
                        fields.splice(index, 1);
                        setTenures(fields);
                        return;
                    }
                })
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingModalText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingModalText(messages.ERR_UNKNOWN);
                }
            })
            .finally(() => {
                hideModal();
            })
    }

    const hideModal = () => {
        delay(() => {
            setLoadingModalOpen(false);
        }, 1000);
    }

    return (
        <div className="manager-screen">
            <Header title={manager && manager.name}
                background={headerBackground}
                color={headerColor} />

            <TabWrapper>
                <TabList style={{
                    backgroundColor: headerBackground,
                    color: headerColor
                }}>
                    <Tab>About</Tab>
                    <Tab>Matches</Tab>
                </TabList>

                <ContentWrapper>
                    <TabPanel>

                        <FeaturePreview isShown={(tenures.length === 0)}
                                        title={translations.features.NO_MANAGER_TENURES}
                                        description={translations.features.MANAGER_TENURE_DESC}>
                            <ManagerTenures
                                manager={manager}
                                currentTeam={currentTeam}
                                tenures={tenures}
                                lastMatch={first(matches)}
                                onUpdate={(tenure) => doTenureUpdate(tenure)}
                                onDelete={(tenure) => doTenureDelete(tenure)} />
                        </FeaturePreview>
                    </TabPanel>

                    <TabPanel>
                        <h4>Matches</h4>
                        <MatchContainer data={matches} onContinueLoading={loadMoreMatches} />
                    </TabPanel>
                </ContentWrapper>
            </TabWrapper>

            <BottomNavWithSheet tabSheetTitle={"Add new tenure"} tabSheetAction={tabSheetAction}>
                <FormContainer isLoading={createFormLoading} error={createError} onSubmit={addManagerTenure}>
                    <Input type="text"
                        id="c-team"
                        label="Team"
                        max={120}
                        value={creationData.team}
                        valueChange={(value) => handleFormValue(value, "team")} />

                    <Input type="text"
                        id="c-startdate"
                        label="Start date"
                        regex={InputRegex.date}
                        value={creationData.start_date}
                        info="Use the following format: yyyy-mm-dd."
                        valueChange={value => handleFormValue(value, "start_date")} />

                    <Input type="text"
                        id="c-enddate"
                        label="End date"
                        value={creationData.end_date}
                        regex={InputRegex.date}
                        info={"Use the following format: yyyy-mm-dd. Leave empty if this manager tenure is ongoing."}
                        valueChange={value => handleFormValue(value, "end_date")} />
                </FormContainer>
            </BottomNavWithSheet>

            <Modal isOpen={loadingModalOpen} onDismiss={() => setLoadingModalOpen(false)}>
                <h3>
                    {loadingModalTitle}
                </h3>
                <p>
                    {loadingModalText}
                </p>

                <CircularLoaderInfinite />
            </Modal>
        </div>
    )
}

export const ManagerScreen = withAuthorization(WrappedManagerScreen);