import {isFunction, map} from "lodash";
import "./horizontal-select.css";

export const HorizontalSelect = ({ value, onChange, options }) => {

    const handleOptionClick = (clickedValue) => {
        if (isFunction(onChange)) {
            onChange.call(this, clickedValue);
        }
    }

    const renderOptions = () => {
        return map(options, (option, index) => {
            return <HorizontalSelectOption key={index}
                                           label={option.label}
                                           value={option.value}
                                           isSelected={value === option.value}
                                           onClick={handleOptionClick}
            />
        })
    }

    return (
        <div className={"horizontal-select"}>
            <div className={"options"}>
                { renderOptions() }
            </div>
        </div>
    )

}

export const HorizontalSelectOption = ({ value, label, onClick, isSelected = false }) => {

    const handleClick = () => {
        if (isFunction(onClick)) {
            onClick.call(this, value);
        }
    }

    return (
        <div className={`horizontal-select-option ${isSelected && "selected"}`} onClick={handleClick}>
            <p>
                { label }
            </p>
        </div>
    )
}