const { codes, config } = require("../config/constants");

export const errorHandlerInterceptor = (response) => {
    if (!response.data.type || response.data.type !== "error") {
        return response;
    }

    if (!response.data.code) {
        return response;
    }

    if ([codes.TOKEN_EXPIRED, codes.TOKEN_INVALID, codes.NO_TOKEN].includes(response.data.code)) {
        window.location = config.BASE_URL;
        return;
    }

    if ([codes.SAVE_NOT_FOUND, codes.SAVE_NO_ACCESS].includes(response.data.code)) {
        window.location = config.BASE_URL + "/save";
        return;
    }

    return response;
} 

export const networkErrorInterceptor = (error) => {
    error.networkError = true;
}