import randomBytes from "randombytes";
import hash from 'hash.js';
import { config, storage } from "../../config/constants";
import _, { isFunction } from "lodash";
import { useEffect, useState } from "react";
import PlusIcon from 'mdi-react/PlusIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import "./styles/buttons.css";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";

export const Button = props => {
    return(
        <>
            <button onClick={props.onClick} className={_.join(["button button-primary", props.extraClassName], " ")}>
                {props.children}
            </button>
        </>
    )
}

export const SubtleButton = props => {
    return(
        <button onClick={props.onClick} className={_.join(["button button-subtle", props.extraClassName], " ")}>
            {props.children}
        </button>
    )
}

export const DisabledButton = props => {
    return(
        <button type={"disabled"} disabled={true} className={_.join(["button button-disabled", props.extraClassName], " ")}>
            {props.children}
        </button>
    )
}

export const DangerButton = props => {
    return(
        <button onClick={props.onClick} className={_.join(["button button-danger", props.extraClassName], " ")}>
            {props.children}
        </button>
    )
}

export const SignInButton = props => {

    const [elementClassName, setElementClassName] = useState("button button-signin");

    useEffect(() => {
        if (props.className) {
            setElementClassName(props.className);
        } else {
            setElementClassName("button button-signin");
        }
    }, [props.className]);

    const startAuthFlow = () => {
        let verificationCode = randomBytes(128).toString("hex");
        let codeChallenge = hash.sha256().update(verificationCode).digest("hex");

        localStorage.setItem(storage.VERIFICATION_CODE, verificationCode);
        
        window.location = _.join([config.AUTH_URL, "?client_id=", config.CLIENT_ID, "&redirect_uri=", encodeURI(config.CALLBACK), "&code_challenge=", codeChallenge, "&code_challenge_method=MSHEX"], "");
    } 

    return(
        <button {...props} 
            className={elementClassName}
            onClick={startAuthFlow}>
            Sign in

            <ArrowRightIcon />

        </button>
    )
}

export const ButtonRow = props => {

    return(
        <div className="buttons-row">
            {props.children}
        </div>
    )
}

export const Fab = ({onClick, styles, openedState}) => {

    const [iconClassName, setIconClassName] = useState("icon plus-open");

    useEffect(() => {
        if (openedState) {
            setIconClassName("icon plus-close");
        } else {
            setIconClassName("icon plus-open");
        }
    }, [openedState]);

    const toggleFab = () => {
        if (iconClassName === "icon plus-open") {
            setIconClassName("icon plus-close");
            if (isFunction(onClick)){
                onClick.call(this, "open");
            }
        } else {
            setIconClassName("icon plus-open");
            if (isFunction(onClick)){
                onClick.call(this, "close");
            }
        }
    }

    return (
        <UnmanagedFab onClick={toggleFab}
                      styles={styles}
                      icon={<PlusIcon color={"#fff"} className={iconClassName}/> }
        />
    )
}

export const UnmanagedFab = ({ onClick, styles, icon, classNames }) => {

    return (
        <div className={`fab ${classNames}`} onClick={onClick} style={styles}>
            { icon }
        </div>
    )
}

export const Badge = ({content}) => {

    return (
        <div className="badge">
            <p>{content}</p>
        </div>
    )
}

export const CloseButton = ({onClick}) => {
    return(
        <div className="close-button" onClick={onClick}>
            <CloseIcon/>
        </div>
    )
}

export const SmallCreateButton = ({onClick}) => {
    return(
        <div className="small-create-button" onClick={onClick}>
            <PlusIcon/>
        </div>
    )
}

export const SmallCreateButtonWithLabel = ({onClick, label}) => {
    return (
        <div className="small-create-button-container">
            <SmallCreateButton onClick={onClick}/>
            <p>{label}</p>
        </div>
    )
}

export const InlineShowMoreButton = ({onClick, label}) => {
    return(
        <div className="button inline-button" onClick={onClick}>
            <p>
                {label}
            </p>
        </div>
    )
}