import axios from 'axios';
import { config, storage } from '../config/constants';
import _ from 'lodash';

export const getAccessTokenData = async (accessToken) => {
    let headers = {
        'Authorization': "Bearer " + accessToken
    }

    let response = await axios({
        method: 'get',
        url: config.API_URL + "/user",
        headers
    });

    return response;
}

export const getAccessToken = async (authzCode) => {
    let verificationCode = localStorage.getItem(storage.VERIFICATION_CODE);

    let data = {
        'code_verifier': verificationCode,
        'client_id': config.CLIENT_ID,
        'grant_type': 'authorization_code',
        'code': authzCode
    };

    let response = await axios({
        method: 'post',
        url: _.join([config.OAUTH_URL, "/oauth"], ""),
        data: data
    });

    if (response.is_error === true) {
        return false;
    } else {
        return response;
    }
}