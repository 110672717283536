import Axios from 'axios';
import _ from 'lodash';
import { config, storage } from '../config/constants';

export const createLineupPositions = async (lineupId, matchId, positions) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties':  {
            'saveGame': saveId,
            'match_id': matchId,
            'lineup_id': lineupId
        }, 
        'items': [
            ...positions
        ]
    };

    let response = await Axios({
        method: 'POST',
        url: config.API_URL + "/save/match/lineup/positions",
        headers,
        data
    });

    return response;
}

export const getPositionsFromLineup = async (matchId, lineupId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: "GET",
        url: _.join([config.API_URL, "/save/match/lineup/positions?saveGame=", saveId, "&match_id=", matchId, "&lineup_id=", lineupId], ""),
        headers
    });

    return response;
}

export const updateLineupPosition = async (matchId, lineupId, positionId,  updatedLineupPosition) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            'match_id': matchId,
            'lineup_id': lineupId,
            'id': positionId,
            ...updatedLineupPosition
        }
    };

    let response = await Axios({
        method: 'PATCH',
        url: config.API_URL + "/save/match/lineup/positions",
        headers,
        data
    });

    return response;
}