import _, {isArray, isEmpty, isFunction, join, map} from 'lodash';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {InlineShowMoreButton} from './Buttons';
import {Label} from './Text';
import ReactVisibilitySensor from "react-visibility-sensor";
import {ContentCard} from "./Cards";
import {SelectSheet} from "./Tabs";

export const KeyValueTable = ({
                                  values,
                              }) => {

    const renderValues = () => {
        return map(values, (value, index) => {
            return (
                <div key={index} className={"key-value-item"}>
                    <div className={"key-value-key"}>
                        <p>
                            {value.name}
                        </p>
                    </div>
                    <div className={"key-value-value"}>
                        <p>
                            {value.value}
                        </p>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className={"key-value-table"}>
            {renderValues()}
        </div>
    )
}

export const PlayerStatsTable = ({
                                     data,
                                     header = "",
                                     keyName = "key",
                                     detailsKeyName = "",
                                     valueName = "value",
                                     displayPositions = false,
                                     legend = [],
                                     onLoadMore,
                                 }) => {

    const [modalShown, setModalShown] = useState(false);

    const getClassName = () => {
        if (displayPositions) {
            return "table-row with-positions";
        } else {
            return "table-row";
        }
    }

    const renderData = (maxItems = -1) => {
        return _.map(data, (value, index) => {
            if (maxItems !== -1 && index >= maxItems) {
                return false;
            } else {
                return (
                    <>
                        <Link to={"/player/" + value[keyName]}>
                            <div className={`${getClassName()} item-${index}`} key={index}>
                                {displayPositions &&
                                    <div className="table-col position">
                                        <div className={"position-circle"}/>
                                        <p>{index + 1}</p>
                                    </div>
                                }
                                <div className={`table-col player ${!isEmpty(detailsKeyName) ? "has-details" : ""}`}>
                                    <p className={"value"}>{value[keyName]}</p>
                                    {!isEmpty(detailsKeyName) &&
                                        <p className={"details"}>
                                            {value[detailsKeyName]}
                                        </p>
                                    }
                                </div>
                                <div className="table-col amount">
                                    <p>{value[valueName]}</p>
                                </div>
                            </div>
                        </Link>
                        {
                            maxItems !== -1 && index + 1 >= maxItems && data.length - maxItems > 0 &&
                            <InlineShowMoreButton onClick={() => setModalShown(true)}
                                                  label={"Show more"}/>
                        }
                    </>
                );
            }
        })
    }

    const renderLegendRow = () => {
        return map(legend, (item, index) => {
            return (
                <div className="table-col" key={index}>
                    {item.name}
                </div>
            )
        })
    }

    const handleLoadMore = (isLoadMoreSensorVisible) => {
        if (isLoadMoreSensorVisible && isFunction(onLoadMore)) {
            onLoadMore.call(this);
        }
    }

    return (
        <>
            <div className="player-stats-table">
                {header &&
                    <h4>{header}</h4>
                }

                <ContentCard>
                    {!isEmpty(legend) &&
                        <div className={getClassName() + " label"}>
                            {renderLegendRow()}
                        </div>
                    }
                    {renderData(5)}
                </ContentCard>
            </div>

            <SelectSheet title={header} isOpen={modalShown} onDismiss={() => setModalShown(false)}>
                <div className="player-stats-table">
                    {!isEmpty(legend) &&
                        <div className={getClassName() + " label"}>
                            {renderLegendRow()}
                        </div>
                    }
                    {renderData()}

                    <ReactVisibilitySensor onChange={(isVisible) => handleLoadMore(isVisible)}>
                        <div className="sensor-load-more"></div>
                    </ReactVisibilitySensor>
                </div>
            </SelectSheet>
        </>
    )
}

export const StatTable = (props) => {

    return (
        <div className="stats-table">
            {props.children}
        </div>
    )
}

export const Stat = ({value, label, labels, values, className}) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const canNavigateThroughValues = () => {
        return (isArray(values) && isArray(labels) && values.length === labels.length);
    }

    const handleTap = () => {
        if (canNavigateThroughValues()) {
            if (currentIndex + 1 === labels.length) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex+1);
            }
        }
    }

    const getValue = () => {
        if (canNavigateThroughValues()) {
            return values[currentIndex];
        } else {
            return value;
        }
    }

    const getLabel = () => {
        if (canNavigateThroughValues()) {
            return labels[currentIndex];
        } else {
            return label;
        }
    }

    return (
        <div className={`stat${canNavigateThroughValues() ? " tappable" : ""}${(className) ? " " + className : ""}`} onClick={handleTap}>
            <p className="value">
                {getValue()}
            </p>
            <p className="label">
                {getLabel()}
            </p>
        </div>
    )
}

export const TableLabels = ({
                                templateColumns,
                                labels
                            }) => {

    const getTemplateColumns = () => {
        return join(templateColumns, " ");
    }

    const renderLabels = () => {
        return map(labels, (label) => {
            return <Label value={label}/>
        })
    }

    return (
        <div className="table-labels"
             style={{
                 gridTemplateColumns: getTemplateColumns()
             }}>
            {renderLabels()}
        </div>
    )
}