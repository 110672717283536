import {LeagueSelect} from "./LeagueSelect";
import {LeagueSeasonSelect} from "./LeagueSeasonSelect";
import {KnockoutRoundSelect} from "../knockout/KnockoutRoundSelect";
import {useEffect, useState} from "react";
import {getKnockoutRoundsByCompetition} from "../../service/knockoutRoundService";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {messages} from "../../config/constants";
import {each, isEmpty, isFunction, join} from "lodash";
import {getCompetitions, getLeagueSeasons} from "../../service/competitionService";
import {Button} from "../generics/Buttons";

export const CompetitionSelectWrapper = ({onChange, onError }) => {

    const [formValues, setFormValues] = useState({});
    const [leagues, setLeagues] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState("");
    const [selectedLeagueCountry, setSelectedLeagueCountry] = useState("");
    const [selectedLeagueName, setSelectedLeagueName] = useState("");
    const [leagueSeasons, setLeagueSeasons] = useState([]);
    const [selectedLeagueSeason, setSelectedLeagueSeason] = useState({});
    const [leagueRounds, setLeagueRounds] = useState([]);

    useEffect(() => {
        getCompetitions()
            .then((response) => {
                if (response.data && response.data.type) {
                    onError(getFriendlyErrorMessage(response.data));
                } else {
                    setLeagues([...response.data]);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    onError(getFriendlyErrorMessage(error.response.data));
                } else {
                    onError(messages.ERR_UNKNOWN);
                }
            })
    }, []);

    const handleChange = (newValue, field) => {
        let updated = {...formValues};
        updated[field] = newValue;
        setFormValues(updated);
    }

    const resetLeagueSeasonFields = (competitionId = "") => {
        setLeagueSeasons([]);
        setSelectedLeagueSeason({...{}});
        handleChange(competitionId, "competition_id");
    }

    const resetKnockoutRoundFields = (knockoutRoundId = "") => {
        handleChange(knockoutRoundId, "knockout_round_id");
        setLeagueRounds([]);
    }

    const handleLeagueChange = (newLeague, competitionId = "", knockoutRoundId = "") => {
        resetLeagueSeasonFields(competitionId);
        resetKnockoutRoundFields(knockoutRoundId);
        setSelectedLeague(newLeague);

        let leagueData = newLeague.split(/:(.+)/);
        let country = leagueData[0];
        let name = leagueData[1];

        setSelectedLeagueCountry(country);
        setSelectedLeagueName(name);

        // league selection should be locked until seasons are loaded
        // to avoid race conditions and have wrong league seasons
        // showing up..
        getLeagueSeasons(name, country)
            .then((response) => {
                if (response.data && response.data.type) {
                    onError(getFriendlyErrorMessage(response.data));
                } else {
                    setLeagueSeasons([...response.data]);
                }
            })
            .catch((error) => {
                if (error.response) {
                    onError(getFriendlyErrorMessage(error.response.data));
                } else {
                    onError(messages.ERR_UNKNOWN);
                }
            })
    }

    const handleLeagueSeasonChange = (value, newLeague) => {
        resetKnockoutRoundFields();
        let updated = {...formValues};
        updated.competition_id = value;
        setSelectedLeagueSeason({...newLeague});
        setFormValues({...updated});

        if (newLeague.is_knockout === 1) {
            getKnockoutRoundsByCompetition(value)
                .then((response) => {
                    if (response.data && response.data.type) {
                        onError(getFriendlyErrorMessage(response.data));
                    } else {
                        setLeagueRounds([...response.data]);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        onError(getFriendlyErrorMessage(error.response.data));
                    } else {
                        onError(messages.ERR_UNKNOWN);
                    }
                    console.error(error);
                })
        } else {
            updated["knockout_round_id"] = null;
        }
    }

    const handleLeagueCreation = (data) => {
        setLeagues([data, ...leagues]);

        handleLeagueChange(join([data.country, data.name], ":"));
    }

    const handleLeagueSeasonCreation = (data) => {
        setLeagueSeasons([data, ...leagueSeasons]);

        handleLeagueSeasonChange(data.id, data);
    }

    const handleConfirmation = () => {
        if (!isEmpty(leagueRounds) && !formValues.knockout_round_id) {
            return onError("You have to choose a knockout round first!");
        }

        if (isFunction(onChange)) {
            onChange.call(this, {
                competition_id: formValues.competition_id,
                knockout_round_id: formValues.knockout_round_id
            })
        }
    }

    return (
        <>
            <LeagueSelect leagues={leagues}
                          onChange={(value) => handleLeagueChange(value)}
                          onCreate={(created) => handleLeagueCreation(created)}
                          value={selectedLeague}/>

            <LeagueSeasonSelect seasons={leagueSeasons}
                                competitionCountry={selectedLeagueCountry}
                                competitionName={selectedLeagueName}
                                onChange={(value, obj) => handleLeagueSeasonChange(value, obj)}
                                onCreate={(created) => handleLeagueSeasonCreation(created)}
                                value={formValues.competition_id}/>

            {selectedLeagueSeason.is_knockout === 1 &&
                <KnockoutRoundSelect onChange={value => handleChange(value, "knockout_round_id")}
                                     rounds={leagueRounds}
                                     value={formValues.knockout_round_id}/>
            }

            <Button onClick={handleConfirmation}>Confirm</Button>
        </>
    )
}