import {isEmpty, reverse} from "lodash";
import {useEffect, useState} from "react";
import {useParams} from "react-router"
import {Tab, TabList, TabPanel} from "react-tabs";
import {api, messages} from "../../config/constants";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {getMatchesFromPlayer, getPlayerMatchWinStats} from "../../service/matchService";
import {
    getPlayerAssistsPerSeason,
    getPlayerBestRatings,
    getPlayerGoalsPerSeason,
    getPlayerStatsPerTeam,
    getPlayerTotalAppearances
} from "../../service/statService";
import {getTransfersFromPlayer} from "../../service/transferService";
import Header from "../generics/Header"
import {BottomNavWithSheet} from "../generics/Navigation";
import withAuthorization from "../generics/WithAuthorizationHOC";
import NewMatchForm from "../match/NewMatchForm";
import {MatchContainer} from "../overview/LeagueMatches";
import {PlayerAbout} from "./PlayerAbout";
import {TabWrapper} from "../generics/Tabs";
import {ContentWrapper} from "../generics/Layout";
import {PlayerTeamStats} from "./PlayerTeamStats";

export const WrappedPlayerScreen = (props) => {

    const {playerName} = useParams();

    const [loadedTabs, setLoadedTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");

    const [goalsPerSeason, setGoalsPerSeason] = useState([]);
    const [appearances, setAppearances] = useState({appearances: 0, substitutions: 0});
    const [matchWins, setMatchWins] = useState({win: 0, draw: 0, lose: 0});

    const [matches, setMatches] = useState([]);
    const [matchesPage, setMatchesPage] = useState(1);
    const [stopLoadingMatches, setStopLoadingMatches] = useState(false);

    const [assistsPerSeason, setAssistsPerSeason] = useState([]);
    const [playerRatings, setPlayerRatings] = useState([]);

    const [transfers, setTransfers] = useState([]);

    const [playerTeamStats, setPlayerTeamStats] = useState({});

    useEffect(() => {
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setError("");
        setSelectedTab(0);
        setLoadedTabs([...[]]);
        setGoalsPerSeason([])
        setAppearances(0);
        setMatchWins({win: 0, draw: 0, lose: 0});
        setMatches([...[]]);
        setMatchesPage(1);
        setStopLoadingMatches(false);
        setAssistsPerSeason([...[]]);
        setPlayerRatings([...[]]);
        setTransfers([...[]]);
        setPlayerTeamStats({...{}});

        init();
    }, [playerName]);

    const init = () => {
        loadGoalsPerSeason();
        loadPlayerTransfers();
        loadPlayerAppearances();
        loadPlayerRatings();
        loadPlayerAssistsPerSeason();
        loadPlayerMatchWins();
    }

    const loadPlayerMatchWins = () => {
        getPlayerMatchWinStats(playerName)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setError(getFriendlyErrorMessage(response.data));
                }

                if (!isEmpty(response.data)) {
                    setMatchWins(response.data[0]);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            });
    }

    const loadPlayerAppearances = () => {
        getPlayerTotalAppearances(playerName)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setError(getFriendlyErrorMessage(response.data));
                }

                if (!isEmpty(response.data)) {
                    setAppearances(response.data[0]);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadGoalsPerSeason = () => {
        getPlayerGoalsPerSeason(playerName)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                setGoalsPerSeason(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadPlayerAssistsPerSeason = () => {
        getPlayerAssistsPerSeason(playerName)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setError(getFriendlyErrorMessage(response.data));
                }

                setAssistsPerSeason(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    return setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadPlayerRatings = () => {
        getPlayerBestRatings(playerName)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setError(getFriendlyErrorMessage(response.data));
                }

                setPlayerRatings(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadPlayerTransfers = () => {
        getTransfersFromPlayer(playerName)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                setTransfers(reverse(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadMoreMatches = () => {
        if (stopLoadingMatches) {
            return;
        }

        getMatchesFromPlayer(playerName, matchesPage)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                    return;
                }

                setMatches([...matches, ...response.data]);

                if (response.data.length === api.RESULTS_PER_PAGE) {
                    setMatchesPage(matchesPage + 1);
                } else {
                    setStopLoadingMatches(true);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const loadPlayerTeamStats = async () => {
        setPlayerTeamStats(await getPlayerStatsPerTeam(playerName));
    }

    const handleInitialTabLoad = (newIndex, oldIndex) => {
        if (newIndex === oldIndex) {
            return;
        }

        setSelectedTab(newIndex);

        if (loadedTabs.includes(newIndex)) {
            return;
        }

        switch (newIndex) {
            case 1:
                loadMoreMatches();
                break;
            case 2:
                loadPlayerTeamStats();
                break;
            default:
        }

        setLoadedTabs([...loadedTabs, newIndex]);
    }

    return (
        <div className="player-screen">
            <Header title={playerName}/>

            <TabWrapper selectedIndex={selectedTab} onSelect={(newIndex, oldIndex) => handleInitialTabLoad(newIndex, oldIndex)}>
                <TabList>
                    <Tab>About</Tab>
                    <Tab>Matches</Tab>
                    <Tab>Stats</Tab>
                </TabList>

                <ContentWrapper>
                    <TabPanel>
                        <PlayerAbout goals={goalsPerSeason}
                                     transfers={transfers}
                                     appearances={appearances}
                                     ratings={playerRatings}
                                     assists={assistsPerSeason}
                                     matchWins={matchWins}/>
                    </TabPanel>

                    <TabPanel>
                        <h4>All matches</h4>
                        <MatchContainer data={matches}
                                        onContinueLoading={loadMoreMatches}/>
                    </TabPanel>

                    <TabPanel>
                        <PlayerTeamStats data={playerTeamStats} />
                    </TabPanel>
                </ContentWrapper>
            </TabWrapper>

            <BottomNavWithSheet tabSheetTitle={"New match"}>
                <NewMatchForm/>
            </BottomNavWithSheet>
        </div>
    )
}

export const PlayerScreen = withAuthorization(WrappedPlayerScreen);