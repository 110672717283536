import { isFunction } from "lodash";
import { isEqual } from "lodash";
import { isEmpty } from "lodash";
import pick from "lodash.pick";
import { useEffect, useState } from "react"
import { messages } from "../../config/constants";
import { createCompetition } from "../../service/competitionService";
import { getFriendlyErrorMessage } from "../../service/errorService";
import { CountryInput, FormContainer, Input, Slider, Toggle } from "../generics/Forms";

export const NewLeagueForm = ({
    onCreate,
    baseData = {},
    isBaseDataEditable = true
}) => {

    const [originalBaseData, setOriginalBaseData] = useState({});
    const [data, setData] = useState({
        knockout_total_rounds: 1
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!isEqual(baseData, originalBaseData)) {
            setOriginalBaseData(baseData);
            setData({
                ...baseData,
            });
        } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseData]);

    const submitLeague = () => {
        setIsLoading(true);

        createCompetition(data)
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setData(pick(data));
                    if (isFunction(onCreate)) {
                        onCreate.call(this, response.data);
                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
                setIsLoading(false);
            })
    }

    const handleChange = (field, value) => {
        if (!isEmpty(baseData[field])) {
            if (!isBaseDataEditable) {
                return;
            }
        }

        let newData = {...data};
        newData[field] = value;
        setData(newData);
    }

    return (
        <>
            <FormContainer onSubmit={submitLeague} isLoading={isLoading} error={error}>
                <Input type="text"
                    value={data.name}
                    name="name"
                    label="Name"
                    disabled={(originalBaseData.name)}
                    onChange={(value) => handleChange("name", value)}/>

                <CountryInput label="Country"
                    name="country"
                    value={data.country}
                    disabled={(originalBaseData.country)}
                    onChange={(value) => handleChange("country", value)}/>  

                <Input type="text"
                    name="season"
                    label="Season"
                    value={data.season}
                    onChange={(value) => handleChange("season", value)}/>

                <Toggle label="International competition"
                    name={"is_international"}
                    value={data.is_international}
                    onChange={(value) => handleChange("is_international", value)}/>

                <Toggle label="Knockout competition"
                    name="is_knockout"
                    value={data.is_knockout}
                    onChange={(value) => handleChange("is_knockout", value)}/>

                {
                    data.is_knockout === true &&

                    <>
                        <h5>Configure knockout competition</h5>

                        <Slider label="Total rounds"
                            min={1}
                            max={20}
                            step={1}
                            value={data.knockout_total_rounds}
                            name="knockout_total_rounds"
                            onChange={(value) => handleChange("knockout_total_rounds", value)}/>

                        <Toggle label="Has group stage"
                            name={"knockout_with_group_stage"}
                            value={data.knockout_with_group_stage}
                            onChange={(value) => handleChange("knockout_with_group_stage", value)}/>

                        <Toggle label="Has third place final"
                            name="knockout_with_third_place"
                            value={data.knockout_with_third_place}
                            onChange={(value) => handleChange("knockout_with_third_place", value)}/>
                    </>
                }

            </FormContainer>
        </>
    )
}