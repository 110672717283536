import {isFunction, map} from "lodash";
import Crest from "../team/Crest";
import {Link} from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor";
import {ContentCard} from "../generics/Cards";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

export const PastWinnersScreen = ({data, onLoadMore}) => {

    const render = () => {
        return map(data, winner => {
            return <PastLeagueWinner data={winner} />
        })
    }

    const visibilityChanged = (isVisible) => {
        console.log("reached end load more?")
        if (isVisible && isFunction(onLoadMore)) {
            onLoadMore();
        }
    }

    return (
        <div className={"league-past-winners"}>
            {render()}

            <ReactVisibilitySensor active={true} onChange={visibilityChanged}>
                <div className={"sensor-load-more"}/>
            </ReactVisibilitySensor>
        </div>
    )
}

export const PastLeagueWinner = ({data}) => {
    return (
        <ContentCard className={"league-past-winner"}>
            <div className={"league-past-winner-header"}>
                <Link to={`/league/${data.id}`}>
                    <p>{data.season}</p>
                    <ChevronRightIcon/>
                </Link>
            </div>
            <div className={"league-past-winner-teams"}>
                <Link to={`/team/${data.team}`}>
                    <div className={"league-past-winner-team"}>
                        <Crest club={data.team}/>
                        <p>{data.team}</p>
                    </div>
                </Link>
            </div>
        </ContentCard>
    )
}