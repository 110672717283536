import {isFunction, reverse, sortBy} from "lodash"
import {each} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import {FullScreenSelect, Select, SelectOption} from "../generics/Forms"
import {SelectSheet} from "../generics/Tabs";
import {NewLeagueForm} from "./NewLeagueForm";
import PlusIcon from "mdi-react/PlusIcon";

const VALUE_NEW_SEASON = "myscores_default_new_season_value";

export const LeagueSeasonSelect = ({
                                       competitionName,
                                       competitionCountry,
                                       seasons,
                                       onChange,
                                       onCreate,
                                       value
                                   }) => {

    const [seasonCreateOpen, setSeasonCreateOpen] = useState(false);
    const [formBaseData, setFormBaseData] = useState({});
    const [actualValue, setActualValue] = useState("");

    useEffect(() => {
        if (value !== actualValue) {
            setActualValue(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (value, obj) => {
        if (value === VALUE_NEW_SEASON) {
            handleOpenSeasonCreation();
        } else if (isFunction(onChange)) {
            onChange.call(this, value, obj);
        }
    }

    const handleOpenSeasonCreation = () => {
        setSeasonCreateOpen(true);
        setFormBaseData({
            name: competitionName,
            country: competitionCountry
        });
    }

    const afterCreate = (data) => {
        if (isFunction(onCreate)) {
            onCreate.call(this, data[0]);
        }

        setSeasonCreateOpen(false);
    }

    const renderOptions = () => {
        let sortedSeasons = reverse(sortBy(seasons, ["season"]));

        let options = [];

        options.push(<SelectOption value={VALUE_NEW_SEASON}
                                   icon={<PlusIcon/>}
                                   key={"default"}
                                   content={"Add new season"}/>);

        each(sortedSeasons, (value, index) => {
            options.push(<SelectOption
                value={value.id}
                id={value}
                content={value.season}
                key={index}
            />);
        });

        return options;
    }

    return (
        <>
            <FullScreenSelect onChange={(value, content, obj) => handleChange(value, obj)}
                              label={(seasons) ? "Choose a season" : "Choose a competition first"}
                              value={actualValue}>
                {renderOptions()}
            </FullScreenSelect>

            <SelectSheet title={"New league"} isOpen={seasonCreateOpen} onDismiss={() => setSeasonCreateOpen(false)}>
                <NewLeagueForm
                    baseData={formBaseData}
                    onCreate={(d) => afterCreate(d)}
                    isBaseDataEditable={false}/>
            </SelectSheet>
        </>
    )
}