import {useEffect} from "react";
import {isFunction} from "lodash";

/**
 *
 * @param ref
 * @param callback
 * @param {Object} opts Additional options
 * @param {boolean} opts.detectOutsideScroll Whether or not to also call the callback function if a scroll event is registered
 */
export const useOutsideAlerter = (ref, callback, opts = {}) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (isFunction(callback)) {
                    callback.call(this);
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        if (opts.detectOutsideScroll) {
            document.addEventListener("scroll", handleClickOutside);
        }

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("scroll", handleClickOutside);
        };
    }, [ref]);
}