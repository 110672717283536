import axios from 'axios';
import {config, storage} from '../config/constants';

export const getSaves = async (accessToken) => {
    let headers = {
        'Authorization': "Bearer " + accessToken
    };

    let response = await axios({
        method: "GET",
        url: `${config.API_URL}/user/saves`,
        headers
    });

    return response;
}

export const getSaveGameStats = () => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return axios({
       method: 'GET',
       url: `${config.API_URL}/save/stats?saveGame=${saveId}`
    });
}

export const createSave = async (accessToken, properties) => {
    let headers = {
        'Authorization': "Bearer " + accessToken
    };

    let data = {
        properties: {
            ...properties
        }
    }

    let response = await axios({
        method: "POST",
        url: `${config.API_URL}/user/saves`,
        headers,
        data
    });

    return response;
}

export const updateSave = (data) => {
    return axios.patch(`${config.API_URL}/save`, {
        properties: {
            ...data,
            saveGame: data.id,
        }
    })
}