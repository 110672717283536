import { map } from "lodash"
import { RATINGS } from "../../config/color-vars"
import { getRatingColor } from "../../data/colors"
import { PlayerRating } from "../generics/Text"
import Crest from "../team/Crest"

const PlayerRatingsLegend = () => {
    return(
        <div className="ratings-legend">
            <p className="score-9">9</p>
            <p className="score-6">6</p>
            <div className="perfect" style={{backgroundColor: RATINGS.PERFECT}}></div>
            <div className="good" style={{backgroundColor: RATINGS.GOOD}}></div>
            <div className="decent" style={{backgroundColor: RATINGS.DECENT}}></div>
            <div className="bad" style={{backgroundColor: RATINGS.BAD}}></div>
            <div className="awful" style={{backgroundColor: RATINGS.AWFUL}}></div>
        </div>
    )
}

const PlayerRatingBar = ({rating}) => {

    const getHeight = () => {
        if (rating.rating <= 5.2) {
            return 5;
        } else {
            return (rating.rating - 5) / 5 * 100
        }
    }

    return(
        <div className="player-rating-bar">
            <div className="team">
                <Crest club={rating.team}/>
            </div>
            <div className="bar">
                <div className="bar-background"
                    style={{
                        height: getHeight() + "%",
                        backgroundColor: getRatingColor(rating.rating)
                    }}></div>
            </div>
            <div className="actual-rating">
                <PlayerRating rating={rating.rating}/>
            </div>
        </div>
    )
}

export const PlayerRatingsChart = ({ratings}) => {

    const renderRatings = () => {
        return map(ratings, (value, index) => {
            return <PlayerRatingBar rating={value} key={index}/>
        })
    }

    return (
        <div className="player-ratings-chart">

            <div className="ratings">
                {renderRatings()}
            </div>

            <PlayerRatingsLegend/>
        </div>
    )
}