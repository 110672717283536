import { isFunction } from "lodash"
import {Label, TitleLabel} from "./Text";


export const ScrollableTagLine = ({
    name,
    children
}) => {

    return (
        <div className="scrollable-tag-line">
            { name &&
                <TitleLabel value={name} />
            }

            <div className="tag-line-tags">
                { children }    
            </div>
        </div>
    )
}

export const Tag = ({
    onClick,
    content,
    value
}) => {

    const handleClick = () => {
        if (isFunction(onClick)) {
            onClick.call(this, value);
        }
    }

    return(
        <button className="tag" onClick={() => handleClick()}>
            { content }
        </button>
    )
}