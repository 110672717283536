import {join} from "lodash"
import {Link} from "react-router-dom"
import {ScoreLineText} from "../generics/Text"
import Crest from "../team/Crest"
import {ContentCard} from "../generics/Cards";

export const FeaturedMatch = ({match}) => {

    return (
        <div className={"featured-match-wrapper"}>
            <h4>Featured match</h4>

            <ContentCard>
                <Link to={join(["/match/", match.id], "")}>
                    <div className="featured-match">
                        <div className="meta">
                            <p>{match.home_team} - {match.away_team}</p>
                        </div>
                        <div className="scores">
                            <Crest club={match.home_team} isInternational={match.is_international}/>
                            <ScoreLineText homeTeamScore={match.home_team_score} awayTeamScore={match.away_team_score}/>
                            <Crest club={match.away_team} isInternational={match.is_international}/>
                        </div>
                    </div>
                </Link>
            </ContentCard>
        </div>
    )
}