import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const getAllManagers = async (page) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/managers/all?saveGame=",
            saveId,
            "&page=",
            page,
            "&sort_order=DESC"
        ], ""),
        headers
    });
    
    return response;
}

export const getManagerById = async (id) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/managers?saveGame=", saveId,
            "&id=", id
        ], ""),
        headers
    });

    return response;
}

export const createManager = async (managerData) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            ...managerData
        }
    };

    let response = await Axios({
        method: 'POST',
        url: config.API_URL + "/save/managers",
        headers,
        data
    });

    return response;
}

export const updateManager = async (managerId, manager) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'id': managerId,
            'saveGame': saveId,
            ...manager
        }
    };

    let response = await Axios({
        method: 'PATCH',
        url: config.API_URL + "/save/managers",
        headers,
        data
    });

    return response;
}

export const deleteManager = async (managerId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        url: join([
            config.API_URL, 
            "/save/managers",
            "?saveGame=", saveId,
            "&id=", managerId
        ], ""),
        headers
    });

    return response;
}