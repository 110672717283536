import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Button, DangerButton, DisabledButton } from './Buttons';
import { CircularLoaderInfinite } from './Loaders';
import {createPortal} from "react-dom";
import "./styles/modals.css";

export const Modal = ({ children, isOpen, onDismiss }) => {

    const closeHandler = () => {
        if (_.isFunction(onDismiss)) {
            onDismiss.call(this);
        }
    }

    return createPortal(
        <div className="modal-wrapper">
            <CSSTransition in={isOpen} timeout={200}>
                <div className={"modal-container"}>
                    <div className="modal-background" onClick={closeHandler}></div>

                    <div className="modal">
                        <div className="modal-content">
                            {children}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    , document.getElementById("root"));
}

export const LoadingModal = ({
    isOpen,
    onDismiss,
    title,
    text
}) => {

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss}>
            <h3>{title}</h3>
            <p>{text}</p>
            <CircularLoaderInfinite />
        </Modal>
    )
}

export const DeletionModal = ({ isOpen, onDismiss, onConfirm, title, text, confirmText = "delete" }) => {

    const [deleteText, setDeleteText] = useState("");
    const [deleteButton, setDeleteButton] = useState(null);

    useEffect(() => {
        if (deleteText === confirmText) {
            setDeleteButton(<DangerButton onClick={onConfirm}>Delete</DangerButton>)
        } else {
            setDeleteButton(<DisabledButton>Delete</DisabledButton>)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteText]);

    useEffect(() => {
        if (!isOpen) {
            setDeleteText("");
        }
    }, [isOpen])

    const handleValue = (value) => {
        if (confirmText.substr(0, value.length) === value) {
            setDeleteText(value);
        }
    }

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss}>
            <h3>Attention: {title}</h3>
            <p>{text}</p>
            <p>
                <strong>
                    Type in "{confirmText}" below to confirm that you want to
                    delete this.
                </strong>
            </p>

            <div className="input-field perma-placeholder">
                <input type={"text"}
                    value={deleteText}
                    onChange={(e) => handleValue(e.target.value)} />
                <p>{confirmText}</p>
            </div>

            <div className="buttons-footer">
                {deleteButton}

                <Button onClick={onDismiss}>Cancel</Button>
            </div>
        </Modal>
    )
}