export const Currencies = {
    "EUR": {
        "symbol": "€"
    },
    "USD": {
        "symbol": "$"
    },
    "GBP": {
        "symbol": "£"
    }
}