import {getRelativeOdds} from "../../data/chance"
import {HorizontalBarChart} from "../generics/Graphs";

export const ChanceOfWinning = ({
                                    homeOdds,
                                    awayOdds,
                                    homeTeam,
                                    awayTeam
                                }) => {

    const chances = getRelativeOdds(homeOdds, awayOdds);

    return (
        <HorizontalBarChart progressLeft={chances.home}
                            progressRight={chances.away}
                            showPercentages={true}
                            labelLeft={homeTeam}
                            labelRight={awayTeam}/>
    )
}