import { isFunction, join } from "lodash";
import { map } from "lodash";
import { useState } from "react";
import { messages } from "../../config/constants";
import { valuesExist } from "../../data/forms";
import { getFriendlyErrorMessage } from "../../service/errorService";
import { createObjectivePlayer } from "../../service/transferObjectivePlayerService";
import { SmallCreateButtonWithLabel } from "../generics/Buttons";
import { SelectSheet } from "../generics/Tabs";
import { MonetaryAmount, PlayerPriority } from "../generics/Text";
import { NewTransferObjectivePlayerForm } from "./NewTransferObjectivePlayerForm";
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';

export const TransferObjectivePlayers = ({ 
    objective, 
    transferWindow,
    onCreate,
    onClickMore
}) => {

    const [sheetOpen, setSheetOpen] = useState(false);
    const [values, setValues] = useState({
        player: "",
        priority: "",
        amount: ""
    });
    const [createError, setCreateError] = useState("");
    const [createLoading, setCreateLoading] = useState(false);

    const renderPlayers = () => {
        if (objective.extra_fields && objective.extra_fields.objective_players) {
            let players = objective.extra_fields.objective_players;

            players.sort((a, b) => {
                return b.active - a.active || a.priority - b.priority;
            })
    
            return map(players, (player, index) => {
                return <TransferObjectivePlayer player={player} 
                    key={index}
                    onClickMore={() => onClickMore.call(this, player)} />
            });
        }
    }

    const createChange = (field, value) => {
        let fields = {...values};
        fields[field] = value;
        setValues(fields);
    }

    const handleCreation = () => {
        let result = valuesExist(values, "player", "amount", "priority");
        if (!result.result) {
            return setCreateError("Missing fields: ", join(result.missing, ", "));
        }

        setCreateLoading(true);
        createObjectivePlayer(transferWindow.id, objective.id, values)
            .then((response) => {
                if (response.data && response.data.type) {
                    setCreateError(getFriendlyErrorMessage(response.data));
                    return setCreateLoading(false);
                }

                if (isFunction(onCreate)) {
                    onCreate.call(this, response.data[0])
                }

                setCreateLoading(false);
                setValues({player: "", priority: "", amount: ""});
                setSheetOpen(false);
            })  
            .catch((error) => {
                if (error.response && error.response.data) {
                    setCreateError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setCreateError(messages.ERR_UNKNOWN);
                }
                setCreateLoading(false);
            })
    }

    return (
        <div className="objective-players">
            {renderPlayers()}

            <div className="add-player">
                <SmallCreateButtonWithLabel label="Add player" onClick={() => setSheetOpen(true)}/>
            </div>

            <SelectSheet title={"Add player to objective"} isOpen={sheetOpen} onDismiss={() => setSheetOpen(false)}>
                <NewTransferObjectivePlayerForm values={values}
                    error={createError}
                    loading={createLoading}
                    onSubmit={handleCreation}
                    onChange={(field, value) => createChange(field, value)}
                />
            </SelectSheet>
        </div>
    )
}



export const TransferObjectivePlayer = ({ player, onClickMore }) => {

    const className = (player.active === 1) ? "player" : "player inactive";

    return (
        <div className={className}>
            <PlayerPriority active={player.active} priority={player.priority} />

            <p className="name">
                {player.player}
            </p>

            <MonetaryAmount value={player.amount} />

            <DotsHorizontalIcon onClick={onClickMore}/>
        </div>
    )
}