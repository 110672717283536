import {getRelativeOdds} from "../../data/chance";
import {ReactChart} from 'chartjs-react';
import {each, first, isArray, isEmpty, isNumber, last, map, max, min, round, toNumber} from "lodash";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import Crest from "../team/Crest";
import {useEffect, useRef, useState} from "react";
import {generateTransferListWithLoans, sortTransfersByDate} from "../transfer/TransferUtils";
import {parseISO} from "date-fns";
import "./styles/graphs.css";

const calculatePpm = (wins, draws, losses) => {
    wins = Number.parseInt(wins);
    draws = Number.parseInt(draws);
    losses = Number.parseInt(losses);

    let maxAchievablePoints = (wins + draws + losses) * 3;

    let actualAchievedPoints = (wins * 3) + draws;

    return round((actualAchievedPoints * 100 / maxAchievablePoints) * 3 / 100, 2);
}

export const ScrollableLineChart = ({
    data,
    labels,
    legend,
    itemColor = "#1887f5",
    lineColor = "#3784D1",
    color = "#babcbf"
}) => {

    const getData = () => {
        let dataSet = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    fill: false,
                    backgroundColor: itemColor,
                    borderColor: lineColor,
                    fontColor: color,
                    lineTension: 0,
                    stepSize: 0.1,
                    label: legend
                }
            ]
        }

        return dataSet;
    }

    const getOptions = () => {
        return {
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        stepSize: 1,
                        fontColor: color
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontColor: color
                    }
                }]
            }
        };
    }

    return (
        <div className="chartjs-chart">
            <ReactChart type={"line"}
                        data={getData()}
                        options={getOptions()} />
        </div>

    )
}

export const TransferHistoryGraph = ({
    transfers,
    lastMatch
}) => {

    const [highestTransferFee, setHighestTransferFee] = useState(0);

    useEffect(() => {
        let highestFee = 0;

        each(transfers, (transfer) => {
            if (transfer.cost > highestFee) {
                highestFee = transfer.cost;
            }
        });

        setHighestTransferFee(highestFee);
    }, [transfers]);

    const render = () => {
        const transfersForBars = generateTransferListWithLoans(sortTransfersByDate(transfers));
        const firstTransfer = first(transfersForBars);
        const lastTransfer = last(transfersForBars);

        let lastDate;
        if (lastTransfer.transfer_type === "career_end") {
            lastDate = lastTransfer.transfer_date;
        } else {
            lastDate = (lastMatch) ? lastMatch.play_date : lastTransfer.transfer_date;
        }

        const totalCareerDays = differenceInCalendarDays(parseISO(lastDate), parseISO(firstTransfer.transfer_date));

        const transferBars = [];

        for(let i = 0; i < transfersForBars.length; i++) {
            const transfer = transfersForBars[i];
            let currentTransferEndDate;
            let nextMarkerHeight;
            let hasNextTransfer = true;
            if (i + 1 < transfersForBars.length) {
                currentTransferEndDate = parseISO(transfersForBars[i + 1].transfer_date);
                nextMarkerHeight = (highestTransferFee === 0) ? 0 : (transfersForBars[i+1].cost / highestTransferFee) * 100;
            } else {
                currentTransferEndDate = parseISO(lastDate);
                nextMarkerHeight = 0;
                hasNextTransfer = false;
            }

            const transferDuration = differenceInCalendarDays(currentTransferEndDate, parseISO(transfer.transfer_date));
            const transferBarWidth = (transferDuration / totalCareerDays) * 100;
            const markerHeight = (highestTransferFee === 0) ? 0 : (transfer.cost / highestTransferFee) * 100;

            let classNames = "";
            if (i+1 === transfersForBars.length) {
                classNames += "last";

                if (transfer.transfer_type === "career_end") {
                    classNames += " eoc";
                }
            }

            transferBars.push(
                <>
                    <TransferHistoryBar key={i}
                                        team={transfer.to_team}
                                        width={transferBarWidth + "%"}
                                        showLine={hasNextTransfer}
                                        markerHeight={markerHeight}
                                        nextMarkerHeight={nextMarkerHeight}
                                        classNames={classNames}
                    />
                </>
            );
        }

        return transferBars;
    }

    return (
        <div className={"transfer-history"}>
            { transfers && !isEmpty(transfers) &&
                render()
            }
        </div>
    )
}

export const TransferHistoryBar = ({
    team,
    width,
    showLine,
    markerHeight,
    nextMarkerHeight,
    classNames
}) => {
    const [actualWidth, setActualWidth] = useState(0);

    const wrapperRef = useRef();

    useEffect(() => {
        setActualWidth(wrapperRef.current.offsetWidth);
    }, [width]);

    return (
        <div className={`transfer-history-bar${classNames ? " " + classNames : ""}`} ref={wrapperRef} style={{
            width: width
        }}>
            <div className={"transfer-history-bar-header"}>
                { actualWidth >= 18  &&
                    <Crest club={team}/>
                }
            </div>
            <div className={"transfer-history-bar-body"}>
                <div className={"transfer-history-bar-marker"} style={{
                    bottom: markerHeight + "%"
                }}/>
                { showLine &&
                    <GraphLine stopOffsetX={0 + "%"} startOffsetX={100 + "%"} stopOffsetY={(100 - markerHeight) + "%"} startOffsetY={(100 - nextMarkerHeight) + "%"} />
                }
            </div>
        </div>
    )
}

export const GraphLine = ({ startOffsetX, startOffsetY, stopOffsetX, stopOffsetY }) => {

    return (
        <svg height={"120px"} width={"100%"} overflow={"visible"}>
            <line shapeRendering={"geometricPrecision"} x1={startOffsetX} x2={stopOffsetX} y1={startOffsetY} y2={stopOffsetY} stroke={"#51b53a"} strokeWidth={"3px"}/>
        </svg>
    )
}

export const ManagerPerformanceChart = ({
    tenures,
    firstTenureStartDate,
    lastTenureEndDate
}) => {

    const [averagePpm, setAveragePpm] = useState(null);

    useEffect(() => {
        let wins = 0, draws = 0, losses = 0;

        each(tenures, (tenure) => {
            wins += tenure.won;
            draws += tenure.draw;
            losses += tenure.lost;
        });

        setAveragePpm(calculatePpm(wins, draws, losses));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRelativeHeight = () => {
        if (isNumber(averagePpm)) {
            const ppmPct = (averagePpm / 3) * 100;
            const ppmPixelsFromBottom = ppmPct * 120 / 100;

            return ppmPixelsFromBottom + "px";
        }
    }

    const render = () => {
        const totalCareerDays = differenceInCalendarDays(lastTenureEndDate, firstTenureStartDate);

        return map(tenures, (tenure, index) => {
            let previousTenureStartDate;
            if (index !== 0) {
                previousTenureStartDate = tenures[index - 1].start_date;
            } else {
                previousTenureStartDate = tenure.end_date;
            }

            const delay = Math.abs(index - (tenures.length - 1)) * 100;

            return (
                <ManagerPerformanceBar tenure={tenure}
                    key={index}
                    animationDelay={delay + "ms"}
                    previousTenureStartDate={previousTenureStartDate}
                    lastTenureEndDate={lastTenureEndDate}
                    totalTenureDays={totalCareerDays} />
            )
        })
    }

    return (
        <div className="manager-performance">
            <div className="legend">
                <p> </p>
                <p>2</p>
                <p>1</p>
            </div>
            <div className="wrapper">
                <div className={"average"} style={{
                    bottom: getRelativeHeight()
                }}/>

                {render()}
            </div>
        </div>
    )
}

export const ManagerPerformanceBar = ({ animationDelay = "0ms", tenure, previousTenureStartDate, lastTenureEndDate, totalTenureDays }) => {

    const [actualWidth, setActualWidth] = useState(0);

    const wrapperRef = useRef();

    useEffect(() => {
        setActualWidth(wrapperRef.current.offsetWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperRef.current]);

    const getHeight = () => {
        if (tenure.played === 0) {
            return "1";
        }

        let ppm = calculatePpm(tenure.won, tenure.draw, tenure.lost);

        return ppm * 100 / 3;
    }

    const getWidth = () => {
        let startDate = new Date(tenure.start_date);
        let endDate;

        if (tenure.end_date === null) {
            endDate = lastTenureEndDate;
        } else {
            endDate = new Date(tenure.end_date);
        }

        let thisTenureDays = differenceInCalendarDays(endDate, startDate);

        let width = thisTenureDays * 100 / totalTenureDays;

        return width;
    }

    const getMarginLeft = () => {
        let endDate = new Date(tenure.end_date);
        let previousDate = new Date(previousTenureStartDate);

        let daysBetween = differenceInCalendarDays(previousDate, endDate);
        return daysBetween * 100 / totalTenureDays;
    }

    return (
        <div ref={wrapperRef} className="manager-bar-wrapper"
            style={{
                width: getWidth() + "%",
                marginRight: "calc(1px + " + getMarginLeft() + "%)"
            }}>
            <div className="club-icon">
                {actualWidth > 22 &&
                    <Crest club={tenure.team} />
                }

            </div>
            <div className="bar-container">
                <div className="manager-bar" style={{
                    height: getHeight() + "%",
                    animationDelay: animationDelay
                }}>

                </div>
            </div>

        </div>
    )
}

export const HorizontalBarChart = ({
    progressLeft,
    progressRight,
    labelLeft = "",
    labelCenter = "",
    labelRight = "",
    colors = {
        left: "var(--chart-bars-left)",
        right: "var(--chart-bars-right)"
    },
    showPercentages = false,
    info
}) => {

    return (
        <div className="chart bars horizontal">
            <div className="bars-wrapper">
                { showPercentages &&
                    <>
                        <p>{progressLeft}%</p>
                        <p>{progressRight}%</p>
                    </>
                }
                <div className={"bar-left"} style={{ width: progressLeft + "%" , backgroundColor: colors.left}}>
                </div>
                <div className={"bar-right"} style={{ width: progressRight + "%", backgroundColor: colors.right }}>
                </div>
            </div>
            <div className="labels">
                <p>{labelLeft}</p>
                <p>{labelCenter}</p>
                <p>{labelRight}</p>
            </div>
            { info &&
                <p className={"info"}>
                    { info }
                </p>
            }
        </div>
    )
}

export const getResult = (sideWon, opponentSide, homeOdds, awayOdds) => {
    if (sideWon === "home") {
        if (opponentSide === "home") {
            return "lost";
        } else {
            return "won";
        }
    } else if (sideWon === "away") {
        if (opponentSide === "home") {
            return "won";
        } else {
            return "lost";
        }
    } else {
        // draw positive: draw but the current team odds to win were lower
        // draw negative: draw but the current team odds to win were higher
        if (opponentSide === "away") {
            if (homeOdds > awayOdds) {
                return "draw positive";
            } else {
                return "draw negative";
            }
        } else {
            if (awayOdds > homeOdds) {
                return "draw positive";
            } else {
                return "draw negative";
            }
        }
    }
}

export const DifficultyBar = ({ homeOdds, awayOdds, sideWon, maxOddsPercent, opponentSide }) => {

    const getBarHeight = () => {
        const chances = getRelativeOdds(homeOdds, awayOdds);
        if (sideWon === "home") {
            return chances.away / maxOddsPercent * 100;
        } else if (sideWon === "away") {
            return chances.home / maxOddsPercent * 100;
        } else if (sideWon === "draw") {
            if (chances.home > chances.away) {
                return (chances.home / 2) / maxOddsPercent * 100;
            } else {
                return (chances.away / 2) / maxOddsPercent * 100;
            }
        } else {
            return 100;
        }
    }

    return (
        <div className={"difficulty-bar"}>
            <div className={"bar " + getResult(sideWon, opponentSide, homeOdds, awayOdds)}>
                <div style={{ height: getBarHeight() + "%" }} className="bar-inside"></div>
            </div>
        </div>
    )
}

export const SmoothProgressBar = ({
    valueLabel,
    amountLeft,
    labelLeft,
    amountMiddle,
    labelMiddle,
    amountRight,
    labelRight,
    total
}) => {

    const getLeft = () => {
        return round(amountLeft / total * 100, 2)
    }

    const getRight = () => {
        return round(amountRight / total * 100, 2);
    }

    const getMiddle = () => {
        return round(amountMiddle / total * 100, 2);
    }

    return(
        <div className="smooth-progress-bar-wrapper">
            {
                valueLabel &&
                <p className="value-label">{valueLabel}</p>
            }
            <div className="smooth-progress-bar">
                <div className="bar">
                    <div className="left" style={{
                        width: getLeft() + "%"
                    }}>
                        {labelLeft}
                    </div>
                    <div className="middle" style={{
                        width: getMiddle() + "%"
                    }}>
                        {labelMiddle}
                    </div>
                    <div className="right" style={{
                        width: getRight() + "%"
                    }}>
                        {labelRight}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ScrollableColumnChart = ({
    values,
    labels,
    selectedValuePrefix = "Amount: "
}) => {

    const [valueTapped, setValueTapped] = useState(false);
    const [selectedValue, setSelectedValue] = useState("Tap a bar to see its value");
    const [rulerHeights, setRulerHeights] = useState({
        0: 0,
        25: 0,
        50: 0,
        75: 0,
        100: 0
    });
    const [relativeValues, setRelativeValues] = useState({
        0: null,
        25: null,
        50: null,
        75: null,
        100: null
    });

    const barRef = useRef(null);
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (barRef.current !== null) {
            const barHeight = barRef.current.clientHeight;

            setRulerHeights({
                0: 0,
                25: barHeight / 4 - 1,
                50: barHeight / 2 - 1,
                75: barHeight / 4 * 3 - 1,
                100: barHeight - 1
            });
        }
    }, [barRef.current]);

    useEffect(() => {
        setValueTapped(false);
        setSelectedValue("Tap a bar to see its value");

        if (!isEmpty(values)) {
            const highestValue = max(values);
            const lowestValue = min(values);

            setRelativeValues({
                0: 1,
                25: round(highestValue / 4, 0),
                50: round(highestValue / 2, 0),
                75: round(highestValue / 4 * 3, 0),
                100: highestValue
            });
        }
    }, [values]);

    const renderColumns = () => {
        if (!isArray(values) || !isArray(labels) || values.length !== labels.length) {
            return <></>;
        }

        const maxValue = max(values);

        return map(values, (value, index) => {
            const label = labels[index];
            const valueInt = toNumber(value);
            const height = ((valueInt) / maxValue) * 100;

            return (
                <div className={"column-wrapper"} key={index}>
                    <div className={"column-value"} ref={(index === 0) ? barRef : null}>
                        <div className={"column-bar"} style={{
                            height: `${height}%`,
                            animationDelay: `${index * 40}ms`
                        }}
                             onClick={() => {
                                 setSelectedValue(value);
                                 setValueTapped(true)
                             }}
                        ></div>
                    </div>
                    <div className={"column-label"}>
                        <p>{label}</p>
                    </div>
                </div>
            )
        })
    }

    return(
        <div className={"scrollable-column-chart"}>
            <div className={"selected-value"}>
                <p>
                    {!isEmpty(selectedValuePrefix) && valueTapped ? selectedValuePrefix : ""}
                    {selectedValue}
                </p>
            </div>
            <div className={"columns"}>
                <div className={"legend"}>
                    <div className={"legend-values"}>
                        <p className={"v-100-pct"}>{relativeValues["100"]}</p>
                        <p className={"v-75-pct"}>{relativeValues["75"]}</p>
                        <p className={"v-50-pct"}>{relativeValues["50"]}</p>
                        <p className={"v-25-pct"}>{relativeValues["25"]}</p>
                        <p className={"v-0-pct"}>{relativeValues["0"]}</p>
                    </div>
                    <div className={"legend-placeholder"}/>
                </div>
                <div className={"column-bars"}>
                    <div className={"column-bars-scrollable"}>
                        { renderColumns() }
                    </div>

                    <div className={"ruler r-pct-0"} style={{
                        top: rulerHeights["0"] + "px"
                    }}/>
                    <div className={"ruler r-pct-25"} style={{
                        top: rulerHeights["25"] + "px"
                    }}/>
                    <div className={"ruler r-pct-50"} style={{
                        top: rulerHeights["50"] + "px"
                    }}/>
                    <div className={"ruler r-pct-75"} style={{
                        top: rulerHeights["75"] + "px"
                    }}/>
                    <div className={"ruler r-pct-100"} style={{
                        top: rulerHeights["100"] + "px"
                    }}/>
                </div>
            </div>
        </div>
    )

}