import {each, first, isEmpty, isFunction, join, map, round} from "lodash";
import {useEffect, useState} from "react";
import {addLeadingZeroes, getSimpleDate} from "../../data/dates";
import useLongPress from "../../hooks/longPress";
import {Button, ButtonRow, DangerButton} from "../generics/Buttons";
import {ContentCard, ListCardRow, ListCardWithClub} from "../generics/Cards";
import {Input, InputRegex} from "../generics/Forms";
import {HorizontalBarChart, ManagerPerformanceChart} from "../generics/Graphs";
import {Modal} from "../generics/Modals";
import compareAsc from 'date-fns/compareAsc'
import {Link, useHistory} from "react-router-dom";
import {Stat, StatTable} from "../generics/Tables";
import "./styles/manager-tenures.css";
import {differenceInDays} from "date-fns";

export const calculatePpm = (wins, draws, losses) => {
    if ((wins === null || wins === undefined)
        || (draws === null || draws === undefined)
        || (losses === null || losses === undefined)) {
        return "";
    }

    wins = Number.parseInt(wins);
    draws = Number.parseInt(draws);
    losses = Number.parseInt(losses);

    let maxAchievablePoints = (wins + draws + losses) * 3;

    let actualAchievedPoints = (wins * 3) + draws;

    return round((actualAchievedPoints * 100 / maxAchievablePoints) * 3 / 100, 2).toFixed(2);
}

export const TenureGraph = ({tenures, lastMatch}) => {

    const [firstTenureStartDate, setFirstTenureStartDate] = useState();
    const [lastTenureEndDate, setLastTenureEndDate] = useState();

    useEffect(() => {
        if (!isEmpty(tenures) && lastMatch !== null) {
            setFirstTenureStartDate(getFirstTenureStartDate());
            setLastTenureEndDate(getLastTenureEndDate());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenures, lastMatch]);

    const getFirstTenureStartDate = () => {
        let earliestStartDate = new Date(9999, 12, 31);

        each(tenures, (tenure) => {
            let tenureDate = new Date(tenure.start_date);

            if (compareAsc(earliestStartDate, tenureDate) === 1) {
                earliestStartDate = tenureDate;
            }

        })

        return earliestStartDate;
    }

    const getLastTenureEndDate = () => {
        let lastTenure = first(tenures);

        if (lastTenure.end_date !== null) {
            return new Date(lastTenure.end_date);
        }

        //If the tenure is still ongoing, first verify if there is
        //already a match played. If there is, use the date of the last 
        //match played. If there is not, use the start date
        if (lastTenure.played === 0) {
            return new Date(lastTenure.start_date);
        }

        if (lastMatch === null || lastMatch === undefined) {
            return new Date(lastTenure.start_date);
        }

        return new Date(lastMatch.play_date);
    }

    return (
        <div className="tenure-graph">
            <ManagerPerformanceChart tenures={tenures}
                                     firstTenureStartDate={firstTenureStartDate}
                                     lastTenureEndDate={lastTenureEndDate}/>
        </div>
    )
}

export const TenureCards = ({tenures, onUpdate, onDelete}) => {

    const [updateModalShown, setUpdateModalShown] = useState(false);
    const [workingTenure, setWorkingTenure] = useState({});

    const [deleteModalShown, setDeleteModalShown] = useState(false);

    const confirmUpdate = () => {
        if (isFunction(onUpdate)) {
            onUpdate.call(this, workingTenure);
        }
        setUpdateModalShown(false);
    }

    const confirmDelete = () => {
        if (isFunction(onDelete)) {
            onDelete.call(this, workingTenure);
        }
        setUpdateModalShown(false);
        setDeleteModalShown(false);
    }

    const closeUpdateModal = () => {
        setUpdateModalShown(false);
        setWorkingTenure({});
    }

    const openUpdateModal = (tenure) => {
        setUpdateModalShown(true);
        let fixedTenure = {
            ...tenure,
            start_date: getSimpleDate(tenure.start_date),
            end_date: (tenure.end_date === "-") ? "" : getSimpleDate(tenure.end_date)
        };

        setWorkingTenure(fixedTenure);
    }

    const handleWorkingValueChange = (value, field) => {
        let fields = {...workingTenure};
        fields[field] = value;
        setWorkingTenure(fields);
    }

    const renderCards = () => {
        return map(tenures, (value, index) => {
            return <TenureCard tenure={value}
                               key={index}
                               onLongPress={tenure => openUpdateModal(tenure)}/>
        })
    }

    return (
        <div className={"tenure-cards"}>
            <div className="tenures-list">
                {renderCards()}
            </div>
            <Modal isOpen={updateModalShown} onDismiss={closeUpdateModal}>
                <h3>Edit manager tenure</h3>

                <Input type="text"
                       id="u-team"
                       label="Team"
                       max={120}
                       value={workingTenure.team}
                       valueChange={value => handleWorkingValueChange(value, "team")}/>

                <Input type="text"
                       id="u-start"
                       label="Start date"
                       value={workingTenure.start_date}
                       regex={InputRegex.date}
                       info="Use the following format: yyyy-mm-dd"
                       valueChange={value => handleWorkingValueChange(value, "start_date")}/>

                <Input type="text"
                       label="End date"
                       value={workingTenure.end_date}
                       regex={InputRegex.date}
                       info="Use the following format: yyyy-mm-dd. Leave empty if the manager tenure is still ongoing."
                       valueChange={value => handleWorkingValueChange(value, "end_date")}/>

                <ButtonRow>
                    <DangerButton onClick={() => setDeleteModalShown(true)}>
                        Delete
                    </DangerButton>
                    <Button onClick={confirmUpdate}>
                        Update
                    </Button>
                </ButtonRow>
            </Modal>

            <Modal isOpen={deleteModalShown} onDismiss={() => setDeleteModalShown(false)}>
                <h3>Attention: delete this manager tenure?</h3>
                <p>You are about to delete this manager tenure permanently. This action cannot be undone.
                    Press 'delete' below to delete this item.
                </p>

                <ButtonRow>
                    <DangerButton onClick={confirmDelete}>
                        Delete
                    </DangerButton>
                    <Button onClick={() => setDeleteModalShown(false)}>
                        Cancel
                    </Button>
                </ButtonRow>
            </Modal>
        </div>
    )
}

export const TenureCard = ({tenure, onLongPress}) => {

    const didLongPress = () => {
        if (isFunction(onLongPress)) {
            onLongPress.call(this, tenure);
        }
    }

    const longPressEvent = useLongPress(didLongPress);

    const getPpm = () => {
        if (tenure.played === 0) {
            return "";
        }

        let ppm = calculatePpm(tenure.won, tenure.draw, tenure.lost);
        return ppm;
    }

    const getDurationText = () => {
        let start = "";

        let startDate = new Date(tenure.start_date);

        start = join([
            addLeadingZeroes(startDate.getMonth() + 1),
            "/",
            startDate.getFullYear()
        ], "");

        if (tenure.end_date === null) {
            return start;
        }

        let end = "";

        let endDate = new Date(tenure.end_date);

        end = join([
            addLeadingZeroes(endDate.getMonth() + 1),
            "/",
            endDate.getFullYear()
        ], "");

        return join([start, " - ", end], "");
    }

    return (
        <Link to={"/team/" + tenure.team}>
            <ListCardWithClub {...longPressEvent} club={tenure.team}>
                <ListCardRow>
                    <h5>{tenure.team}</h5>
                    <p className="ppm">
                        {getPpm()}
                    </p>
                </ListCardRow>
                <ListCardRow>
                    <p className="duration">
                        {getDurationText()}
                    </p>
                    <p className="stats">
                        <span className="total">
                            {tenure.played || 0}
                        </span>
                        <span className="won">
                            {(tenure.played - tenure.draw - tenure.lost) || 0}
                        </span>
                        <span className="draw">
                            {tenure.draw || 0}
                        </span>
                        <span className="lost">
                            {tenure.lost || 0}
                        </span>
                    </p>
                </ListCardRow>
            </ListCardWithClub>
        </Link>
    )
}

export const ManagerStats = ({manager, tenures, currentTeam, lastMatch}) => {

    const [totalWins, setTotalWins] = useState(0);
    const [totalDraws, setTotalDraws] = useState(0);
    const [totalLosses, setTotalLosses] = useState(0);

    const [relativeWins, setRelativeWins] = useState(0);
    const [relativeLosses, setRelativeLosses] = useState(0);

    const [daysInCharge, setDaysInCharge] = useState(-1);

    useEffect(() => {
        let wins = 0;
        let draws = 0;
        let losses = 0;
        let total = 0;

        each(tenures, (tenure) => {
            draws += tenure.draw;
            losses += tenure.lost;
            wins += tenure.played - tenure.lost - tenure.draw;
        });

        total = wins + draws + losses;

        setTotalWins(wins);
        setTotalDraws(draws);
        setTotalLosses(losses);
        setRelativeWins(Math.floor(wins / total * 100));
        setRelativeLosses(Math.floor(losses / total * 100));
        getDaysInChargeOfCurrentTeam();
    }, [tenures]);

    const history = useHistory();

    const goToTeamPage = () => {
        if (currentTeam === "No team") {
            return;
        }

        history.push("/team/" + currentTeam);
    }

    const getTotalMatchesPlayed = () => {
        let count = 0;
        each(tenures, (tenure) => {
            count += tenure.played;
        })
        return count;
    }

    const getTotalPpm = () => {
        return calculatePpm(totalWins, totalDraws, totalLosses);
    }

    const getDaysInChargeOfCurrentTeam = () => {
        const lastDate = new Date(lastMatch.play_date);
        const lastTenure = first(tenures); // tenures are ordered from most recent to oldest, so the 'last' tenure is first in the array

        if (lastTenure.end_date) {
            setDaysInCharge(-1);
            return;
        }
        const lastTenureStartDate = new Date(lastTenure.start_date);

        if (compareAsc(lastDate, lastTenureStartDate) !== 1) {
            setDaysInCharge(0);
            return;
        }

        setDaysInCharge(differenceInDays(lastDate, lastTenureStartDate));
    }

    return (
        <div className={"manager-stats"}>
            <div className={"manager-stats-header"}>
                <ListCardWithClub club={currentTeam}
                                  onClick={goToTeamPage}>
                    <h5>{currentTeam}</h5>
                    {
                        daysInCharge !== -1 &&
                        <p>{daysInCharge} days in charge</p>
                    }
                </ListCardWithClub>

                <StatTable>
                    <Stat value={getTotalMatchesPlayed()} label={"Matches"}/>
                    <Stat value={getTotalPpm()} label={"Points per match"}/>
                </StatTable>
            </div>

            <h4>Performance</h4>

            <ContentCard>
                <h5>Wins / Draws / Losses</h5>
                <HorizontalBarChart progressLeft={relativeWins}
                                    progressRight={relativeLosses}
                                    labelLeft={`${totalWins} (${relativeWins}%)`}
                                    labelCenter={`${totalDraws} (${100 - relativeWins - relativeLosses}%)`}
                                    labelRight={`${totalLosses} (${relativeLosses}%)`}
                                    colors={{right: "var(--form-lost-color)"}}
                />
            </ContentCard>
        </div>
    )
}

export const ManagerTenures = ({manager, currentTeam, tenures, lastMatch, onUpdate, onDelete}) => {

    return (
        <div className="manager-tenures">
            <ManagerStats manager={manager}
                          tenures={tenures}
                          lastMatch={lastMatch}
                          currentTeam={currentTeam}/>
            <div className={"manager-career"}>
                <h4>Career</h4>
                <ContentCard>
                    <TenureGraph tenures={tenures}
                                 lastMatch={lastMatch}/>
                    <TenureCards tenures={tenures}
                                 onUpdate={onUpdate}
                                 onDelete={onDelete}/>
                </ContentCard>
            </div>
        </div>
    )
}
