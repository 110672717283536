import {delay, each, join} from "lodash"
import {useEffect, useState} from "react"
import {api, messages} from "../../config/constants"
import {valuesExist} from "../../data/forms"
import {getFriendlyErrorMessage} from "../../service/errorService"
import {createManager, deleteManager, getAllManagers, updateManager} from "../../service/managerService"
import {FormContainer, Input} from "../generics/Forms"
import Header from "../generics/Header"
import {CircularLoaderInfinite} from "../generics/Loaders"
import {Modal} from "../generics/Modals"
import {BottomNavWithSheet} from "../generics/Navigation"
import withAuthorization from "../generics/WithAuthorizationHOC"
import {ManagerList} from "./ManagerList"
import {ContentCard} from "../generics/Cards";

export const WrappedManagerOverviewScreen = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalText, setModalText] = useState("");

    const [managers, setManagers] = useState([]);
    const [page, setPage] = useState(1);
    const [stopLoadingManagers, setStopLoadingManagers] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [loadError, setLoadError] = useState("");

    const [createData, setCreateData] = useState({});
    const [tabSheetAction, setTabSheetAction] = useState("none");
    const [createError, setCreateError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadMoreManagers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tabSheetAction === "close") {
            setTabSheetAction("none");
        }
    }, [tabSheetAction]);

    const loadMoreManagers = () => {
        if (stopLoadingManagers) {
            return;
        }

        getAllManagers(page)
            .then((response) => {
                if (response.data && response.data.type) {
                    setStopLoadingManagers(true);
                    return setLoadError(response.data);
                }

                setManagers([...managers, ...response.data]);

                if (response.data.length === api.RESULTS_PER_PAGE) {
                    setPage(page + 1);
                } else {
                    setStopLoadingManagers(true);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadError(messages.ERR_UNKNOWN);
                }
                setStopLoadingManagers(true);
            })
    }

    const handleFormValue = (value, field) => {
        let fields = {...createData};
        fields[field] = value;
        setCreateData(fields);
    }

    const submitManager = () => {
        setIsLoading(true);
        let missingFields = valuesExist(createData, "name", "country");

        if (!missingFields.result) {
            setCreateError("Missing values: " + join(missingFields.missing, ", "));
            setIsLoading(false);
            return;
        }

        createManager(createData)
            .then((response) => {
                if (response.data && response.data.type) {
                    setCreateError(response.data);
                    setIsLoading(false);
                    return;
                }

                setManagers([...response.data, ...managers]);
                setTabSheetAction("close");
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setCreateError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setCreateError(messages.ERR_UNKNOWN);
                }
                setIsLoading(false);
            })
    }

    const doManagerUpdate = (manager) => {
        setModalOpen(true);
        setModalTitle("Updating manager...");
        setModalText("Please wait while the manager is being updated.");

        updateManager(manager.id, manager)
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    return hideModal();
                }

                if (response.data.changedRows === 0) {
                    setModalText("There were no changes.");
                } else {
                    each(managers, (value, index) => {
                        if (value.id === manager.id) {
                            let updatedManagers = managers;
                            updatedManagers[index] = manager;
                            setManagers(updatedManagers);
                            return;
                        }
                    })
                    setModalText("The manager has been updated successfully.");
                }

                hideModal();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setModalText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal();
            })
    }

    const doManagerDelete = (manager) => {
        setModalOpen(true);
        setModalTitle(`Deleting manager "${manager.name}"...`);
        setModalText("Please wait while the manager is being deleted.");

        deleteManager(manager.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    return hideModal();
                }

                setModalText(`Manager "${manager.name}" has been deleted successfully.`);

                each(managers, (value, index) => {
                    if (value.id === manager.id) {
                        let managersArray = managers;
                        managersArray.splice(index, 1);
                        setManagers(managersArray);
                        return;
                    }
                })

                hideModal();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setModalText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal();
            })
    }

    const hideModal = () => {
        delay(() => {
            setModalOpen(false);
        }, 1000);
    }

    return (
        <div className="manager-screen">
            <Header title={"Managers"}/>

            <h4>All</h4>
            <ContentCard>
                <ManagerList data={managers}
                             onContinueLoading={loadMoreManagers}
                             onUpdate={manager => doManagerUpdate(manager)}
                             onDelete={manager => doManagerDelete(manager)}/>
            </ContentCard>

            <BottomNavWithSheet tabSheetTitle={"New manager"} tabSheetAction={tabSheetAction}>
                <FormContainer onSubmit={submitManager} error={createError} isLoading={isLoading}>
                    <Input type="text"
                           id="manager-name"
                           label="Manager name"
                           max={120}
                           valueChange={(value) => handleFormValue(value, "name")}/>

                    <Input type="text"
                           id="manager-country"
                           label="Country of origin"
                           max={120}
                           valueChange={value => handleFormValue(value, "country")}/>
                </FormContainer>
            </BottomNavWithSheet>

            <Modal isOpen={modalOpen} onDismiss={() => setModalOpen(false)}>
                <h3>{modalTitle}</h3>

                <p>
                    {modalText}
                </p>

                <CircularLoaderInfinite/>
            </Modal>
        </div>
    )
}

export const ManagerOverviewScreen = withAuthorization(WrappedManagerOverviewScreen);