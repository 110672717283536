import PullToRefresh from "react-simple-pull-to-refresh";
import {CircularLoaderInfinite} from "./Loaders";
import "./styles/refresh-wrapper.css";

export const PullToRefreshWrapper = ({children, onRefresh}) => {

    return (
        <PullToRefresh onRefresh={onRefresh}
                       className={"refreshing-wrapper"}
                       pullingContent={"Pull to refresh"}
                       refreshingContent={<CircularLoaderInfinite/>}
                       resistance={1}
                       pullDownThreshold={95}
                       maxPullDownDistance={110}
        >
            {children}
        </PullToRefresh>
    )
}