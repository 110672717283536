import { join } from "lodash";
import { round } from "lodash";

export const asCurrency = (number) => {
    let postFix = "";
    let divideBy = 1;
    let isNegative = (number < 0);

    number = Math.abs(number);

    if (number >= 1000) {
        postFix = "k";
        divideBy = 1000;
    } 
    
    if (number >= 1_000_000) {
        postFix = "M";
        divideBy = 1_000_000;
    } 
    
    if (number >= 1_000_000_000) {
        postFix = "B";
        divideBy = 1_000_000_000;
    }

    let result = join([round(number / divideBy, 2), postFix], "");

    if (isNegative) {
        result = "-" + result;
    }

    return result;
}

export const asDecimal = (number, precision = 2) => {
    let num = Number.parseFloat(number);
    return num.toPrecision(precision);
}