import {isFunction, isString, map} from "lodash"
import {InlineFilter} from "../generics/Filtering"
import {PlayerStatsTable} from "../generics/Tables"
import "./styles/top-players.css"

export const TopPlayers = ({
    topScorers,
    topAssists,
    topAppearances,
    topMOTM,
    topDuos,
    filterSeason,
    competitions,
    filterCompetition,
    seasons,
    onSelectedSeasonChange,
    onSelectedCompetitionChange,
    onLoadMore
}) => {

    const seasonFilterChanged = (newSeason) => {
        if (isFunction(onSelectedSeasonChange)) {
            onSelectedSeasonChange(newSeason);
        }
    }

    const competitionFilterChanged = (newCompetition) => {
        if (isFunction(onSelectedCompetitionChange)) {
            onSelectedCompetitionChange(newCompetition);
        }
    }

    const handleLoadMore = (source) => {
        if (isFunction(onLoadMore)) {
            onLoadMore.call(this, source);
        }
    }

    const getCompetitionsAsOptions = () => {
        return map(competitions ?? [], (competition) => {
            if (isString(competition)) {
                return {
                    label: competition,
                    value: "All"
                };
            }

            return {
                label: `${competition.country} - ${competition.name}`,
                value: JSON.stringify(competition)
            }
        })
    }

    const getSeasonsAsOptions = () => {
        return map(seasons ?? [], (season) => {
            return {
                label: season,
                value: season
            }
        })
    }

    return (
        <div className="team-best-players">
            <InlineFilter label={"Filter by competition"}
                          value={filterCompetition}
                          options={getCompetitionsAsOptions()}
                          onChange={(newValue) => competitionFilterChanged(newValue)}
            />
            <InlineFilter label={"Filter by season"}
                options={getSeasonsAsOptions()}
                value={filterSeason}
                onChange={(newValue) => seasonFilterChanged(newValue)} />

            <div className={"team-stats-wrapper"}>
                <PlayerStatsTable
                    header="Most goals"
                    legend={[
                        {
                            name: "#"
                        },
                        {
                            name: "Player"
                        },
                        {
                            name: "Goals"
                        }
                    ]}
                    displayPositions={true}
                    data={topScorers}
                    onLoadMore={() => handleLoadMore("topScorers")}
                    keyName="player"
                    valueName="goals_scored" />

                <PlayerStatsTable
                    header="Most assists"
                    legend={[
                        {
                            name: "#"
                        },
                        {
                            name: "Player"
                        },
                        {
                            name: "Assists"
                        }
                    ]}
                    displayPositions={true}
                    data={topAssists}
                    onLoadMore={() => handleLoadMore("topAssists")}
                    keyName="player"
                    valueName="assists"
                />

                <PlayerStatsTable
                    header="Most appearances"
                    legend={[
                        {
                            name: "#"
                        },
                        {
                            name: "Player"
                        },
                        {
                            name: "Appearances"
                        }
                    ]}
                    displayPositions={true}
                    data={topAppearances}
                    onLoadMore={() => handleLoadMore("topAppearances")}
                    keyName="player"
                    valueName="amount"
                />

                <PlayerStatsTable
                    header="Most MOTM"
                    legend={[
                        {
                            name: "#"
                        },
                        {
                            name: "Player"
                        },
                        {
                            name: "MOTM"
                        }
                    ]}
                    displayPositions={true}
                    data={topMOTM}
                    onLoadMore={() => handleLoadMore("topMOTM")}
                    keyName="player"
                    valueName="amount"
                />

                <PlayerStatsTable
                    header={"Top attacking duos"}
                    legend={[
                        {
                            name: "#"
                        },
                        {
                            name: "Goal / Assist"
                        },
                        {
                            name: "Goals"
                        }
                    ]}
                    displayPositions={true}
                    data={topDuos}
                    onLoadMore={() => handleLoadMore("topDuos")}
                    keyName={"goal_scorer"}
                    detailsKeyName={"assist_giver"}
                    valueName={"goals_scored"}
                />
            </div>
        </div>
    )
}