import "./labeled-select.css";
import {classNames} from "../../../utils/component-utils";
import {isFunction, map} from "lodash";

/**
 * @param label
 * @param value
 * @param onChange
 * @param className
 * @param {LabeledSelectOption[]} options
 * @returns {JSX.Element}
 * @constructor
 */
export const LabeledSelect = ({
    label,
    value,
    onChange,
    className,
    options
}) => {

    const handleOptionClick = (value) => {
        if (isFunction(onChange)) {
            onChange(value);
        }
    }

    const renderOptions = () => {
        return map(options, (option, index) => {
            return <LabeledSelectOption key={index}
                                        label={option.label}
                                        icon={option.icon}
                                        isSelected={value === option.value}
                                        onClick={() => handleOptionClick(option.value)} />
        })
    }

    return (
        <div className={classNames("labeled-select", className)}>
            <div className={"labeled-select-label"}>
                <p className={"labeled-select-label"}>{label}</p>
            </div>
            <div className={"labeled-select-options"}>
                {renderOptions()}
            </div>
        </div>
    )
}

export const LabeledSelectOption = ({
    icon,
    label,
    isSelected,
    onClick
}) => {

    return (
        <div className={`labeled-select-option ${isSelected ? "selected" : ""}`} onClick={onClick}>
            {icon}
            {label &&
                <p>
                    {label}
                </p>
            }
        </div>
    )
}

/**
 * @typedef LabeledSelectOption
 * @property {ReactElement} icon
 * @property {string} label
 * @property {any} value
 */