import Axios from 'axios';
import _ from 'lodash';
import { config, storage } from '../config/constants';

export const createLineup = async (matchId, lineupData) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'match_id': matchId,
            'saveGame': saveId,
            ...lineupData
        }
    };

    let response = await Axios({
        method: 'POST',
        url: config.API_URL + "/save/match/lineups",
        headers,
        data
    });

    return response;
}

export const createLineupTemplate = async (values) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    const data = {
        "properties": {
            'saveGame': saveId,
            'side': values.side,
            'is_template': 1,
            'template_name': values.template_name
        }
    }

    return Axios({
        method: 'POST',
        url: config.API_URL + "/save/match/lineups",
        data
    });
}

export const getMatchLineups = async (matchId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: _.join([
            config.API_URL, 
            "/save/match/lineups?match_id=", matchId, 
            "&saveGame=", saveId,
            "&extra_data=positions"
        ], ""),
        headers
    });

    return response;
}

export const getLineupTemplates = async () => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/match/lineups?saveGame=${saveId}&is_template=1&extra_data=positions`
    });
}

export const deleteLineup = async (matchId, lineupId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        url: _.join([config.API_URL, "/save/match/lineups?id=", lineupId, "&match_id=", matchId, "&saveGame=", saveId], ""),
        headers
    });

    return response;
}