import {isFunction, join} from "lodash";
import {useEffect, useState} from "react";
import {Button} from "../generics/Buttons";
import {FormContainer, Input, Select, SelectOption, TeamNameInput} from "../generics/Forms";
import {GridRow} from "../generics/Layout";
import {standingPosition as SPECIAL} from "../../config/constants";
import CheckboxBlankCircleIcon from "mdi-react/CheckboxBlankCircleIcon";
import {STANDING_POSITIONS_COLORS} from "../../config/color-vars";

export const NewStandingForm = ({ onCreate, isLoading, createError, formAction }) => {

    const [standingData, setStandingData] = useState({});
    const [standingPositions, setStandingPositions] = useState([]);

    const [allowEditPositions, setAllowEditPositions] = useState(false);
    const [editStandingPositions, setEditStandingPositions] = useState(false);

    useEffect(() => {
        if (formAction === "clear") {
            setStandingData({});
            setStandingPositions([]);
            setEditStandingPositions(false);
        }
    }, [formAction])

    const triggerOnCreate = () => {
        if (isFunction(onCreate)) {
            onCreate.call(this, standingData, standingPositions);
        }
    }

    const handleChangeStanding = (field, value) => {
        let values = standingData;
        values[field] = value;
        setStandingData(values);

        if (standingData.name && standingData.teams) {
            setAllowEditPositions(true);
        } else {
            setAllowEditPositions(false);
        }
    }

    const handleChangePosition = (index, field, value) => {
        let values = standingPositions;
        let position = values[index] || {};
        position[field] = value;
        values[index] = position;
        setStandingPositions([...values]);
    }

    const renderStandingPositionInputs = () => {
        let elements = [];

        for (let i = 0; i < standingData.teams; i++) {
            elements.push(
                <GridRow className="position-group" templateColumns={["3fr", "2fr"]} key={i}>
                    <TeamNameInput type="text"
                        name={join([i, "name"], "")}
                        label={join(["Team", i + 1, "name"], " ")}
                        value={(standingPositions[i]) ? standingPositions[i].team : ""}
                        max={120}
                        onChange={(value) => handleChangePosition(i, "team", value)}
                    />

                    <Input type="number"
                        id={join([i, "points"], "")}
                        name={join([i, "points"], "")}
                        label={join(["Team", i + 1, "points"], " ")}
                        value={(standingPositions[i]) ? standingPositions[i].points : ""}
                        valueChange={(value) => handleChangePosition(i, "points", value)}
                    />

                    <PositionSpecialSelect 
                        onChange={(number, value) => handleChangePosition(number, "special", value)}
                        teamNumber={i}
                    />
                </GridRow>
            )
        }

        return elements;
    }

    return (
        <div className="standing-form">
            
            { !editStandingPositions &&
                <>
                    <Input label="Name"
                        type="text"
                        value={standingData.name}
                        valueChange={(value) => handleChangeStanding("name", value)}
                        name="standing-name"
                        id="std-nm" />

                    <Input label="Number of teams"
                        type="number"
                        min={1}
                        max={50}
                        value={standingData.teams}
                        valueChange={(value) => handleChangeStanding("teams", value)}
                        name="standing-teams"
                        id="std-tm" />


                    {
                        allowEditPositions &&
                        <Button onClick={() => setEditStandingPositions(true)}>Add positions</Button>
                    }
                </>
            }

            { editStandingPositions &&
                <FormContainer onSubmit={triggerOnCreate} error={createError} isLoading={isLoading}>
                    {
                        renderStandingPositionInputs()
                    }
                </FormContainer>
            }
        </div>
    )
}

export const PositionSpecialSelect = ({
    onChange,
    teamNumber,
    value
}) => {

    const handleSelectChange = (value) => {
        if (isFunction(onChange)) {
            onChange.call(this, teamNumber, value);
        }
    }

    return (
        <Select onChange={(value) => handleSelectChange(value)}
            label={join(["Team", teamNumber + 1, "special"], " ")}
            value={value}>
            <SelectOption icon={<CheckboxBlankCircleIcon color={"white"}/>} value={SPECIAL.NONE} content="White" />
            <SelectOption icon={<CheckboxBlankCircleIcon color={STANDING_POSITIONS_COLORS.CL_QUALIFIED}/>} value={SPECIAL.CL_QUALIFIED} content="Dark green" />
            <SelectOption icon={<CheckboxBlankCircleIcon color={STANDING_POSITIONS_COLORS.CL_QUALIFICATION}/>} value={SPECIAL.CL_QUALIFICATION} content="Green" />
            <SelectOption icon={<CheckboxBlankCircleIcon color={STANDING_POSITIONS_COLORS.EL_QUALIFIED}/>} value={SPECIAL.EL_QUALIFIED} content="Dark blue" />
            <SelectOption icon={<CheckboxBlankCircleIcon color={STANDING_POSITIONS_COLORS.EL_QUALIFICATION}/>} value={SPECIAL.EL_QUALIFICATION} content="Blue" />
            <SelectOption icon={<CheckboxBlankCircleIcon color={STANDING_POSITIONS_COLORS.REL_PLAYOFF}/>} value={SPECIAL.REL_PLAYOFF} content="Orange" />
            <SelectOption icon={<CheckboxBlankCircleIcon color={STANDING_POSITIONS_COLORS.REL}/>} value={SPECIAL.RELEGATED} content="Red" />
        </Select>
    )
}