import Axios from "axios";
import { config, storage } from "../config/constants";
import _ from 'lodash';

export const createMatchFact = async (matchId, matchFactData) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        properties: {
            saveGame: saveId,
            ...matchFactData,
            match_id: matchId
        }
    };

    let response = await Axios({
        method: 'POST',
        url: config.API_URL + '/save/match/match_facts',
        headers,
        data
    });

    return response;
}

export const updateMatchFact = async (factToUpdate) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            ...factToUpdate
        }
    }

    let response = await Axios({
        method: 'PATCH',
        url: config.API_URL + "/save/match/match_facts",
        headers,
        data
    });

    return response;
}

export const deleteMatchFact = async (matchId, factId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    }

    let response = await Axios({
        method: "DELETE",
        url: _.join([config.API_URL, "/save/match/match_facts?saveGame=", saveId, "&id=", factId, "&match_id=", matchId], ""),
        headers
    });

    return response;
}

export const getMatchFactsFromMatch = async (matchId) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: _.join([config.API_URL, '/save/match/match_facts?', "saveGame=", saveId, "&match_id=", matchId], ""),
        headers
    });

    return response;
}