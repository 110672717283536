import {addLeadingZeroes, monthName} from "./dates";

export const reset = (obj) => {
    for (let value in obj) {
        value = "";
    }
}

/**
 * Converts a unix timestamp to a human-readable date
 *
 * @param {Number} timestamp A unix timestamp
 * @param {Object} opts Options
 * @param {Boolean} opts.showTime Whether or not to also display the time
 * @param {Boolean} opts.zeroTimestampShowsUnknown Whether the timestamp 0 should be displayed as "Unknown" instead of January 1st 1980
 * @param {Boolean} opts.timestampInMillis Whether the timestamp is represented in milliseconds or not
 *
 * @return {String} A string representing the given timestamp
 */
export const getReadableDate = (timestamp, opts) => {
    if (timestamp === 0 && opts.zeroTimestampShowsUnknown === true) {
        return "Unknown";
    }

    let timestampAdjusted = timestamp;

    if (!opts.timestampInMillis) {
        timestampAdjusted = timestamp*1000;
    }

    let date = new Date(timestampAdjusted);

    let dateString = `${date.getDate()} ${monthName(date.getMonth(), false, true)} ${date.getFullYear()}`;

    if (opts.showTime === true) {
        dateString += ` at ${addLeadingZeroes(date.getHours())}:${addLeadingZeroes(date.getMinutes())}`
    }

    return dateString;
}