import React, {cloneElement, useEffect, useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import {CloseButton} from './Buttons';
import {Tabs} from "react-tabs";
import {isFunction, uniqueId} from "lodash";
import {createPortal} from "react-dom";
import "./styles/tabs.css";

export const SelectSheet = ({
                                children,
                                title,
                                isOpen,
                                onDismiss,
                                classNames
                            }) => {

    const zIndex = useRef(uniqueId()).current;

    return createPortal(
        <>
            {/* Actual timeout of the animation */}
            <CSSTransition in={isOpen} timeout={200} classNames="tab-sheet-select">
                <div className={ `tab-sheet-select ${classNames}`} style={{
                    zIndex: 1000 + parseInt(zIndex)
                }}>

                    {/* A low timeout is needed here otherwise the transition won't play */}
                    <CSSTransition in={isOpen} timeout={10} classNames={"sheet-backdrop"}>
                        <div className="backdrop" onClick={onDismiss}></div>
                    </CSSTransition>

                    <div className="sheet-wrapper">
                        <CSSTransition in={isOpen} timeout={10} classNames="sheet-content">
                            <div className="sheet">
                                <div className="sheet-header">
                                    <h4>{title}</h4>
                                    <CloseButton onClick={onDismiss}/>
                                </div>
                                <div className="content">
                                    {children}
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </CSSTransition>
        </>
        , document.getElementById("root"));
}

export const SelectSheetTab = ({text, icon, onClick}) => {
    return (
        <div className="select-sheet-tab" onClick={onClick}>
            {icon}
            <p>{text}</p>
        </div>
    )
}

export const SelectSheetTabLarge = ({text, icon, onClick}) => {
    return (
        <div className="select-sheet-tab-large" onClick={onClick}>
            {icon}
            <p>{text}</p>
        </div>
    )
}

export const TabWrapper = ({
                               children,
                               selectedIndex,
                               onSelect
                           }) => {

    const handleSelect = (newIndex, oldIndex) => {
        if (isFunction(onSelect)) {
            onSelect.call(this, newIndex, oldIndex);
        }
    }

    return (
        <>
            <div className={"fixed-tab-spacing"}></div>
            <Tabs selectedIndex={selectedIndex} onSelect={(newIndex, oldIndex) => handleSelect(newIndex, oldIndex)}>
                {children}
            </Tabs>
        </>
    )
}