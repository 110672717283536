import { remove } from "lodash";
import { join } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router"
import { messages, objectiveTypes } from "../../config/constants";
import { valuesExist } from "../../data/forms";
import { getFriendlyErrorMessage } from "../../service/errorService";
import { createTransferObjective, getTransferObjectivesFromTransferWindow } from "../../service/transferObjectiveService";
import { getTransferWindowById } from "../../service/transferWindowService";
import Header from "../generics/Header"
import { BottomNavWithSheet } from "../generics/Navigation";
import { NewTransferObjectiveForm } from "./NewTransferObjectiveForm";
import { TransferObjectives } from "./TransferObjective";
import { TransferWindowHeader } from "./TransferWindowHeader";
import {translations} from "../../constants/translations.en.js";
import { FeaturePreview } from "../feature/FeaturePreview";
import withAuthorization from "../generics/WithAuthorizationHOC";
import {ContentWrapper} from "../generics/Layout";

export const WrappedTransferWindowScreen = (props) => {

    const { id } = useParams();

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");
    const [sheetAction, setSheetAction] = useState("none");
    const [createFormAction, setCreateFormAction] = useState("none");

    const [transferWindow, setTransferWindow] = useState({});
    const [transferObjectives, setTransferObjectives] = useState([]);

    const [createLoading, setCreateLoading] = useState(false);
    const [createError, setCreateError] = useState("");

    useEffect(() => {
        fetchTransferWindow();
        fetchTransferObjectives();

    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sheetAction === "close") {
            setSheetAction("none");
        }
    }, [sheetAction]);

    useEffect(() => {
        if (createFormAction === "clear") {
            setCreateFormAction("none");
        }
    }, [createFormAction])

    const fetchTransferWindow = () => {
        getTransferWindowById(id)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setError(getFriendlyErrorMessage(response.data));
                }

                if (response.data.length > 0) {
                    setTransferWindow(response.data[0]);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const fetchTransferObjectives = () => {
        getTransferObjectivesFromTransferWindow(id)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setError(getFriendlyErrorMessage(response.data));
                }

                setTransferObjectives(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const handleObjectiveCreationFormSubmission = (data) => {
        let missingType = valuesExist(data, "objective_type", "amount");
        if (!missingType.result) {
            return setCreateError("Missing fields: ", join(missingType.missing, ", "))
        }

        if (data.objective_type === objectiveTypes.BUY) {
            if (!valuesExist(data, "position").result) {
                return setCreateError("Missing field: position");
            }
        }

        if (data.objective_type === objectiveTypes.SELL) {
            if (!valuesExist(data, "player").result) {
                return setCreateError("Missing field: player");
            }
        }

        setCreateLoading(true);

        createTransferObjective(id, data)
            .then((response) => {
                if (response.data && response.data.type) {
                    setCreateLoading(false);
                    return setCreateError(getFriendlyErrorMessage(response.data));
                }

                setTransferObjectives([...response.data, ...transferObjectives]);
                setCreateLoading(false);
                setSheetAction("close");
                setCreateFormAction("clear");
            })  
            .catch((error) => {
                if (error.response && error.response.data) {
                    setCreateError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setCreateError(messages.ERR_UNKNOWN);
                }

                setCreateLoading(false);
            })
    }

    const handleObjectiveUpdate = (objective) => {
        let index;
        transferObjectives.forEach((obj, i) => {
            if (obj.id === objective.id) {
                index = i;
            }
        })

        let objs = [...transferObjectives];
        objs[index] = objective;

        setTransferObjectives(objs);
    }

    const handleObjectiveDelete = (objective) => {
        let objs = [...transferObjectives];
        remove(objs, (obj) => {
            return obj.id === objective.id;
        })


        setTransferObjectives(objs);
    }

    const handlePlayerCreation = (player) => {
        let objectivesCopy = [...transferObjectives];

        let index;
        objectivesCopy.forEach((obj, i) => {
            if (obj.id === player.transfer_objective_id) {
                index = i;
            }
        });

        let workingObjective = objectivesCopy[index];
        if (!workingObjective.extra_fields || !workingObjective.extra_fields.objective_players) {
            objectivesCopy[index].extra_fields = {
                    objective_players: [player],
                    ...workingObjective.extra_fields
            }
        } else {
            objectivesCopy[index].extra_fields.objective_players.push(player);
        }

        setTransferObjectives(objectivesCopy);
    }

    const handlePlayerUpdate = (player) => {
        let objectivesCopy = [...transferObjectives];

        let index;
        objectivesCopy.forEach((obj, i) => {
            if (obj.id === player.transfer_objective_id) {
                index = i;
            }
        });

        let players = objectivesCopy[index].extra_fields.objective_players;
        let pIndex;
        players.forEach((plr, i) => {
            if (plr.id === player.id) {
                pIndex = i;
            }
        });

        objectivesCopy[index].extra_fields.objective_players[pIndex] = player;

        setTransferObjectives(objectivesCopy);
    }

    const handlePlayerDeletion = (player) => {
        let objectivesCopy = [...transferObjectives];

        let index;
        objectivesCopy.forEach((obj, i) => {
            if (obj.id === player.transfer_objective_id) {
                index = i;
            }
        });

        let players = objectivesCopy[index].extra_fields.objective_players;
        
        remove(players, (value) => {
            return value.id === player.id;
        });

        setTransferObjectives(objectivesCopy);
    }

    const handleTransferCreation = (objectiveId, transfer) => {
        let objectivesCopy = [...transferObjectives];

        let index;
        objectivesCopy.forEach((obj, i) => {
            if (obj.id === objectiveId) {
                index = i;
            }
        });

        let objective = objectivesCopy[index];

        if (!objective.extra_fields || !objective.extra_fields.transfer) {
            objective.extra_fields = {
                transfer: transfer,
                ...objective.extra_fields
            }
        } else {
            objective.extra_fields.transfer = transfer;
        }

        objective.transfer_id = transfer[0].id;
        objectivesCopy[index] = objective;

        setTransferObjectives(objectivesCopy);
    }

    const handleRemoveTransferAssociation = (objectiveId) => {
        let objectivesCopy = [...transferObjectives];

        let index;
        objectivesCopy.forEach((obj, i) => {
            if (obj.id === objectiveId) {
                index = i;
            }
        });

        let objective = objectivesCopy[index];

        if (!objective.extra_fields || !objective.extra_fields.transfer) {
            objective.extra_fields = {
                transfer: null
            }
        } else {
            objective.extra_fields.transfer = null;
        }

        objective.transfer_id = null;
        objectivesCopy[index] = objective;

        setTransferObjectives(objectivesCopy);
    }

    const handleTransferUpdate = (objectiveId, transfer) => {
        let objectivesCopy = [...transferObjectives];

        let index;
        objectivesCopy.forEach((obj, i) => {
            if (obj.id === objectiveId) {
                index = i;
            }
        });

        let objective = objectivesCopy[index];

        objective.extra_fields.transfer = [transfer];

        objectivesCopy[index] = objective;

        setTransferObjectives(objectivesCopy);
    }

    return(
        <div className="transfer-window-screen">
            <Header title={transferWindow.name}/>

            <ContentWrapper>
                <TransferWindowHeader transferWindow={transferWindow}
                                      transferObjectives={transferObjectives}/>

                <FeaturePreview isShown={(transferObjectives.length === 0)}
                                title={translations.features.NO_TRANSFER_OBJECTIVES}
                                description={translations.features.TRANSFER_OBJECTIVE_DESC}>
                    <TransferObjectives transferObjectives={transferObjectives}
                                        transferWindow={transferWindow}
                                        onUpdate={(objective) => handleObjectiveUpdate(objective)}
                                        onDelete={(objective) => handleObjectiveDelete(objective)}
                                        onCreatePlayer={(player) => handlePlayerCreation(player)}
                                        onUpdatePlayer={(player) => handlePlayerUpdate(player)}
                                        onDeletePlayer={(player) => handlePlayerDeletion(player)}
                                        onCreateTransfer={(objectiveId, transfer) => handleTransferCreation(objectiveId, transfer)}
                                        onRemoveAssociation={(objectiveId) => handleRemoveTransferAssociation(objectiveId)}
                                        onUpdateTransfer={(objectiveId, transfer) => handleTransferUpdate(objectiveId, transfer)}
                                        onDeleteTransfer={(objectiveId, transfer) => handleRemoveTransferAssociation(objectiveId, transfer)} />
                </FeaturePreview>
            </ContentWrapper>

            <BottomNavWithSheet tabSheetTitle={"New transfer objective"} tabSheetAction={sheetAction}>
                <NewTransferObjectiveForm action={createFormAction} isLoading={createLoading} error={createError} onSubmit={handleObjectiveCreationFormSubmission}/>
            </BottomNavWithSheet>
        </div>
    )
}

export const TransferWindowScreen = withAuthorization(WrappedTransferWindowScreen);