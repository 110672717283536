import {each, filter, find, findIndex, isArray, isEmpty, map, trimStart} from "lodash";
import Crest from "../team/Crest";
import TrophyIcon from "mdi-react/TrophyIcon";
import {FeaturePreview} from "../feature/FeaturePreview";
import {useState} from "react";
import {Modal} from "../generics/Modals";
import MatchPreview from "../match/MatchPreview";
import {useHistory} from "react-router-dom";
import "./styles/knockout-screen.css";
import {shortTeamName} from "../../data/teams";

export const KnockoutScreen = ({
                                   matches,
                                   isInternational,
                                   knockoutRounds
                               }) => {
    const createAbstractTree = (relevantRounds) => {
        const result = [];

        for (let i = 0; i < relevantRounds.length * 2; i++) {
            const roundIndex = (i >= relevantRounds.length) ? relevantRounds.length * 2 - i - 1 : i;
            const roundNumberInversed = Math.abs(roundIndex - relevantRounds.length);
            const round = relevantRounds[roundIndex];
            const amountOfSquares = Math.pow(2, roundNumberInversed) / 2;

            const row = {
                "round": round.label,
                "squares": []
            };

            for (let x = 0; x < amountOfSquares; x++) {
                row.squares.push({
                    "matches": null // will be populated later
                })
            }

            result.push(row);

            if (i + 1 === relevantRounds.length) {
                result.push({
                    "round": "Final",
                    "squares": [
                        {
                            "matches": null
                        }
                    ]
                })
            }
        }

        return result;
    }

    const populateTreeWithMatches = (tree, groupedMatches) => {
        if (!isEmpty(tree) && !isEmpty(groupedMatches)) {
            const rows = tree.length;
            const finalIndex = (rows - 1) / 2; // final is always the middlemost element
            const final = groupedMatches[0].matches[0];
            let firstRoundWithMatchesIndex = null;

            for (const index in groupedMatches) {
                const groupedMatchRound = groupedMatches[index];

                if (isArray(groupedMatchRound.matches) && groupedMatchRound.matches.length > 0) {
                    firstRoundWithMatchesIndex = Number.parseInt(index);
                    break;
                }
            }

            const maxPossibleMatchesInRound = Math.pow(2, firstRoundWithMatchesIndex);

            const populate = (tree, index, squareIndex, team, direction = "up") => {
                const row = tree[index];
                const round = row.round;

                const roundInMatches = find(groupedMatches, g => g.round === round);
                let match = find(roundInMatches.matches, m => m.home_team === team || m.away_team === team);

                // if no match is found => exit
                if (!match) {
                    return;
                }

                tree[index].squares[squareIndex].matches = match;
                if (index === finalIndex) {
                    tree[index].squares[squareIndex].matches.isFinal = true;
                }

                if (index !== 0 && index + 1 !== rows) {
                    if (direction === "up") {
                        // populate home team
                        populate(tree, index - 1, squareIndex * 2, match.home_team, "up");

                        // populate away team
                        if (index !== finalIndex) {
                            populate(tree, index - 1, squareIndex * 2 + 1, match.away_team, "up");
                        }
                    } else if (direction === "down") {
                        populate(tree, index + 1, squareIndex * 2 + 1, match.away_team, "down");

                        if (index !== finalIndex) {
                            populate(tree, index + 1, squareIndex * 2, match.home_team, "down");
                        }
                    }
                }
            }

            // iterate over each of this round's matches and call populate()
            const matches = groupedMatches[firstRoundWithMatchesIndex].matches;

            if (maxPossibleMatchesInRound === 1) {
                // if the current index is > maxPossibleMatchesInRound / 2, then direction => "down"
                populate(tree, finalIndex, 0, final.home_team);
                populate(tree, finalIndex + 1, 0, final.away_team, "down");
            } else {
                for (const matchIndex in matches) {
                    const match = matches[matchIndex];

                    if (parseInt(matchIndex) + 1 > maxPossibleMatchesInRound / 2) {
                        const yIndex = finalIndex - firstRoundWithMatchesIndex;
                        populate(tree, yIndex, parseInt(matchIndex - maxPossibleMatchesInRound / 2), match.home_team, "up");
                    } else {
                        const yIndex = finalIndex + firstRoundWithMatchesIndex;
                        populate(tree, yIndex, parseInt(matchIndex), match.home_team, "down");
                    }
                }
            }

            return tree;
        }
    }

    /*
        Generates a JSON object representing the rows and squares. The result looks like:
        Example: scratches -> KnockoutRowsExample.json
    */
    const generateKnockoutRoundTree = () => {
        try {
            let relevantRounds = filter(knockoutRounds, (round) => round.is_final === 0 && round.is_group_stage === 0 && round.is_third_place_final === 0)
                .sort((a, b) => a.round - b.round);

            if (relevantRounds.length > 3) {
                let roundsToExclude = relevantRounds.length - 3;
                relevantRounds.splice(0, roundsToExclude);
            }

            const groupedRoundMatches = groupMatchesByRound(matches, knockoutRounds);
            const tree = populateTreeWithMatches(createAbstractTree(relevantRounds), groupedRoundMatches);
            // iterate over tree and create KnockoutRows with KnockoutSquares from top to bottom of tree

            let row = -1;
            return map(tree, t => {
                const squares = [];

                let square = 0;
                each(t.squares, s => {
                    squares.push(<KnockoutSquare key={row + "" + square} matches={(s.matches) ? s.matches : {}}
                                                 isInternational={isInternational}
                                                 isFinal={(s.matches) ? s.matches.isFinal : false}/>);
                    square++;
                });

                row++;
                return (
                    <KnockoutRow key={row}>{squares}</KnockoutRow>
                )
            })
        } catch (err) {
            console.error(err);
            return (
                <FeaturePreview isShown={true}
                                icon={<TrophyIcon/>}
                                title={"The tournament tree will appear once a full round of matches has been added."}
                                description={"Either add a final or add a full round of matches to see the tree appear."}

                >

                </FeaturePreview>
            )
        }

    }

    /*
        Result should look like this for each round and each match in each round:
     */
    const groupMatchesByRound = (matches, rounds) => {
        const result = [];
        const relevantRounds = filter(rounds, (r) => r.is_group_stage === 0 && r.is_third_place_final === 0);

        each(relevantRounds, (round) => {
            const roundData = {
                "round": round.label,
                "matches": []
            };

            const roundMatches = filter(matches, (m) => m.knockout_round_id === round.id);

            each(roundMatches, (roundMatch) => {
                // check if home team vs away team is already present in roundData.matches
                const rdmIndex = findIndex(roundData.matches, (rdm) => ((rdm.home_team === roundMatch.home_team && rdm.away_team === roundMatch.away_team) || (rdm.away_team === roundMatch.home_team && rdm.home_team === roundMatch.away_team)));
                // if no => push to it
                if (rdmIndex === -1) {
                    roundData.matches.push({
                        "home_team": roundMatch.home_team,
                        "away_team": roundMatch.away_team,
                        "combined_home_team_score": roundMatch.home_team_score,
                        "combined_away_team_score": roundMatch.away_team_score,
                        "expanded": [
                            roundMatch
                        ]
                    })
                } else {
                    roundData.matches[rdmIndex].expanded.push(roundMatch);

                    if (roundMatch.home_team === roundData.matches[rdmIndex].home_team) {
                        roundData.matches[rdmIndex].combined_home_team_score += roundMatch.home_team_score;
                        roundData.matches[rdmIndex].combined_away_team_score += roundMatch.away_team_score;
                    } else {
                        roundData.matches[rdmIndex].combined_home_team_score += roundMatch.away_team_score;
                        roundData.matches[rdmIndex].combined_away_team_score += roundMatch.home_team_score;
                    }
                }
            })

            result.push(roundData);
        });

        return result.sort((a, b) => {
            if (a.round === "Final") {
                return -1;
            } else if (b.round === "Final") {
                return 1;
            }

            let aRoundNumber = parseInt(trimStart(a.round, "Round "));
            let bRoundNumber = parseInt(trimStart(b.round, "Round "));

            if (aRoundNumber > bRoundNumber) {
                return -1;
            } else {
                return 1;
            }
        });
    }


    return (
        <div className={"knockout-rounds-tree"}>
            {generateKnockoutRoundTree()}
        </div>
    )

}

const KnockoutRow = ({
                         children
                     }) => {

    return (
        <div className={"knockout-row"}>
            {children}
        </div>
    )
}

const KnockoutSquare = ({
                            matches = {},
                            isFinal = false,
                            isInternational = false
                        }) => {

    const history = useHistory();
    const [expand, setExpand] = useState(false);

    const squareClickHandler = () => {
        if (!isEmpty(matches)) {
            if (matches.expanded.length > 1) {
                setExpand(true);
            } else {
                history.push(`/match/${matches.expanded[0].id}`);
            }
        }
    }

    const renderExpandedMatchPreviews = () => {
        return map(matches.expanded, m => {
            if (!isEmpty(m)) {
                return <MatchPreview data={m}/>
            }
        })
    }

    return (
        <>
            <div className={`knockout-square ${isFinal ? "final" : ""}`} onClick={squareClickHandler}>
                {matches &&
                    <>
                        <div className={"crests"}>
                            <Crest club={matches.home_team} isInternational={isInternational}/>

                            <Crest club={matches.away_team} isInternational={isInternational}/>
                        </div>

                        <div className={"team-names-abbr"}>
                            <p>
                                <span
                                    className={(matches.combined_home_team_score > matches.combined_away_team_score) ? "winner" : ""}>
                                    {shortTeamName(matches?.home_team ?? "")}
                                </span>
                                <span
                                    className={(matches.combined_away_team_score > matches.combined_home_team_score) ? "winner" : ""}>
                                    {shortTeamName(matches?.away_team ?? "")}
                                </span>
                            </p>
                        </div>

                        <div className={"scores"}>
                            <p>
                            <span
                                className={(matches.combined_home_team_score > matches.combined_away_team_score) ? "winner" : ""}>
                                {matches.combined_home_team_score}
                            </span>
                                :
                                <span
                                    className={(matches.combined_away_team_score > matches.combined_home_team_score) ? "winner" : ""}>
                                     {matches.combined_away_team_score}
                            </span>
                            </p>
                        </div>
                    </>
                }
            </div>

            {matches.expanded && matches.expanded.length > 1 &&
                <Modal isOpen={expand} onDismiss={() => setExpand(false)}>
                    {renderExpandedMatchPreviews()}
                </Modal>
            }
        </>
    )
}