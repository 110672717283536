import {HorizontalScrollable} from "../generics/Layout";
import {useEffect, useState} from "react";
import {getSaveGameStats} from "../../service/saveService";
import {isNumber} from "lodash";
import SoccerIcon from "mdi-react/SoccerIcon";
import SoccerFieldIcon from "mdi-react/SoccerFieldIcon";
import SwapHorizontalIcon from "mdi-react/SwapHorizontalIcon";

export const OverviewStats = () => {

    const [stats, setStats] = useState({});

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await getSaveGameStats();

                if (response.data && response.data.type) {
                    setStats(Object.assign({}, {}));
                } else {
                    setStats(response.data);
                }
            } catch (err) {
                setStats(Object.assign({}, {}));
            }
        }

        fetchStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const displayInLocale = (number) => {
        if (isNumber(number)) {
            return number.toLocaleString(navigator.language);
        } else {
            return "";
        }
    }

    return (
        <div className={"overview-stats"}>
            <HorizontalScrollable>
                <StatCard
                    name={"Matches"}
                    value={displayInLocale(stats.matches)}
                    icon={<SoccerFieldIcon/>}
                />
                <StatCard
                    name={"Goals"}
                    value={displayInLocale(stats.goals)}
                    icon={<SoccerIcon/>}
                />
                <StatCard
                    name={"Transfers"}
                    value={displayInLocale(stats.transfers)}
                    icon={<SwapHorizontalIcon/>}
                />
            </HorizontalScrollable>
        </div>
    )
}

export const StatCard = ({
    name,
    value,
    icon
}) => {

    return (
        <div className={"stat-card"}>
            <p className={"stat-value"}>
                { value }
            </p>
            <p className={"stat-name"}>
                { name }
            </p>
            <div className={"stat-icon"}>
                { icon }
            </div>
        </div>
    )
}