export const translations = {
    "features": {
        "NO_INTERNATIONAL_MATCHES": "No international matches",
        "INTERNATIONAL_MATCHES_DESC": "International matches are games between two nations. Create a match from the orange 'plus' button at the bottom and choose 'International' to add a match here.",
        "NO_NATIONAL_MATCHES": "No national matches",
        "NATIONAL_MATCHES_DESC": "National matches are games between two teams from a same football federation. For example: league games, cup games,... Create a match from the orange 'plus' button at the bottom.",
        "NO_CONTINENTAL_MATCHES": "No continental matches",
        "CONTINENTAL_MATCHES_DESC": "Continental matches are games in competitions such as Champions League, Copa Libertadores,... Create a match from the orange 'plus' button at the bottom and choose 'Continental' to add a match here.",
        "NO_MANAGERS": "No managers",
        "MANAGER_DESC": "Create a manager to track progress and statistics across different teams managed. Create a new manager from the orange 'plus' button at the bottom.",
        "NO_MANAGER_TENURES": "No manager tenures",
        "MANAGER_TENURE_DESC": "Manager tenures represent the period this manager has spent at a given team. To start seeing manager stats, create a manager tenure. Create one from the 'plus' button at the bottom.",
        "NO_TRANSFER_WINDOWS": "No transfer windows",
        "TRANSFER_WINDOW_DESC": "A transfer window regroups all your transfer objectives for a given transfer window. Create a transfer window to start setting transfer objectives. Create one from the blue 'plus' button at the bottom.",
        "NO_TRANSFER_OBJECTIVES": "No transfer objectives",
        "TRANSFER_OBJECTIVE_DESC": "A transfer objective is an objective you have set to buy or sell a player. Use this to prepare for and to keep track of the players you wish to bring in or sell during a transfer window. Create a new objective from the orange 'plus' button at the bottom.",
        "NO_SEASONS": "No seasons",
        "SEASONS_DESC": "Seasons help you organize your matches better. You can view all matches from a specific season here. To start, create a match and all future matches with the same season will appear here.",
        "SEARCH": "Search for players or teams",
        "SEARCH_DESC": "Search for players that appear in this save's match events, lineups and transfers, or teams from this save's standings and matches. Start by typing in the start of the player or team's name."
    }
}