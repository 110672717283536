import {ContentCard} from "../generics/Cards";
import Crest from "../team/Crest";
import {isEmpty, map} from "lodash";

export const LeagueWinners = ({top, last}) => {

    const renderMostTimeWinners = () => {
        return map(top, (winner, index) => {
            return <LeagueWinner winner={winner} label={"Most titles"} key={index} />
        })
    }

    return (
        <div className={"league-winners"}>
            <h4>Winners</h4>

            <ContentCard>
                {!isEmpty(last) &&
                    <LeagueWinner winner={last[0]} label={"Title holder"}/>
                }
                {!isEmpty(top) &&
                    <div className={"team"}>
                        {renderMostTimeWinners()}
                    </div>
                }
            </ContentCard>
        </div>
    )
}

const LeagueWinner = ({winner, label}) => {

    return (
        <div className={"league-winner"}>
            <Crest club={winner.team}/>
            <p className={"name"}>
                {winner.team}
            </p>
            <p className={"winner-label"}>
                {label} ({winner.win_count})
            </p>
        </div>
    )
}