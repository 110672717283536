import {each, isArray} from "lodash"
import {HorizontalBarChart, ScrollableColumnChart, TransferHistoryGraph} from "../generics/Graphs"
import {Stat, StatTable} from "../generics/Tables";
import {getCurrencySymbol} from "../generics/Text";
import {getReadableTransferFee, TransferContainer} from "../team/TransferContainer";
import {PlayerRatingsChart} from "./PlayerRatingsChart";
import {useEffect, useState} from "react";
import {getLatestMatchInSave} from "../../service/matchService";
import "./styles/player-about.css";
import {ContentCard} from "../generics/Cards";

export const PlayerAbout = ({
    goals,
    transfers,
    appearances,
    ratings,
    assists,
    matchWins
}) => {

    useEffect(() => {
        loadLatestSaveMatch();
    }, []);

    const [latestMatch, setLatestMatch] = useState({});
    const [relativeWins, setRelativeWins] = useState(0);
    const [relativeDraws, setRelativeDraws] = useState(0);
    const [relativeLosses, setRelativeLosses] = useState(0);

    useEffect(() => {
        const wins = Number.parseInt(matchWins.win);
        const draws = Number.parseInt(matchWins.draw);
        const losses = Number.parseInt(matchWins.lose);
        const played = wins + draws + losses;

        setRelativeWins(Math.floor(wins / played * 100));
        setRelativeDraws(Math.floor(draws / played * 100));
        setRelativeLosses(Math.floor(losses / played * 100));
    }, [matchWins]);

    const loadLatestSaveMatch = () => {
        getLatestMatchInSave()
            .then(response => {
                if (response.data && isArray(response.data) && response.data.length === 1) {
                    setLatestMatch(response.data[0]);
                }
            })
            .catch(err => {
                console.error("Could not load latest save match", err);
            })
    }

    const getDataArray = () => {
        let goalCount = [];
        each(goals, (value) => {
            goalCount.push(value.goals_scored);
        });
        return goalCount.reverse();
    }

    const getLabelArray = () => {
        let labels = [];
        each(goals, (value) => {
            labels.push(value.season);
        });
        return labels.reverse();
    }

    const getTotalGoals = () => {
        let count = 0;
        each(goals, (value) => {
            count = count + value.goals_scored;
        });
        return count;
    }

    const getTransferFees = () => {
        let total = 0;
        each(transfers, (value) => {
            total = total + value.cost;
        })
        return total;
    }

    const getTotalAssists = () => {
        let total = 0;
        each(assists, (value) => {
            total = total + value.assists;
        })
        return total;
    }

    return (
        <div className={"player-about"}>
            <div className={"player-stats-wrapper"}>
                <StatTable>
                    <Stat label="Appearances" labels={["Appearances", "Starts", "Substitutions"]} values={[appearances.appearances + appearances.substitutions, appearances.appearances, appearances.substitutions]} value={appearances}/>
                    <Stat label="Goals" value={getTotalGoals()}/>
                    <Stat className="player-transfer-value" label="Transfer fees" value={getReadableTransferFee(getTransferFees(), getCurrencySymbol())}/>
                    <Stat label="Assists" value={getTotalAssists()}/>
                </StatTable>

                <div className={"player-stats"}>
                    <h4>
                        Team performance
                    </h4>

                    <ContentCard>
                        <HorizontalBarChart
                            progressLeft={relativeWins}
                            progressRight={relativeLosses}
                            labelLeft={`${matchWins.win} (${relativeWins}%)`}
                            labelCenter={`${matchWins.draw} (${relativeDraws}%)`}
                            labelRight={`${matchWins.lose} (${relativeLosses}%)`}
                            colors={{right: "var(--form-lost-color)"}}
                        />
                    </ContentCard>

                    <h4>
                        Goals
                    </h4>

                    <ContentCard>
                        <ScrollableColumnChart values={getDataArray()} labels={getLabelArray()} selectedValuePrefix={"Goals scored: "} />
                    </ContentCard>

                    <h4>
                        Ratings
                    </h4>

                    <ContentCard>
                        <PlayerRatingsChart ratings={ratings}/>
                    </ContentCard>
                </div>
            </div>

            <div className={"player-transfers"}>
                <h4>
                    Transfers
                </h4>

                <ContentCard>
                    <TransferHistoryGraph transfers={transfers} lastMatch={latestMatch}/>

                    <TransferContainer data={transfers}
                                       isPlayerTransfer={true}
                    />
                </ContentCard>
            </div>

        </div>
    )
}