import {useEffect, useRef, useState} from "react";
import {Button} from "./Buttons";
import React from 'react';
import {LinearLoaderInfinite} from "./Loaders";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import _, {isEmpty, isFunction} from 'lodash';
import {CSSTransition} from "react-transition-group";
import {uniqueId} from "lodash";
import {getMatchingTeamNames} from "../../service/TeamNamesService";
import {getMatchingCountryNames} from "../../service/countryService";
import {join} from "lodash";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import CloseIcon from "mdi-react/CloseIcon";
import "./styles/form.css"
import {SelectSheet} from "./Tabs";

export const InputRegex = {
    date: /^(?:\d){4}[-](?:\d){2}[-](?:\d){2}$/gm
}

const InputSuggestion = ({value, tabIndex, onClick}) => {

    const handleClick = () => {
        if (_.isFunction(onClick)) {
            onClick.call(this, value);
        }
    }

    return (
        <div className="input-suggestion" tabIndex={tabIndex} onClick={handleClick}>
            <p>{value}</p>
        </div>
    )
}

export const InputWithCompletion = ({
                                        fetchSuggestions,
                                        value,
                                        onChange,
                                        name,
                                        regex,
                                        label,
                                        max,
                                        min,
                                        type,
                                        disabled
                                    }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [currentValue, setCurrentValue] = useState("");

    const input = useRef(null);

    useEffect(() => {
        if (currentValue !== value) {
            setCurrentValue(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const inputChangeHandler = (val) => {
        if (!isFocused) {
            setIsFocused(true);
        }

        if (_.isFunction(onChange)) {
            onChange.call(this, val);
        }

        if (_.isFunction(fetchSuggestions)) {
            fetchSuggestions(val)
                .then((newSuggestions) => {
                    setSuggestions(newSuggestions);
                })
        }
    }

    const inputFocusOutHandler = (event) => {
        if (event.relatedTarget == null) {
            setIsFocused(false);
            return;
        }

        if (event.relatedTarget.getAttribute("class") !== "input-suggestion") {
            if (isFocused) {
                setIsFocused(false);
            }
        }
    }

    const inputFocusInHandler = (event) => {
        if (!isFocused) {
            setIsFocused(true);
        }
    }

    const handleSuggestionClick = (val) => {
        if (_.isFunction(onChange)) {
            input.current.focus();
            onChange.call(this, val);
            setIsFocused(false);
        }
    }

    const renderSuggestions = () => {
        return _.map(suggestions, (suggestion, index) => {
            return (
                <InputSuggestion key={index}
                                 tabIndex={index}
                                 onClick={value => handleSuggestionClick(value)}
                                 value={suggestion}/>
            )
        })
    }

    return (
        <div className="input-with-completion">
            <Input
                ref={input}
                min={min}
                max={max}
                type={type}
                label={label}
                name={name}
                regex={regex}
                value={currentValue}
                disabled={disabled}
                onChange={(val) => inputChangeHandler(val)}
                onFocusIn={(event) => inputFocusInHandler(event)}
                onFocusOut={(event) => inputFocusOutHandler(event)}
            />
            <div className={_.join(["suggestions", (isFocused) ? "show" : "hide"], " ")}>
                {renderSuggestions()}
            </div>

        </div>
    )
}

export const TeamNameInput = ({
                                  label,
                                  onChange,
                                  min,
                                  max,
                                  name,
                                  regex,
                                  value,
                                  disabled
                              }) => {

    const doFetchSuggestions = (val) => {
        return new Promise(resolve => {
            getMatchingTeamNames(val)
                .then(teams => {
                    resolve(teams);
                })
        })
    }

    return <InputWithCompletion label={label}
                                onChange={onChange}
                                min={min}
                                max={max}
                                name={name}
                                regex={regex}
                                type="text"
                                value={value}
                                disabled={disabled}
                                fetchSuggestions={(val) => doFetchSuggestions(val)}/>
}

export const CountryInput = ({
                                 label,
                                 onChange,
                                 min,
                                 max,
                                 name,
                                 regex,
                                 value,
                                 disabled
                             }) => {
    const doFetchSuggestions = (val) => {
        return new Promise(resolve => {
            getMatchingCountryNames(val)
                .then(countries => {
                    resolve(countries);
                })
        })
    }

    return <InputWithCompletion label={label}
                                onChange={onChange}
                                min={min}
                                max={max}
                                name={name}
                                regex={regex}
                                type="text"
                                value={value}
                                disabled={disabled}
                                fetchSuggestions={(val) => doFetchSuggestions(val)}/>
}

export const Input = React.forwardRef((props, ref) => {
    const [current, setCurrent] = useState("");
    const elementId = useRef(uniqueId("input-")).current;

    useEffect(() => {
        setCurrent(props.value);
    }, [props.value]);

    const handleChange = (value) => {
        if (_.isFunction(props.valueChange)) {
            props.valueChange.call(this, value);
        } else if (_.isFunction(props.onChange)) {
            props.onChange.call(this, value);
        }

        setCurrent(value);
    }

    const handleFocusIn = (event) => {
        if (_.isFunction(props.onFocusIn)) {
            props.onFocusIn.call(this, event);
        }
    }

    const handleFocusOut = (event) => {
        if (_.isFunction(props.onFocusOut)) {
            props.onFocusOut.call(this, event);
        }
    }

    return (
        <div className={join([
            "input-field",
            (props.disabled) ? "disabled" : ""
        ], " ")}>
            <div className="actual-input">
                <SimpleInput type={props.type}
                             ref={ref}
                             id={elementId}
                             placeholder={" "}
                             name={props.name}
                             regex={props.regex}
                             value={current}
                             min={props.min}
                             max={props.max}
                             disabled={props.disabled}
                             autofocus={props.autofocus}
                             onChange={(value) => handleChange(value)}
                             onFocusIn={(event) => handleFocusIn(event)}
                             onFocusOut={(event) => handleFocusOut(event)}
                />

                <label htmlFor={elementId}>
                    {props.label}
                </label>

                {props.clearButton === true &&
                    <div className="clear-wrapper" onClick={() => handleChange("")}>
                        <CloseIcon/>
                    </div>
                }
            </div>

            {props.info &&
                <div className="info">
                    <p>{props.info}</p>
                </div>
            }
            {props.max && props.type === "text" && current &&
                <div className="max-length-hint">
                    {current.length} / {props.max}
                </div>
            }
        </div>
    )
})

export const SimpleInput = React.forwardRef((props, ref) => {

    const [current, setCurrent] = useState("");
    const [matchesRegex, setMatchesRegex] = useState(false);

    useEffect(() => {
        setCurrent(props.value);
    }, [props.value]);

    const handleChange = (event) => {
        let value = event.target.value;

        if (props.regex) {
            testRegex(value);
        }

        if (props.type === "text") {
            handleTextChange(value);
        } else if (props.type === "number") {
            handleNumberChange(value);
        } else {
            props.onChange.call(this, value);
        }
    }

    const testRegex = (value) => {
        setMatchesRegex(props.regex.test(value));
    }

    const handleTextChange = (value) => {
        if (!props.max) {
            setCurrent(value);
            if (props.onChange) {
                props.onChange.call(this, value);
            }
            return;
        }

        if (value.length <= props.max) {
            setCurrent(value);
            if (props.onChange) {
                props.onChange.call(this, value);
            }
        }
    }

    const handleNumberChange = (value) => {
        if (value === "") {
            setCurrent(value);
            if (props.onChange) {
                props.onChange.call(this, value);
            }
            return;
        }

        let numericValue = Number.parseFloat(value);

        if (!props.max && !props.min) {
            setCurrent(numericValue);
            if (props.onChange) {
                props.onChange.call(this, numericValue);
            }
        }

        let maxValue = (props.max !== undefined) ? props.max : Number.MAX_SAFE_INTEGER;
        let minValue = (props.min !== undefined) ? props.min : Number.MIN_SAFE_INTEGER;

        if (maxValue === minValue) {
            return;
        }

        if (numericValue <= maxValue && numericValue >= minValue) {
            setCurrent(numericValue);
            if (props.onChange) {
                props.onChange.call(this, numericValue);
            }
        }
    }

    const handleFocusIn = (event) => {
        if (_.isFunction(props.onFocusIn)) {
            props.onFocusIn.call(this, event);
        }
    }

    const handleBlur = (event) => {
        if (_.isFunction(props.onFocusOut)) {
            props.onFocusOut.call(this, event);
        }
    }

    return (
        <input type={props.type}
               ref={ref}
               placeholder={props.placeholder}
               id={props.id}
               name={props.name}
               disabled={props.disabled}
               autoFocus={props.autofocus}
               onChange={(event) => handleChange(event)}
               onFocus={(event) => handleFocusIn(event)}
               onBlur={(event) => handleBlur(event)}
               value={(props.value) ? props.value : current}
               className={(props.regex !== undefined) ? (matchesRegex) ? "valid" : "invalid" : null}/>
    )
})

export const HorizontalInput = (props) => {

    return (
        <div className="input-field horizontal">
            <div className="horizontal-label">
                <label htmlFor={props.id}>
                    {props.label}
                </label>
            </div>
            <div className={"input"}>
                {props.children}
            </div>
        </div>
    )
}

export const FormContainer = (props) => {

    const submitForm = () => {
        props.onSubmit.call(this);
    }

    return (
        <div className="form-container">
            {props.isLoading &&
                <div className="submission-loader">
                    <LinearLoaderInfinite/>
                </div>
            }

            {props.children}

            {
                props.hideSubmitButton !== true &&
                <>
                    <div className="submit-spacing"></div>

                    <div className="submit-wrapper">
                        <Button onClick={submitForm}>
                            {props.submitText || "Submit"}
                        </Button>

                        {props.error &&
                            <p>{props.error}</p>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export const FullScreenSelect = ({
    label, onChange, children, value, hasCategories = false, info
}) => {

    const [extraClasses, setExtraClasses] = useState("");
    const [valueContent, setValueContent] = useState(null);
    const [currentIcon, setCurrentIcon] = useState(null);
    const [optionsShown, setOptionsShown] = useState(false);

    const optionSelectedHandler = (selectedValue, selectedValueContent, id, icon) => {
        setValueContent(selectedValueContent);
        setCurrentIcon(icon);
        setOptionsShown(false);

        if (onChange) {
            onChange.call(this, selectedValue, selectedValueContent, id);
        } else {
            console.log("[WARNING] You have no onChange handler on a Select element. Change this if you want to capture the selected value.");
        }
    }

    const renderChildren = () => {
        if (!Array.isArray(children)) {
            children = [children];
        }

        return children.map((child, index) => {
            if (child.type === SelectOption) {
                return React.cloneElement(child, {...child.props, onClick: optionSelectedHandler, key: index});
            } else if (child.type === SelectCategory) {
                return React.cloneElement(child, {...child.props, key: index});
            } else {
                console.log("Unsupported child type for Select component. Only SelectOption and SelectCategory are allowed as Select children.");
                return false;
            }
        })
    }

    return (
        <div className={"full-screen-select"}>
            <div className={`option default disabled ${(currentIcon) ? "with-icon" : ""}`} onClick={() => setOptionsShown(true)}>
                {currentIcon}
                <p>{(valueContent !== null) ? valueContent : label}</p>
                <ChevronDownIcon/>
            </div>

            <SelectSheet classNames={"full-screen-select-sheet"} isOpen={optionsShown} onDismiss={() => setOptionsShown(false)} title={label}>
                { renderChildren() }
            </SelectSheet>
        </div>
    )

}

export const Select = ({
                           label,
                           onChange,
                           children,
                           value,
                           hasCategories = false,
                           info
                       }) => {

    const [extraClasses, setExtraClasses] = useState("");
    const [valueContent, setValueContent] = useState(null);
    const [currentIcon, setCurrentIcon] = useState(null);
    const [optionsShown, setOptionsShown] = useState(false);

    const optionsRef = useRef(null);

    useEffect(() => {
        if (hasCategories === true) {
            setExtraClasses("with-categories");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (value === "") {
            setValueContent(null);
        } else {
            _.each(children, (child) => {
                if (child.type === SelectOption && child.props.value === value) {
                    setValueContent(child.props.content);
                    return;
                }
            })
        }
    }, [value, children]);

    const renderChildren = () => {
        if (!Array.isArray(children)) {
            children = [children];
        }

        return children.map((child, index) => {
            if (child.type === SelectOption) {
                return React.cloneElement(child, {...child.props, onClick: optionSelectedHandler, key: index});
            } else if (child.type === SelectCategory) {
                return React.cloneElement(child, {...child.props, key: index});
            } else {
                console.log("Unsupported child type for Select component. Only SelectOption and SelectCategory are allowed as Select children.");
                return false;
            }
        })
    }

    const triggerAllOptions = () => {
        setOptionsShown(!optionsShown);
    }

    const optionSelectedHandler = (selectedValue, selectedValueContent, id, icon) => {
        setValueContent(selectedValueContent);
        setCurrentIcon(icon);

        triggerAllOptions();
        if (onChange) {
            onChange.call(this, selectedValue, selectedValueContent, id);
        } else {
            console.log("[WARNING] You have no onChange handler on a Select element. Change this if you want to capture the selected value.");
        }
    }

    return (
        <div className={(optionsShown) ? "input-select shown " + extraClasses : "input-select " + extraClasses}>
            <div className={`option default disabled ${(currentIcon) ? "with-icon" : ""}`} onClick={triggerAllOptions}>
                {currentIcon}
                <p>{(valueContent !== null) ? valueContent : label}</p>
                <ChevronDownIcon/>
            </div>
            <div className="options-wrapper">
                <CSSTransition in={optionsShown} timeout={500} className="options">
                    <div className="options" ref={optionsRef}>
                        {renderChildren()}
                    </div>
                </CSSTransition>
            </div>

            {info &&
                <p className="info">{info}</p>
            }
        </div>
    )
}

export const SelectOption = ({value, content, onClick, id, icon}) => {

    const onClickHandler = () => {
        onClick.call(this, value, content, id, icon);
    }

    return (
        <div className={`option ${(icon) ? "with-icon" : ""}`} onClick={onClickHandler}>
            {icon}
            <p>{content}</p>
        </div>
    )
}

export const SelectCategory = ({children}) => {

    return (
        <div className="option category">
            {children}
        </div>
    )
}

export const NumericSelect = ({label, onChange, minValue, maxValue, jumps = 1, order = "asc"}) => {

    const renderSelect = () => {
        let items = [];
        if (order === "desc") {
            for (let i = maxValue; i >= minValue; i = i - jumps) {
                items.push(getItemsForRender(i));
            }
        } else {
            for (let i = minValue; i <= maxValue; i = i + jumps) {
                items.push(getItemsForRender(i));
            }
        }
        return items;
    }

    const getItemsForRender = (currentValue) => {
        return <SelectOption key={currentValue} value={currentValue} content={currentValue}/>
    }

    return (
        <Select label={label} onChange={onChange}>
            {renderSelect()}
        </Select>
    )
}

export const Toggle = ({
                           value,
                           onChange,
                           name,
                           label
                       }) => {

    const id = useRef(uniqueId("toggle-")).current;

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(value);
    }, [value]);

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            setIsChecked(!isChecked);
            onChange.call(this, !isChecked);
        }
    }

    return (
        <div className="input-toggle">
            <label htmlFor={id}>
                {label}
            </label>

            <input type="checkbox"
                   name={name}
                   id={id}
                   checked={(!isEmpty(value)) ? value : isChecked}
                   onChange={(event) => handleChange(event)}
                   value={value}/>
        </div>
    )
}

export const Slider = ({
                           value,
                           onChange,
                           name,
                           label,
                           min,
                           max,
                           step,
                           disabled
                       }) => {

    const id = useRef(uniqueId("input-")).current;

    const [actualValue, setActualValue] = useState(1);

    useEffect(() => {
        if (value !== actualValue) {
            setActualValue(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange.call(this, event.target.value);
        }
    }

    return (
        <div className={"input-field slider"}>
            <div className="slider-label">
                <label htmlFor={id}>
                    {label}
                </label>
                <p>
                    {actualValue}
                </p>
            </div>

            <input type="range"
                   id={id}
                   name={name}
                   min={min}
                   max={max}
                   step={step}
                   disabled={disabled}
                   value={actualValue}
                   onChange={(event) => handleChange(event)}/>
        </div>
    )
}

export const NumberArrowsInput = ({
                                      onChange,
                                      value = 0,
                                      allowDirectInput = false,
                                      label,
                                      id,
                                  }) => {

    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        if (value && value !== currentValue) {
            setCurrentValue(parseInt(value));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const arrowClickHandler = (direction) => {
        let newValue = 0;
        if (direction === "up") {
            newValue = currentValue + 1;
        } else if (direction === "down") {
            newValue = currentValue - 1;
        }

        setCurrentValue(newValue);
        if (isFunction(onChange)) {
            onChange.call(this, newValue);
        }
    }

    const inputChangeHandler = (event) => {
        setCurrentValue(parseInt(event.target.value));

        if (isFunction(onChange)) {
            onChange.call(this, parseInt(event.target.value));
        }
    }

    return (
        <div className={"number-arrow-input"}>
            <div className={"input-wrapper"}>
                <input type={"number"}
                       id={id}
                       step={1}
                       value={currentValue}
                       onChange={(e) => inputChangeHandler(e)}/>

                <div className={"arrows-wrapper"}>
                    <div className={"arrow"} onClick={() => arrowClickHandler("up")}>
                        <ChevronUpIcon/>
                    </div>
                    <div className={"arrow"} onClick={() => arrowClickHandler("down")}>
                        <ChevronDownIcon/>
                    </div>
                </div>
            </div>

            {label &&
                <p>{label}</p>
            }

        </div>
    )
}

export const TeamScoreInput = ({
                                   onChange,
                                   homeTeamScore,
                                   awayTeamScore
                               }) => {

    return (
        <div className={"team-score-input"}>
            <NumberArrowsInput value={homeTeamScore}
                               label={"Home team score"}
                               onChange={newValue => onChange.call(this, "home_team_score", newValue)}/>
            <p>
                -
            </p>
            <NumberArrowsInput value={awayTeamScore}
                               label={"Away team score"}
                               onChange={newValue => onChange.call(this, "away_team_score", newValue)}/>
        </div>
    )
}