import {delay, join, map} from "lodash";
import {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import {messages} from "../../config/constants";
import {TabSheetAction} from "../../constants/TabSheetAction";
import {getCompetitions} from "../../service/competitionService";
import {getFriendlyErrorMessage} from "../../service/errorService";
import Header from "../generics/Header"
import {BottomNavWithSheet} from "../generics/Navigation";
import withAuthorization from "../generics/WithAuthorizationHOC";
import Crest from "../team/Crest";
import {NewLeagueForm} from "./NewLeagueForm";
import {ContentWrapper} from "../generics/Layout";
import "./styles/league-overview-screen.css"
import {ContentCard} from "../generics/Cards";

const WrappedLeagueOverviewScreen = (props) => {

    const [competitions, setCompetitions] = useState([]);
    const [sheetAction, setSheetAction] = useState(TabSheetAction.RESET);

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");

    useEffect(() => {
        loadAllCompetitions();
    }, []);

    const loadAllCompetitions = () => {
        getCompetitions()
            .then((response) => {
                if (response.data && response.data.type) {
                    setError(getFriendlyErrorMessage(response.data));
                } else {
                    setCompetitions(response.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const handleCompetitionCreation = (createdCompetition) => {
        setCompetitions([...competitions, ...createdCompetition]);
        setSheetAction(TabSheetAction.CLOSE);
        delay(() => setSheetAction(TabSheetAction.RESET), 500);
    }

    return (
        <div className="league-overview-screen">
            <Header title="Leagues" />

            <ContentWrapper>
                <Leagues data={competitions} />
            </ContentWrapper>

            <BottomNavWithSheet tabSheetTitle={"New league"} tabSheetAction={sheetAction}>
                <NewLeagueForm onCreate={(createdCompetition) => handleCompetitionCreation(createdCompetition)}/>
            </BottomNavWithSheet>
        </div>
    )
}

const Leagues = ({ data }) => {

    const renderData = () => {
        let countryGroup = "";

        return map(data, (value, index) => {
            let item = [];

            if (value.country !== countryGroup) {
                countryGroup = value.country;
                item.push(<LeagueCountry country={value.country} key={join([index, value.country], "")} />);
            }

            let isLastOfGroup = false;
            if (data.length > index + 1) {
                if (data[index].country !== countryGroup) {
                    isLastOfGroup = true;
                }
            } else {
                isLastOfGroup = true;
            }

            item.push(<League data={value} key={index} lastOfGroup={isLastOfGroup} />);

            return item;
        })
    }

    return (
        <div className="leagues-container">
            <h4>All leagues</h4>

            <ContentCard>
                {renderData()}
            </ContentCard>
        </div>
    )
}

const LeagueCountry = ({ country }) => {
    return (
        <div className="league-country">
            <div className={"country-container"}>
                <div className="country">
                    <Crest club={country} isInternational={true} />
                </div>
            </div>

            <p>{country}</p>
        </div>
    )
}

const League = ({ data, lastOfGroup }) => {
    return (
        <Link to={join(["/league/", data.id], "")}>
            <div className={"league"}>
                <p>{data.name}</p>
            </div>
        </Link>
    )
}

export const LeagueOverviewScreen = withAuthorization(WrappedLeagueOverviewScreen);