import {isFunction} from "lodash";
import {useState} from "react"
import {Fab} from "../generics/Buttons"
import {SelectSheet} from "../generics/Tabs";
import {NewTransferForm} from "../transfer/NewTransferForm";
import {TransferContainer} from "./TransferContainer";
import {ContentCard} from "../generics/Cards";
import {InlineFilter} from "../generics/Filtering";

export const Transfers = ({
    team,
    data,
    onContinueLoading,
    teamColor,
    color,
    onCreateTransfer,
    onUpdateTransfer,
    onDeleteTransfer }) => {

    const [transferModalOpen, setTransferModalOpen] = useState(false);
    const [directionFilter, setDirectionFilter] = useState("All");
    const [filterFunction, setFilterFunction] = useState(() => {return () => true});

    const toggleTransferModal = (action) => {
        if (action == null) {
            return setTransferModalOpen(false);
        }

        if (action === "open") {
            setTransferModalOpen(true);
        } else {
            setTransferModalOpen(false);
        }
    }

    const onCreateFormSubmit = (transfer) => {
        if (isFunction(onCreateTransfer)) {
            toggleTransferModal("close");
            onCreateTransfer.call(this, transfer)
        }
    }

    const directionFilterChange = (direction) => {
        setDirectionFilter(direction);
        if (direction === "Incoming") {
            setFilterFunction(() => filterIncomingTransfers);
        } else if (direction === "Outgoing") {
            setFilterFunction(() => filterOutgoingTransfers);
        } else {
            setFilterFunction(() => filterAllTransfers);
        }
    }

    const filterIncomingTransfers = (transfer) => {
        return transfer?.to_team === team;
    }

    const filterOutgoingTransfers = (transfer) => {
        return transfer.from_team === team;
    }

    const filterAllTransfers = (transfer) => {
        return true;
    }

    return (
        <>
            <InlineFilter label={"Filter by type"}
                          options={[
                              {
                                  label: "All",
                                  value: "All"
                              },
                              {
                                  label: "Incoming",
                                  value: "Incoming"
                              },
                              {
                                  label: "Outgoing",
                                  value: "Outgoing"
                              }
                          ]}
                          onChange={directionFilterChange}
                          value={directionFilter}
            />

            <div className="transfers">

                <h4>Transfers</h4>

                <ContentCard>
                    <TransferContainer data={data}
                                       filterFunction={filterFunction}
                                       onContinueLoading={onContinueLoading}
                                       onUpdate={onUpdateTransfer}
                                       onDelete={onDeleteTransfer} />
                </ContentCard>

                <Fab styles={{ backgroundColor: teamColor, color: color }}
                     openedState={transferModalOpen}
                     onClick={action => toggleTransferModal(action)} />

                <SelectSheet title={"New transfer"} isOpen={transferModalOpen} onDismiss={toggleTransferModal}>
                    <NewTransferForm onSubmit={(transfer) => onCreateFormSubmit(transfer)}/>
                </SelectSheet>
            </div>
        </>
    )
}