import React from 'react';
import Header from '../generics/Header';
import { BottomNavWithSheet } from '../generics/Navigation';
import withAuthorization from '../generics/WithAuthorizationHOC';
import NewMatchForm from '../match/NewMatchForm';
import MatchesPerLeague from './MatchesPerLeague';
import {OverviewStats} from "./OverviewStats";
import {ContentWrapper} from "../generics/Layout";
import "./styles/overview-component.css";

export const WrappedOverview = props => {

    return (
        <div className={"overview-screen"}>
            <Header title="Matches" />

            <ContentWrapper>
                <OverviewStats/>

                <MatchesPerLeague/>
            </ContentWrapper>

            <BottomNavWithSheet tabSheetTitle={"New match"}>
                <NewMatchForm/>
            </BottomNavWithSheet>
        </div>
    )
}

export const Overview = withAuthorization(WrappedOverview);