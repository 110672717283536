export const RATINGS = {
    AWFUL: "#db1212",
    BAD: "#f77d25",
    DECENT: "#c9db21",
    GOOD: "#4bcc2f",
    PERFECT: "#00b582"
};

export const STANDING_POSITIONS_COLORS = {
    WINNER: "#1b880f",
    CL_QUALIFIED: "#1b880f",
    CL_QUALIFICATION: "#40c830",
    EL_QUALIFIED: "#144cbb",
    EL_QUALIFICATION: "#216bfc",
    REL_PLAYOFF: "#ff9e3d",
    REL: "#e02828",
    NEXT_STAGE_QUALIFIED: "#144cbb",
    NONE: "rgb(255,255,255)"
}