import Axios from 'axios';
import {config, storage} from "../config/constants";

/**
 * @param {String} text
 * @param {String} type "player" or "team"
 */
export const search = (text, type) => {
    if (type === "player") {
        return searchPlayers(text);
    } else if (type === "team") {
        return searchTeams(text);
    }
}

export const searchPlayers = (value) => {
    return Axios.post(`${config.API_URL}/save/search/players`, {
        properties: {
            saveGame: localStorage.getItem(storage.SAVE_ID),
            search: value
        }
    })
}

export const searchTeams = (value) => {
    return Axios.post(`${config.API_URL}/save/search/teams`, {
        properties: {
            saveGame: localStorage.getItem(storage.SAVE_ID),
            search: value
        }
    })
}