import { delay } from "lodash";
import React, { useEffect, useState } from "react";
import { authorizeUser } from "../../data/authorization";
import { Button } from "./Buttons";
import { LoadingModal, Modal } from "./Modals";

export default function withAuthorization(WrappedComponent, redirectOffline = true, redirectNoSave = true) {

    const AnonymousComponent = () => {

        const [offlineModalShown, setOfflineModalShown] = useState(false);
        const [loadingModalShown, setLoadingModalShown] = useState(false);
        const [connectionDoneShown, setConnectionDoneShown] = useState(false);
        const [retries, setRetries] = useState(0);
        const [wrappedShown, setWrappedShown] = useState(true);

        useEffect(() => {
            doAuthorization();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const retryConnection = () => {
            setLoadingModalShown(true);
            setOfflineModalShown(false);
            setRetries(retries + 1);

            delay(() => doAuthorization(), 1000);
        }

        const doAuthorization = () => {
            authorizeUser(redirectOffline, redirectNoSave)
                .then((result) => {
                    if (result.offline === true) {
                        setOfflineModalShown(true);
                        setLoadingModalShown(false);
                    }

                    if (retries > 0 && result.online === true) {
                        setLoadingModalShown(false);
                        setConnectionDoneShown(true);
                        reloadWrappedComponent();
                        delay(() => setConnectionDoneShown(false), 700);
                    } 
                })
        }

        const reloadWrappedComponent = () => {
            setWrappedShown(false);
            delay(() => setWrappedShown(true), 500);
        }

        return (
        <>
            <Modal isOpen={offlineModalShown}>
                <h3>You're offline</h3>
                <p>We are having some trouble connecting you to myscores.io...</p>
                <Button onClick={() => retryConnection()}>
                    Retry
                </Button>
            </Modal>
            <LoadingModal isOpen={loadingModalShown} 
                onDismiss={() => setLoadingModalShown(false)} 
                title={"Reconnecting..."}/>

            <Modal isOpen={connectionDoneShown} onDismiss={() => setConnectionDoneShown(false)}>
                <h3>You're connected</h3>
                <p>Connection to myscores.io was successful. Welcome back.</p>
            </Modal>

            {wrappedShown &&
                <WrappedComponent/>
            }
        </>)
    }

    return AnonymousComponent;
}