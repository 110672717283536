import Crest from "../team/Crest";
import _ from 'lodash';
import {monthName} from "../../data/dates";
import {Link} from "react-router-dom";
import {PlayerRating} from "../generics/Text";
import {MatchStory} from "./MatchStory";
import ReactVisibilitySensor from "react-visibility-sensor";

const MatchHeader = ({data, matchFacts, onScoreVisibleChange}) => {

    let correctedDate = new Date(data.play_date);

    return (
        <div className={"match-header-wrapper"}>
            <div className="match-header">
                <div className={"match-header-meta"}>
                    <p className={"competition"}>
                        <Link to={"/league/" + data.competition_id}>
                            {data.country} - {data.name} - {data.season}
                            {data.label &&
                                <>- {data.label}</>
                            }
                        </Link>
                    </p>
                    <p className="teams">{data.home_team} - {data.away_team}</p>
                    <ReactVisibilitySensor offset={{top: 47}} onChange={onScoreVisibleChange}>
                        <div className="score">
                            <Link to={"/team/" + data.home_team}>
                                <Crest club={data.home_team} isInternational={(data.is_international)}/>
                            </Link>
                            <div className="scores">
                                <p>
                            <span className={(data.home_team_score > data.away_team_score) ? "won" : null}>
                                {data.home_team_score}
                            </span>
                                    -
                                    <span className={(data.away_team_score > data.home_team_score) ? "won" : null}>
                                {data.away_team_score}
                            </span>
                                </p>
                            </div>
                            <Link to={"/team/" + data.away_team}>
                                <Crest club={data.away_team} isInternational={(data.is_international)}/>
                            </Link>
                        </div>
                    </ReactVisibilitySensor>

                        <p className="date">
                            {_.join([
                                correctedDate.getDate(),
                                monthName(correctedDate.getMonth(), false, true),
                                correctedDate.getFullYear()
                            ], " ")}
                        </p>
                </div>

                <MatchStory matchFacts={matchFacts}/>

                {
                    ((data.home_team_best_player && data.home_team_best_rating)
                        ||
                        (data.away_team_best_player && data.away_team_best_rating))
                    &&
                    <MatchBestPlayers match={data}/>
                }
            </div>
        </div>
)
}

export const MatchBestPlayers = (
    {
        match
    }
) =>
    {

        return (
            <div className="match-best-players">
                {
                    (match.home_team_best_player !== undefined && match.home_team_best_rating !== undefined)
                    &&
                    <MatchBestPlayer player={match.home_team_best_player}
                                     rating={match.home_team_best_rating}
                                     side="home"/>
                }

                {
                    (match.away_team_best_player !== undefined && match.away_team_best_rating !== undefined)
                    &&
                    <MatchBestPlayer player={match.away_team_best_player}
                                     rating={match.away_team_best_rating}
                                     side="away"/>
                }
            </div>
        )
    }

export const MatchBestPlayer = (
    {
        player, rating, side
    }
) =>
    {
        return (
            <Link to={"/player/" + player}>
                <div className={"player " + side}>
                    <div className="rating-wrapper">
                        <PlayerRating rating={rating}/>
                    </div>

                    <p className="name">
                        {player}
                    </p>
                </div>
            </Link>
        )
    }

export default MatchHeader;