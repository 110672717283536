import {isFunction, join, map, remove} from "lodash";
import {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor";
import {api, messages} from "../../config/constants";
import {asCurrency} from "../../data/number";
import useLongPress from "../../hooks/longPress";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {deleteTransferWindow, getAllTransferWindows, updateTransferWindow} from "../../service/transferWindowService";
import {FeaturePreview} from "../feature/FeaturePreview";
import {Button, ButtonRow, DangerButton} from "../generics/Buttons";
import {CategoryCard, ContentCard} from "../generics/Cards";
import {Input} from "../generics/Forms";
import Header from "../generics/Header"
import {DeletionModal, LoadingModal, Modal} from "../generics/Modals";
import {BottomNavWithSheet} from "../generics/Navigation"
import {TableLabels} from "../generics/Tables";
import {NewTransferWindowForm} from "./NewTransferWindowForm";
import {translations} from "../../constants/translations.en.js";
import withAuthorization from "../generics/WithAuthorizationHOC";
import {CurrencySymbol} from "../generics/Text";
import {ContentWrapper} from "../generics/Layout";
import {SimpleFieldSort} from "../generics/filter/SimpleFieldSort";
import {TabSheetAction} from "../../constants/TabSheetAction";

const TransferWindowSortFields = {
    id: {
        property: "id",
        label: "ID",
        sortType: "Date"
    },
    name: {
        property: "name",
        label: "Name",
        sortType: "Alphabetical"
    },
    year: {
        property: "year",
        label: "Year",
        sortType: "Date"
    },
    baseBudget: {
        property: "base_budget",
        label: "Base budget",
        sortType: "Numerical"
    },
    expectedRevenue: {
        property: "expected_revenue",
        label: "Expected revenue",
        sortType: "Numerical"
    }
}

export const WrappedTransferWindowOverviewScreen = (props) => {

    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");

    const [offset, setOffset] = useState(0);
    const [orderBy, setOrderBy] = useState(TransferWindowSortFields.id);
    const [order, setOrder] = useState("DESC");

    const [transferWindows, setTransferWindows] = useState([]);
    const [stopLoadingTransferWindows, setStopLoadingTransferWindows] = useState(false);

    const [createLoading, setCreateLoading] = useState(false);
    const [createError, setCreateError] = useState("");
    const [formAction, setFormAction] = useState("none");
    const [sheetAction, setSheetAction] = useState("none");

    useEffect(() => {
        fetchTransferWindows();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (formAction === "clear") {
            setFormAction("none");
        }
    }, [formAction]);

    useEffect(() => {
        if (sheetAction === "close") {
            setSheetAction("none");
        }
    }, [sheetAction]);

    useEffect(() => {
        fetchTransferWindows(true);
    }, [orderBy, order]);

    const fetchTransferWindows = (resetExisting) => {
        if (stopLoadingTransferWindows) {
            return;
        }

        getAllTransferWindows(api.RESULTS_PER_PAGE, offset, orderBy.property, order)
            .then((response) => {
                if (response.data && response.data.type) {
                    return setError(getFriendlyErrorMessage(response.data));
                }

                if (response.data.length === api.RESULTS_PER_PAGE) {
                    setOffset(offset + api.RESULTS_PER_PAGE);
                } else {
                    setStopLoadingTransferWindows(true);
                }

                if (resetExisting) {
                    setTransferWindows(response?.data);
                } else {
                    setTransferWindows([...transferWindows, ...response.data]);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setError(messages.ERR_UNKNOWN);
                }
            })
    }

    const handleCreateTransferWindow = (data) => {
        setTransferWindows([...data, ...transferWindows]);
        setSheetAction(TabSheetAction.CLOSE);
    }

    const handleUpdate = (window) => {
        let windows = transferWindows;
        for (let i = 0; i < windows.length; i++) {
            let currentWindow = windows[i];

            if (currentWindow.id === window.id) {
                windows[i] = window;
                break;
            }
        }
        setTransferWindows(windows);
    }

    const handleDelete = (id) => {
        let windows = transferWindows;
        remove(windows, (window) => {
            return window.id === id;
        });
        setTransferWindows(windows);
    }

    return (
        <div className={"transfer-window-overview-screen"}>
            <Header title={"Transfer Windows"}/>

            <ContentWrapper>
                <SimpleFieldSort fields={TransferWindowSortFields}
                                 activeField={orderBy}
                                 activeOrder={order}
                                 onFieldChange={(field) => {
                                     setOrderBy(field);
                                     setStopLoadingTransferWindows(false);
                                     setOffset(0);
                                 }}
                                 onOrderChange={(order) => {
                                     setOrder(order);
                                     setStopLoadingTransferWindows(false);
                                     setOffset(0);
                                 }}/>


                <FeaturePreview isShown={(transferWindows.length === 0)}
                                title={translations.features.NO_TRANSFER_WINDOWS}
                                description={translations.features.TRANSFER_WINDOW_DESC}>
                    <ContentCard>
                        <TransferWindowsPreview transferWindows={transferWindows}
                                                onContinueLoading={() => fetchTransferWindows()}
                                                onUpdate={window => handleUpdate(window)}
                                                onDelete={windowId => handleDelete(windowId)}/>
                    </ContentCard>
                </FeaturePreview>
            </ContentWrapper>

            <BottomNavWithSheet tabSheetTitle={"New transfer window"} tabSheetAction={sheetAction}
                                addBackgroundColor={"var(--primary)"}>
                <NewTransferWindowForm onCreate={handleCreateTransferWindow}
                                       isLoading={createLoading}
                                       createError={createError}
                                       formAction={formAction}/>
            </BottomNavWithSheet>
        </div>
    )
}

export const TransferWindowsPreview = ({transferWindows, onContinueLoading, onUpdate, onDelete}) => {

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [workingWindow, setWorkingWindow] = useState({});

    const [deletionModalOpen, setDeletionModalOpen] = useState(false);

    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const [loadingText, setLoadingText] = useState("");

    const handleLongPress = (transferWindow) => {
        setUpdateModalOpen(true);
        setWorkingWindow(transferWindow);
    }

    const renderTransferWindows = () => {
        return map(transferWindows, (transferWindow, index) => {
            return (
                <Link to={join(["/transfers/windows/", transferWindow.id], "")} key={index}>
                    <TransferWindowPreview onLongPress={handleLongPress}
                                           transferWindow={transferWindow}/>
                </Link>

            )
        })
    }

    const handleChange = (field, value) => {
        let fields = {...workingWindow};
        fields[field] = value;
        setWorkingWindow(fields);
    }

    const handleTransferWindowUpdate = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Updating transfer window");
        setLoadingText("Please wait while the transfer window is being updated.");

        updateTransferWindow(workingWindow.id, workingWindow)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                if (response.data.changedRows > 0) {
                    setLoadingText("The transfer window has been updated successfully.");
                    if (isFunction(onUpdate)) {
                        onUpdate.call(this, workingWindow);
                    }
                } else {
                    setLoadingText("There were no changes.");
                }
                setUpdateModalOpen(false);
                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const handleTransferWindowDelete = () => {
        setLoadingModalOpen(true);
        setLoadingTitle("Deleting transfer window");
        setLoadingText("Please wait while this transfer window is being deleted.");

        deleteTransferWindow(workingWindow.id)
            .then((response) => {
                if (response.data && response.data.type) {
                    setLoadingText(getFriendlyErrorMessage(response.data));
                    return closeLoading();
                }

                setLoadingText("The transfer window has been deleted successfully.");

                if (isFunction(onDelete)) {
                    onDelete.call(this, workingWindow.id);
                }

                closeLoading();
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setLoadingText(getFriendlyErrorMessage(error.response.data));
                } else {
                    setLoadingText(messages.ERR_UNKNOWN);
                }
                closeLoading();
            })
    }

    const closeLoading = () => {
        setTimeout(() => {
            setLoadingModalOpen(false)
        }, 700);
    }

    return (
        <div className="transfer-windows">
            <TableLabels templateColumns={["44%", "14%", "21%", "21%"]}
                         labels={["Name", "Year", "Base budget", "Expected revenue"]}/>
            {renderTransferWindows()}

            <Modal isOpen={updateModalOpen} onDismiss={() => setUpdateModalOpen(false)}>
                <h3>Edit transfer window</h3>

                <Input type="text"
                       id="edit-name"
                       label="Name"
                       value={workingWindow.name}
                       max={80}
                       onChange={value => handleChange("name", value)}/>

                <Input type="number"
                       id="edit-year"
                       label="Year"
                       value={workingWindow.year}
                       onChange={value => handleChange("year", value)}/>

                <Input type="number"
                       id="edit-base-budget"
                       label="Base budget"
                       value={workingWindow.base_budget}
                       onChange={value => handleChange("base_budget", value)}/>

                <Input type="number"
                       id="edit-er"
                       label="Expected revenue"
                       value={workingWindow.expected_revenue}
                       onChange={value => handleChange("expected_revenue", value)}/>

                <ButtonRow>
                    <DangerButton onClick={() => setDeletionModalOpen(true)}>
                        Delete
                    </DangerButton>

                    <Button onClick={() => handleTransferWindowUpdate()}>
                        Update
                    </Button>
                </ButtonRow>
            </Modal>

            <DeletionModal isOpen={deletionModalOpen}
                           onDismiss={() => setDeletionModalOpen(false)}
                           onConfirm={() => handleTransferWindowDelete()}
                           title="Delete transfer window"
                           text="You are about to delete this transfer window and all its objectives permanently."/>

            <LoadingModal isOpen={loadingModalOpen}
                          onDismiss={() => setLoadingModalOpen(false)}
                          title={loadingTitle}
                          text={loadingText}/>

            <ReactVisibilitySensor onChange={onContinueLoading}>
                <div className="load-more"></div>
            </ReactVisibilitySensor>
        </div>
    )
}

export const TransferWindowPreview = ({onLongPress, transferWindow}) => {

    const longPressHandler = () => {
        if (isFunction(onLongPress)) {
            onLongPress.call(this, Object.assign(transferWindow));
        }
    }

    const longPressEvent = useLongPress(longPressHandler);

    return (
        <CategoryCard {...longPressEvent}>
            <p>{transferWindow.name}</p>
            <p className="year">{transferWindow.year}</p>
            <p className="value"><CurrencySymbol/> {asCurrency(transferWindow.base_budget)}</p>
            <p className={"value"}><CurrencySymbol/> {asCurrency(transferWindow.expected_revenue)}</p>
        </CategoryCard>
    )
}

export const TransferWindowOverviewScreen = withAuthorization(WrappedTransferWindowOverviewScreen);