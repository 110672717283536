import Header from "../generics/Header";
import {useEffect, useState} from "react";
import {findIndex, remove} from "lodash";
import {TransferContainer} from "../team/TransferContainer";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {getAllTransfers} from "../../service/transferService";
import {BottomNavWithSheet} from "../generics/Navigation";
import {NewTransferForm} from "./NewTransferForm";
import {SimpleFieldSort} from "../generics/filter/SimpleFieldSort";
import {ContentWrapper} from "../generics/Layout";
import {TabSheetAction} from "../../constants/TabSheetAction";
import {ContentCard} from "../generics/Cards";

const TransferSortFields = {
    id: {
        property: "id",
        label: "ID",
        sortType: "Date"
    },
    transferDate: {
        property: "transfer_date",
        label: "Transfer date",
        sortType: "Date"
    },
    cost: {
        property: "cost",
        label: "Cost",
        sortType: "Numerical"
    },
    player: {
        property: "player",
        label: "Player",
        sortType: "Alphabetical"
    }
}

const RESULTS_PER_PAGE = 25;

export const TransferScreen = ({}) => {

    const [offset, setOffset] = useState(0);
    const [orderBy, setOrderBy] = useState(TransferSortFields.id);
    const [order, setOrder] = useState("DESC");

    const [transfers, setTransfers] = useState([]);
    const [error, setError] = useState("");
    const [sheetAction, setSheetAction] = useState(TabSheetAction.RESET);

    useEffect(() => {
        fetchTransfers();
    }, []);

    useEffect(() => {
        fetchTransfers(true);
    }, [orderBy, order]);

    useEffect(() => {
        if (sheetAction === TabSheetAction.CLOSE) {
            setSheetAction(TabSheetAction.RESET);
        }
    }, [sheetAction]);

    const fetchTransfers = async (resetExisting = false) => {
        try {
            const response = await getAllTransfers(RESULTS_PER_PAGE, offset, orderBy.property, order);

            if (resetExisting) {
                setTransfers(response?.data);
            } else {
                setTransfers([...transfers, ...response?.data]);
            }
            setOffset(offset + RESULTS_PER_PAGE);
        } catch (err) {
            setError(getFriendlyErrorMessage(err?.response?.data));
        }
    }

    const handleTransferCreated = (transfer) => {
        setTransfers([transfer[0], ...transfers]);
        setSheetAction(TabSheetAction.CLOSE);
    }

    const handleTransferUpdate = (transfer) => {
        const transfersClone = [...transfers];
        const index = findIndex(transfersClone, t => t.id === transfer.id);
        transfersClone[index] = transfer;
        setTransfers(transfersClone);
    }

    const handleTransferDelete = (transferId) => {
        const transfersClone = [...transfers];
        remove(transfersClone, t => t.id === transferId);
        setTransfers(transfersClone);
    }

    return (
        <div className={"transfer-screen"}>
            <Header title={"All transfers"}/>

            <ContentWrapper>
                <SimpleFieldSort activeField={orderBy}
                                 activeOrder={order}
                                 onFieldChange={(field) => {
                                     setOrderBy(field);
                                     setOffset(0);
                                 }}
                                 onOrderChange={(order) => {
                                     setOrder(order);
                                     setOffset(0);
                                 }}
                                 fields={TransferSortFields}/>

                <h4>All transfers</h4>
                <ContentCard>
                    <TransferContainer data={transfers}
                                       onContinueLoading={() => fetchTransfers()}
                                       onUpdate={transfer => handleTransferUpdate(transfer)}
                                       onDelete={transferId => handleTransferDelete(transferId)}
                                       sortResultsByDate={false}
                    />
                </ContentCard>
            </ContentWrapper>

            <BottomNavWithSheet tabSheetTitle={"New transfer"}
                                addBackgroundColor={"var(--primary)"}
                                tabSheetAction={sheetAction}
            >
                <NewTransferForm onSubmit={handleTransferCreated}/>
            </BottomNavWithSheet>
        </div>
    )
}