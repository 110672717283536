export const getFriendlyErrorMessage = (errorResponseData) => {
    if (!errorResponseData.code) {
        return "An unknown error happened. Please try again later.";
    } 

    switch (errorResponseData.code) {
        case 300: 
            return "Not all required fields were filled in.";
        default:
            return "An error happened. Please try again later.";
    }
}