import Header from "../../generics/Header";
import {ContentWrapper} from "../../generics/Layout";
import {FeaturePreview} from "../../feature/FeaturePreview";
import {translations} from "../../../constants/translations.en";
import {BottomNavWithSheet} from "../../generics/Navigation";
import {TransferWindowsPreview} from "../TransferWindowOverviewScreen";
import {useEffect, useState} from "react";
import {getFriendlyErrorMessage} from "../../../service/errorService";
import {getAllTransfers} from "../../../service/transferService";
import {getAllTransferWindows} from "../../../service/transferWindowService";
import {findIndex, remove} from "lodash";
import {TransferContainer} from "../../team/TransferContainer";
import {CardContentWrapper} from "../../generics/Cards";
import {useHistory} from "react-router-dom";
import {SelectSheet, SelectSheetTab} from "../../generics/Tabs";
import SwapHorizontalCircleOutlineIcon from "mdi-react/SwapHorizontalCircleOutlineIcon";
import CalendarClockIcon from "mdi-react/CalendarClockIcon";
import {NewTransferWindowForm} from "../NewTransferWindowForm";
import {TabSheetAction} from "../../../constants/TabSheetAction";
import {NewTransferForm} from "../NewTransferForm";
import "./styles/transfer-center-screen.css";

export const TransferCenterScreen = ({}) => {

    const history = useHistory();
    const [errors, setErrors] = useState({
        transfers: null,
        transferWindows: null
    });
    const [sheetAction, setSheetAction] = useState("");
    const [transfers, setTransfers] = useState([]);
    const [transferWindows, setTransferWindows] = useState([]);

    const [createTransferWindowOpen, setCreateTransferWindowOpen] = useState(false);
    const [createTransferOpen, setCreateTransferOpen] = useState(false);

    useEffect(() => {
        loadRecentTransfers();
        loadRecentTransferWindows();
    }, []);

    useEffect(() => {
        if (sheetAction === TabSheetAction.CLOSE) {
            setSheetAction(TabSheetAction.RESET);
        }
    }, [sheetAction]);

    const loadRecentTransfers = async () => {
        try {
            const response = await getAllTransfers(4, 0);

            setTransfers([...response?.data]);
        } catch (err) {
            setErrors({
                transfers: getFriendlyErrorMessage(err?.response?.data)
            });
        }
    }

    const loadRecentTransferWindows = async () => {
        try {
            const response = await getAllTransferWindows(4, 0);

            setTransferWindows([...response?.data]);
        } catch (err) {
            setErrors({
                transferWindows: getFriendlyErrorMessage(err?.response?.data)
            });
        }
    }

    const handleWindowCreate = (window) => {
        setTransferWindows([...window, ...transferWindows]);
        setCreateTransferWindowOpen(false);
        setSheetAction(TabSheetAction.CLOSE);
    }

    const handleWindowUpdate = (window) => {
        const windows = [...transferWindows];
        const index = findIndex(windows, tw => tw.id === window.id);
        windows[index] = window;
        setTransferWindows(windows);
    }

    const handleWindowDelete = (windowId) => {
        let windows = [...transferWindows];
        remove(windows, (window) => {
            return window.id === windowId;
        });
        setTransferWindows(windows);
    }

    const handleTransferCreate = (transfer) => {
        setTransfers([...transfer, ...transfers]);
        setCreateTransferOpen(false);
        setSheetAction(TabSheetAction.CLOSE);
    }

    const handleTransferUpdate = (transfer) => {
        const transfersClone = [...transfers];
        const index = findIndex(transfersClone, t => t.id === transfer.id);
        transfersClone[index] = transfer;
        setTransfers(transfersClone);
    }

    const handleTransferDelete = (transferId) => {
        const transfersClone = [...transfers];
        remove(transfersClone, t => t.id === transferId);
        setTransfers(transfersClone);
    }

    return (
        <div className={"transfer-center-screen"}>
            <Header title={"Transfer Center"}/>

            <ContentWrapper>
                <h4>Recent transfer windows</h4>
                <CardContentWrapper onShowMoreClick={() => {history.push("/transfers/windows")}}>
                    <FeaturePreview isShown={(transferWindows.length === 0)}
                                    title={translations.features.NO_TRANSFER_WINDOWS}
                                    description={translations.features.TRANSFER_WINDOW_DESC}>
                        <TransferWindowsPreview transferWindows={transferWindows}
                                                onUpdate={window => handleWindowUpdate(window)}
                                                onDelete={windowId => handleWindowDelete(windowId)}/>
                    </FeaturePreview>
                </CardContentWrapper>

                <h4>Recent transfers</h4>
                <CardContentWrapper onShowMoreClick={() => history.push("/transfers/all")}>
                    <TransferContainer data={transfers}
                                       onUpdate={transfer => handleTransferUpdate(transfer)}
                                       onDelete={transferId => handleTransferDelete(transferId)}/>
                </CardContentWrapper>
            </ContentWrapper>

            <BottomNavWithSheet tabSheetTitle={"What do you want to do"}
                                tabSheetAction={sheetAction}
                                addBackgroundColor={"var(--primary)"}>
                <SelectSheetTab icon={<CalendarClockIcon />} text={"Add new transfer window"} onClick={() => setCreateTransferWindowOpen(true)}/>
                <SelectSheetTab icon={<SwapHorizontalCircleOutlineIcon />} text={"Add new transfer"} onClick={() => setCreateTransferOpen(true)}/>
            </BottomNavWithSheet>

            <SelectSheet isOpen={createTransferWindowOpen}
                         title={"New transfer window"}
                         onDismiss={() => setCreateTransferWindowOpen(false)}>
                <NewTransferWindowForm onCreate={handleWindowCreate} />
            </SelectSheet>

            <SelectSheet isOpen={createTransferOpen}
                         title={"New transfer"}
                         onDismiss={() => setCreateTransferOpen(false)}>
                <NewTransferForm onSubmit={handleTransferCreate} />
            </SelectSheet>
        </div>
    )

}