import _ from 'lodash';

export const getRelativeOdds = (homeOdds, awayOdds, drawOdds = 0.00) => {
    const relatives = {home: 50.00, away: 50.00, draw: 0.00};

    homeOdds = parseFloat(homeOdds);
    awayOdds = parseFloat(awayOdds);

    if (homeOdds === 0 || awayOdds === 0) {
        return relatives;
    }

    const sum = homeOdds + awayOdds + drawOdds;

    relatives.home = _.round(awayOdds * 100 / sum, 2);
    relatives.away = _.round(homeOdds * 100 / sum, 2);
    relatives.draw = _.round(sum - awayOdds - homeOdds);

    return relatives;
}

export const relatives = (values, precision = 2) => {
    if (!_.isArray(values)) {
        return 0;
    }
    
    let sum = _.sum(values);
    let relatives = [];

    _.each(values, (value) => {
        relatives.push(_.round(value / sum * 100, precision));
    });

    return relatives;
}