import {find, isFunction, map} from "lodash"
import {SelectSheet, SelectSheetTabLarge} from "./Tabs";
import {useState} from "react";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";

export const InlineFilter = ({
    onChange,
    label,
    options,
    value
}) => {

    const [isOpen, setOpen] = useState(false);

    const handleChange = (newValue) => {
        setOpen(false);
        if (isFunction(onChange)) {
            onChange(newValue);
        }
    }

    const renderOptions = () => {
        return map(options, (option, index) => {
            return <SelectSheetTabLarge text={option?.label} onClick={() => handleChange(option?.value)} key={index}/>
        })
    }

    const getSelectedValueLabel = () => {
        const option = find(options, o =>  o?.value === value);

        return option?.label;
    }

    return (
        <>
            <div className="inline-filter">
                <div className="label">
                    <p>
                        {label}
                    </p>
                </div>
                <div className={"select-toggle"} onClick={() => setOpen(true)}>
                    <p>
                        {getSelectedValueLabel()}
                    </p>
                    <ChevronDownIcon />
                </div>
            </div>
            <SelectSheet isOpen={isOpen} onDismiss={() => setOpen(false)} title={label}>
                { renderOptions() }
            </SelectSheet>
        </>

    )
}