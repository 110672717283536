import {Component} from "react";
import {Modal} from "./modules/generics/Modals";
import {Button, SubtleButton} from "./modules/generics/Buttons";
import {formatError} from "pretty-print-error";

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            hasError: false,
            moreDetailsOpen: false
        };
    }

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }

    render() {
        if (this.state.hasError === true) {
            return (
                <>
                    <h4>An error happened and MyScores crashed</h4>
                    <p>
                        An unexpected error occurred and forced MyScores to crash.
                        Click on 'More details' for more details about the error. Click
                        on 'Reload MyScores' to reload the app.
                    </p>

                    <Button onClick={() => window.location.reload()}>
                        Reload MyScores
                    </Button>
                    <br/>
                    <SubtleButton onClick={() => this.setState({moreDetailsOpen: true})}>
                        More details
                    </SubtleButton>
                    <Modal isOpen={this.state.moreDetailsOpen} onDismiss={() => this.setState({moreDetailsOpen: false})}>
                        <h4>Error details</h4>
                        <p>
                            You may screenshot the details below and send them to MyScores support to help us
                            solve this issue.
                        </p>
                        <code>
                            {this.state.error.name} <br/>
                            {formatError(this.state.error)}
                        </code>
                    </Modal>
                </>
            )
        }

        return this.props.children;
    }
}