import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {factTypes, matchViewMode} from "../../config/constants";
import _, {map} from 'lodash';
import {Modal} from "../generics/Modals";
import {Input, Select, SelectOption} from "../generics/Forms";
import {Button, DangerButton} from "../generics/Buttons";
import useLongPress from "../../hooks/longPress";
import {MatchFactIcon} from "./MatchFactIcon";
import {DefaultMatchFact} from "./DefaultMatchFact";
import {findViewMode} from "../../data/match-view-mode-helper";
import {TimelineMatchFact} from "./timeline-match-fact/TimelineMatchFact";
import {getTeamColor} from "../../data/colors";

const MatchFacts = ({data, match, onUpdate, onDelete, viewMode}) => {

    const [teamColors, setTeamColors] = useState({homeTeam: "var(--primary)", awayTeam: "var(--secondary)"});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [updateFactModalOpen, setUpdateFactModalOpen] = useState(false);
    const [workingFact, setWorkingFact] = useState({});

    useEffect(() => {
        const loadTeamColors = async () => {
            setTeamColors({
                homeTeam: await getTeamColor(match.home_team),
                awayTeam: await getTeamColor(match.away_team)
            });
        }

        loadTeamColors();
    }, [match]);

    const startFactUpdate = (fact) => {
        setUpdateFactModalOpen(true);
        let factToUpdate = {...fact};
        setWorkingFact(factToUpdate);
    }

    const updateFactField = (value, field) => {
        let fact = {...workingFact};
        fact[field] = value;
        setWorkingFact(fact);
    }

    const doUpdate = () => {
        if (_.isFunction(onUpdate)) {
            onUpdate.call(this, workingFact);
            closeUpdateFactModal();
        }
    }

    const closeUpdateFactModal = () => {
        setUpdateFactModalOpen(false);
        setWorkingFact({});
    }

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    }

    const showDeleteModal = () => {
        setDeleteModalOpen(true);
    }

    const doDelete = () => {
        if (_.isFunction(onDelete)) {
            onDelete.call(this, workingFact);
            closeDeleteModal();
            closeUpdateFactModal();
        }
    }

    const renderFacts = () => {
        let scoreHome = 0;
        let scoreAway = 0;

        return map(data, (value, index) => {

            if ([factTypes.GOAL, factTypes.PENALTY_GOAL, factTypes.OWNGOAL].includes(value.type)) {
                if (value.side === "home") {
                    scoreHome++;
                } else {
                    scoreAway++;
                }
            }

            return <SingleMatchFact scoreHome={scoreHome}
                                    scoreAway={scoreAway}
                                    viewMode={viewMode}
                                    homeColor={teamColors.homeTeam}
                                    awayColor={teamColors.awayTeam}
                                    isLastFact={(index + 1 === data.leading)}
                                    data={value}
                                    match={match}
                                    onUpdate={(fact) => startFactUpdate(fact)}
                                    key={index}/>
        });
    }

    return (
        <div className={`match-facts ${findViewMode(viewMode).className}`}>
            {renderFacts()}

            <Modal isOpen={updateFactModalOpen} onDismiss={closeUpdateFactModal}>
                <h3>Update match fact</h3>

                <Select value={workingFact.type} onChange={(value) => updateFactField(value, "type")}>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.GOAL}/>} value={factTypes.GOAL}
                                  content="Goal"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.OWNGOAL}/>} value={factTypes.OWNGOAL}
                                  content="Own goal"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.PENALTY_GOAL}/>}
                                  value={factTypes.PENALTY_GOAL} content="Penalty scored"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.PENALTY_MISS}/>}
                                  value={factTypes.PENALTY_MISS} content="Penalty missed"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.YELLOW_CARD}/>} value={factTypes.YELLOW_CARD}
                                  content="Yellow card"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.DOUBLE_YELLOW_CARD}/>}
                                  value={factTypes.DOUBLE_YELLOW_CARD} content="Second yellow card"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.RED_CARD}/>} value={factTypes.RED_CARD}
                                  content="Red card"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.SUB}/>} value={factTypes.SUB}
                                  content="Substitution"/>
                    <SelectOption icon={<MatchFactIcon factType={factTypes.VAR}/>} value={factTypes.VAR}
                                  content="VAR check"/>
                </Select>

                <div className="fact-form fact">
                    <Input id="u-factTime"
                           type="text"
                           name="factTime"
                           label="Time"
                           value={workingFact.fact_time}
                           valueChange={(value) => updateFactField(value, "fact_time")}/>
                    <Input id="u-details"
                           type="text"
                           name="details"
                           label="Details"
                           max={80}
                           value={workingFact.details}
                           valueChange={(value) => updateFactField(value, "details")}/>
                </div>

                <div className="fact-form extra-details">
                    <Input id="u-extraDetails"
                           type="text"
                           name="extraDetails"
                           label="Extra details"
                           max={140}
                           value={workingFact.extra_details}
                           valueChange={(value) => updateFactField(value, "extra_details")}/>
                </div>

                <Select label="-- Fact side --" value={workingFact.side}
                        onChange={(value) => updateFactField(value, "side")}>
                    <SelectOption value={"home"} content={"Home team"}/>
                    <SelectOption value={"away"} content={"Away team"}/>
                </Select>

                <div className="buttons-footer">
                    <DangerButton onClick={showDeleteModal}>
                        Delete
                    </DangerButton>
                    <Button onClick={doUpdate}>
                        Update
                    </Button>
                </div>
            </Modal>

            <Modal isOpen={deleteModalOpen} onDismiss={closeDeleteModal}>
                <h3>Attention: delete this match fact?</h3>

                <p>
                    You are about to delete this match fact permanently.
                    Click on "Delete" if you are sure you want to delete this
                    match fact. This action cannot be undone.
                </p>

                <DangerButton onClick={doDelete}>
                    Delete
                </DangerButton>
            </Modal>
        </div>
    )

}

const SingleMatchFact = ({data, match, homeColor, awayColor, scoreHome, scoreAway, onUpdate, viewMode, isLastFact}) => {

    let classNameBasedOnSide = (data.side === "home") ? "fact home" : "fact away";

    const longPressed = () => {
        if (_.isFunction(onUpdate)) {
            onUpdate.call(this, {...data});
        }
    }

    const longPressEvent = useLongPress(longPressed)

    const renderBasedOnViewMode = () => {
        switch (parseInt(viewMode)) {
            case matchViewMode.TIMELINE.id:
                return <TimelineMatchFact data={data}
                                          match={match}
                                          homeTeamColor={homeColor}
                                          awayTeamColor={awayColor}
                                          scoreHome={scoreHome}
                                          scoreAway={scoreAway}
                                          isLastFact={isLastFact}
                />
            case matchViewMode.COMPACT.id:

                break;
            case matchViewMode.DEFAULT.id:
            default:
                return <DefaultMatchFact data={data} scoreHome={scoreHome} scoreAway={scoreAway} />
        }
    }

    return (
        <Link to={"/player/" + data.details}>
            <div className={`${classNameBasedOnSide} ${findViewMode(viewMode).className}`}
                 {...longPressEvent}
            >
                { renderBasedOnViewMode() }
            </div>
        </Link>
    )
}

export default MatchFacts;