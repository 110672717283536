import { isFunction } from "lodash";
import { useEffect, useState } from "react"
import { FormContainer, Input } from "../generics/Forms"
import { Row } from "../generics/Layout";
import {valuesExist} from "../../data/forms";
import {createTransferWindow} from "../../service/transferWindowService";
import {getFriendlyErrorMessage} from "../../service/errorService";
import {messages} from "../../config/constants";

export const NewTransferWindowForm = ({
    onCreate
}) => {

    const [createError, setCreateError] = useState("");
    const [loading, setIsLoading] = useState(false);

    const [values, setValues] = useState({});

    const handleOnSubmit = () => {
        setIsLoading(true);
        let missingValues = valuesExist(values, "name", "year");

        if (!missingValues.result) {
            setIsLoading(false);
            return setCreateError("Missing required fields: ", missingValues.missing);
        }

        createTransferWindow(values)
            .then((response) => {
                if (response.data && response.data.type) {
                    setIsLoading(false);
                    return setCreateError(getFriendlyErrorMessage(response.data));
                }

                setValues({name: "", year: "", base_budget: "", expected_revenue: ""});

                if (isFunction(onCreate)) {
                    onCreate.call(this, response.data);
                }

                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setCreateError(getFriendlyErrorMessage(error.response.data));
                } else {
                    setCreateError(messages.ERR_UNKNOWN);
                }
                setIsLoading(false);
            })
    }

    const handleOnChange = (property, value) => {
        let fields = values;
        fields[property] = value;
        setValues(fields);
    }

    return (
        <FormContainer onSubmit={handleOnSubmit} error={createError} isLoading={loading}>
            <Row>
                <Input type="text"
                    max={80}
                    id="tw-name"
                    label="Name"
                    value={values.name}
                    onChange={value => handleOnChange("name", value)} />
                
                <Input type="number"
                    id="tw-year"
                    label="Year"
                    value={values.year}
                    onChange={value => handleOnChange("year", value)}/>
            </Row>
            
            <Row>
                <Input type="number"
                    id="tw-base-budget"
                    label="Base budget"
                    value={values.base_budget}
                    onChange={value => handleOnChange("base_budget", value)}/>

                <Input type="number"
                    id="tw-expected-revenue"
                    label="Expected revenue"
                    value={values.expected_revenue}
                    onChange={value => handleOnChange("expected_revenue", value)}/>
            </Row>

        </FormContainer>

    )
}