import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import HomeOutlineIcon from 'mdi-react/HomeOutlineIcon';
import TrophyOutlineIcon from 'mdi-react/TrophyOutlineIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import SwapHorizontalCircleOutlineIcon from 'mdi-react/SwapHorizontalCircleOutlineIcon';
import { Link } from 'react-router-dom';
import {cloneElement, useEffect, useRef, useState} from 'react';
import { SelectSheet } from './Tabs';
import { CSSTransition } from 'react-transition-group';
import {isArray, isEmpty, isFunction, map} from 'lodash';
import React from 'react';
import { TabSheetAction } from '../../constants/TabSheetAction';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import AppsIcon from "mdi-react/AppsIcon";
import "./styles/navigation.css";
import {useOutsideAlerter} from "../../hooks/outside-click-detection";
import {SearchWindow} from "./SearchWindow";

export const SideNavItem = (props) => {

    return (
        <div className="side-nav-item" {...props}>
            {props.children}
        </div>
    )
}

export const SideNavContent = (props) => {
    return (
        <div className="side-nav-content" {...props}>
            {props.children}
        </div>
    )
}

export const SideNavFooter = React.forwardRef(({ children }, ref) => {

    return (
        <div className="side-nav-footer" ref={ref}>
            {children}
        </div>
    )
})

export const SideNavHeader = (props) => {
    return (
        <div className="side-nav-header">
            {props.children}
        </div>
    )
}

export const SideNav = ({ isOpen, onDismiss, children }) => {

    const doDismiss = () => {
        if (isFunction(onDismiss)) {
            onDismiss.call(this);
        }
    }

    return (
        <div>
            <CSSTransition in={isOpen} timeout={500}>
                <div className="side-nav-wrapper">
                    <div className="side-nav-background" onClick={doDismiss}></div>

                    <div className="side-nav">
                        {children}
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export const BottomFixedNav = ({ children }) => {

    const [appDrawerOpen, setAppDrawerOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const shouldOpenAppDrawer = () => {
        if (!appDrawerOpen) {
            setAppDrawerOpen(true);
        }
    }

    return (
        <>
            <SearchWindow isOpen={searchOpen} onDismiss={() => setSearchOpen(false)}/>

            <BottomNavAppDrawer isOpen={appDrawerOpen} onDismiss={() => setAppDrawerOpen(false)}>
                <BottomNavItem text="" link="#" onClick={() => setSearchOpen(true)}>
                    <MagnifyIcon />
                </BottomNavItem>

                <BottomNavItem text="" link="/transfers">
                    <SwapHorizontalCircleOutlineIcon />
                </BottomNavItem>
            </BottomNavAppDrawer>

            <div className="bottom-nav-spacing"/>
            <div className="nav bottom">
                <BottomNavItem text="Managers" link="/managers">
                    <AccountCircleOutlineIcon />
                </BottomNavItem>
                <BottomNavItem text="Leagues" link="/leagues">
                    <TrophyOutlineIcon />
                </BottomNavItem>
                <div className="nav-item bottom">
                    {children}
                </div>
                <BottomNavItem text="Home" link="/overview">
                    <HomeOutlineIcon />
                </BottomNavItem>
                <BottomNavItem text="More" link={"#"} onClick={() => setAppDrawerOpen(!appDrawerOpen)}>
                    <AppsIcon />
                </BottomNavItem>
            </div>
        </>
    )
}

export const BottomNavItem = ({ children, text, link, onClick, style }) => {

    const handleClick = (event) => {
        if (isFunction(onClick)) {
            onClick.call(this);
        }
    }

    return (
        <Link className="nav-item bottom" to={link} onClick={(event) => handleClick(event)} style={style}>
            {children}
            { !isEmpty(text) &&
                <p>{text}</p>
            }
        </Link>
    )
}

export const BottomNavPlusItem = ({ backgroundColor = "", color = "", onClick }) => {

    return (
        <div className="bottom-nav plus" onClick={onClick} style={{ backgroundColor, color }}>
            <PlusIcon />
        </div>
    )
}

export const BottomNavWithSheet = ({ 
    children, 
    addBackgroundColor, 
    addColor, 
    tabSheetAction,
    tabSheetTitle
 }) => {

    const [sheetOpen, setSheetOpen] = useState((tabSheetAction === TabSheetAction.OPEN));

    useEffect(() => {
        if (tabSheetAction === TabSheetAction.OPEN) {
            openSheet();
        } else if (tabSheetAction === TabSheetAction.CLOSE) {
            dismissSheet();
        }
    }, [tabSheetAction]);

    const triggerSheet = () => {
        if (!sheetOpen) {
            openSheet();
        } else {
            dismissSheet();
        }
    }

    const openSheet = () => {
        setSheetOpen(true);
    }

    const dismissSheet = () => {
        setSheetOpen(false);
    }

    return (
        <>
            <SelectSheet isOpen={sheetOpen} title={tabSheetTitle} onDismiss={dismissSheet}>
                {children}
            </SelectSheet>

            <BottomFixedNav>
                <BottomNavPlusItem onClick={triggerSheet} backgroundColor={addBackgroundColor} color={addColor || "white"} />
            </BottomFixedNav>
        </>
    )
}

export const BottomNavAppDrawer = ({ isOpen, children, onDismiss }) => {

    const appDrawerRef = useRef(null);

    useOutsideAlerter(appDrawerRef, () => {
        dismissHandler();
    }, { detectOutsideScroll: true })

    const dismissHandler = (nestedClickHandler) => {
        if (isFunction(onDismiss)) {
            onDismiss.call(this);
        }

        if (isFunction(nestedClickHandler)) {
            nestedClickHandler.call(this);
        }
    }

    const renderChildren = () => {
        if (!isArray(children)) {
            children = [children];
        }

        return map(children, (child, index) => {
            if (child) {
                const delay = 70 * index;

                return (
                    <CSSTransition in={isOpen} timeout={250 + delay} key={index}>
                        {
                            cloneElement(child, {
                                ...child.props,
                                style: {
                                    ...child.props.style,
                                    transitionDelay: `${delay}ms`
                                },
                                onClick: () => {
                                    dismissHandler(child.props.onClick)
                                },
                                key: index
                            })
                        }
                    </CSSTransition>
                )
            }
        })
    }

    return (
        <CSSTransition in={isOpen} timeout={250} classNames={"bottom-nav-app-drawer"}>
            <div className={"bottom-nav-app-drawer"} ref={appDrawerRef}>
                { renderChildren() }
            </div>
        </CSSTransition>
    )
}