import {ChanceOfWinning} from "./ChanceOfWinning"
import {ContentCard} from "../generics/Cards";

export const GenericMatchData = ({match}) => {

    return (
        <ContentCard>
            <div className="match-data">
                {
                    (match.home_team_odds && match.away_team_odds) ?
                        <>
                            <h5>Chance of winning</h5>
                            <ChanceOfWinning homeOdds={match.home_team_odds}
                                             awayOdds={match.away_team_odds}
                                             homeTeam={match.home_team}
                                             awayTeam={match.away_team}/>
                        </>
                        :
                        null
                }
            </div>
        </ContentCard>
    )
}