import Axios from "axios";
import {isArray, join} from "lodash";
import {config, storage} from "../config/constants"

export const getTeamTopDuos = async (team, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_duos?saveGame=${saveId}&team=${team}&page=${page}`
    })
}

export const getTeamTopDuosForSeason = async (team, season, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_duos/season?saveGame=${saveId}&team=${team}&season=${season}&page=${page}`
    })
}

export const getTeamTopDuosForAllSeasonsAndCompetition = async (team, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_duos?saveGame=${saveId}&page=${page}&team=${team}&country=${country}&competition=${name}`
    });
}

export const getTeamTopDuosForSeasonAndCompetition = async (team, season, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_duos?saveGame=${saveId}&page=${page}&team=${team}&season=${season}&country=${country}&competition=${name}`
    });
}

export const getTeamAppearances = async (team, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET', 
        url: `${config.API_URL}/save/team/top_appearances?saveGame=${saveId}&team=${team}&page=${page}`
    })
}

export const getTeamAppearancesForSeason = async (team, season, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET', 
        url: `${config.API_URL}/save/team/top_appearances/season?saveGame=${saveId}&team=${team}&season=${season}&page=${page}`
    })
}

export const getTeamAppearancesForAllSeasonsAndCompetition = async (team, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_appearances?saveGame=${saveId}&page=${page}&team=${team}&country=${country}&competition=${name}`
    });
}

export const getTeamAppearancesForSeasonAndCompetition = async (team, season, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_appearances?saveGame=${saveId}&page=${page}&team=${team}&season=${season}&country=${country}&competition=${name}`
    });
}

export const getTeamMOTM = async (team, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET', 
        url: `${config.API_URL}/save/team/top_motm?saveGame=${saveId}&team=${team}&page=${page}`
    })
}

export const getTeamMOTMForSeason = async (team, season, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET', 
        url: `${config.API_URL}/save/team/top_motm/season?saveGame=${saveId}&team=${team}&season=${season}&page=${page}`
    })
}

export const getTeamTopMOTMForAllSeasonsAndCompetition = async (team, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_motm?saveGame=${saveId}&page=${page}&team=${team}&country=${country}&competition=${name}`
    });
}

export const getTeamTopMOTMForSeasonAndCompetition = async (team, season, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_motm?saveGame=${saveId}&page=${page}&team=${team}&season=${season}&country=${country}&competition=${name}`
    });
}

export const getTeamTopAssistGivers = async (team, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_assists?saveGame=${saveId}&team=${team}&page=${page}`
    });
}

export const getTeamTopAssistGiversFromSeason = async (team, season, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_assists/season?saveGame=${saveId}&team=${team}&season=${season}&page=${page}`
    });
}

export const getTeamTopAssistGiversForAllSeasonsAndCompetition = async (team, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_assists?saveGame=${saveId}&page=${page}&team=${team}&country=${country}&competition=${name}`
    });
}

export const getTeamTopAssistGiversForSeasonAndCompetition = async (team, season, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_assists?saveGame=${saveId}&page=${page}&season=${season}&team=${team}&country=${country}&competition=${name}`
    });
}

export const getTeamSeasons = async (team) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET', 
        url: `${config.API_URL}/save/team/seasons?saveGame=${saveId}&team=${team}`
    });
}

export const getTeamTopScorersForSeason = async (team, season, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_scorers/season?saveGame=${saveId}&team=${team}&season=${season}&page=${page}`
    });
}

export const getTeamTopScorersForAllSeasonsAndCompetition = (team, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_scorers?saveGame=${saveId}&page=${page}&team=${team}&country=${country}&competition=${name}`
    });
}

export const getTeamTopScorersForSeasonAndCompetition = (team, season, country, name, page) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_scorers?saveGame=${saveId}&page=${page}&team=${team}&season=${season}&country=${country}&competition=${name}`
    });
}

export const getTeamTopScorers = async (teamName, page) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: `${config.API_URL}/save/team/top_scorers?page=${page}&saveGame=${saveId}&team=${teamName}`,
        headers
    });

    return response;
}

export const getPlayerGoalsPerSeason = async (playerName) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([config.API_URL, "/save/player/goals?player=", playerName, "&saveGame=", saveId], ""),
        headers
    });

    return response;
}

export const getPlayerTotalAppearances = async (playerName) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/player/appearances?saveGame=",
            saveId,
            "&player=", 
            playerName
        ], ""),
        headers
    });

    return response;
}

export const getPlayerAssistsPerSeason = async (playerName) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/player/assists?saveGame=", saveId,
            "&player=", playerName
        ], ""),
        headers
    });

    return response;
}

export const getPlayerBestRatings = async (playerName) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL, 
            "/save/player/ratings?saveGame=", saveId,
            "&player=", playerName
        ], ""),
        headers
    });

    return response;
}

export const getPlayerStatsPerTeam = async (playerName) => {
    return {
        goals: await wrapStatsRequest(getPlayerGoalsPerTeam, playerName),
        assists: await wrapStatsRequest(getPlayerAssistsPerTeam, playerName),
        appearances: await wrapStatsRequest(getPlayerAppearancesPerTeam, playerName)
    }
}

const wrapStatsRequest = async (fun, playerName) => {
    try {
        const response = await Promise.resolve(fun.call(this, playerName));
        if (isArray(response?.data)) {
            return response.data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

export const getPlayerGoalsPerTeam = (playerName) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/player/goals/team?saveGame=${saveId}&player=${playerName}`
    });
}

export const getPlayerAssistsPerTeam = (playerName) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/player/assists/team?saveGame=${saveId}&player=${playerName}`
    });
}

export const getPlayerAppearancesPerTeam = (playerName) => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/player/appearances/team?saveGame=${saveId}&player=${playerName}`
    });
}