export const SortOrderTypes = {
    Alphabetical: {
        ASC: "A - Z",
        DESC: "Z - A"
    },
    Numerical: {
        ASC: "Smallest to largest",
        DESC: "Largest to smallest"
    },
    Date: {
        ASC: "Oldest to newest",
        DESC: "Newest to oldest"
    }
}