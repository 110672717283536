import _, {chain, each, filter, isArray, isFunction, map, split, trim} from 'lodash';
import {useEffect, useState} from 'react';
import {Button, ButtonRow, DangerButton, SubtleButton} from '../generics/Buttons';
import {HorizontalInput, Input, SimpleInput} from '../generics/Forms';
import {CircularLoaderInfinite} from '../generics/Loaders';
import {Modal} from '../generics/Modals';
import {SelectSheet} from '../generics/Tabs';
import {EditableLineupPosition, LineupPosition} from './LineupPositions';
import {createLineup as createNewLineup} from '../../service/lineupService';
import {getFriendlyErrorMessage} from '../../service/errorService';
import {factTypes, loaderStatus, messages, positionsOrder} from '../../config/constants';
import {
    createLineupPositions,
    updateLineupPosition as updateLineupPositionFromDb
} from '../../service/lineupPositionsService';
import {deleteLineup as deleteLineupFromDb} from '../../service/lineupService';
import * as hashObject from 'object-hash';
import {getTeamColor} from '../../data/colors';
import {ScrollableTagLine, Tag} from '../generics/Tags';
import {LineupTemplateCard} from '../generics/Cards';
import "./lineups.css";
import {TitleLabel} from "../generics/Text";

let __positionContainer_lineupId = 0;

export const LineupPositionsContainer = ({
                                             lineupPositions,
                                             lineupTemplates,
                                             onCreateTemplate,
                                             onRequestCreation,
                                             onRequestUpdate,
                                             wasLineupCreated = false,
                                             shirtColor = "red",
                                             side,
                                             onTemplateDelete,
                                             matchFacts
                                         }) => {

    const [positions, setPositions] = useState([]);
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [creationMode, setCreationMode] = useState(false);
    const [formation, setFormation] = useState({GK: 1, D: 0, DM: 0, AM: 0, A: 0});
    const [remainingSpots, setRemainingSpots] = useState(10);
    const [formationError, setFormationError] = useState(null);
    const [lineupToCreate, setLineupToCreate] = useState([]);
    const [templateSelectionOpen, setTemplateSelectionOpen] = useState(false);
    const [templateNameModalOpen, setTemplateNameModalOpen] = useState(false);
    const [lineupTemplateName, setLineupTemplateName] = useState("");
    const [publishButton, setPublishButton] = useState(null);
    const [color, setColor] = useState("");

    const [playerToEdit, setPlayerToEdit] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const containerClass = (_.isEmpty(lineupPositions)) ? "positions-container create" : "positions-container";

    useEffect(() => {
        __positionContainer_lineupId++;
    }, []);

    useEffect(() => {
        setColor(shirtColor);
    }, [shirtColor]);

    useEffect(() => {
        if (!wasLineupCreated) {
            return;
        }

        setPositions([]);
        setPublishButton(null);
        setPositionsFromActualLineup();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wasLineupCreated]);

    useEffect(() => {
        setPositions([]);
        setPublishButton(null);
        setPositionsFromActualLineup();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineupPositions]);

    const getAmountOfPlayersInFormation = (lineupFormation) => {
        let sum = 0;

        each(lineupFormation, (val) => {
            sum = sum + Number.parseInt(val);
        });

        return sum;
    }

    const setPositionsFromActualLineup = () => {
        let groupedUnsorted = _.groupBy(lineupPositions, "position");

        let grouped = {};

        Object.keys(groupedUnsorted)
            .sort(function (a, b) {
                return positionsOrder.indexOf(a.toUpperCase()) - positionsOrder.indexOf(b.toUpperCase());
            })
            .forEach(key => {
                grouped[key] = groupedUnsorted[key];
            });

        _.mapKeys(grouped, (obj, position) => {
            let sorted = _.sortBy(obj, ["place_number"]);
            grouped[position] = sorted;
        })

        setPositions(grouped);
        setCreationMode(false);
    }

    const updateFormation = (position, value) => {
        let oldFormation = formation;
        if (value === "") {
            value = 0;
        }
        oldFormation[position] = value;

        const sum = getAmountOfPlayersInFormation(oldFormation);

        if (sum > 11 || sum < 1) {
            setFormationError("Warning: your formation has more than 11 spots! You need exactly 11 to proceed.");
        } else {
            setRemainingSpots(11 - sum);
            setFormation(oldFormation);
            setFormationError(null);
        }
    }

    const handleQuickFormationSelection = (value) => {
        const positions = split(value, ",");

        each(positions, (v, i) => {
            const positionName = positionsOrder[i + 1];

            updateFormation(positionName, v);
        });

        startCreatorMode();
    }

    const triggerSheet = () => {
        setIsSheetOpen(!isSheetOpen);
    }

    const playerChangeHandler = (position, rowNum, player, shirtNumber) => {
        let lineup = lineupToCreate;
        let updated = false;

        lineup.forEach((value) => {
            if (value.position === position && value.place_number === rowNum) {
                value.player = player;
                value.shirt_number = (shirtNumber || 0);
                updated = true;
            }
        });

        if (!updated) {
            lineup.push({
                position,
                place_number: rowNum,
                shirt_number: (shirtNumber || 0),
                player
            });
        }

        const nonEmptyPositions = filter(lineup, (l) => !trim(l.player) !== "");

        if (nonEmptyPositions.length === 11) {
            setPublishButton(
                <ButtonRow>
                    <Button extraClassName="publish-lineup-button" onClick={publishLineup}>
                        Publish lineup
                    </Button>
                    <Button extraClassName="publish-lineup-button" onClick={startTemplateCreation}>
                        Save as template
                    </Button>
                </ButtonRow>
            )
        }
        setLineupToCreate(lineup);
    }

    const publishLineup = () => {
        onRequestCreation.call(this, lineupToCreate);
    }

    const startTemplateCreation = () => {
        setLineupTemplateName("");
        setTemplateNameModalOpen(true);
    }

    const publishAsTemplate = () => {
        if (isFunction(onCreateTemplate)) {
            const createData = {
                template_name: lineupTemplateName,
                side: side,
                positions: lineupToCreate
            }

            onCreateTemplate.call(this, createData);

            setTemplateNameModalOpen(false);
        }
    }

    const editPlayer = (player) => {
        setPlayerToEdit({...player});
        setEditModalOpen(true);
    }

    const updateEditField = (value, field) => {
        let fields = {...playerToEdit};
        fields[field] = value;
        setPlayerToEdit(fields);
    }

    const confirmEditChanges = () => {
        if (_.isFunction(onRequestUpdate)) {
            onRequestUpdate.call(this, playerToEdit);
        }

        dismissEditModal();
    }

    const dismissEditModal = () => {
        setEditModalOpen(false);
    }

    const populatePositions = (data, isEditable = false) => {
        let items = [];
        for (const [key, objValue] of Object.entries(data)) {
            let positionItems = [];

            let iteratee;
            if (isEditable && !isArray(objValue)) {
                iteratee = objValue;
            } else {
                iteratee = objValue.length;
            }

            if (isEditable && objValue === 0) {
                continue;
            }

            for (let i = 0; i < iteratee; i++) {
                if (isEditable) {
                    positionItems.push(<EditableLineupPosition
                        key={i + key}
                        onChange={(position, rowNum, name, shirt) => playerChangeHandler(position, rowNum, name, shirt)}
                        color={color}
                        player={(isArray(objValue) ? objValue[i].player : "")}
                        position={key}
                        rowNum={i}/>);
                } else {
                    positionItems.push(
                        <div className="player-position-wrapper">
                            <LineupPosition color={color}
                                            withLink={true}
                                            key={i + key}
                                            position={key}
                                            rowNum={i}
                                            player={objValue[i].player}
                                            onLongPress={() => editPlayer(objValue[i])}
                                            matchFacts={filter(matchFacts, f => (f.details === objValue[i].player || (f.extra_details === objValue[i].player && f.type === factTypes.SUB)))}
                                            shirtNumber={objValue[i].shirtNumber}/>
                        </div>
                    );
                }

            }
            items.push(positionItems);
        }

        let rows = [];

        for (let item of items) {
            if (item.length > 0) {
                rows.push(
                    <div className="position-row">
                        {item}
                    </div>
                )
            }
        }

        return rows;
    }

    const startCreatorMode = (fromTemplate = false) => {
        const amount = getAmountOfPlayersInFormation(formation);
        if (amount === 11) {
            if (!fromTemplate) {
                setPositions(formation);
            }

            setCreationMode(true);
        } else {
            setFormationError("Your formation needs 11 players in total. Currently: " + amount);
        }
    }

    const loadLineupTemplate = (template) => {
        setTemplateSelectionOpen(false);
        setIsSheetOpen(false);

        const templateClone = {...template};

        const groupedByPosition = chain(templateClone.extra_fields.positions)
            .groupBy("position")
            .value();

        each(groupedByPosition, (value, index) => {
            if (index !== "GK") {
                updateFormation(index, value.length);

                // sort by place_number 
                value.sort((a, b) => (a.place_number > b.place_number) ? 1 : -1);

                if (templateClone.side !== side) {
                    const length = value.length;

                    each(value, (p, i) => {
                        p.place_number = length - 1 - i
                    })

                    value.sort((a, b) => (a.place_number > b.place_number) ? 1 : -1);
                }
            }
        });

        setLineupToCreate(templateClone.extra_fields.positions);
        setPositions(groupedByPosition);

        each(templateClone.extra_fields.positions, p => {
            playerChangeHandler(p.position, p.place_number, p.player, p.shirt_number)
        })

        startCreatorMode(true);
    }

    const handleTemplateDeletion = (t) => {
        if (isFunction(onTemplateDelete)) {
            setIsSheetOpen(false);
            setTemplateSelectionOpen(false);
            onTemplateDelete.call(this, t);
        }
    }

    const renderLineupTemplates = () => {
        return map(lineupTemplates, (template) => {
            return (
                <LineupTemplateCard onClick={loadLineupTemplate} onClickDelete={(t) => handleTemplateDeletion(t)}
                                    template={template}/>
            )
        })
    }

    const renderPositions = () => {
        if (_.isEmpty(positions)) {
            return (
                <>
                    <SelectSheet title={"Add lineup"} isOpen={isSheetOpen} onDismiss={() => setIsSheetOpen(false)}>
                        {formationError &&
                            <p className="error">
                                {formationError}
                            </p>
                        }

                        <ScrollableTagLine name={"Quick formations"}>
                            <Tag content="4-2-3-1" value="4,2,3,1" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="4-3-3" value="4,3,0,3" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="4-4-2" value="4,4,0,2" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="3-4-3" value="3,4,0,3" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="3-5-2" value="3,5,2" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="4-1-4-1" value="4,1,4,1" onClick={(v) => handleQuickFormationSelection(v)} />
                            <Tag content="5-4-1" value="5,4,0,1" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="3-4-2-1" value="3,4,2,1" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="5-3-2" value="5,3,0,2" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="3-4-1-2" value="3,4,1,2" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="4-3-1-2" value="4,3,1,2" onClick={(v) => handleQuickFormationSelection(v)}/>
                            <Tag content="4-3-2-1" value="4,3,2,1" onClick={(v) => handleQuickFormationSelection(v)} />
                        </ScrollableTagLine>

                        <div className="form-wrapper">
                            <TitleLabel value={"Custom formation"}/>
                            <HorizontalInput id={__positionContainer_lineupId + "-d"} label="Amount of defenders">
                                <SimpleInput id={__positionContainer_lineupId + "-d"}
                                             type={"number"}
                                             placeholder={"0-" + remainingSpots}
                                             min={0}
                                             max={remainingSpots}
                                             onChange={(value) => updateFormation("D", value)}/>
                            </HorizontalInput>

                            <HorizontalInput id={__positionContainer_lineupId + "-dm"}
                                             label={"Amount of defensive midfielders"}>
                                <SimpleInput id={__positionContainer_lineupId + "-dm"}
                                             type={"number"}
                                             placeholder={"0-" + remainingSpots}
                                             min={0}
                                             max={remainingSpots}
                                             onChange={(value) => updateFormation("DM", value)}/>
                            </HorizontalInput>

                            <HorizontalInput id={__positionContainer_lineupId + "-am"}
                                             label={"Amount of attacking midfielders"}>
                                <SimpleInput id={__positionContainer_lineupId + "-am"}
                                             type={"number"}
                                             placeholder={"0-" + remainingSpots}
                                             min={0}
                                             max={remainingSpots}
                                             onChange={(value) => updateFormation("AM", value)}/>
                            </HorizontalInput>

                            <HorizontalInput id={__positionContainer_lineupId + "a"} label={"Amount of attackers"}>
                                <SimpleInput id={__positionContainer_lineupId + "-a"}
                                             type={"number"}
                                             placeholder={"0-" + remainingSpots}
                                             min={0}
                                             max={remainingSpots}
                                             onChange={(value) => updateFormation("A", value)}/>
                            </HorizontalInput>

                            <ButtonRow>
                                <Button onClick={() => startCreatorMode(false)}>
                                    Create
                                </Button>
                                <SubtleButton onClick={() => setTemplateSelectionOpen(true)}>
                                    Create from template
                                </SubtleButton>
                            </ButtonRow>
                        </div>
                    </SelectSheet>

                    <SelectSheet title={"Load a lineup template"} isOpen={templateSelectionOpen}
                                 onDismiss={() => setTemplateSelectionOpen(false)}>
                        {renderLineupTemplates()}
                    </SelectSheet>

                    <Button extraClassName="create-lineup" onClick={triggerSheet}>Create a lineup</Button>
                </>
            )
        } else {
            return (
                <>
                    {populatePositions(positions, creationMode)}

                    {publishButton}

                    <Modal isOpen={editModalOpen} onDismiss={dismissEditModal}>
                        <h3>Edit player</h3>

                        <Input type="text"
                               label={"Player name"}
                               clearButton={true}
                               value={(playerToEdit) ? playerToEdit.player : ""}
                               valueChange={(value) => updateEditField(value, "player")}
                        />

                        <Input type="number"
                               label="Shirt number"
                               value={(playerToEdit) ? playerToEdit.shirt_number : ""}
                               valueChange={value => updateEditField(value, "shirt_number")}/>

                        <Button onClick={confirmEditChanges}>
                            Save changes
                        </Button>
                    </Modal>
                </>
            )
        }
    }

    return (
        <div className={containerClass}>
            {renderPositions()}

            <Modal isOpen={templateNameModalOpen} onDismiss={() => setTemplateNameModalOpen(false)}>
                <h3>Give this template a name</h3>

                <Input type="text"
                       label={"Template name"}
                       value={lineupTemplateName}
                       valueChange={(value) => setLineupTemplateName(value)}
                />

                <Button onClick={publishAsTemplate}>
                    Create template
                </Button>
            </Modal>
        </div>
    )
}

export const TeamLineup = ({
                               lineupPositions,
                               lineupTemplates,
                               onCreateTemplate,
                               onRequestCreation,
                               onRequestUpdate,
                               wasLineupCreated = false,
                               shirtColor = "red",
                               side,
                               onTemplateDelete,
                               matchFacts,
                           }) => {

    return (
        <div className="positions">
            <div className="gk-box">
                <div className="small-box"></div>
                <div className="half-circle"></div>
            </div>
            <div className="middle-circle"></div>
            <LineupPositionsContainer wasLineupCreated={wasLineupCreated}
                                      onTemplateDelete={onTemplateDelete}
                                      lineupTemplates={lineupTemplates}
                                      onCreateTemplate={onCreateTemplate}
                                      onRequestCreation={(lineup) => onRequestCreation.call(this, lineup)}
                                      onRequestUpdate={player => onRequestUpdate.call(this, player)}
                                      lineupPositions={lineupPositions}
                                      shirtColor={shirtColor}
                                      side={side}
                                      matchFacts={matchFacts}
            />
        </div>
    )
}

export const LineupContainer = ({
                                    team,
                                    lineupPositions,
                                    lineupTemplates,
                                    onCreateTemplate,
                                    onRequestCreation,
                                    onRequestUpdate,
                                    onRequestDelete,
                                    wasLineupCreated = false,
                                    side,
                                    onTemplateDelete,
                                    matchFacts,
                                }) => {

    const [color, setColor] = useState("white");

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        getColorForShirts();
    });

    const getColorForShirts = () => {
        getTeamColor(team)
            .then(color => {
                setColor(color);
            })
            .catch((err) => {
                setColor("#ffffff");
            })
    }

    const triggerDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen);
    }

    return (
        <div className="lineup">
            <div className="lineup-header">
                <p>{team}</p>

                <div className={"buttons"}>
                    <SubtleButton onClick={triggerDeleteModal}>
                        Delete
                    </SubtleButton>
                </div>
            </div>
            <TeamLineup wasLineupCreated={wasLineupCreated}
                        onTemplateDelete={onTemplateDelete}
                        onCreateTemplate={onCreateTemplate}
                        lineupTemplates={lineupTemplates}
                        onRequestCreation={(lineup) => onRequestCreation.call(this, lineup)}
                        onRequestUpdate={player => onRequestUpdate.call(this, player)}
                        lineupPositions={lineupPositions}
                        shirtColor={color}
                        side={side}
                        matchFacts={matchFacts}
            />

            <Modal isOpen={deleteModalOpen} onDismiss={() => setDeleteModalOpen(false)}>
                <h3>
                    Delete lineup of {team}?
                </h3>
                <p>
                    Warning: the lineup will be deleted forever! This action cannot be undone. Are
                    you sure you want to delete this lineup? Press "Delete" to delete this lineup
                    permanently.
                </p>
                <DangerButton onClick={() => onRequestDelete.call(this, "id")}>
                    Delete
                </DangerButton>
            </Modal>
        </div>
    )
}

export const Lineups = (
    {
        matchId,
        matchFacts,
        homeTeam,
        awayTeam,
        lineupHome,
        lineupAway,
        lineupPositionsHome,
        lineupPositionsAway,
        lineupTemplates,
        onCreateTemplate,
        onDelete,
        onUpdate,
        onCreate,
        onTemplateDelete
    }) => {

    const [creationModalShown, setCreationModalShown] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalText, setModalText] = useState("");
    const [homeLineup, setHomeLineup] = useState(null);
    const [awayLineup, setAwayLineup] = useState(null);
    const [homeLineupPositions, setHomeLineupPositions] = useState([]);
    const [awayLineupPositions, setAwayLineupPositions] = useState([]);

    useEffect(() => {
        if (hashObject(homeLineupPositions) !== hashObject(lineupPositionsHome)) {
            setHomeLineupPositions(lineupPositionsHome);
        }

        if (hashObject(awayLineupPositions) !== hashObject(lineupPositionsAway)) {
            setAwayLineupPositions(lineupPositionsAway);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineupPositionsHome, lineupPositionsAway]);

    useEffect(() => {
        if (!_.isEqual(homeLineup, lineupHome)) {
            setHomeLineup(lineupHome);
        }

        if (!_.isEqual(awayLineup, lineupAway)) {
            setAwayLineup(lineupAway);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineupHome, lineupAway]);

    const createLineup = (lineup, side) => {
        setCreationModalShown(true);
        setModalTitle("Creating a lineup");
        setModalText("Creating a lineup...");

        createNewLineup(matchId, {side: side})
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText("Could not create a lineup.");
                    hideModal(loaderStatus.FAILED);
                } else {
                    setModalText("Uploading lineup player positions. Please hang on...");

                    if (_.isEmpty(response.data)) {
                        setModalText("Unable to retrieve created lineup data.");
                        hideModal(loaderStatus.FAILED);
                        return;
                    }

                    createLineupPositions(response.data[0].id, matchId, lineup)
                        .then((res) => {
                            if (res.data && res.data.type) {
                                setModalText("Could not create lineup player positions.");
                                hideModal(hideModal(loaderStatus.FAILED));
                            } else {
                                setModalText("Lineup has been created!");
                                if (side === "home") {
                                    setHomeLineupPositions(res.data);
                                } else if (side === "away") {
                                    setAwayLineupPositions(res.data);
                                }
                                onCreate.call(this, response.data[0], res.data, side);
                                hideModal(loaderStatus.SUCCESS);
                            }
                        })
                        .catch((err) => {
                            if (err.response && err.response.data && err.response.data.type) {
                                setModalText(getFriendlyErrorMessage(err.response.data));
                            } else {
                                setModalText(messages.ERR_UNKNOWN);
                            }
                            hideModal(loaderStatus.FAILED);
                        })
                }
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setModalText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
            })
    }

    const deleteLineup = (side) => {
        setCreationModalShown(true);
        setModalTitle("Deleting lineup");
        setModalText("Please wait while the lineup is being deleted...");

        let id, matchId;
        try {
            id = (side === "home") ? homeLineup.id : awayLineup.id;
            matchId = (side === "home") ? homeLineup.match_id : awayLineup.match_id;
        } catch (err) {
            setModalText("Could not delete lineup. This lineup does not exist.");
            hideModal(loaderStatus.FAILED);
            return;
        }

        deleteLineupFromDb(matchId, id)
            .then(response => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    hideModal(loaderStatus.FAILED);
                } else {
                    setModalText("The lineup has been deleted successfully.");
                    hideModal(loaderStatus.SUCCESS);

                    if (_.isFunction(onDelete)) {
                        onDelete.call(this, side);
                    }
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.type) {
                    setModalText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal(loaderStatus.FAILED);
            })
    }

    const updateLineupPosition = (player, side) => {
        setCreationModalShown(true);
        setModalTitle("Updating player position");
        setModalText("Please wait while the player position is being updated...");

        updateLineupPositionFromDb(matchId, player.lineup_id, player.id, player)
            .then((response) => {
                if (response.data && response.data.type) {
                    setModalText(getFriendlyErrorMessage(response.data));
                    hideModal(loaderStatus.FAILED);
                    return;
                }

                if (response.data.changedRows === 1) {
                    setModalText("Player has been updated successfully!");
                    hideModal(loaderStatus.SUCCESS);

                    if (_.isFunction(onUpdate)) {
                        onUpdate.call(this, player, side);
                    }
                } else {
                    setModalText("There was nothing to update.");
                    hideModal(loaderStatus.SUCCESS);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type) {
                    setModalText(getFriendlyErrorMessage(err.response.data));
                } else {
                    setModalText(messages.ERR_UNKNOWN);
                }
                hideModal(loaderStatus.FAILED);
            })
    }

    const hideModal = (status = loaderStatus.LOADING) => {
        _.delay(function () {
            setCreationModalShown(false);
        }, 1500);
    }

    return (
        <>
            <div className="lineups">
                <LineupContainer
                    onCreateTemplate={onCreateTemplate}
                    onTemplateDelete={onTemplateDelete}
                    lineupTemplates={JSON.parse(JSON.stringify(lineupTemplates))}
                    onRequestCreation={(lineup) => createLineup(lineup, "home")}
                    onRequestUpdate={player => updateLineupPosition(player, "home")}
                    team={homeTeam}
                    side={"home"}
                    lineup={homeLineup}
                    lineupPositions={homeLineupPositions}
                    matchFacts={filter(matchFacts, f => f.side === "home")}
                    onRequestDelete={() => deleteLineup("home")}/>
                <LineupContainer
                    onCreateTemplate={onCreateTemplate}
                    onTemplateDelete={onTemplateDelete}
                    lineupTemplates={JSON.parse(JSON.stringify(lineupTemplates))}
                    onRequestCreation={(lineup) => createLineup(lineup, "away")}
                    onRequestUpdate={player => updateLineupPosition(player, "away")}
                    team={awayTeam}
                    side={"away"}
                    lineup={awayLineup}
                    lineupPositions={awayLineupPositions}
                    matchFacts={filter(matchFacts, f => f.side === "away")}
                    onRequestDelete={() => deleteLineup("away")}/>
            </div>
            <Modal isOpen={creationModalShown}>
                <h3>{modalTitle}</h3>
                <p>{modalText}</p>
                <CircularLoaderInfinite/>
            </Modal>
        </>
    )
}
