import { filter } from "lodash"; 
import TeamNames from './../constants/teamnames.json';

export const getMatchingTeamNames = (text) => {
    return new Promise((resolve) => {
        let teams = filter(TeamNames.teams, (name) => {
            return name.startsWith(text);
        });

        if (teams.length > 5) {
            teams = teams.slice(0, 5);
        }

        resolve(teams);
    })
}