import {join} from "lodash";
import {each} from "lodash";
import {isFunction} from "lodash"
import {useEffect, useState} from "react";
import {FullScreenSelect, Select, SelectCategory, SelectOption} from "../generics/Forms"
import {SelectSheet} from "../generics/Tabs";
import Crest from "../team/Crest";
import {NewLeagueForm} from "./NewLeagueForm";
import PlusIcon from "mdi-react/PlusIcon";

const VALUE_CREATE_NEW_LEAGUE = "myscores_default_new_league_value";

export const LeagueSelect = ({
                                 leagues,
                                 onChange,
                                 onCreate,
                                 value
                             }) => {

    const [actualValue, setActualValue] = useState(null);
    const [leagueCreateModalOpen, setLeagueCreateModalOpen] = useState(false);

    useEffect(() => {
        if (value !== actualValue) {
            setActualValue(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (value) => {
        // if value is "create new league" then open new sheet to create league and set value 
        // to created league
        if (value === VALUE_CREATE_NEW_LEAGUE) {
            setLeagueCreateModalOpen(true);
        } else {
            callParentOnChange(value);
        }
    }

    const callParentOnChange = (value) => {
        if (isFunction(onChange)) {
            onChange.call(this, value);
        }
    }

    const handleAfterCreate = (data) => {
        if (isFunction(onCreate)) {
            onCreate.call(this, data[0]);
        }

        setLeagueCreateModalOpen(false);
    }

    const renderOptions = () => {
        let currentCountry = "";
        let items = [];

        items.push(<SelectOption value={VALUE_CREATE_NEW_LEAGUE}
                                 icon={<PlusIcon/>}
                                 content={"Add new league"}
                                 key={"unique_create_new_league_ms"}/>);

        each(leagues, (value, index) => {
            if (value.country !== currentCountry) {
                currentCountry = value.country;
                items.push(
                    <SelectCategory key={join(["cat", value.country, value.name, index])}>
                        <Crest club={value.country} isInternational={true}/>
                        <p>{value.country}</p>
                    </SelectCategory>
                );
            }

            items.push(
                <SelectOption value={join([value.country, value.name], ":")}
                              content={value.name}
                              key={join(value.country, value.name, index)}/>
            );
        })

        return items;
    }

    return (
        <>
            <FullScreenSelect label={"Choose competition"}
                              onChange={(v) => handleChange(v)}
                              value={actualValue}
                              hasCategories={true}>

                {renderOptions()}

            </FullScreenSelect>

            <SelectSheet title={"New competition"} isOpen={leagueCreateModalOpen}
                         onDismiss={() => setLeagueCreateModalOpen(false)}>
                <NewLeagueForm onCreate={(data) => handleAfterCreate(data)}/>
            </SelectSheet>
        </>
    )
}