import {config, storage} from "../config/constants";
import {getAccessTokenData} from "../service/oauthService";
import {isExpired} from "./token";

export const authorizeUser = async (redirectOffline = true, redirectNoSave = true) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    if (accessToken) {
        return await authorizeAccessToken(accessToken, redirectOffline, redirectNoSave);
    } else {
        if (redirectOffline) {
            window.location = config.BASE_URL;
        }
    }
}

const authorizeAccessToken = async (token, redirectOffline, redirectNoSave) => {
    let accessTokenExpireDate = localStorage.getItem(storage.ACCESS_TOKEN_EXPIRE_DATE);
    if (accessTokenExpireDate) {
        return await accessTokenChecks(token, accessTokenExpireDate, redirectOffline, redirectNoSave);
    } else {
        removeTokensFromStorage();
        if (redirectOffline) {
            window.location = config.BASE_URL;
        }
    }
}

const accessTokenChecks = async (token, accessTokenExpireDate, redirectOffline, redirectNoSave) => {
    if (!isExpired(accessTokenExpireDate)) {
        return await verifyAccessTokenViaApi(token, redirectOffline, redirectNoSave);
    } else {
        removeTokensFromStorage();
        if (redirectOffline) {
            window.location = config.BASE_URL;
        }
    }
}

const verifyAccessTokenViaApi = async (token, redirectOffline, redirectNoSave) => {
    try {
        let response = await getAccessTokenData(token);

        if (response.data.type && response.data.type === "error") {
            removeTokensFromStorage();
            if (redirectOffline) {
                window.location = config.BASE_URL;
            }
            return;
        }

        let saveId = localStorage.getItem(storage.SAVE_ID);
        if (!saveId) {
            if (redirectNoSave) {
                window.location = config.BASE_URL + "/save";
            }
            return {save: false};
        }

        //If we are on the offline page, but we are actually signed in
        //Redirect to the /overview
        if (!redirectOffline) {
            window.location = config.BASE_URL + "/overview";
            return;
        }

        return {online: true};
    } catch (err) {
        if (err.networkError) {
            // network error, do not remove tokens
            return {offline: true};
        } else {
            removeTokensFromStorage();
            window.location = config.BASE_URL;
        }
    }
}

const removeTokensFromStorage = () => {
    localStorage.removeItem(storage.ACCESS_TOKEN);
    localStorage.removeItem(storage.ACCESS_TOKEN_EXPIRE_DATE);
}