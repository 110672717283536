import {isFunction, map} from "lodash"
import {useState} from "react";
import {useHistory} from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor"
import {FeaturePreview} from "../feature/FeaturePreview";
import {Button, ButtonRow, DangerButton} from "../generics/Buttons";
import {CategoryCard} from "../generics/Cards";
import {Input} from "../generics/Forms";
import {DeletionModal, Modal} from "../generics/Modals";
import {translations} from "../../constants/translations.en.js";
import {ContentWrapper} from "../generics/Layout";

export const SingleManager = ({onClickEdit, manager}) => {

    const history = useHistory();

    const navigateToManagerProfile = () => {
        history.push(`/manager/${manager.id}`);
    }

    return (
        <CategoryCard editButton={true} onClickEdit={onClickEdit} onClick={navigateToManagerProfile}>
            <p>{manager.name}</p>
        </CategoryCard>
    )
}

export const ManagerList = ({data, onContinueLoading, onUpdate, onDelete}) => {

    const [updateModalShown, setUpdateModalShown] = useState(false);
    const [workingManager, setWorkingManager] = useState({});

    const [deleteModalShown, setDeleteModalShown] = useState(false);

    const showUpdateModal = (manager) => {
        setWorkingManager(manager);
        setUpdateModalShown(true);
    }

    const hideUpdateModal = () => {
        setUpdateModalShown(false);
        setWorkingManager({});
    }

    const toggleDeleteModal = () => {
        setDeleteModalShown(!deleteModalShown);
    }

    const handleUpdateChange = (value, field) => {
        let fields = {...workingManager};
        fields[field] = value;
        setWorkingManager(fields);
    }

    const doUpdate = () => {
        if (isFunction(onUpdate)) {
            onUpdate.call(this, workingManager);
            setUpdateModalShown(false);
        }
    }

    const doDelete = () => {
        if (isFunction(onDelete)) {
            onDelete.call(this, workingManager);
            setDeleteModalShown(false);
            hideUpdateModal();
        }
    }

    const visibilityChanged = (isVisible) => {
        if (isVisible) {
            if (isFunction(onContinueLoading)) {
                onContinueLoading.call(this);
            }
        }
    }

    const renderChildren = () => {
        return map(data, (value, index) => {
            return <SingleManager manager={value} key={index} onClickEdit={(() => showUpdateModal(value))}/>
        })
    }

    return (
        <ContentWrapper>
            <div className="managers-list">
                <FeaturePreview isShown={(data.length === 0)}
                                title={translations.features.NO_MANAGERS}
                                description={translations.features.MANAGER_DESC}>
                    {renderChildren()}
                </FeaturePreview>

                <ReactVisibilitySensor onChange={(isVisible) => visibilityChanged(isVisible)}>
                    <div className="sensor-load-more"></div>
                </ReactVisibilitySensor>

                <Modal isOpen={updateModalShown} onDismiss={hideUpdateModal}>
                    <h3>Edit manager</h3>

                    <Input type="text"
                           id="u-name"
                           label={"Manager name"}
                           value={workingManager.name}
                           max={120}
                           valueChange={value => handleUpdateChange(value, "name")}/>

                    <Input type="text"
                           id="u-country"
                           label={"Country of origin"}
                           max={120}
                           value={workingManager.country}
                           valueChange={value => handleUpdateChange(value, "country")}/>

                    <ButtonRow>
                        <DangerButton onClick={toggleDeleteModal}>
                            Delete
                        </DangerButton>

                        <Button onClick={doUpdate}>
                            Update
                        </Button>
                    </ButtonRow>
                </Modal>

                <DeletionModal isOpen={deleteModalShown}
                               onConfirm={doDelete}
                               onDismiss={() => setDeleteModalShown(false)}
                               title="delete this manager?"
                               text="You are about to delete this manager permanently. This action cannot be undone."
                />
            </div>
        </ContentWrapper>
    )
} 