import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const createStandingPositions = async (standingId, competitionId, positions) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            'standing_id': standingId,
            'competition_id': competitionId
        },
        'items': [
            ...positions
        ]
    }

    let response = await Axios({
        method: 'POST',
        url: join([
            config.API_URL,
            "/save/standing/positions"
        ], ""),
        headers,
        data
    });

    return response;
}

export const updateStandingPosition = async (positionNumber, standingId, position) => {
    let saveId = localStorage.getItem(storage.SAVE_ID);
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            'position_number': positionNumber,
            'standing_id': standingId,
            ...position
        }
    };

    let response = await Axios({
        method: 'PATCH',
        url: join([
            config.API_URL,
            "/save/standing/positions"
        ], ""),
        headers,
        data
    });

    return response;
}