import "./styles/text.css";
import { join } from "lodash";
import { each } from "lodash";
import { useEffect, useState } from "react";
import { storageSettings } from "../../config/constants";
import { Currencies } from "../../constants/currencies";
import { PlayerPriorities } from "../../constants/PlayerPriorities";
import { getRatingColor, getTextColor } from "../../data/colors";
import {asCurrency, asDecimal} from "../../data/number";

export const PlayerRating = ({rating}) => {

    return(
        <p className="rating" style={{color: getRatingColor(rating)}}>
            {asDecimal(rating, 2)}
        </p>
    )
}

export const TitleLabel = ({value}) => {
    return (
        <p className={"title-label"}>
            {value}
        </p>
    )
}

export const Label = ({value}) => {
    
    return (
        <p className="label">
            {value}
        </p>
    )
}

export const MonetaryAmount = ({value, className}) => {
    return(
        <p className={join(["monetary value", className], " ")}>
            <CurrencySymbol/> {asCurrency(value)}
        </p>
    )
}

export const Tag = ({color, background, value, className}) => {
    return(
        <div className={join(["tag", className], " ")} style={{
            color, background
        }}>
            <p>
                {value}
            </p>
        </div>
    )
}

export const PlayerPriority = ({priority, active = 1}) => {

    const [priorityData, setPriorityData] = useState({});

    useEffect(() => {
        let data;

        each(PlayerPriorities, (playerPriority) => {
            if (playerPriority.value === priority) {
                data = {...playerPriority};
            }
        })

        if (active === 0) {
            data.color = PlayerPriorities.INACTIVE.color;
        }
        
        setPriorityData({...data});
    }, [priority, active]);

    return(
        <div className="player-priority" style={{
            backgroundColor: priorityData.color,
            color: getTextColor(priorityData.color)
        }}>
            <p>
                {priority}
            </p>
        </div>
    )
}

export const ScoreLineText = ({homeTeamScore, awayTeamScore}) => {
    return(
        <div className="scoreline">
            <p className={(homeTeamScore > awayTeamScore) ? "winner" : ""}>{homeTeamScore}</p>
            <p>-</p>
            <p className={(awayTeamScore > homeTeamScore) ? "winner" : ""}>{awayTeamScore}</p>
        </div>
    )
}

export const ArrowRight = ({color}) => {
    return (
        <div className="crest">
            <div className="to-arrow" style={{borderLeftColor: color}}></div>
        </div>
    )
}

export const getCurrencySymbol = () => {
    if (localStorage.getItem(storageSettings.CURRENCY)) {
        let currency = localStorage.getItem(storageSettings.CURRENCY);

        return Currencies[currency].symbol;
    } else {
        return Currencies.EUR.symbol;
    }
}

export const CurrencySymbol = () => {

    const [symbol, setSymbol] = useState("");

    useEffect(() => {
        setSymbol(getCurrencySymbol());
    }, []);

    return (
        <>{symbol}</>
    )
}