export const MatchFactIcon = ({
    factType,
    time,
    style
}) => {

    return(
        <div className={`fact-icon ${factType}`} style={style}>
            <img src={process.env.PUBLIC_URL + "/resources/images/icons/" + factType + ".png"}
                 alt={"Icon of " + factType} />
            { time &&
                <span className="time">
                    {time}'
                </span>
            }
        </div>
    )
}
