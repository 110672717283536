import _ from 'lodash';

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const MONTHS_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

export const monthName = (monthNum, shortName = false, startsFromZero = false) => {
    return (shortName) ? MONTHS_SHORT[(startsFromZero) ? monthNum : monthNum - 1] : MONTHS[(startsFromZero) ? monthNum : monthNum - 1];
}

export const addLeadingZeroes = (num) => {
    if (num < 10) {
        return "0" + num;
    } else {
        return num;
    }
}

export const getSimpleDate = dateString => {
    if (!dateString) {
        return "";
    }

    let date = new Date(dateString);

    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = addLeadingZeroes(month);

    day = addLeadingZeroes(day);

    return _.join([
        date.getFullYear(),
        month,
        day
    ], "-");
}