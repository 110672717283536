import Axios from "axios";
import { join } from "lodash";
import { config, storage } from "../config/constants"

export const createTransfer = async (transferData) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'saveGame': saveId,
            ...transferData
        }
    };

    let response = await Axios({
        method: 'POST',
        url: config.API_URL + '/save/transfers',
        headers,
        data
    });

    return response;
}

export const updateTransfer = async (newValues) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        'properties': {
            'id': newValues.id,
            'saveGame': saveId,
            ...newValues
        }
    };

    let response = await Axios({
        method: 'PATCH',
        url: config.API_URL + "/save/transfers",
        headers,
        data
    });

    return response;
} 

export const deleteTransfer = async (transferIdToDelete) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'DELETE',
        headers,
        url: join([
            config.API_URL,
            '/save/transfers?saveGame=',
            saveId,
            '&id=',
            transferIdToDelete
        ], "")
    });

    return response;
}

export const getAllTransfers = async (limit, offset, orderBy = "id", order = "DESC") => {
    const saveId = localStorage.getItem(storage.SAVE_ID);

    return Axios({
        method: 'GET',
        url: `${config.API_URL}/save/transfers/all?saveGame=${saveId}&limit=${limit}&offset=${offset}&orderBy=${orderBy}&order=${order}`
    });
}

export const getTransfersFromTeam = async (teamName, page) =>  {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/transfers/team?saveGame=",
            saveId,
            "&team=",
            teamName,
            "&page=",
            page
        ], ""),
        headers
    });

    return response;
}

export const getTransfersFromPlayer = async (playerName) => {
    let accessToken = localStorage.getItem(storage.ACCESS_TOKEN);
    let saveId = localStorage.getItem(storage.SAVE_ID);

    let headers = {
        'Authorization': 'Bearer ' + accessToken
    };

    let response = await Axios({
        method: 'GET',
        url: join([
            config.API_URL,
            "/save/transfers/player?saveGame=", 
            saveId,
            "&player=", 
            playerName
        ], ""),
        headers
    });

    return response;
}