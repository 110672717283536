import {each, join} from "lodash";
import {useEffect, useState} from "react";
import {objectiveTypes} from "../../config/constants";
import {asCurrency} from "../../data/number";
import {HighlightCard, HighlightCardTitle} from "../generics/Cards";
import {SmoothProgressBar} from "../generics/Graphs";
import {GridRow} from "../generics/Layout";
import {getCurrencySymbol} from "../generics/Text";

export const TransferWindowHeader = ({
    transferWindow,
    transferObjectives
}) => {

    const [expectedRevenue, setExpectedRevenue] = useState(0);
    const [actualRevenue, setActualRevenue] = useState(0);
    const [actualExpenses, setActualExpenses] = useState(0);

    useEffect(() => {
        setExpectedRevenue(calcExpectedRevenueFromObjectives());
        setActualRevenue(calcActualRevenue());
        setActualExpenses(calcActualExpenses());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferObjectives]);

    const calcTotalExpenses = () => {
        let expenses = 0;

        each(transferObjectives, (objective) => {
            if (objective.objective_type === objectiveTypes.BUY) {
                expenses += objective.amount;
            }
        })

        return expenses;
    }

    const calcActualExpenses = () => {
        let expenses = 0;

        each(transferObjectives, (objective) => {
            if (objective.objective_type === objectiveTypes.BUY) {
                if (objective.extra_fields && objective.extra_fields.transfer) {
                    expenses += objective.extra_fields.transfer[0].cost;
                }
            }
        });
        return expenses;
    }

    const calcExpectedRevenueFromObjectives = () => {
        let revenue = 0;

        each(transferObjectives, (objective) => {
            if (objective.objective_type === objectiveTypes.SELL) {
                revenue += objective.amount;
            }
        });

        return revenue;
    }

    const calcActualRevenue = () => {
        let revenue = 0;

        each(transferObjectives, (objective) => {
            if (objective.objective_type === objectiveTypes.SELL) {
                if (objective.extra_fields && objective.extra_fields.transfer) {
                    revenue += objective.extra_fields.transfer[0].cost;
                }
            }
        });
        return revenue;
    }

    return (
        <div className="transfer-window-header">
            <h4>Overview</h4>
            <GridRow>
                <HighlightCard label={"Base budget"}>
                    <HighlightCardTitle value={getCurrencySymbol() + asCurrency(transferWindow.base_budget)} />

                    <SmoothProgressBar
                        total={transferWindow.base_budget}
                        amountLeft={calcTotalExpenses()}
                    />
                    <p>Expected budget used</p>

                    <SmoothProgressBar
                        total={transferWindow.base_budget}
                        amountRight={calcActualExpenses()}
                    />

                    <p>Actual budget used</p>
                </HighlightCard>
                <HighlightCard label={"Revenue"}>
                    <HighlightCardTitle value={getCurrencySymbol() + asCurrency(expectedRevenue)} />

                    <SmoothProgressBar
                        valueLabel={join([getCurrencySymbol(), asCurrency(actualRevenue), " of ", "€", asCurrency(expectedRevenue) ], "")}
                        total={expectedRevenue}
                        amountLeft={actualRevenue}/>
                    <p>Actual revenue made</p>
                </HighlightCard>
            </GridRow>

            <GridRow>
                <HighlightCard label={"Remaining Budget + Revenue"}>
                    <HighlightCardTitle value={getCurrencySymbol() + asCurrency(transferWindow.base_budget - actualExpenses + actualRevenue)} />

                    <p>Base budget - actual expenses + actual revenue</p>
                </HighlightCard>

                <HighlightCard label={"Net spend"}>
                    <HighlightCardTitle value={getCurrencySymbol() + asCurrency(actualRevenue - actualExpenses)}
                        color={((actualRevenue - actualExpenses) < 0) ? "var(--tag-negative-background)" : "var(--transfer-fee-color)"} />

                    <p>Actual revenue - actual expenses</p> 
                </HighlightCard>
            </GridRow>
        </div>
    )
}